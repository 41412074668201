import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styles from './userTeamsPreview.module.scss';
import { useTranslation } from 'react-i18next';
import { isObjectEmpty } from '../../../_utils';
import { modalActions } from '../../../_store/actions/modal.actions';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PlayerDisplay from '../../playerDisplay/playerDisplay';
import 'react-perfect-scrollbar/dist/css/styles.css';

const UserTeamsPreview = ({ rules, data, result }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [user, setUser] = useState({})
  const [headers, setHeaders] = useState([])
  const [players, setPlayers] = useState([])

  const openCPlayerInfo = (data) => {
    // dispatch(modalActions.openModal(<PlayerDisplay data={data} />, 'Teams Preview', 'md'));
  };

  const getPlayers = async () => {
    if (data && data.team) {
      const pointsMap = data.playerPoints || {}
      const playerMap = result.contest.playersMap
      const temp = []
      data.team.forEach(id => {
        const player = playerMap[id]
        const pointsData = pointsMap[id] || {}
        Object.keys(pointsData).forEach(key => pointsData[key.trim()] = pointsData[key])
        if (player) {
          temp.push({...player, result: pointsData})
        }
      })
      setPlayers(temp)
    }
  }

  useEffect(() => {
    const newHeaders = []
    if (rules && !isObjectEmpty(rules)) {
      Object.keys(rules).forEach(key => newHeaders.push(key.trim()))
    }
    setHeaders(newHeaders)
  }, [rules])

  useEffect(() => {
    getPlayers()
    if (data && data.user) {
      setUser(data.user)
    }
  }, [data])

  return (
    <div className={styles.wrapper}>
      <h4>{user.username}</h4>
      <PerfectScrollbar className="greenScroll">
        <div className={styles.tableCustom}>
          <div className={styles.tableHead}>
            <span className={`${styles.width10}`}>{t('d09094712')}</span>
            <span className={`${styles.width10}`}>{t('d2582874')}</span>
            {headers.map((head, i) =>
              <span key={i} className={`${styles.width10}`}>{t(head)}</span>
            )}
            <span className={`${styles.width10}`}>{t('d71123719')}</span>
          </div>
          {!isObjectEmpty(headers) &&
            <div className={styles.tableBody}>
              {players.map((player, i) =>
                <div key={`standings_player_${i}`} onClick={() => openCPlayerInfo(player)}>
                  <>
                    <span className={`${styles.width10} ${styles.colorGreen}`}>
                      {i + 1}
                    </span>
                    <span className={styles.width10}>{player.nick}</span>
                    {headers.map(key => <span key={`standings_action-${i}-${key}`}
                      className={styles.width10}>{player.result && player.result[key.trim()] ? player.result[key.trim()] : '-'}</span>)}
                    <span
                      className={`${styles.width10} ${styles.colorGreen}`}>{player.result && player.result.points ? player.result.points: '-'}</span>
                  </>
                </div>
              )}
            </div>
          }
        </div>
      </PerfectScrollbar>
    </div>
  )
}

export default UserTeamsPreview;