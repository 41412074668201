import { paymentConstants } from '../constants';
import paymentService from '../../_services/payment.service';
import storageService from '../../_services/storage.service';
import { userActions } from './user.actions';

const run = (mode, data = {}, query) => {
  if (mode === 'token') {
    const request = () => { return { type: paymentConstants.TOKEN_REQUEST }; };
    const success = (token) => { return { type: paymentConstants.TOKEN_SUCCESS, token }; };
    const failure = (error) => { return { type: paymentConstants.TOKEN_FAILURE, error }; };

    return dispatch => {
      dispatch(request());
      return paymentService.run(mode)
        .then(
          token => {
            dispatch(success(token));
          },
          error => {
            dispatch(failure(error));
          }
        );
    };
  }
  if (mode === 'stripe-charge') {
    const request = () => { return { type: paymentConstants.DEPOSIT_REQUEST }; };
    const success = (deposit) => { return { type: paymentConstants.DEPOSIT_SUCCESS, deposit }; };
    const failure = (error) => { return { type: paymentConstants.DEPOSIT_FAILURE, error }; };

    return dispatch => {
      dispatch(request());
      return paymentService.run(mode, data, query)
        .then(
          response => {
            dispatch(success(response));
            return response
          },
          error => {
            dispatch(failure(error));
          }
        );
    };
  }
  if (mode === 'paypal-check') {
    const request = () => { return { type: paymentConstants.DEPOSIT_REQUEST }; };
    const success = (deposit) => { return { type: paymentConstants.DEPOSIT_SUCCESS, deposit }; };
    const failure = (error) => { return { type: paymentConstants.DEPOSIT_FAILURE, error }; };

    return dispatch => {
      dispatch(request());
      return paymentService.run(mode, data, query)
        .then(
          response => {
            dispatch(success(response));
            return response
          },
          error => {
            dispatch(failure(error));
          }
        );
    };
  }
  if (mode === 'paypal-transaction') {
    const request = () => { return { type: paymentConstants.DEPOSIT_REQUEST }; };
    const success = (deposit) => { return { type: paymentConstants.DEPOSIT_SUCCESS, deposit }; };
    const failure = (error) => { return { type: paymentConstants.DEPOSIT_FAILURE, error }; };

    return dispatch => {
      dispatch(request());
      return paymentService.run(mode, data, query)
        .then(
          response => {
            dispatch(success(response));
            return response
          },
          error => {
            dispatch(failure(error));
          }
        );
    };
  }
  if (mode === 'deposit') {
    const request = () => { return { type: paymentConstants.DEPOSIT_REQUEST }; };
    const success = (deposit) => { return { type: paymentConstants.DEPOSIT_SUCCESS, deposit }; };
    const failure = (error) => { return { type: paymentConstants.DEPOSIT_FAILURE, error }; };

    return dispatch => {
      dispatch(request());
      return paymentService.run(mode, data, query)
        .then(
          response => {
            dispatch(success(response));
            dispatch(userActions.updateUser(response.user));
          },
          error => {
            dispatch(failure(error));
          }
        );
    };
  }
  if (mode === 'cashToVirtual') {
    const request = () => { return { type: paymentConstants.CONVERT_REQUEST }; };
    const success = (data) => { return { type: paymentConstants.CONVERT_SUCCESS, data }; };
    const failure = (error) => { return { type: paymentConstants.CONVERT_FAILURE, error }; };

    return dispatch => {
      dispatch(request());
      return paymentService.run(mode, data)
        .then(
          response => {
            dispatch(success(response));
            return response;
          },
          error => {
            dispatch(failure(error));
          }
        );
    };
  }
  if (mode === 'transactions') {
    const request = () => { return { type: paymentConstants.TRANSACTION_REQUEST }; };
    const success = (transactions) => { return { type: paymentConstants.TRANSACTION_SUCCESS, transactions }; };
    const failure = (error) => { return { type: paymentConstants.TRANSACTION_FAILURE, error }; };

    return dispatch => {
      dispatch(request());
      const fromStorage = storageService.getData('transactions');
      if (fromStorage) {
        dispatch(success(fromStorage));
      }
      return paymentService.run(mode, data)
        .then(
          res => {
            dispatch(success(res));
            storageService.setData('transactions', res);
          },
          error => {
            dispatch(failure(error));
          }
        );
    };
  }
  if (mode === 'withdraw') {
    const request = () => { return { type: paymentConstants.WITHDRAW_REQUEST }; };
    const success = (withdraw) => { return { type: paymentConstants.WITHDRAW_SUCCESS, withdraw }; };
    const failure = (error) => { return { type: paymentConstants.WITHDRAW_FAILURE, error }; };

    return dispatch => {
      dispatch(request());
      return paymentService.run(mode, data)
        .then(
          response => {
            dispatch(success(response));
            return response;
          },
          error => {
            dispatch(failure(error));
          }
        );
    };
  }
};

export const paymentActions = {
  run
};
