export const preparePlayerProps = (playerPropsData) => {
  return (
    playerPropsData?.playerProps?.map((item) => {
      return {
        ...item,
        name: playerPropsData.name,
        from: playerPropsData.from,
        playerPropsId: playerPropsData.id,
        match: item.match,
      };
    }) || []
  );
};

export const findContestWithPlayerProps = (contests) => {
  return contests?.find((contest) => !!contest.playerProps) || {};
};

export const sortPlayerProps = (playerProps = []) => {
  return playerProps.sort((a, b) => {
    if (a.boosted === b.boosted) {
      return a.from > b.from ? 1 : -1;
    }
    return a.boosted ? -1 : 1;
  })
};

export const filterPlayerPropsByGame = (
  playerProps = [],
  selectedGames = {}
) => {
  if (!Object.keys(selectedGames).length) return playerProps;
  return playerProps.filter((playerProp) => selectedGames[playerProp.game]);
};
