import React, { useState, useEffect } from 'react';
import accounting from 'accounting-js';
import styles from './eventLineup.module.scss';
import LineupElement from '../lineupElement/lineupElement';
import Checkbox from '../checkbox/checkbox';
import Button from '../button/button';
import ProgressBar from '../progressBar/progressBar';
import SmallTitle from '../smallTitle/smallTitle';
import toastService from '../../_services/toastService';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import storageService from '../../_services/storage.service';
import { isObjectEmpty } from '../../_utils';
import { useHistory } from 'react-router-dom';
import Input from '../input/input';
const sortArr = (data) => {
  return data.sort((a, b) => (a.tier > b.tier) ? 1 : ((b.tier > a.tier) ? -1 : 0));
};

const defaultAmount = 1

// d12792751 Captain
// d85876432  Salary cap
// d09094712 Tier
const EventLineup = ({ isUpdate, selectedData, contest = {}, tiers = [], onEnter, selectedEditData = [], contestType, compare }) => {
  const store = useSelector(state => state);
  const [data, setData] = useState([]);
  const [saving, setSaving] = useState(false);
  const [cap, setCap] = useState(0);
  const history = useHistory();
  const [captainToDraft, setCaptainToDraft] = useState((contest && contest.game && contest.game.captainToDraft) ? contest.game.captainToDraft : 0);
  const [playersToDraft, setPlayersToDraft] = useState((contest && contest.game && contest.game.playersToDraft) ? contest.game.playersToDraft : 0);
  const [t] = useTranslation();
  const [wallet] = useState(storageService.getData('transactions', 'wallet', 'get', { id: store.auth.user ? store.auth.user.id : '-1'}) || {});
  const [paymentMetod, setPaymentMetod] = useState(contest.subtype === 'beatThePro' ? 'cash' : (contest.entryFee === 0 ? 'cash' : (contest.entryCreditFee === 0 ? 'credit' : '')));
  const [amount, setAmount] = useState(defaultAmount.toFixed(2));
  const onPaymentChange = (e) => {
    const { value } = e.target;
    setPaymentMetod(value);
  };
  useEffect(() => {
    setData(selectedEditData);
    setCap(selectedEditData.reduce((sum, data) => sum + (data && data.player && data.player.salary ? data.player.salary : 0), 0));
  }, [selectedEditData]);

  useEffect(() => {
    if (contest && contest.game && contest.game.captainToDraft) {
      setCaptainToDraft(contest.game.captainToDraft)
    }
  }, [contest.game.captainToDraft]);
  useEffect(() => {
    if (contest && contest.game && contest.game.playersToDraft) {
      setPlayersToDraft(contest.game.playersToDraft)
    }
  }, [contest.game.playersToDraft]);

  useEffect(() => {
    if (!isObjectEmpty(selectedData)) {
      updateArr(selectedData);
    }
  }, [selectedData]);
  const updateArr = (el) => {
    const found = data.find(temp => {
      if (contestType === 'd09094712') {
        return JSON.parse(temp.tier) === JSON.parse(el.tier);
      } else if (contestType === 'd85876432') {
        return temp.player.id === el.player.id;
      } else if (contestType === 'd12792751') {
        if (el.tier == 0) {
          const selectedCaptains = data.filter(el => el.tier == 0);
          if (selectedCaptains.length < (+captainToDraft)) {
            return temp.player.id === el.player.id;
          } else {
            return JSON.parse(temp.tier) === JSON.parse(el.tier);
          }
        } else {
          return temp.player.id === el.player.id;
        }
      }
    });
    let newData = [...data];
    if (found) {
      newData.splice(data.indexOf(found), 1, el);
    } else {
      if (contestType === 'd09094712' || (contestType === 'd85876432' && cap <= contest.cap && data.length < +playersToDraft)) {
          newData = sortArr([...data, { ...el }]);
      } else if (contestType === 'd85876432' && (data.length >= +playersToDraft ||  cap > contest.cap)) {
        newData.splice(data.indexOf(found), 1, el)
      } else {
        if ((contestType === 'd12792751' && data.length >= ((+playersToDraft) + (+captainToDraft))) || (cap > contest.cap)) {
          newData.splice(data.indexOf(found), 1, el);
        } else {
          newData = sortArr([...data, { ...el }]);
        }
      }
    }
    setData(newData);
    compare(newData)
    if (contestType === 'd12792751') {
      if (el.tier != 0) {
        setCap(newData.reduce((sum, data) => sum + (data && data.player && data.player.salary ? data.player.salary : 0), 0));
      }
    } else {
      setCap(newData.reduce((sum, data) => sum + (data && data.player && data.player.salary ? data.player.salary : 0), 0));
    }
  };

  const removeElement = (el) => {
    const found = data.find(temp => {
      return temp.player.id === el.player.id;
    });
    if (found) {
      const newData = [...data];
      newData.splice(data.indexOf(found), 1);
      setData(sortArr(newData));
      compare(sortArr(newData))
      if (contestType === 'd12792751') {
        if (el.tier != 0) {
          setCap(newData.reduce((sum, data) => sum + (data && data.player && data.player.salary ? data.player.salary : 0), 0));
        }
      } else {
        setCap(newData.reduce((sum, data) => sum + (data && data.player && data.player.salary ? data.player.salary : 0), 0));
      }
    }
  };

  const emptyContainers = [];
  if (tiers && tiers.length && contestType === 'd09094712') {
    for (let i = 1; i <= (tiers.length - data.length); i++) {
      emptyContainers.push(i);
    }
  } else {
    if ((contest.cap - cap > 0 && cap <= contest.cap)) {
      if (contestType === 'd12792751') {
        if (data.length < ((+playersToDraft) + (+captainToDraft))) {
          emptyContainers.push(1);
        }
      } else if (contestType === 'd85876432') {
        if (data.length < (+playersToDraft)) {
          emptyContainers.push(1);
        }
      }
    }
  }

  const handleAmountChange = (e) => {
    let { value } = e.target;

    // remove not a number carracter
    if (isNaN(value)) {
      value = value.replace(/[^0-9\.]/g, "");
      if (value.split(".").length > 2) {
        value = value.replace(/\.+$/, "");
      }
    }
    // remove all caracters after second decimal
    if (
      Array.isArray(value.split(".")) &&
      value.split(".").length === 2 &&
      value.split(".")[1].length > 2
    ) {
      value = value.replace(/^(\d+.?\d{0,2})\d*$/, "$1");
    }

    setAmount(value);
  };

  const handleSubmit = async () => {
    if (contestType === 'd09094712' && tiers.length > data.length) {
      // Tier "You need to select one player from every tier"

      toastService.show('error', t('d54125228'));
    } else if ((contestType === 'd85876432' && data.length < 1) || (contestType === 'd12792751' && data.length < 1)) {
      // Salary cap and Captain  "You must select players",
      toastService.show('error', t('d3158695'));
    } else if (contestType === 'd12792751' && (data.filter(el => el.tier == 0).length < +captainToDraft)) {
      // Captain You must select the minimum required captains
      toastService.show('error', t('d78384647'));
    } else if (contestType === 'd12792751' && (data.filter(el => el.tier == 1).length < (+playersToDraft))) {
      // Captain You must select the minimum required players
      toastService.show('error', t('d81547419'));
    } else if (contestType === 'd85876432' && data.length < (+playersToDraft)) {
      // Sallary cap You must select the minimum required players
      toastService.show('error', t('d81547419'));
    } else if ((contestType === 'd85876432' || contestType === 'd12792751') && contest.cap - cap < 0) {
      // Salary cap and Captain" You have exceeded the salary cap. Customize your chosen team to not go over salary cap.",
      toastService.show('error', t('d97068425'));
    } else if (paymentMetod === '' && selectedEditData.length < 1 && !history.location.pathname.includes('influencer')) {
      // "Please select a payment method"
      toastService.show('error', t('d38293220'));
    } else {
      if (paymentMetod === 'cash') {
        if (!wallet || (wallet.amount < contest.entryFee && selectedEditData.length < 1)) {
          // "You do not have enough cash"
          toastService.show('error', t('d5604941'));
        } else {
          data.entryFee = Number(amount);
          setSaving(true)
          await onEnter(data, paymentMetod);
          setSaving(false)
        }
      } else {
        if (!wallet || (wallet.credit < contest.entryCreditFee && selectedEditData.length < 1)) {
          // "You do not have enough credit",
          toastService.show('error', t('d56577928'));
        } else {
          setSaving(true)
          data.entryCreditFee = Number(amount);
          await onEnter(data, paymentMetod);
          setSaving(false)
        }
      }

    }
  };

  return (
    <div className={styles.container}>
        <div className={styles.finish}>
          {/* {contest && (contest.from > new Date().getTime()) &&
            <div style={{display:"flex", width:"50%", alignItems: 'center'}}>
              {contest.subtype !== 'beatThePro' && (contest.entryFee && contest.entryFee !== 0) && selectedEditData.length < 1 && !history.location.pathname.includes('influencer') ? <Checkbox checkboxClass="checkboxInputClass" name="paymentMetod" value="cash" isSelected={paymentMetod === 'cash'} onChange={onPaymentChange} label={t('d96275128')} /> : null}
            <div style={{width:"10%"}}></div>
            {contest.subtype !== 'beatThePro' && (contest.entryFee && contest.entryFee !== 0) && selectedEditData.length < 1 && !history.location.pathname.includes('influencer') ? <Checkbox checkboxClass="checkboxInputClass" name="paymentMetod" value="credit" isSelected={paymentMetod === 'credit'} onChange={onPaymentChange} label={t('d96603721')} /> : null}
          </div>} */}
          {contest && (contest.from > new Date().getTime()) &&
        <div style={{display:"flex", alignItems:"center"}} className={styles.amountAndCheckboxWrapper}>
          {contest.team && selectedEditData.length === 0 ? <div className={styles.amountWrapper}><span>$</span><Input type='text' min='1' name='amount' onChange={(e) => handleAmountChange(e)} value={amount} placeholder={t('d56807038')} label={t('d56807038')} customClass="amountAlignStart" /></div> : null }
          {contest.subtype !== 'beatThePro' && (contest.entryFee && contest.entryFee !== 0) && selectedEditData.length < 1 && !history.location.pathname.includes('influencer') ? <Checkbox checkboxClass="checkboxInputClass" name="paymentMetod" value="cash" isSelected={paymentMetod === 'cash'} onChange={onPaymentChange} label={t('d96275128')} /> : null }
          <div style={{width:"10%"}}></div>
          {contest.subtype !== 'beatThePro' && (contest.entryFee && contest.entryFee !== 0) && selectedEditData.length < 1 && !history.location.pathname.includes('influencer') ? <Checkbox checkboxClass="checkboxInputClass" name="paymentMetod" value="credit" isSelected={paymentMetod === 'credit'} onChange={onPaymentChange} label={t('d96603721')} /> : null}
        </div>}
          <Button label={t(contest && contest.userContest && contest.userContest.id && isUpdate ? 'd70225352' : 'd30907702')}
              btnClass="btnPrimary"
              type="submit"
              loading={saving}
              onClick={handleSubmit}
              disabled={saving ? true : false}/>
      </div>
      <div className={styles.header}>
        <div className={styles.yourLineup}>
          <hr /><SmallTitle title={t('d97393144') + ":" } ></SmallTitle><hr />
        </div>
        {/* {contest && (contest.from > new Date().getTime()) &&
        <div style={{display:"flex", width:"50%", flexDirection:"row-reverse"}}>
          {contest.team && selectedEditData.length === 0 && <Input type='number' min='1' name='amount' onChange={(e) => handleAmountChange(e)} value={amount} placeholder={t('d56807038')} label={t('d56807038')} customClass="amount"/> }
          {contest.subtype !== 'beatThePro' && (contest.entryFee && contest.entryFee !== 0) && selectedEditData.length < 1 && !history.location.pathname.includes('influencer') && <Checkbox checkboxClass="checkboxInputClass" name="paymentMetod" value="cash" isSelected={paymentMetod === 'cash'} onChange={onPaymentChange} label={t('d96275128')} />}
          <div style={{width:"10%"}}></div>
          {contest.subtype !== 'beatThePro' && (contest.entryFee && contest.entryFee !== 0) && selectedEditData.length < 1 && !history.location.pathname.includes('influencer') && <Checkbox checkboxClass="checkboxInputClass" name="paymentMetod" value="credit" isSelected={paymentMetod === 'credit'} onChange={onPaymentChange} label={t('d96603721')} />}
        </div>} */}
      </div>
      {/* {(contestType === 'd85876432' || contestType === 'd12792751') && <ProgressBar done={'' + ((cap / contest.cap) * 100)} />} */}
      {(contestType === 'd85876432' || contestType === 'd12792751') && <div className={styles.balance}>
        <div className={styles.available}>
          <span>{t('d85226264')} </span>
          {contest.cap - cap >= 0 ? (
            <h4>{accounting.formatMoney(contest.cap - cap)}</h4>
          ) :
            (
              <h6>{t('d70524567')}</h6>
            )}
        </div>
        <div className={styles.total}>
          <span>{t('d97917174')}</span>
          <h4>{accounting.formatMoney(contest.cap - (contest.cap - cap))}</h4>
        </div>
        <div className={styles.salaryCap}>
          <span>{t('d85876432')}</span>
          <h4>{accounting.formatMoney(contest.cap)}</h4>
        </div>
      </div>}
      {(contestType === 'd85876432' || contestType === 'd12792751') && <ProgressBar done={'' + ((cap / contest.cap) * 100)} />}
      <div id={styles.lineup}>
      {data && data.length ? <>
        {data.map(el => {
          return (
            <LineupElement element={el} key={'player_id_' + el.player.id} remove={removeElement} contestType={contestType} contest={contest} />
          );
        })}
      </> : null}
      {emptyContainers.map(el => {
        return (
          <div className={styles.empty} key={el}>
            <span>{t('d2582874')}{t('d74624411')} {el + data.length}</span>
          </div>
        );
      })}
      </div>
      <div style={{width:"100%", textAlign:"right"}}>
        {contestType === 'd09094712' && <small className={styles.msgPlayers}>{t('d73543825')}. </small>}
        {contestType === 'd85876432' && <small className={styles.msgPlayers}>{t('d71211885')} {playersToDraft || 0}.</small>}
        {contestType === 'd12792751' && <small className={styles.msgPlayers}>{t('d71211885')} {playersToDraft || 0}. {t('d71964352')} {captainToDraft}. {t('d97917174')} {+playersToDraft + (+captainToDraft)}.</small>}
      </div>
      {/* {contest && (contest.from > new Date().getTime()) &&
        <div className={styles.options}>
          {selectedEditData.length < 1 && !history.location.pathname.includes('influencer') && <Checkbox checkboxClass="checkboxInputClass" name="paymentMetod" value="cash" isSelected={paymentMetod === 'cash'} onChange={onPaymentChange} label={t('d96275128')} />}
          {selectedEditData.length < 1 && !history.location.pathname.includes('influencer') && <Checkbox checkboxClass="checkboxInputClass" name="paymentMetod" value="credit" isSelected={paymentMetod === 'credit'} onChange={onPaymentChange} label={t('d96603721')} />}
          {contest.team && selectedEditData.length === 0 && <Input type='number' min='1' name='amount' onChange={(e) => handleAmountChange(e)} value={amount} placeholder={t('d56807038')} label={t('d56807038')} customClass="amount"/> }
          <div>
            <Button label={t(isUpdate ? 'd70225352' : 'd28344393')}
              btnClass="btnPrimary"
              type="submit"
              loading={saving}
              onClick={handleSubmit}
              disabled={saving ? true : false}
            />
          </div>
        </div>} */}
    </div>
  );
};

export default EventLineup;
