import React from 'react';
import PageHeading from '../../../_components/pageHeading/pageHeading';
import styles from './withdrawPolicy.module.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const WithdrawPolicy = () => {
    const [t] = useTranslation();

    const withdrawalTips = [
        {
            id: 1,
            text:<p>{t('d07215938')}</p>
        },
        {
            id: 2,
            text:<p>{t('d81904492')}</p>
        },
        {
            id: 3,
            text:<p>{t('d08074612')}</p>
        },
        {
            id: 4,
            text:<p>{t('d08453185')}</p>
        },
        {
            id: 5,
            text:<p> {t('d08813449')} <Link className={styles.link} to="/contact"> {t('d40913853')} </Link> {t('d09725536')}</p>
        },
        {
            id: 6,
            text:<p>{t('d10281799')}</p>
        },
    ];

    const withdrawalInfo = [
        {
            id: 1,
            text: <p> {t('d16284311')} $100 {t('d16644201')}</p>
        },
        {
            id: 2,
            text: <p>{t('d13035361')}</p>
        }
    ]
    return (
        <div className="container-wrapper">
            <PageHeading heading={t('d91514961')} subheading={t('d29083902')} />
            <div className={styles.wrapper}>
                <h4> {t('d11013002')} </h4>
                {withdrawalTips.map(item => (
                    <div className={styles.content} key={item.id}>
                        <span>
                            {item.text}
                        </span>
                    </div>
                ))}
                <h4> {t('d12163520')} </h4>
                {withdrawalInfo.map(item => (
                    <div className={styles.content} key={item.id}>
                        <span>
                            {item.text}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default WithdrawPolicy;