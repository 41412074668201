import { profileConstants } from '../constants';
import profileService from '../../_services/profile.service';
import storageService from '../../_services/storage.service';
import { modalActions } from './modal.actions';
import { userActions } from './user.actions';
import toastService from '../../_services/toastService';

const TYPE = 'profile';

const run = (mode, data = {}, message) => {
  const request = () => { return { type: profileConstants.PROFILE_REQUEST }; };
  const success = (result) => { return { type: profileConstants.PROFILE_SUCCESS, result, mode }; };
  const failure = (error) => { return { type: profileConstants.PROFILE_FAILURE, error }; };

  return dispatch => {
    dispatch(request());
    // If we want to get data we check first in storage
    if (mode === 'all') {
      const fromStorage = storageService.getData(TYPE);
      if (fromStorage) {
        dispatch(success(fromStorage));
      }
    }
    delete data.loading;

    return profileService.run(mode, data, message)
      .then(
        result => {
          dispatch(success(result));
          if (message) toastService.show('success', message);
          if (mode === 'all') {
            storageService.setData(TYPE, result);
          } else if (mode === 'update') {
            dispatch(modalActions.closeModal());
            dispatch(userActions.updateUser(result));
          }
          return result;
        },
        error => {
          dispatch(failure(error));
        }
      );
  };
};

export const profileActions = {
  run
};
