import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ModalWrapper from "../_components/modal/modal";
import Footer from "../_components/footer/footer";
import Copyright from '../_components/copyright/copyright';
import { sessionService } from "../_sessionService/storage";
import storageService from "../_services/storage.service";
import withImages from '../_components/core/withImages';
import Loading from '../_components/Loading';
import { useLocation } from "react-router-dom";
import { routesActions } from '../_store/actions/routes.actions';
import referralBg from '../_assets/images/backgrounds/referral_bg.png';

const MainWrapper = ({ children, acquireImages, images, currentPage }) => {
  const store = useSelector(state => state);
  const location = useLocation();
  const dispatch = useDispatch();
  const [image, setImage] = useState(sessionService.getImg('app_cover'));
  const [ready, setReady] = useState(false);

  useEffect(() => {
    dispatch(routesActions.getRoutesHistory({ path: location.pathname, name: currentPage }));
    if (location.pathname === '/lobby' && location.search) storageService.setRef(location.search)
  }, [location && location.pathname]);

  useEffect(() => {
    if (store.screen.width) {
      acquireImages([
        { name: 'app_cover', width: store.screen.width > 1800 ? 1800 : store.screen.width, height: store.screen.height }
      ]);
    }
  }, [store.screen.width]);

  useEffect(() => {
    if (images[`app_cover`]) {
      setImage(images[`app_cover`])
    }
  }, [images[`app_cover`], store.screen.width])


  useEffect(() => {
    if (store.language && store.language.lang) {
      setReady(!!store.language.lang)
    }
  }, [store.language]);

  return (
    <div className={`main-wrapper ${currentPage}`}>
      {store.modal.show ? <ModalWrapper show={store.modal.show} content={store.modal.content} size={store.modal.size} closeOnOutsideClick={store.modal.closeOnOutsideClick} showCloseIcon={store.modal.showCloseIcon}  /> : null}
      <div className="app-body">
        {location.pathname === '/referral' ? (<div className="referral-background" style={{ backgroundImage: `url("${referralBg}")` }}></div>) : (<div className="app-background" style={{ backgroundImage: `url("${image}")` }}></div>)}
        <div className="page-content">
          {ready ? children : <Loading />}
        </div>
      </div>
      {location.pathname === '/contact' ? (
        <Footer ready={ready} />
      ) :
        !location.pathname.includes('influencer') && <footer>
          <Copyright ready={ready} />
        </footer>

      }
    </div>
  );
};

export default withImages(MainWrapper);
