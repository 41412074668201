import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PageHeading from '../../_components/pageHeading/pageHeading';
import Button from '../../_components/button/button';
import settingsIcon from '../../_assets/images/icons/settingsIcon.svg';
import { modalActions } from '../../_store/actions/modal.actions';
import ProfileModal from './profileModal';
import AvatarModal from './avatarModal';
import PasswordModal from './passwordModal';
import withImages from '../../_components/core/withImages';
import avatarDefault from '../../_assets/images/avatar.png';
import styles from './profile.module.scss';
import storageService from '../../_services/storage.service'
import VerifyAccount from './verifyAccount'
import { mainActions } from '../../_store/actions/main.actions';
import { sessionService } from '../../_sessionService/storage';
import { getPipeline } from '../../_utils';
import back from '../../_assets/images/icons/back6489.svg';
import { useHistory } from 'react-router-dom';
import accounting from 'accounting-js';
import mainService from '../../_services/main.service';
// import { combineReducers } from 'redux';

const Profile = ({ acquireImages, images }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useSelector(state => state);
  const [setWallet] = useState(storageService.getData('transactions', 'wallet', 'get', { id: store.auth.user.id }));
  const refFileInput = useRef(null);
  const [user, setUser] = useState(store.auth.user);
  const [gamesPlayed, setGamesPlayed] = useState(0);
  const [won, setWon] = useState(0);
  const [totalPrize, setTotalPrize] = useState(0);
  const [totalFTPS, setTotalFTPS] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [avatarSrc, setAvatarSrc] = useState(sessionService.getImg(`${store.auth.user.id}_avatar`));
  const openEditModal = () => {
    dispatch(modalActions.openModal(<ProfileModal />, 'Edit profile modal', 'md'));
  };
  const openPasswordModal = () => {
    dispatch(modalActions.openModal(<PasswordModal />, 'Edit password modal', 'md'));
  };
  const openAvatarModal = (e) => {
 
    const file = e.target.files[0];
    dispatch(modalActions.openModal(<AvatarModal id={user.id} avatarSrc={file} />, 'Change Profile Picture', 'md'));

  };

  const fetch = async () => {
    const res = await mainService.run('transactions', "user", 'all', {
      filter: { userId: store.auth.user.id, transactionType: {$in: ["ENTRY_FEE", "WIN"]} }
    })
    if (res) {
      let win = 0, played = 0, prize = 0;
      for (let element of res) {
        if (element.transactionType === "ENTRY_FEE") {
          played++;
        } else {
          win++;
          prize+= element.amount;
        }
      }
      if (win > 0) {
        setWon(win);
      }
      if (played > 0) {
        setGamesPlayed(played);
      }
      if (prize > 0) {
        setTotalPrize(prize);
      }
      if (win > 0 && played > 0) {
        setPercentage((win/played)*100);
      }
    }
  }

  useEffect(() => {
    if (store && store.auth && store.auth.user) {
      fetch();
    }
  }, []);

  useEffect(() => { 
    dispatch(mainActions.agg('wallet', 'transactions', 'user', 'get', getPipeline('wallet', user.id), user.id));
  }, []);

  useEffect(() => {
    if (store.main.wallet && store.main.wallet && store.main.wallet.data && store.main.wallet.data[store.auth.user.id]) {
      const userWallet = store.main.wallet.data[store.auth.user.id];
      setWallet(userWallet)
    }
  }, [store.main.wallet && store.main.wallet && store.main.wallet.data]);

  useEffect(() => {
    acquireImages([
      { name: `${store.auth.user.id}_avatar`, width: 400, height: 400 }
    ]);
    setUser(store.auth.user);
  }, [store.auth.user]);

  useEffect(() => {
    setAvatarSrc(sessionService.getImg(`${store.auth.user.id}_avatar`) ? sessionService.getImg(`${store.auth.user.id}_avatar`) : images[`${store.auth.user.id}_avatar`]);
  }, [images[`${store.auth.user.id}_avatar`]]);

  // useEffect(() => {
  //   setProfile(store.profile.data);
  // }, [store.profile && store.profile.data]);

  const triggerUploadBtn = () => {
    refFileInput.current.click();
  };

  const returnBack = () => {
    history.goBack();
  }

  return (
    <div className="container-wrapper-fluid">
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <img src={back} alt='back' onClick={returnBack}/>
          <Button label={t('d60155617')}
                btnClass="btnPrimary" onClick={openPasswordModal}/>
        </div>
        <div className={styles.info}>
          <div style={{width:"40%"}}>
            <div className={styles.profileImg}>
              <img style={{height:"100px", width:"100px"}} src={avatarSrc ? avatarSrc : avatarDefault} alt='profile-img' onClick={triggerUploadBtn} />
              <div className={styles.profileFile}>
                <small onClick={triggerUploadBtn}>{t('d61897085')}</small>
                <input type="file" style={{ visibility: 'hidden' }} ref={refFileInput} onChange={openAvatarModal} />
              </div>
            </div>
            <div className={styles.text}>
              <small onClick={openEditModal}>{t('d08017694')}</small>
              <h2>{user.firstName ? (user.firstName + ' ' + (user.lastName ? user.lastName : '')) : user.username}</h2>
              <h5>{user.email}</h5>
              {/* <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</h5> */}
            </div>
          </div>
          <div className={styles.percentageWrapper}>
            <div className={styles.rating} style={{background: `conic-gradient(#33435B ${100 - percentage}%, #00c84a 0 100%)`}}>
              <div className={styles.percentage}><span>{parseFloat(percentage).toFixed(0)}%</span><p>{t("d70724403")}</p></div>
            </div>
          </div>
          <div className={styles.tableWrapper} style={{height: '100px', minWidth: "35%"}}>
              <table>
                <tbody>
                <tr>
                  <th>{t("d06491909")}</th>
                  <th>{t("d87474551")}</th>
                  <th>{t("d07327401")}</th>
                </tr>
                <tr>
                  <td>{gamesPlayed}</td>
                  <td>{won}</td>
                  <td>{accounting.formatMoney(totalPrize)}</td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
        <hr />
        <div className={styles.profileWrapper}>
          <VerifyAccount userId={store.auth.user.id}/>
          {/*<div className={styles.ratingWrapper}>*/}

          {/*<div className={styles.container}>*/}
          {/*<img src={educationIcon} alt="trophy-icon" />*/}
          {/*<div className={styles.text}>*/}
          {/*<h4>{t('d89712856')}</h4>*/}
          {/*<img src={educationIcon} alt="trophy-icon" />*/}
          {/*<h4>{user.ranking ? user.ranking : 0}</h4>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*<div className={styles.container}>*/}
          {/*<img src={dollarIcon} alt="dollar-icon" />*/}
          {/*<div className={styles.text}>*/}
          {/*<h4>{t('d90861476')}</h4>*/}
          {/*<img src={dollarIcon} alt="dollar-icon" />*/}

          {/*<h4>{user.totalWin ? accounting.formatMoney(user.totalWin) : accounting.formatMoney(0)}</h4>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*<div className={styles.container}>*/}
          {/*<img src={diceIcon} alt="dice-icon" />*/}
          {/*<div className={styles.text}>*/}
          {/*<h4>{t('d91501019')}</h4>*/}
          {/*<img src={diceIcon} alt="dice-icon" />*/}
          {/*<h4>{user.totalPlayed ? user.totalPlayed : 0}</h4>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};

export default withImages(Profile);
