/**
 * Created by laslo on 9/17/20.
 */

export const DATE_FORMAT = "MMMM d, yyyy h:mm aa";
export const DATE_FORMAT_NO_TIME = "MMMM d, yyyy";

export const LIVE = "d79567838";
export const UPCOMING = "d80132619";
export const HISTORY = "d321672";

export const TRANSACTIONS = {
  TYPES: {
    WIN: "WIN",
  },
};

export const CONTEST_PLAYER_PROPS = {
  BET_TYPES: {
    OVER: "over",
    UNDER: "under",
  },
};
