import React, { useState, useEffect } from 'react';
import mainService from '../../../_services/main.service';
import styles from './activityOverall.module.scss';
import { useTranslation } from 'react-i18next';
import DatePickerComp from '../../../_components/datePicker/datePickerComp';
import Button from '../../../_components/button/button';
import Select from '../../../_components/selector/selector';
import Input from '../../../_components/input/input';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CustomPagination from '../../../_components/pagination/serverPagination';
import moment from 'moment';
import * as _ from 'lodash'
import { ExportToCsv } from 'export-to-csv';

const TYPES = {
  "user_registered": "Registered",
  "user_login": "Logged in",
  "user_forgot_password": "Forgot Password Clicked",
  "user_forgot_password_changed": "Forgot Password Changed",
  "user_password_changed": "Changed Password",
  "user_invited": "Invited User",
  "user_won_contest": "Won Contest",
  "user_won_player_props": "Won Player Props",
  "user_entered_contest": "Entered Contest",
  "user_entered_playerProps": "Entered Player Props",
  "user_canceled_playerProps": "Canceled Player Props",
  "user_document_uploaded": "Uploaded Document",
  "user_document_deleted": "Deleted Document",
  "user_approving": "Approved User",
  "user_active": "Activated User",
  "user_amount_corrected": "Corrected User Funds",
  "user_deposited": "Deposited",
  "user_withdraw_request": "Withdraw request",
  "user_returned": "User Returned"
}

const _getType = (type) => {
  return TYPES[type] || type
}

const OPTIONS = Object.keys(TYPES).map(key => ({ label: TYPES[key], value: key }))

const ActivityDetails = ({ hidden }) => {
  const [result, setResult] = useState([]);
  const [from, setFrom] = useState(moment().startOf('month').toDate());
  const [to, setTo] = useState(moment().endOf('month').toDate());
  const [text, setText] = useState('');
  const [types, setTypes] = useState();
  const [total, setTotal] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [previousFilter, setPreviousFilter] = useState(null)
  const [t] = useTranslation();

  useEffect(() => {
    getData();
  }, [pageNumber, pageSize]);

  const handleSelectChange = (e) => {
    if (e && e.length > 0) {
      setTypes(e.map(one => one.value))
    } else {
      setTypes(null)
    }
  }

  const setDate = (type, date) => {
    if (type === 'from'){
      setFrom(moment(date).startOf('day').toDate())
    } else if (type === 'to'){
      setTo(moment(date).endOf('day').toDate())
    }
  }

  const handleTextChange = (e) => {
    e.preventDefault()
    const { value } = e.target;
    setText(value)
  }

  const getData = async (isExport) => {
    setResult(null)
    const filter = { createdAt: { $gte: from.getTime(), $lte: to.getTime() } }
    if (text && text.trim()) {
      filter['$or'] = [{ username: {'$regex' : `^${text.trim()}`, '$options' : 'i'} }, { email: {'$regex' : `^${text.trim()}`, '$options' : 'i'} }]
    }
    if (types && types.length > 0){
      filter.type = { '$in' : types }
    }
    let page = pageNumber
    if (!_.isEqual(filter, previousFilter)) {
      if (pageNumber != 1) {
        setPageNumber(1)
        return
      }
    }
    const data = await mainService.run('activities', 'user', isExport ? 'export' : 'details', { filter, types, pageNumber, pageSize, sort: { createdAt: -1 } } );
    setPreviousFilter(filter)
    if (!isExport) {
      setTotal(data.total)
      setResult(data.rows)
    }
    return data
  }

  const onPageChange = async (page, pageSize) => {
    setPageNumber(page);
    setPageSize(pageSize);
  };

  const exportData = async () => {
    let headers = ['#', 'Date', 'Username', 'Email', 'Activity', 'Data']
    let data = [headers]

    const exportResult = await getData(true)

    exportResult.forEach((t,i) => {
      data.push([i+1, moment(t.createdAt).format('MM-DD-YYYY hh:mm:ss A'), t.username, t.email, _getType(t.type), t.data && t.data != 'undefined' ? t.data : ''])
    })

    const options = {
      fieldSeparator: ',',
      quoteStrings: '',
      decimalSeparator: '.',
      showLabels: false,
      showTitle: false,
      title: 'Transactions',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: headers,
      filename: 'Activity_'+moment(from).format("MMM_Do_YY")+'_'+moment(to).format("MMM_Do_YY"),
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  };

  return (
    <div className={styles.wrapperManagement} style={{display: !hidden ? 'block': 'none'}}>
      <div className={styles.overall}>
        <DatePickerComp startDate={from} onChange={(date) => setDate('from', date)} label='From Date' noMinDate={true} hideTime={true}/>
        <DatePickerComp startDate={to} onChange={(date) => setDate('to', date)} label='To Date' noMinDate={true} hideTime={true}/>
        <Select selectClassname="selectForm" multiselect={true} options={OPTIONS} handleChangeSelect={handleSelectChange}/>
        <Input type='text' onChange={handleTextChange} value={text} placeholder={t('Enter username or email')} label={t('Search text')}/>
        <Button btnClass='btnFormGreen' label={t('Load')} onClick={() => { getData() }} />
        <Button btnClass='btnFormGreen' label={t('Export')} onClick={() => { exportData() }} />
      </div>
      <div>
        <div>

        </div>
        {result && result.length > 0 ? <div className={styles.tb}>
          <PerfectScrollbar className="purpleScroll">
            <div className={styles.head}>
              <div className={styles.items}>
                <small>#</small>
                <span className={styles.date}>{t('Date')}</span>
                <span className={styles.name}>{t('Username')}</span>
                <span className={styles.email}>{t('Email')}</span>
                <span className={styles.type}>{t('Activity')}</span>
                <span className={styles.data}>{t('Data')}</span>
              </div>
            </div>
            {result.map((el, i) => (
              <div key={el.id} className={styles.body}>
                <div className={styles.items}>
                  <small>{((pageNumber - 1) * pageSize) + i + 1}</small>
                  <span className={styles.date}>{moment(el.createdAt).format('MM-DD-YYYY hh:mm:ss A')}</span>
                  <span className={styles.name}>{el.username}</span>
                  <span className={styles.email}>{el.email}</span>
                  <span className={styles.type}>{_getType(el.type)}</span>
                  <span className={styles.data}>{el.data || ''}</span>
                </div>
              </div>
            ))}
          </PerfectScrollbar>
        </div> : <div className={styles.msg}> <div className="message-info">
          {result ? t('No Activities for entered filters') : ''}
        </div> </div>}
        <CustomPagination
          total={total}
          data={result}
          currentPage={pageNumber}
          currentPageSize={pageSize}
          defaultPageSize={10}
          onChange={onPageChange} />
      </div>
    </div>
  );
};

export default ActivityDetails;