import { mainConstants } from '../constants';

const initialState = {
  loading: false
};

export const main = (state = initialState, { actionType, type, name, mode, sent, result, id, error }) => {
  const newState = { ...state };
  if (!newState[name]) newState[name] = {};
  if (!newState[name][type]) newState[name][type] = { data: {}, list: [], page: { total: 0, rows: [] }};
  let typeData = newState[name][type];
  if (!typeData.data) typeData.data = {};
  if (!typeData.list) typeData.list = [];
  if (!typeData.page) typeData.page = { total: 0, rows: [] };
  switch (actionType) {
    case mainConstants.MAIN_REQUEST:
      typeData = { mode, sent, loading: true };
      break;
    case mainConstants.MAIN_SUCCESS:
      if (mode === 'get') {
        if (result) {
          typeData.data[result.id] = result;
        } else if (id){
          typeData.data[id] = {};
        }
      } else if (mode === 'all') {
        typeData.list = result
      } else if (mode === 'page') {
        typeData.page = result;
      }
      typeData.loading = false
      break;
    case mainConstants.MAIN_FAILURE:
      typeData.loading = false
      typeData.error = error
      break;
    default:
      return newState;
  }
  return newState;
};
