import React, { useState } from 'react';
import styles from './activity.module.scss';
import { useTranslation } from 'react-i18next';
import ActivityDetails from './activityDetails'
import ActivityOverall from './activityOverall'
import moment from 'moment';
import Tabs from '../../../_components/tabs/tabs';

const tabs = {
  tab1: "Overall",
  tab2: "Details"
};

const Activity = () => {
  const [activeTab, setActiveTab] = useState('Overall');

  return (
    <div className={styles.wrapperManagement}>
      <Tabs tabs={tabs} activeTab={activeTab} changeTab={setActiveTab} />
      <ActivityOverall hidden={activeTab !== 'Overall'} />
      <ActivityDetails hidden={activeTab !== 'Details'} />
    </div>
  )
};

export default Activity;