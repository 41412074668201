import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../_components/input/input';
import Button from '../../_components/button/button';
import Selector from '../../_components/selector/selector';
import { profileActions } from '../../_store/actions/profile.actions';
import { countriesActions } from '../../_store/actions/countries.actions';
import { generateMonths } from '../../_json/months';
import { generateDays } from '../../_json/days';
import { generateYears } from '../../_json/years';
import moment from 'moment';

const mapCountries = (countries = []) => {
  return countries.map((el) => {
    return {
      label: el.country+(!el.allow ? ' - Not Allowed' : ''),
      data: el,
      isDisabled: !el.allow
    };
  });
};

const mapProvinces = (provinces = []) => {
  return provinces.map((el) => {
    return {
      label: el.name+(!el.selected ? ' - Not Allowed' : ''),
      data: el,
      isDisabled: !el.selected
    };
  });
};

const ProvinceSelectStyles = {
  option: (styles, { data }) => {
    return {
      ...styles,
      color: !data.data.selected ? '#912323!important' : null,
      cursor: !data.data.selected ? 'not-allowed' : 'default'
    }
  }
};

const CountrySelectStyles = {
  option: (styles, { data }) => {
    return {
      ...styles,
      color: !data.data.allow ? '#912323!important' : null,
      cursor: !data.data.allow ? 'not-allowed' : 'default'
    }
  }
};

const ProfileModal = () => {
  const dispatch = useDispatch();
  const store = useSelector(state => state);
  const [t] = useTranslation();
  const [profile, setProfile] = useState(store.auth.user);
  const [countries, setCountries] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [months, setMonths] = useState([]);
  const [days, setDays] = useState([]);
  const [years, setYears] = useState([]);

  useEffect(() => {
    setYears(generateYears());
    setMonths(generateMonths());
    setDays(generateDays(null));
  }, []);

  useEffect(() => {
    setProfile(store.auth.user);
  }, [store.auth && store.auth.user]);

  useEffect(() => {
    const country = countries.find(country => country?.label === profile?.country?.name)
    if(country && country.data && country.data.provinces) setProvinces(mapProvinces(country.data.provinces))
  }, [countries && countries.length])

  useEffect(() => {
    dispatch(countriesActions.get('get'));
  }, []);

  useEffect(() => {
    if (countries.length < 1) {
      const list = mapCountries(store.countries.list);
      setCountries(list);
    }
  }, [store.countries && store.countries.list]);

  const handleChange = e => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value.trim();
    const name = target.name;
    setProfile(profile => ({ ...profile, [name]: value }));
  };

  const handleSelectChange = (selected, type) => {
    if (type === 'countries') {
      setProfile({ ...profile, country: { ...profile.country, code: selected.value, name: selected.label }, province: '' });
      selected.data && selected.data.provinces ? setProvinces(mapProvinces(selected.data.provinces)) : setProvinces([])
    } else if (type === 'provinces') {
      setProfile({ ...profile, province:  selected.label })
    } 
    if (type === 'day') {
      setProfile({ ...profile, day: selected.label });
    }
    if (type === 'month') {
      setDays(generateDays(selected.value, moment().year()));
      setProfile({ ...profile, month: selected.label });
    }
    if (type === 'year') {
      setDays(generateDays(profile.month, selected.value));
      setProfile({ ...profile, year: selected.label });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(profileActions.run('update', profile, t('d41166397')));
  };

  return (
    <div>
      <form className="auth-form" onSubmit={handleSubmit}>
        <h4 className="auth-header">{t('d61811848')}</h4>
        <div className="form-body">
          <Input name="firstName" value={profile.firstName} onChange={handleChange} placeholder={t('d471724')} />
          <Input name="lastName" value={profile.lastName} onChange={handleChange} placeholder={t('d478789')} />
          <Input name="address" value={profile.address} onChange={handleChange} placeholder={t('d431689')} />
          <Input name="address2" value={profile.address2} onChange={handleChange} placeholder={t('d93708075')} />
          <Input name="city" value={profile.city} onChange={handleChange} placeholder={t('d147450')} />
          <Selector
            selectedOption={countries.find(obj => obj.label === profile.country.name)}
            selectClassname="selectLg"
            styles={CountrySelectStyles} 
            options={countries}
            handleChangeSelect={(e) => { handleSelectChange(e, 'countries'); }}
            placeholder={t('d8538260')} />
          <Selector styles={ProvinceSelectStyles} selectClassname="selectLg" selectedOption={provinces.find(obj => obj.label === profile.province) || ''} options={provinces} handleChangeSelect={(e) => { handleSelectChange(e, 'provinces') }} placeholder={t('Province')} />
          <div className="date-wrapper">
            <div className="date-wrapper-selector">
              <Selector selectClassname="selectLg" options={months} handleChangeSelect={(e) => { handleSelectChange(e, 'month') }} placeholder={t('d493738')}
                selectedOption={months.find(obj => obj.label === profile.month)} />
            </div>
            <div className="date-wrapper-selector">
              <Selector selectClassname="selectLg" options={days} handleChangeSelect={(e) => { handleSelectChange(e, 'day') }} placeholder={t('d86256703')}
                selectedOption={days.find(obj => obj.label === profile.day)} />
            </div>
            <div className="date-wrapper-selector">
              <Selector selectClassname="selectLg" options={years} handleChangeSelect={(e) => { handleSelectChange(e, 'year') }} placeholder={t('d422325')}
                selectedOption={years.find(obj => obj.label === profile.year)} />
            </div>
          </div>
          <Input name="zipCode" value={profile.zipCode} onChange={handleChange} placeholder={t('d45581388')} />
          <Input name="phone" value={profile.phone} onChange={handleChange} placeholder={t('d963355')} />
        </div>
        <Button
          label={t('d58938009')}
          btnClass="btnPrimary"
          fullWidth={true}
          type="submit"
        />
      </form>
    </div>
  );
};

export default ProfileModal;
