import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./eventHeader.module.scss";
import { modalActions } from "../../_store/actions/modal.actions";
import ContestInfoDisplay from "../contestInfoDisplay/contestInfoDisplay";
import { useDispatch, useSelector } from "react-redux";
import { isObjectEmpty } from "../../_utils";
import { sessionService } from "../../_sessionService/storage";
import { imageActions } from "../../_store/actions/image.actions";
import usd from "../../_assets/images/icons/usd6838.svg";
import credit from "../../_assets/images/icons/credit6502.svg";
import abacus from "../../_assets/images/icons/abacus.svg";
import clock from "../../_assets/images/icons/clock.svg";
import money from "../../_assets/images/icons/money.svg";
import moment from "moment";
import SmallTitle from "../smallTitle/smallTitle";
import accounting from "accounting-js";

const EventHeader = ({ contest, matches }) => {
  const store = useSelector((state) => state);
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [image, setImage] = useState(
    sessionService.getImg(`${contest.game.id}_gameCover`)
  );
  useEffect(() => {
    if (contest) {
      setData(contest);
      if (!sessionService.getImg(`${contest.game.id}_gameCover`))
        fetchData(contest.game.id);
    }
  }, [contest]);

  useEffect(() => {
    if (
      contest &&
      contest.game.id &&
      store.image &&
      store.image[contest.game.id + "_gameCover"]
    ) {
      setImage(store.image[contest.game.id + "_gameCover"]);
    }
  }, [
    contest &&
      contest.game.id &&
      store.image &&
      store.image[contest.game.id + "_gameCover"],
  ]);

  const fetchData = async (id) => {
    await dispatch(imageActions.getImage(id + "_gameCover", 200, 200));
  };
  const openContestInfo = () => {
    dispatch(
      modalActions.openModal(
        <ContestInfoDisplay
          contest={contest ? contest : null}
          matches={matches ? matches : null}
        />,
        "Prizes modal",
        "lg"
      )
    );
  };
  return (
    <div className={styles.wrapper}>
      {!isObjectEmpty(data) && (
        <div className={styles.container}>
          <div className={styles.header}>
          </div>
          <div className={styles.body}>
            <div className={styles.headerDiv} style={{ width: "100%" }}>
              <SmallTitle title={(t("d79965431") + ":")}></SmallTitle>
              <div ><small onClick={openContestInfo}>{t('d86475838')}</small></div>
            </div>
            <div className={styles.basicInfoTop}>
            <div className={styles.displayBox} >
              <div className={styles.leftImageDiv}>
                <div className={styles.image}>
                  <span></span>
                  <img src={clock} alt="clock" />
                </div>
                <div className={styles.image}>
                  <span></span>
                  <img src={money} alt="money" />
                </div>
              </div>
              <div>
                <p className={styles.leftDiv}>
                  {t("d465736704").toLocaleUpperCase()}:{" "}
                </p>
                <p className={styles.leftDiv}>
                  {t("d71557884").toLocaleUpperCase()}:
                </p>
              </div>
              <div>
                <p>{moment(data.from).format("MM/DD hh:mmA")}</p>
                <p>
                  {accounting.formatMoney(data.entryFee ? data.entryFee : 0)}{" "}
                </p>
              </div>
              </div>
              <div className={styles.displayBox}>
              <div className={styles.rightImageDiv}>
                <div className={styles.image}>
                  <span></span>
                  <img src={abacus} alt="abacus" />
                </div>
                <div className={styles.image}>
                  <span></span>
                  <img src={credit} alt="credit" />
                </div>
              </div>
              <div className={styles.rightDiv}>
                <p className={styles.leftDiv}>
                  {t("d88318091").toLocaleUpperCase()}:
                </p>
                <p className={styles.leftDiv}>
                  {t("d8768678").toLocaleUpperCase()}:
                </p>
              </div>
              <div>
                <p>
                  {(data.entered || 0) +
                    "/" +
                    (data.team ? "∞" : data.totalEntries)}
                </p>
                <p>
                  {accounting.formatNumber(
                    data.entryCreditFee ? data.entryCreditFee : 0
                  )}
                </p>
              </div>
              </div>
            </div> 
          </div>
          {/* <div className={styles.header}>
          <div className={styles.headerImg}>
            <img src={image ? image : gamePlaceholder} alt="header-img" />
          </div>
          <div className={styles.text}><h4>{data.name}</h4>
            <h5>{t('d58873906')}: {data.game.name}</h5>
          </div>
        </div>
        <div className={styles.body}>
          <div>
            <div className={styles.eventBalance}>
              <div className={styles.eventFunds}>
                <div><img src={bagImg} alt="bag" /></div>
                <h2 className={styles.balance}>{data.prize}</h2>
              </div>
              <Button
                btnClass="btnDark"
                label={t('d86475838')}
                onClick={openContestInfo}
              />
            </div>
            <div className={styles.info}>
              <div className={styles.textWeb}><h4>{data.name}</h4>
                <h5>{t('d58873906')}: {data.game.name}</h5></div>
              <div className={styles.general}>
                <div className={styles.data}>
                  <img src={timerIcon} alt="timer-icon" />
                  <div className={styles.infoText}>
                    <CountdownTimer date={data.from} />
                    <p className={styles.timerTextColor}>{t('d87085962')}</p>
                  </div>
                </div>
                <div className={styles.data}>
                  <img src={entriesIcon} alt="entries-icon" />
                  <div className={styles.infoText}>
                    <p>{(data.userTotalEntries ? data.userTotalEntries.length : 0) + '/'  + (data.team ? '∞' : data.totalEntries)}</p>
                    <p className={styles.entriesTextColor}>{t('d88318091')}</p>
                  </div>
                </div>
                <div className={styles.data}>
                  <img src={creditIcon} alt="credit-icon" />
                  <div className={styles.infoText}>
                    <p>{data.entryCreditFee}</p>
                    <p className={styles.creditTextColor}>{t('d8768678')}</p>
                  </div>
                </div>
                <div className={styles.data}>
                  <img src={entryFeeIcon} alt="fee-icon" />
                  <div className={styles.infoText}>
                    <p>{ data.team ? '1/∞' : data.entryFee}</p>
                    <p className={styles.feeTextColor}>{t('d71557884')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        </div>
      )}
    </div>
  );
};

export default EventHeader;
