import { formsConstants } from '../constants';
import formsService from '../../_services/forms.service';

const run = (mode, data = {}) => {
  const request = () => { return { type: formsConstants.FORMS_REQUEST, mode }; };
  const success = (data) => { return { type: formsConstants.FORMS_SUCCESS, data, mode }; };
  const failure = (error) => { return { type: formsConstants.FORMS_FAILURE, error, mode }; };
  return dispatch => {
    dispatch(request());
    formsService.run(mode, data)
      .then(
        res => {
          dispatch(success(res));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };
};

export const formsActions = {
  run
};
