import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styles from './list.module.scss';
import Forms from '../forms/forms';
import Button from '../../../_components/button/button';
import DynamicFilters from '../../../_components/dynamicForm/dynamicFilters';
import CSVExport from '../../../_components/dynamicForm/export/CSVExport';
import Import from '../../../_components/dynamicForm/import/Import';
import toastService from '../../../_services/toastService';
import { mainActions } from '../../../_store/actions/main.actions';
import { modalActions } from '../../../_store/actions/modal.actions';
import { useTranslation } from 'react-i18next';
import SearchBar from '../../../_components/searchBar/searchBar';
import ModalYesNo from '../../../_components/modal/modalYesNo';
import Loading from '../../../_components/Loading';
import CustomPagination from '../../../_components/pagination/serverPagination';
import greenLeftArrow from '../../../_assets/images/icons/greenLeftArrow.svg';
import Checkbox from '../../../_components/checkbox/checkbox';
import { calcs } from '../../../calcs';
import { setReplacements, getFormTemplate } from '../../../_utils';
import copy from "copy-to-clipboard";

const List = ({ form }) => {
  const { collection, type, actions } = form;
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const pathHistory = useHistory();
  const [rows, setRows] = useState(null);
  const [total, setTotal] = useState(0);
  const [checktype, setChecktype] = useState(type);
  const [editData, setEditData] = useState({});
  const [loading, setLoading] = useState(false);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [filters, setFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [visible, setVisible] = useState('list');
  const [formMode, setFormMode] = useState();
  const [searchText, setSearchText] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalCancel, setShowModalCancel] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [tbHead, setTbHead] = useState([]);
  const [sortType, setSortType] = useState('DEFAULT');
  const [sortIndex, setSortIndex] = useState(-1);
  const [sortField, setSortField] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [history, setHistory] = useState(false);

  useEffect(() => {
    toggleView('list');
    getHeaders(form);
  }, [form, sortField, sortType, pageNumber, pageSize, searchTerm, filters, history]);


  const getFieldProjection = (field) => {
    const { map, dbname } = field
    let projection
    if (map) {
      const { path, property } = map
      projection = path ? path + '.' : ''
      projection += property
    } else {
      projection = dbname
    }
    return projection
  }

  const toggleView = async (view) => {
    setEditData({});
    setVisible(view);
    if (view === 'list') {
      setLoading(true);
      try {
        let result;
        const filter = []
        let sort = {}
        if (searchText) {
          const textFilters = []
          form.fields.forEach(field => {
            if (field.type === 'text' || field.type == 'select') {
              textFilters.push({ [field.dbname]: { '$regex': searchText, '$options': 'i' } })
            }
          })
          filter.push({ $or: textFilters })
        }
        if (sortType !== 'DEFAULT') {
          sort = { [getFieldProjection(sortField)]: sortType === 'ASC' ? -1 : 1 }
        }

        if (filters && filters.length > 0) {
          filter.push(...filters)
        }

        let filterString
        if (!history && form.filter) {
          filterString = setReplacements(form.filter)
        } else if (history && form.history) {
          filterString = setReplacements(form.history)
        }

        if (filterString) filter.push(JSON.parse(filterString))

        result = await dispatch(mainActions.run(collection, type, 'page', { sort, filter, pageNumber, pageSize }, null, true));

        const calsFunc = calcs[form.type]
        if (calsFunc) {
          calsFunc(result)
        }
        setRows(result.rows);
        setTotal(result.total);
        setLoading(false);
      } catch (err) {
        console.log(err)
      }
      setLoading(false);
    }
  };

  const getEditData = async (e, el, mode) => {
    e.preventDefault();

    setEditData(el);
    setFormMode(mode)
    setVisible('form');
  };

  const openDeleteModal = async (e, type, id, name) => {
    e.preventDefault();
    setShowModalDelete(true);
    setModalData({ type, id, name });
  };

  const openCancelModal = (e, type, id, name) => {
    e.preventDefault()
    setShowModalCancel(true);
    setModalData({ type, id, name })
  }
  const handleDelete = async (answer, data) => {
    const { id, type, name } = data;
    if (answer === t('d13586174')) {
      await dispatch(mainActions.run(collection, type, 'delete', { id, name }, t('d65237007')));
      await toggleView('list');
      setShowModalDelete(false);
    } else {
      setShowModalDelete(false);
    }
  };

  const handleCancel = async (answer, data) => {
    const { id, type, name } = data;
    if (answer === t('d13586174')) {
      await dispatch(mainActions.run(collection, type, 'cancel', { id, name }, 'Successfully canceled'));
      await toggleView('list');
      setShowModalCancel(false);
    } else {
      setShowModalCancel(false);
    }
  };

  const onSubmit = (type) => {
    toggleView('list');
  };
  const handleSearch = (e) => {
    setSearchText(e.target.value);
    e.preventDefault();
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSearchTerm(searchText);
  };

  const onPageChange = async (page, pageSize) => {
    setPageNumber(page);
    setPageSize(pageSize);
  };
  const getHeaders = (form) => {
    const header = form.fields.filter(field => {
      if (field.isColumn) {
        return field;
      }
    });

    setTbHead(header);
  };

  const getFieldValue = (type, value) => {
    if (value === null || value === undefined) return 'N/A';
    let result = value;
    switch (type) {
      case 'date':
        result = moment(value).format('MM/DD hh:mmA');
        break;
      case 'checkbox':
        result = value ? 'd13586174' : 'd13276142';
        break;
    }
    if (type === 'date') {
      return result;
    } else if (type === 'link') {
      return result;
    }
    return t(''+result);
  };

  const getListValue = (field, el) => {
    const { type, map, dbname } = field;
    let value
    if (map) {
      const { path, property } = map;
      let data = el;
      if (path) {
        const depth = path.split('.');
        depth.forEach(subPath => data = data ? data[subPath] : {});
      }
      if (data) value = data[property];
    } else {
      value = el[dbname];
    }
    return getFieldValue(type, value);
  };

  const sortData = (sortType, index, field) => {
    setSortType(sortType);
    setSortIndex(index);
    setSortField(field);
  };

  const onFilter = (receivedFilters, selected) => {
    setSelectedFilters(selected);
    setFilters(receivedFilters);
    setFiltersVisible(false);
  };
  const onCloseFilter = () => {
    setFiltersVisible(false);
  };
  const onChange = (e) => {
    const { checked } = e.target;
    setHistory(checked)
  }

  const copyToClipboard = (fieldValue) => {
    copy(fieldValue);
    toastService.show('success', t('d62575158'))
  }

  const openExportModal = () => {
    dispatch(modalActions.openModal(<CSVExport form={form} onClose={closePrizeModal} />, 'd0733608', 'md', false));
  };

  const openImportModal = () => {
    dispatch(modalActions.openModal(<Import form={form} onClose={closeImportModal} />, 'Import', 'xlg', false, false));
  };

  const closePrizeModal = () => {
    dispatch(modalActions.closeModal());
  };

  const closeImportModal = () => {
    toggleView('list')
    dispatch(modalActions.closeModal());
  };

  const generateTemplate = () => {
    getFormTemplate(form, t)
  }

  const importData = () => {
    getFormTemplate(form, t)
  }

  const _getElementPathField = (el, elementPath) => {
    if (el && elementPath && el[elementPath]) {
      return el[elementPath]
    } else return ''
  }

  const getCustomActions = (el) => {
    const customs = actions.filter(one => one.type === 'custom' && (!one.onlyHistory || history) )
    return customs.map(one => {
      return <div className={styles.adminCustomButton}><Button fullWidth={true} btnClass='btnFormPurple' label={one.label} onClick={(e) => { pathHistory.push(`/${one.path}/${_getElementPathField(el, one.elementPathField)}`); }} /></div>
    })
  }

  return (<div className={styles.wrapper}>
    {filtersVisible && <DynamicFilters onSubmit={onFilter} selectedFilters={selectedFilters} form={form} onClose={onCloseFilter} />}
    {showModalDelete && <ModalYesNo show={showModalDelete} handleYesNoAnswer={handleDelete} modalData={modalData} question='d54856307' />}
    {showModalCancel && <ModalYesNo show={showModalCancel} handleYesNoAnswer={handleCancel} modalData={modalData} question='Are you sure that you want to cancel selected item' />}
    <div className={styles.header}>
      {visible === 'list' ?
        <> {actions.indexOf('add') > -1 && <Button btnClass='btnFormGreen' label={`New ${type}`} onClick={() => { toggleView('form') }} />}
        </> :
        <> {actions.indexOf('list') > -1 &&
          <div className={styles.goBack}>
            <span onClick={() => { toggleView('list') }}><img src={greenLeftArrow} alt="Go back" /> {t('d72876492')}</span>
          </div>}
        </>}
      {visible === 'list' &&
        <>
          <div className={styles.headerSearch}>
            <SearchBar onChange={handleSearch} value={searchText} onClick={handleSearchSubmit} placeholder={t('d653442620')} />
          </div>
          {form.history && <div className={styles.checkboxWrapper}>
            <Checkbox checkboxClass="checkboxInputClass"
              name='history'
              checked={history}
              onChange={onChange}
              label={'History'} />
          </div>}
          <Button btnClass='btnFormGreen' label={t('d0733608')} onClick={() => { openExportModal() }} />
          {form.import && <Button btnClass='btnFormGreen' label={t('Template')} onClick={generateTemplate} />}
          {form.import && <Button btnClass='btnFormGreen' label={t('Import')} onClick={openImportModal} />}
          <Button btnClass='btnFormGreen' label={t('d601389159')} onClick={() => { setFiltersVisible(true) }} />
        </>
      }

    </div>
    {visible === 'list' ?
      <div className={styles.tb}>
        <PerfectScrollbar className="purpleScroll">
          {loading || !rows ? <Loading className="admin-list-loader" /> : null}
          <div className={styles.head}>
            <div>
              <b>#</b>
              {tbHead.map((field, i) => (
                <span key={i} style={field.listStyle}>
                  {t(field.columnLabel || field.label)}
                  <div className={styles.sort}>
                    {(sortType === 'DEFAULT' || sortIndex !== i) && <div onClick={() => sortData('DESC', i, field)}>
                      <i className="fa fa-caret-up" aria-hidden="true"></i>
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </div>}
                    {sortType === 'DESC' && sortIndex === i && <div onClick={() => sortData('ASC', i, field)}>
                      <i className="fa fa-caret-up" aria-hidden="true"></i>
                      <i className="fa fa-caret-down" aria-hidden="true" style={{ color: "#00c84a" }}></i>
                    </div>}
                    {sortType === 'ASC' && sortIndex === i && <div onClick={() => sortData('DEFAULT', i, field)}>
                      <i className="fa fa-caret-up" aria-hidden="true" style={{ color: "#00c84a" }}></i>
                      <i className="fa fa-caret-down" aria-hidden="true" ></i>
                    </div>}
                  </div>
                </span>
              ))}
            </div>
          </div>
          {rows && rows.length > 0 && (checktype === type) ? <ul className={styles.body}>
            {rows.map((el, i) => (
              <li key={el.id}>
                <div>
                  <b>{(pageNumber -1) * pageSize + i + 1}</b>
                  {form.fields.map((field, i) => {
                    const fieldValue = getListValue(field, el)
                    return field.isColumn ?
                    <React.Fragment key={i}>
                      <span key={`field-dbname-${field.id}`} style={field.listStyle}>{fieldValue}
                        {field.type === 'link' ?
                          <Button btnClass='btnTransparent' label={<i className="fa fa-copy" aria-hidden="true"></i>} onClick={() => { copyToClipboard(fieldValue) }} /> : null}
                      </span>

                    </React.Fragment>
                    : null })}
                </div>
                <div className={styles.btnWrapperIcons}>
                  {getCustomActions(el)}
                  {actions.indexOf('edit') > -1 && <Button btnClass='btnPrimary' label={<i className="fa fa-pencil-square-o" aria-hidden="true"></i>} onClick={(e) => { getEditData(e, el, 'edit') }} />}
                  {actions.indexOf('view') > -1 && <Button btnClass='btnPrimary' label={<i className="fa fa-eye" aria-hidden="true"></i>} onClick={(e) => { getEditData(e, el, 'view') }} />}
                  {actions.indexOf('delete') > -1 && <Button btnClass='btnSecondary' label={<i className="fa fa-trash-o" aria-hidden="true"></i>} onClick={(e) => { openDeleteModal(e, form.type, el.id, el.name) }} />}
                  {actions.indexOf('cancel') > -1 && <Button btnClass='btnFormRed' customStyle={{width: '45px'}} label="Cancel" onClick={(e) => { openCancelModal(e, form.type, el.id, el.name) }} />}
                </div>
              </li>
            ))}

          </ul> : <div className={styles.msg}> <div className="message-info">
            {rows ? t('d58155026') : ''}
          </div> </div>}
        </PerfectScrollbar>
      </div> : <div>
        <Forms history={history} form={form} editData={editData} formMode={formMode} onSubmit={onSubmit} />
      </div>}
    {visible === 'list' &&
      <CustomPagination
        total={total}
        data={rows}
        currentPage={pageNumber}
        currentPageSize={pageSize}
        defaultPageSize={10}
        onChange={onPageChange} />}
  </div>);
};

export default List;
