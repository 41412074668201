import axios from 'axios';
import CryptoJS from 'crypto-js';
import fp from 'fingerprintjs2';
import { sessionService } from '../_sessionService/storage';
import { url } from '../environment';
import toastService from './toastService';
import i18n from '../i18n';
import { store } from '../_store/store';
import { imageActions } from '../_store/actions/image.actions';
import { languageActions } from '../_store/actions/language.actions';
import { isObjectEmpty } from '../_utils';
const updateCrc = (images, language) => {
  if (images) {
    images.forEach(element => {
      store.dispatch(imageActions.getImage(element, 400, 200));
    });
  }
  if (!isObjectEmpty(language)) {
    store.dispatch(languageActions.run('get', { lang: language.lang }, `lang=${language.lang}`));
  }
};
axios.interceptors.response.use((response) => {
  // Returns response body
  if ((response.data.language && response.data.language.lang) || (response.data.images && response.data.images.length > 0)) {
    sessionService.removeItemsByCrc(response.data.images, response.data.language);
    updateCrc(response.data.images, response.data.language);
  }
  return response.data.data ? response.data.data : response.data;
}, async (error) => {
  // Handle response error
  _handleError(error);
  return Promise.reject(error);
});

const getSessionData = async () => {
  const { innerWidth: width, innerHeight: height } = window;
  const data = sessionService.getSessionData();
  data.user = sessionService.get('user');
  data.screenSize = { width, height };
  data.timestamp = +new Date;
  // passing the fingerprint with fingerprint lib
  const options = {
    extraComponents: [
      {
        key: 'sessdata',
        getData: function (done) {
          const sessdata = localStorage.getItem('sessdata');
          done(JSON.parse(sessdata));
        }
      },
      {
        key: 'screen',
        getData: function (done) {
          const screen = localStorage.getItem('screen');
          done(JSON.parse(screen));
        }
      },
      {
        key: 'timestamp',
        getData: function (done) {
          const timestamp = +new Date;
          done(JSON.parse(timestamp));
        }
      },
      {
        key: 'user',
        getData: function (done) {
          const user = localStorage.getItem('user');
          done(JSON.parse(user));
        }
      }
    ]
  };
  return (fp.getPromise(options).then(function (components) {
    return components;
  })
  );
};

const apiRequest = async (method, apiUrl, body = {}, headers, responseType) => {
  try {
    const sessionData = await getSessionData();
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(sessionData), 'secret').toString();
    if (body instanceof FormData) {
      body.append('fingerprint', ciphertext);
    } else {
      body.fingerprint = ciphertext;
    }

    const requestHeaders = (!headers) ? {} : headers;
    if (method === 'get' || method === 'delete') return axios[method](url + apiUrl, { headers: requestHeaders })
    else if (method === 'post' || method === 'put' || method === 'patch') return axios[method](url + apiUrl, body, { headers: requestHeaders, responseType })
  } catch (err) {
    _handleError(err);
  }
};

const outsideRequest = async (method, url, body, headers) => {
  try {
    const requestHeaders = (!headers) ? {} : headers;
    if (method === 'get' || method === 'delete') return axios[method](url, { headers: requestHeaders });
    else if (method === 'post' || method === 'put') return axios[method](url, body, { headers });
  } catch (err) {
    _handleError(err);
  }
};

const _handleError = async (err) => {
  if (err && err.response) {
    if (err.response.status === 403 || err.response.status === 401) {
      sessionService.destroy('user');
      sessionService.destroy('sessdata');
      window.location.replace('/');
    }
    let errorText, data;
    if (err.response.data && err.response.data.error && err.response.data.error.message) {
      errorText = err.response.data.error.message;
      data = err.response.data.error.data
    } else {
      errorText = err.response.statusText;
    }
    toastService.show('error', i18n.t(errorText)+ (data && (typeof data == 'string') ? '\n'+data : ''));
  }

  return err;
};

export default {
  apiRequest,
  outsideRequest
};
