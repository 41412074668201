import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './balanceChart.scss';

const BalanceChart = ({ value, color, trailColor, won, amount }) => {
  return (
    <div className="balanceChart">
      <CircularProgressbar value={value} text={`${value}%`} strokeWidth={5} styles={buildStyles({
        pathColor: color,
        trailColor: trailColor,
      })} />
    </div>
  );
};

BalanceChart.propTypes = {
  value: PropTypes.number,
  color: PropTypes.string,
  trailColor: PropTypes.string,
};

export default BalanceChart;
