import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import styles from "./tableResults.module.scss";
import avatarDefault from "../../_assets/images/whitePlayer.png";
import teamDefault from "../../_assets/images/transparent.png";
import { isObjectEmpty } from "../../_utils";
import { modalActions } from "../../_store/actions/modal.actions";
import PlayerDisplay from "../playerDisplay/playerDisplay";
import { useDispatch } from "react-redux";
import withImages from "../../_components/core/withImages";


const styleTopLeft={borderTopLeftRadius: '1rem' }
const styleTopRight = {borderTopRightRadius: '1rem'}
const styleBottomLeft = {borderBottomLeftRadius: '1rem'}
const styleBottomRight = {borderBottomRightRadius: '1rem'}

const divHeight = 475
const customDivHeight = 50
const calculateHeight = (numberOfPlayers) => {
  if (!numberOfPlayers) return divHeight
  const innerDivsHeight = numberOfPlayers * customDivHeight
  return innerDivsHeight >= divHeight - customDivHeight ? customDivHeight : innerDivsHeight - divHeight;
}

const TablePlayers = ({
  rules,
  players,
  result,
  playerPoints = {},
  acquireImages,
  images,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    const newHeaders = ["#", "d2582874", "d21583920", "d71123719"];
    if (rules && !isObjectEmpty(rules)) {
      Object.keys(rules).forEach((key) => newHeaders.push(key));
    }
    setHeaders(newHeaders);
  }, [rules]);

  useEffect(() => {
    if (acquireImages) {
      const imagesToAcquire = [];
      players.forEach(({ id, game, team }) => {
        const teamLogoName = (game + "_" + team).replace(/\s+/g, "_") + "_logo";
        imagesToAcquire.push({
          name: `${id}_profileAvatar`,
          width: 150,
          height: 150,
        });
        imagesToAcquire.push({ name: teamLogoName, width: 150, height: 150 });
      });
      acquireImages(imagesToAcquire);
    }
  }, []);

  const _calcPoints = useCallback(() => {
    let points = 0;
    players.forEach(
      (player) =>
        (points += playerPoints[player.id]
          ? playerPoints[player.id]["points"]
          : 0)
    );
    return points;
  }, [playerPoints]);

  const openCPlayerInfo = (data) => {
    dispatch(
      modalActions.openModal(
        <PlayerDisplay data={data} />,
        "Teams Preview",
        "md"
      )
    );
  };

  const gridTemplateColumns = headers
  .map((item, index) => {
    if (index === 0) return "5%";
    if (index === 1) return '30%'
    if (headers.length < 6) return "24%";
    return "20%";
  })
  .join(" ");

  const last = players.length
  const offsetHeight = calculateHeight(last) 
  return (
    <>
      <div className={styles.tbWrapper}>
        <div className={`${styles.tableCustom} ${styles.tablePlayersCustom} `}>
          <PerfectScrollbar  className={`"purpleScroll" ${styles.ps_vertical}`}>
            <div className={styles.headDiv} style={{gridTemplateColumns}} >
            {headers.map((head, i) =>
              <span key={i} className={styles.headColum} >{t(head)}</span>
            )}
            </div>
            <div className={styles.wrapperDiv} id="wrapper-div">
            {players.map((data, i) => 
            <div style={i === last - 1 ?  {gridTemplateColumns} : {gridTemplateColumns}  } key={`standings_player_${i}`} className={`${styles.gridDiv}`}>
              <span  style={i === 0 ? (styleTopLeft) : {} } >{i + 1}</span>
              <span className={styles.imageDiv} onClick={() => openCPlayerInfo(data)}>
                <img src={images[data.id+'_profileAvatar'] || avatarDefault} />
                <span className={styles.nick}>{data.nick}</span>
              </span>
              <span onClick={() => openCPlayerInfo(data)}>
                <img src={images[`${(data.game+'_'+data.team).replace(/\s+/g, '_')}_logo`] || teamDefault} />
              </span>
              <span  >{playerPoints[data.id] && playerPoints[data.id]['points'] ? playerPoints[data.id]['points'] : '-'}</span>
              {headers.slice(4).map((key, ind) =><span style={i === 0 && ind === headers.length-5 ? (styleTopRight) : {} } key={`standings_action-${i}-${key}`}>{playerPoints[data.id] && playerPoints[data.id][key] ? playerPoints[data.id][key] : '-'}</span>)}

            </div>
            )}
            <div style={{gridTemplateColumns, height: offsetHeight < 0 ? `${Math.abs(offsetHeight)}px` : '50px' }} className={`${styles.gridDiv} ${styles.emptyDiv}`}>
              <span style={styleBottomLeft}>{' '}</span>
              <span className={styles.imageDiv}>
                {' '}
                <span className={styles.nick}>{' '}</span>
              </span>
              <span>
                {' '}
              </span>
              <span >{' '}</span>
            {headers.slice(4).map((key, ind) => {
              return <span style={ind === headers.slice(4).length - 1 ? styleBottomRight : {}} key={`standings_action-${ind}-${key}`}>{' '}</span>
              })}
            </div>
            </div>
            
            {/* </div> */}
          </PerfectScrollbar>
          <div className={styles.tableFooter}>
              <div className={styles.title}><h4>{t('d97917174')}</h4></div>
              <div className={styles.points}><span>{_calcPoints()}</span></div>
            </div>
        </div>
      </div>
    </>
  );
};

TablePlayers.propTypes = {
  activeColor: PropTypes.string,
  scrollColor: PropTypes.string,
  activeTab: PropTypes.string,
  tbHeading: PropTypes.array,
  tbData: PropTypes.array,
};

export default withImages(TablePlayers);
