import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
import CarouselItem from '../../../../_components/carouselItem/carouselItem';
import MultiCarousel from '../../../../_components/carousel/carousel';
import { useDispatch } from 'react-redux';
import withImages from '../../../../_components/core/withImages';
import { useTranslation } from 'react-i18next';
import { mainActions } from '../../../../_store/actions/main.actions';
import './MatchSelection.scss';

const MatchSelection = ( { chosenGame, alreadySelectedMatches, setMatchesCallback } ) => {
  const [t] = useTranslation();
  const [matches, setMatches] = useState();
  const [game, setGame] = useState(chosenGame);
  const dispatch = useDispatch();
  const [selectedMatches, setSelectedMatches] = useState({});

  useEffect(() => {
    setGame(chosenGame);
  }, [chosenGame]);
  
  useEffect(() => {
    if(alreadySelectedMatches && alreadySelectedMatches.length > 0) {
      const alreadySelected = {}
      alreadySelectedMatches.forEach(m => {
        alreadySelected[m._id] = m;
      });
      setSelectedMatches(alreadySelected);
    }
    game && getMatches();
  }, [game]);

  const getMatches = async () => {
    const response = await dispatch(mainActions.run('data', 'match', 'all', {filter: [{ game: game.name }, {startAt: {'$gt': Date.now()}}]}));
    setMatches(response);

    if(response.length === 0) {
      setMatchesCallback([]);
      setSelectedMatches([]);
    }
  }

  const onMatchClick = (item) => {
    let obj = {...selectedMatches};
    if(selectedMatches[item._id]) {
      delete obj[item._id];
    } else {
      obj[item._id] = item;
    }
    setSelectedMatches(obj);
    setMatchesCallback(Object.values(obj));
  }

  const getStyleClass = (item) => {
    return selectedMatches && selectedMatches[item._id] ? 'selected' : 'unselected';
  }

  return (
    <>
     <div className="container-wrapper">
     { matches ? <MultiCarousel title={t('d65557017')} numItems={matches.length}>
          {matches.map((item) =>
            <div key={item._id} className={ getStyleClass(item) } onClick={() => onMatchClick(item)}><CarouselItem item={item} key={item.id} /></div>
          )}
        </MultiCarousel> : 
        !game && <p> { t('d58901163') } </p>}
      </div>
    </>
  );
};

export default withImages(MatchSelection);
