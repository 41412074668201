import React, { useEffect } from "react";
import PropTypes from "prop-types";
import PlayerPropCard from "../card/playerPropCard";
import avatarDefault from "../../_assets/images/player.png";
import withImages from "../../_components/core/withImages";
import { isFinite } from "lodash";

import styles from "./playerProps.module.scss";

const PlayerProps = ({
  playerProps = [],
  acquireImages,
  images = {},
  selectedPlayersMap = {},
  addCustomStyle = false,
  onClick,
}) => {
  useEffect(() => {
    fetchImages();
  }, [playerProps.length]);

  const fetchImages = () => {
    if (acquireImages) {
      const imagesToAcquire = [];
      playerProps.forEach(({ player }) => {
        const teamLogoName =
          (player.game + "_" + player.team).replace(/\s+/g, "_") + "_logo";
        imagesToAcquire.push({
          name: `${player.id}_profileAvatar`,
          width: 150,
          height: 150,
        });
        imagesToAcquire.push({ name: teamLogoName, width: 150, height: 150 });
      });
      acquireImages(imagesToAcquire);
    }
  };


  return (
    <section
      className={`${styles.wrapper} ${addCustomStyle ? styles.customWrapper : ""}`}
    >
      <div
        className={`${styles.player} ${addCustomStyle ? styles.customPlayer : ""}`}
      >
        {playerProps.map((item) => {
          if (
            !item ||
            !item.id ||
            !item.player ||
            !item.player.id ||
            !item.action ||
            !isFinite(item.value) ||
            !item.from ||
            !item.player.team ||
            !item.player.game ||
            !item.playerPropsId
          ) {
            return null;
          }
          return (
            <PlayerPropCard
              playerPick={item}
              key={item.id}
              player={item.player}
              action={item.action}
              value={item.value}
              from={item.from}
              name={item.name}
              image={images[`${item.player.id}_profileAvatar`] || avatarDefault}
              isDefaultImage={!images[`${item.player.id}_profileAvatar`]}
              logo={
                images[
                  `${
                    (item.player.game + "_" + item.player.team).replace(
                      /\s+/g,
                      "_"
                    ) + "_logo"
                  }`
                ]
              }
              boosted={item?.boosted}
              selected={
                selectedPlayersMap[
                  // `${item.player.id};${item.action};${item.from}`
                  item.id
                ]
              }
              canceled={item.canceled}
              playerPropsId={item.playerPropsId}
              match={item.match}
              game={item.game}
              id={item.id}
              onClick={onClick}
            />
          );
        })}
      </div>
    </section>
  );
};

PlayerProps.propTypes = {
  playerProps: PropTypes.array,
  acquireImages: PropTypes.func,
  images: PropTypes.object,
  selectedPlayersMap: PropTypes.object,
  addCustomStyle: PropTypes.bool,
  onClick: PropTypes.func,
};

export default withImages(PlayerProps);
