import { toast } from 'react-toastify';

const defaultOptions = {
  autoClose: 3000,
  // autoClose:false,
  hideProgressBar: true,
};

// types: success, info, warn, error
const show = (type, message, options) => {
  let aditionalOptions = {};
  if (options) {
    aditionalOptions = { ...defaultOptions, ...options };
  }
  const toastOptions = (!options) ? defaultOptions : aditionalOptions;
  toast[type](message, toastOptions);
};

export default {
  show
};
