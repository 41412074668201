import React from 'react';
import PropTypes from 'prop-types';
import styles from './smallTitle.module.scss';

const SmallTitle = ({ children, title }) => {
  return (
    <h4 className={styles.eventTitle}>{title} {children}</h4>
  );
};
SmallTitle.propTypes = {
  children: PropTypes.object,
  title: PropTypes.string,
};

export default SmallTitle;
