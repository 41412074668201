import React, { useState, useEffect } from 'react';
import Selector from '../../selector/selector';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { mainActions } from '../../../_store/actions/main.actions';
import { getMapingValue, parseDynamicFieldFilters } from '../../../_utils';
import i18n from '../../../i18n';

const mapForDefaultSelect = (data, dbName) => {
  return data.map(el => {
    return {
      dbName,
      label: i18n.t(el),
      value: el
    };
  });
}
const mapForSelect = (data = [], dbName, selectLabel, selectField) => {
  return data.map((el) => {
    return {
      dbName: dbName,
      label: el[selectLabel],
      value: el[selectField || selectLabel],
      obj: el
    };
  });
};
const mapMultiSelectResponse = (data) => {
  if (!data) return [];
  return data.map(e => e.value);
};
const SelectInput = ({ history, type, dataName, dbName, label, selectLabel, selectField, onSelectChange, multiselect, selected, selectDataReceived, defaultValue, fields = {}, field, additionalClass }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    fetchData();
  }, [fields[field.selectFilter], selected]);

  const fetchData = async () => {
    if (!defaultValue) {
      let filterData
      const filter = []
      if (field) {
        if (field.selectFilter) {
          let value = getMapingValue(fields, field.selectFilter)
          if (!value) value = -1
          filter.push({[field.selectFilter]: value})
        }
        let dynamicFilters = []
        if (!history) {
          const additionalFilters = parseDynamicFieldFilters(field)
          dynamicFilters = [ ...additionalFilters ]
        }
        if (selected && (!Array.isArray(selected) || selected.length > 0)) {
          if (dynamicFilters.length > 0) {
            dynamicFilters = [{$or: [{ $and: dynamicFilters }]}]
            dynamicFilters[0].$or.push({[field.selectField || field.selectLabel]: Array.isArray(selected) ? {$in: selected} : selected})
          }
        }
        if (dynamicFilters.length > 0) {
          filter.push(...dynamicFilters)
        }
      }
      filterData = { filter }
      const response = await dispatch(mainActions.run(dataName, type, 'all', filterData));
      if (selectDataReceived) selectDataReceived(dataName, type, response)
      const list = mapForSelect(response, dbName, selectLabel, selectField);
      setOptions(options => ([...list]));
    } else {
      const list = mapForDefaultSelect(defaultValue, dbName);
      setOptions(options => ([...list]));
    }
  };

  const handleSelectChange = (e) => {
    const data = multiselect ? mapMultiSelectResponse(e) : e.value;
    onSelectChange(data, e ? e.obj: {}, dbName, field);
    setSelectedValue(e);
  };

  const getSelectedValue = () => {
    if (!multiselect) {
      let selectedData = {};
      options.some(obj => {
        if (obj && obj.value === selected) {
          selectedData = obj;
          return true
        }
      });
      setSelectedValue(selectedData);
    } else {
      const selectedData = [];
      options.forEach(obj => {
        obj && selected.forEach(el => {
          if (obj.value === el) {
            selectedData.push(obj);
          }
        });
      });
      setSelectedValue(selectedData);
    }
  };
  useEffect(() => {
    if (selected) {
      getSelectedValue();
    } else {
      setSelectedValue(null);
    }
  }, [selected && options]);

  return (
    <div>
      {options && <Selector
        selectClassname="selectForm"
        options={options}
        handleChangeSelect={handleSelectChange}
        placeholder={t('d465729339')}
        label={label}
        multiselect={multiselect}
        value={selectedValue}
        selectedOption={selectedValue}
        additionalClass={additionalClass}
      />}
    </div>
  );
};

export default SelectInput;