import { userConstants } from '../constants';
import userService from '../../_services/user.service';
import { history } from '../../_utils';
import { sessionService } from '../../_sessionService/storage';
import { modalActions } from './modal.actions';
import toastService from '../../_services/toastService';
import i18n from '../../i18n';
const login = (data) => {
  const request = () => { return { type: userConstants.LOGIN_REQUEST }; };
  const success = (user) => { return { type: userConstants.LOGIN_SUCCESS, user }; };
  const failure = (error) => { return { type: userConstants.LOGIN_FAILURE, error }; };

  return (dispatch, useState) => {
    const state = useState();
    dispatch(request());
    return userService.login(data)
      .then(
        user => {
          if (user) {
            dispatch(success(user));
            sessionService.set('user', user);
            dispatch(modalActions.closeModal());
            const contestEntry = sessionStorage.getItem('contest_entry')
            const playerPoolEntry = sessionStorage.getItem('playerPool_Entry')
            if (!contestEntry && !playerPoolEntry && state.auth.routeRedirect) {
              history.push(`${state.auth.routeRedirect}`);
              dispatch({ type: userConstants.SET_ROUTE, redirect: null });
            }
            sessionService.setSessionData(sessionService.getSessionData().images, sessionService.getSessionData().lang, null);
            return user;
          }
        },
        error => {
          dispatch(failure(error));
        }
      );
  };
};

const logout = () => {
  sessionService.destroy('user');
  sessionStorage.clear();
  history.push('/');
  return { type: userConstants.LOGOUT };
};

const saveRoute = (path) => {
  sessionService.destroy('user');
  return { type: userConstants.SET_ROUTE, redirect: path };
};

const register = (user) => {
  const request = () => { return { type: userConstants.REGISTER_REQUEST }; };
  const success = () => { return { type: userConstants.REGISTER_SUCCESS }; };
  const failure = (error) => { return { type: userConstants.REGISTER_FAILURE, error }; };

  return dispatch => {
    dispatch(request(user));
    return userService.register(user)
      .then(
        (res) => {
          if (res) {
            dispatch(success());
            dispatch(modalActions.closeModal());
            const successLogin = (user) => { return { type: userConstants.LOGIN_SUCCESS, user }; };
            const failureLogin = (error) => { return { type: userConstants.LOGIN_FAILURE, error }; };
            return userService.login({ email: res.username, password: user.password })
              .then(
                result => {
                  if (result) {
                    dispatch(successLogin(result));
                    sessionService.set('user', result);
                    const contestEntry = sessionStorage.getItem('contest_entry')
                    const playerPoolEntry = sessionStorage.getItem('playerPool_Entry')
                    if (contestEntry) {
                      history.push({
                        pathname: '/lobby/draft/'+contestEntry.contestId
                      });
                    } else if (playerPoolEntry) {
                      history.push({
                        pathname: '/lobby/picks/'
                      });
                    } else {
                      history.push({
                        pathname: '/wallet',
                        hash: '#deposit',
                      });
                    }
                    sessionService.setSessionData(sessionService.getSessionData().images, sessionService.getSessionData().lang, null);
                    return result;
                  }
                },
                error => {
                  dispatch(failureLogin(error));
                }
              );
          }

        },
        error => {
          dispatch(failure(error));
        }
      );
  };
};
const resetLink = (data) => {
  const request = () => { return { type: userConstants.RESET_LINK_REQUEST }; };
  const success = () => { return { type: userConstants.RESET_LINK_SUCCESS }; };
  const failure = (error) => { return { type: userConstants.RESET_LINK_FAILURE, error }; };

  return dispatch => {
    dispatch(request());

    userService.resetLink(data)
      .then(
        () => {
          dispatch(success());
          dispatch(modalActions.closeModal());
          toastService.show('success', 'Message with reset link was sent to entered email');
        },
        error => {
          dispatch(failure(error));
        }
      );
  };
};
const forgotPassword = (data) => {
  const request = () => { return { type: userConstants.FORGOT_REQUEST }; };
  const success = () => { return { type: userConstants.FORGOT_SUCCESS }; };
  const failure = (error) => { return { type: userConstants.FORGOT_FAILURE, error }; };

  return dispatch => {
    dispatch(request());

    userService.forgotPassword(data)
      .then(
        () => {
          dispatch(success());
          dispatch(modalActions.closeModal());
          toastService.show('success', 'Password is successfully changed');
          history.push('/');
        },
        error => {
          dispatch(failure(error));
        }
      );
  };
};
const changePassword = (data) => {
  const request = () => { return { type: userConstants.CHANGE_PASSWORD_REQUEST }; };
  const success = () => { return { type: userConstants.CHANGE_PASSWORD_SUCCESS }; };
  const failure = (error) => { return { type: userConstants.CHANGE_PASSWORD_FAILURE, error }; };

  return dispatch => {
    dispatch(request());
    userService.changePassword(data)
      .then(
        () => {
          dispatch(success());
          dispatch(modalActions.closeModal());
          toastService.show('success', 'Password successfully changed');
        },
        error => {
          dispatch(failure(error));
        }
      );
  };
};

const refreshUser = (id) => {
  const request = () => { return { type: userConstants.REFRESH_REQUEST }; };
  const success = (user) => { return { type: userConstants.REFRESH_SUCCESS, user }; };
  const failure = (error) => { return { type: userConstants.REFRESH_FAILURE, error }; };
  return dispatch => {
    dispatch(request());
    userService.refreshUser(id)
      .then(
        user => {
          if (user) {
            dispatch(success(user));
            sessionService.set('user', user);
            return user;
          }
        },
        error => {
          dispatch(failure(error));
        }
      );
  };
};

const sendReferral = (email) => {
  const request = () => { return { type: userConstants.REFERRAL_REQUEST }; };
  const success = (email) => { return { type: userConstants.REFERRAL_SUCCESS, email }; };
  const failure = (error) => { return { type: userConstants.REFERRAL_FAILURE, error }; };
  return dispatch => {
    dispatch(request());
    return userService.sendReferral(email)
      .then(
        email => {
          dispatch(success(email));
          return true;
        },
        error => {
          dispatch(failure(error));
          return false;
        }
      );
  };
};
const updateUser = (user) => {
  sessionService.set('user', user);
  return { type: userConstants.UPDATE_USER, user };
};

const sendContactInfo = (data) => {
  const request = () => { return { type: userConstants.CONTACT_FORM_REQUEST }; };
  const success = (data) => { return { type: userConstants.CONTACT_FORM_SUCCESS, data }; };
  const failure = (error) => { return { type: userConstants.CONTACT_FORM_FAILURE, error }; };
  return dispatch => {
    dispatch(request());
    return userService.contactUs(data)
      .then(
        response => {
          dispatch(success(response));
          return response;
        },
        error => {
          dispatch(failure(error));
        }
      )
  }
}
const sendEmailTemplate = (data) => {
  const request = () => { return { type: userConstants.EMAIL_TEMPLATE_REQUEST }; };
  const success = (data) => { return { type: userConstants.EMAIL_TEMPLATE_SUCCESS, data }; };
  const failure = (error) => { return { type: userConstants.EMAIL_TEMPLATE_FAILURE, error }; };
  return dispatch => {
    dispatch(request());
    return userService.sendEmailTemplate(data)
      .then(
        response => {
          dispatch(success(response));
          toastService.show('success', i18n.t('d91101625'));
          return response;
        },
        error => {
          dispatch(failure(error));
        }
      )
  }
}

export const userActions = {
  login,
  register,
  logout,
  saveRoute,
  sendReferral,
  resetLink,
  forgotPassword,
  changePassword,
  updateUser,
  sendContactInfo,
  sendEmailTemplate,
  refreshUser
};
