import React, { useState,useEffect } from 'react';
import styles from './progressBar.module.scss';
import PropTypes from 'prop-types';

const ProgressBar = ({ done, className }) => {
  const [style, setStyle] = useState({});

  useEffect(() => {
    const newStyle = {
      opacity: 1,
      width: `${done}%`,
      maxWidth: '101.5%'
    };
    const overStyle = {
      opacity: 1,
      width: `${done}%`,
      maxWidth:'101.5%',
      background: `linear-gradient(to left, red, red)`,
      boxShadow: `0px 0px 15px red`
    }
    className || done <= 100 ? 
    setStyle(newStyle) :
    setStyle(overStyle)
  }, [done]);
  return (
    <div className={`${styles.progressBar} ${className}`}>
      <div className={styles.progressDone} style={style}>
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  done: PropTypes.string
};

export default ProgressBar;
