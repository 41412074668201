import React from 'react';
import PropTypes from 'prop-types';
import styles from './button.module.scss';

const Button = ({ label, btnClass, fullWidth, iconRight, iconLeft, disabled, onClick, loading, customStyle = {} }) => {
  const btnWidth = fullWidth ? `${styles.btnWide}` : '';
  return (
    <> { !loading ?
      <button className={`${styles[btnClass]} ${btnWidth}`} onClick={onClick} disabled={disabled} style={customStyle}>
        {iconLeft && <div className={styles.iconLeft}>{iconLeft}</div>} {label && label}  {iconRight && <div className={styles.iconRight}>{iconRight}</div>}
      </button> :
      <button className={`${styles[btnClass]}`} disabled={true} style={customStyle}>
        <i className="fa fa-spinner fa-spin" aria-hidden="true" ></i>
      </button>
      }
    </>
  );
};

Button.propTypes = {
  label: PropTypes.node,
  btnClass: PropTypes.string,
  fullWidth: PropTypes.bool,
  iconRight: PropTypes.object,
  iconLeft: PropTypes.object,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  customStyle: PropTypes.object
};

export default Button;
