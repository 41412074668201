/**
 * Created by laslo on 1/14/22.
 */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { mainActions } from '../../../_store/actions/main.actions';
import Input from '../../input/input';
import Selector from '../../selector/selector';
import Button from '../../button/button';
import { isObjectEmpty, getMapingValue, parseDynamicFieldFilters } from '../../../_utils';
// import { objToArray, arrayToObj, isObjectEmpty, getMapingValue, checkConditions, pullFieldsValue } from '../../_utils';
import i18n from '../../../i18n';
import styles from './keySelect.module.scss';


const mapForDefaultSelect = (data, dbName) => {
  return data.map(el => {
    return {
      dbName,
      label: i18n.t(el),
      value: el
    };
  });
}
const mapForSelect = (data = [], dbName, selectLabel, selectField) => {
  return data.map((el) => {
    return {
      dbName: dbName,
      label: el[selectLabel],
      value: el[selectField || selectLabel],
      obj: el
    };
  });
};

const KeySelect = ({ field, fields, onChange, error }) => {
  const [t] = useTranslation();
  const [keyValueArray, setKeyValueArray] = useState([{key: '1', value: ''}])
  const [valueData, setValueData] = useState({})
  const [options, setOptions] = useState([]);
  const [optionMap, setOptionsMap] = useState({});
  const dispatch = useDispatch();


  useEffect(() => {
    if (options && options.length>0 ) {
      let value = fields[field.dbname] || {}
      if (Array.isArray(value)) value = {}
      setValueData(value)
      const result = []
      Object.keys(value).forEach(key => {
        result.push({key, value: value[key]})
      })
      if (result.length === 0) result.push({key: '1', value: ''})
      setKeyValueArray(result);
    }
  }, [options]);

  useEffect(() => {
    fetchData();
  }, [fields[field.selectFilter]]);

  const fetchData = async () => {
    const { dbName, dataName, selectType, selectLabel, selectField, selectFilter, defaultValue } = field
    let list
    if (!defaultValue) {
      let filterData
      const filter = []
      if (field) {
        if (field.selectFilter) {
          let value = getMapingValue(fields, selectFilter)
          if (!value) value = -1
          filter.push({[selectFilter]: value})
        }
      }
      filterData = { filter }
      const response = await dispatch(mainActions.run(dataName, selectType, 'all', filterData));
      list = mapForSelect(response, dbName, selectLabel, selectField);
    } else {
      list = mapForDefaultSelect(defaultValue, dbName);
    }
    const map = {}
    list.forEach(e => map[e.value] = e)
    setOptionsMap(map)
    setOptions(options => ([...list]));
  };

  const onFocusOut = (index) => {
    const changingValue = keyValueArray[index]
    valueData[changingValue.key] = changingValue.value
    setValueData(valueData)
    if (onChange && changingValue.key && changingValue.value) onChange(field.dbname, valueData)
  };

  const handleKeyChange = (e, index) => {
    const { value } = e.target;
    const changingValue = keyValueArray[index]
    changingValue.key = ''+value
    setKeyValueArray([...keyValueArray])
  };

  const handleRemoveOneClick = (e, index) => {
    e.preventDefault();
    const deletedValue = keyValueArray[index]
    keyValueArray.splice(index, 1)
    delete valueData[deletedValue.key]
    setKeyValueArray([...keyValueArray])
    setValueData(valueData)
    if (onChange) onChange(field.dbname, valueData)
  };

  const handleAddNewClick = (e) => {
    e.preventDefault()
    keyValueArray.push({ key: '', value: ''})
    setKeyValueArray([...keyValueArray])
  };

  const handleSelectValueChange = (e, index) => {
    const value = e.value;
    const changingValue = keyValueArray[index]
    changingValue.value = value
    valueData[changingValue.key] = value
    setKeyValueArray([...keyValueArray])
    setValueData(valueData)
    if (onChange && changingValue.key && changingValue.value) onChange(field.dbname, valueData)
  };

  return (
    <div key={field.dbname + '-keySelect' } className={styles.keySelectWrapper}>
      {field.label && <label>{t(field.label)}</label>}
      <small>{t(field.description)}</small>
      {keyValueArray.length > 0 && <div className={styles.keySelectStyle} >
        {keyValueArray.map((element, i) => {
          return (
            <React.Fragment key={i}>
              <div className={styles.keySelectInput}>
                <Input type="text" name="key" placeholder={t(field.label1)} value={t(element.key)} disabled={field.label1Disabled} onChange={e => handleKeyChange(e, i, field.dbname)} label={t(field.label1)} onFocusOut={() => onFocusOut(i)}/>
                <div>
                  <Selector
                    selectClassname="selectForm"
                    options={options}
                    label="Skins"
                    handleChangeSelect={e => handleSelectValueChange(e, i)}
                    placeholder={t('d465729339')}
                    selectedOption={optionMap[element.value]}
                  />
                </div>
                {keyValueArray.length !== 1 &&
                <Button type="button" btnClass='btnSecondary' label={<i className="fa fa-minus" aria-hidden="true"></i>} onClick={(e) => handleRemoveOneClick(e, i)} />
                }
              </div>
              {
                keyValueArray.length - 1 === i &&
                <div className={styles.keySelectAdd}>
                  <Button type="button" btnClass='btnPrimary' label={<i className="fa fa-plus" aria-hidden="true"></i>} onClick={(e) => handleAddNewClick(e)} />
                </div>
              }

              {error && (!element.value && (element.value !== 0)) && field.required && <div className="input-err-msg">{t('d621915485')} {t(field.label1)}</div>}
              {error && (!element.key && (element.key !== 0)) && field.required && <div className="input-err-msg">{t('d621915485')} {t(field.label2)}</div>}
            </React.Fragment>
          )
        })}
      </div>}
    </div>)
};

export default KeySelect;