import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PageHeading from "../../_components/pageHeading/pageHeading";
import styles from "./referral.module.scss";
import Input from "../../_components/input/input";
import Button from "../../_components/button/button";
import { useDispatch } from "react-redux";
import { userActions } from "../../_store/actions/user.actions";
import toastService from "../../_services/toastService";
import logo from "../../_assets/images/logo/elite_logo.svg";
import searchSvg from '../../_assets/images/icons/search.svg';
import layerImg from '../../_assets/images/icons/ok.svg';

const Referral = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [referral, setReferral] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);


  const handleChange = (e) => {
    const { value } = e.target;
    setReferral(value);
  };


  const inviteFriend = async () => {
      const check = await dispatch(userActions.sendReferral({ email: referral }));
      if (check) {
        toastService.show("success", `${t("d59445402")} ${referral}`);
        setShowSuccess(true)
        const timeout = setTimeout(() => {
          setShowSuccess(false)
          clearTimeout(timeout);
        }, 3000);
        setReferral("");
      }
    
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    referral.length
      ? inviteFriend()
      : toastService.show("error", t("d59824546"));
  };

  return (
    <div>
      { !showSuccess ?
      <form className={styles.wrapper} onSubmit={handleSubmit}>
        <div className={styles.logo}>
          <img src={logo} alt="Logo" />
        </div>
        <h2>{t('d10411890')}</h2>
        <p>
          {t('d12038237')}
        </p>
        <div className={styles.input}>
          <Input
            name="referral"
            type="email"
            value={referral}
            onChange={handleChange}
            placeholder={t("d1125298")}
            required
          />
          <Button type="submit" btnClass="btnBright" label="Send" />
        </div>
      </form> : 
      <div className={styles.wrapper}>
        <img className={styles.layer} src={layerImg} alt="OK" />
        <h5>THANK YOU</h5>
      </div> }
    </div>
  );
};

export default Referral;
