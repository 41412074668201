import React from 'react';
import './linksSocial.scss';
import { ReactComponent as FacebookIcon } from '../../_assets/images/social/facebookIcon.svg';
import { ReactComponent as InstagramIcon } from '../../_assets/images/social/instagramIcon.svg';
// import { ReactComponent as DiscordIcon } from '../../_assets/images/social/discordIcon.svg';
// import { ReactComponent as TwitchIcon } from '../../_assets/images/social/twitchIcon.svg';
import { ReactComponent as TwitterIcon } from '../../_assets/images/social/twitterIcon.svg';
import { ReactComponent as YoutubeIcon } from '../../_assets/images/social/youtubeIcon.svg';
import { ReactComponent as PodcastIcon } from '../../_assets/images/social/podcast.svg';

import PropTypes from 'prop-types';

const LinksSocial = () => {
  return (
    <div className="socialLinks">
      <ul>
        <li>
          <a href="https://www.facebook.com/eliteduels/" target="_blank" rel="noopener noreferrer" className="links">
            <FacebookIcon />
          </a>

        </li>
        <li>
          <a href="https://www.instagram.com/eliteduels/" target="_blank" rel="noopener noreferrer" className="links">
            <InstagramIcon />
          </a>
        </li>
        <li>
          <a href="https://twitter.com/eliteduels" target="_blank" rel="noopener noreferrer" className="links">
            <TwitterIcon />
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UCyzi4bxkqDzaseeByxt9dxg" target="_blank" rel="noopener noreferrer" className="links">
            <YoutubeIcon />
          </a>
        </li>
        {/* <li>
          <a href="#" className="links">
            <DiscordIcon />
          </a>
        </li> */}
        <li>
          <a href="http://www.buzzsprout.com/270537" target="_blank" rel="noopener noreferrer" className="links">
            <PodcastIcon />
          </a>
        </li>
        {/* <li>
          <a href="#" className="links">
            <TwitchIcon />
          </a>
        </li> */}
      </ul>
    </div>
  );
};

LinksSocial.propTypes = {
  linksClass: PropTypes.string
};

export default LinksSocial;
