import React from 'react';
import styles from './copyright.module.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const Copyright = ({ ready }) => {
  const [t] = useTranslation();
    return !ready ? null : (
        <div className={`${styles.wrapper} container-wraper`}>
            <h5>
                &copy; {moment().year()} {t('d28166255')} <br />
                Proud member of the Fantasy Sports and Gaming Association
            </h5>
        </div>
    )
}

export default Copyright;