import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mainActions } from '../../../_store/actions/main.actions';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Loading from '../../../_components/Loading';
import styles from './InfluencerList.module.scss';
import CustomPagination from '../../../_components/pagination/serverPagination';
import { useTranslation } from 'react-i18next';
import Button from '../../../_components/button/button';
import ModalYesNo from '../../../_components/modal/modalYesNo';
import SearchBar from '../../../_components/searchBar/searchBar';
import Filter from '../Filter/Filter';

const header = ['d39251520', 'd58873906', 'd234103673', 'd41312757', 'd76823011']
const fields = ['name', 'game', 'contestType', 'createdAt', 'publish']

const InfluencerList = (props) => {
    const [t] = useTranslation();
    const store = useSelector(state => state);
    const dispatch = useDispatch();
    const [influencerContests, setInfluencerContests] = useState();
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [sortType, setSortType] = useState('DEFAULT');
    const [sortIndex, setSortIndex] = useState(-1);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [filters, setFilters] = useState([]);
    const [reload, setReload] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState({
      name: '',
      game: '',
      contestType: '',
      from: '',
      publish: undefined
  });
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
      setLoading(true);
      const sort = {}
      if (sortType !== 'DEFAULT' && sortIndex !== -1 ) {
        sort[fields[sortIndex]] = sortType === 'ASC' ? -1 : 1;
      }
      let filter = [{createdBy: store.auth.user.id}];
      if(filters.length > 0) {
        filter = [...filter, ...filters];
      }
      if (searchText) {
        const textFilters = []
        fields.forEach(field => {
          textFilters.push({ [field]: { '$regex': searchText, '$options': 'i' } })
        })
        filter.push({ $or: textFilters })
      }
      filter.push({ subtype: 'beatThePro'});
      dispatch(mainActions.run('data', 'contest', 'page', {sort, pageNumber, pageSize, filter}));
      reload && setReload(false);
    }, [pageNumber, pageSize, sortType,, searchTerm, filters, reload])

    useEffect(() => {
        if(store.main.data && store.main.data.contest && store.main.data.contest.page) {
          const contest = store.main.data.contest.page;
          setInfluencerContests(contest.rows);
          setTotal(contest.total);
          setLoading(false);
        }

    }, [store.main.data && store.main.data.contest && store.main.data.contest.page])

    const sortData = (sortType, index) => {
      setSortType(sortType);
      setSortIndex(index);
    };

    const editData = (el) => {
      props.history.push({
        pathname: '/influencer/contests/edit',
        search: `?id=${el.id}`
      })
    }

  const onFilter = (receivedFilters, selected) => {
    selected && setSelectedFilters(selected);
    setFilters(receivedFilters);
    setFiltersVisible(false);
  };
  const onCloseFilter = () => {
    setFiltersVisible(false);
  };

    const openDeleteModal = async (e, el) => {
      e.preventDefault();
      setShowModalDelete(true);
      setModalData({ type: 'contest', id: el.id, name: el.name });
    };

    
  const onPageChange = async (page, pageSize) => {
    setPageNumber(page);
    setPageSize(pageSize);
  };

    const handleDelete = async (answer, data) => {
      const { id, type, name } = data;
      if (answer === t('d13586174')) {
        await dispatch(mainActions.run('data', type, 'delete', { id, name }, t('d65237007'))).then(() => {
          setReload(true);
        }
      );;
        setShowModalDelete(false);
      } else {
        setShowModalDelete(false);
      }
    };

    const openInfluencerForm = () => {
      props.history.push('/influencer/contests/new')
    }

    const publish = (el) => {
      dispatch(mainActions.run('data', 'contest', 'update', { id: el.id, publish: true, subtype: 'beatThePro' }, t('d79057940')))
      .then(() => {
          setReload(true);
        }
      );
    }

    const handleSearch = (e) => {
      setSearchText(e.target.value);
      e.preventDefault();
    };
  
    const handleSearchSubmit = (e) => {
      e.preventDefault();
      setSearchTerm(searchText);
    };

    return (
    <div className={styles.wrapper}>
    {filtersVisible && <Filter onSubmit={onFilter} selectedFilters={selectedFilters} onClose={onCloseFilter} />}
    {showModalDelete && <ModalYesNo show={showModalDelete} handleYesNoAnswer={handleDelete} modalData={modalData} question='d54856307' />}
       <div className={styles.header}>
        <Button btnClass='btnFormGreen' label={`New ${t('d7478979')}`} onClick={() => {openInfluencerForm()}} />
        <div className={styles.headerSearch}>
            <SearchBar onChange={handleSearch} value={searchText} onClick={handleSearchSubmit} placeholder={t('d653442620')} />
          </div>
          <Button btnClass='btnFormGreen' label={t('d601389159')} onClick={() => { setFiltersVisible(true) }} />
        </div>
         <div className={styles.tb}>
        <PerfectScrollbar className="purpleScroll">
          {loading || !influencerContests ? <Loading className="admin-list-loader" /> : null}
          <div className={styles.head}>
            <div>
              <b>#</b>
              {header.map((field, i) => (
                <span key={i}>
                  {t(field)}
                  <div className={styles.sort}>
                    {(sortType === 'DEFAULT' || sortIndex !== i) && <div onClick={() => sortData('DESC', i)}>
                      <i className="fa fa-caret-up" aria-hidden="true"></i>
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </div>}
                    {sortType === 'DESC' && sortIndex === i && <div onClick={() => sortData('ASC', i)}>
                      <i className="fa fa-caret-up" aria-hidden="true"></i>
                      <i className="fa fa-caret-down" aria-hidden="true" style={{ color: "#00c84a" }}></i>
                    </div>}
                    {sortType === 'ASC' && sortIndex === i && <div onClick={() => sortData('DEFAULT', i)}>
                      <i className="fa fa-caret-up" aria-hidden="true" style={{ color: "#00c84a" }}></i>
                      <i className="fa fa-caret-down" aria-hidden="true" ></i>
                    </div>}
                  </div>
                </span>
              ))}
            </div>
          </div>
          {influencerContests && influencerContests.length > 0 ? <ul className={styles.body}>
            {influencerContests.map((el, i) => (
              <li key={el._id}>
                <div>
                  <b>{(pageNumber -1) * pageSize + i + 1}</b>
                    <React.Fragment key={i}>
                      <span key={`field-${el.name}`}>{el.name}
                      </span>
                      <span key={`field-${el.game}`}>{el.game}
                      </span>
                      <span key={`field-${el.contestType}`}> {t(el.contestType)}
                      </span>
                      <span key={`field-${el.from}`}>{el.from}
                      </span>
                      <span key={`field-${el.published}`}>{el.publish ? t('d13586174') : 

                        <div className={styles.btnWrapperIcons}>
                          <Button btnClass='btnPrimary' label={<i className="fa fa-check-square"></i>} onClick={(e) => { publish(el) }} />
                        </div>}
                      </span>
                    </React.Fragment>

                </div>
                <div className={styles.btnWrapperIcons}>
                  {!el.publish && <Button btnClass='btnPrimary' label={<i className="fa fa-pencil-square-o" aria-hidden="true"></i>} onClick={(e) => { editData(el) }} />}
                  {<Button btnClass='btnSecondary' label={<i className="fa fa-trash-o" aria-hidden="true"></i>} onClick={(e) => { openDeleteModal(e, el) }} />}
                </div>
              </li>
            ))}

          </ul> : <div className={styles.msg}> <div className="message-info">
            {influencerContests ? t('d58155026') : ''}
          </div> </div>}
        </PerfectScrollbar>
      </div>
      <CustomPagination
        total={total}
        data={influencerContests}
        currentPage={pageNumber}
        currentPageSize={pageSize}
        defaultPageSize={10}
        onChange={onPageChange} />
    </div>);
}

export default InfluencerList;