import React, { useState, useEffect } from 'react';
import styles from './config.module.scss';
import { useTranslation } from 'react-i18next';
import Button from '../../../_components/button/button';
import Select from '../../../_components/selector/selector';
import mainService from '../../../_services/main.service';
import ModalYesNo from '../../../_components/modal/modalYesNo';
import ModalMessage from '../../../_components/modal/modalMessage';

const LEADEROARD_OPTIONS = [
  {label: 'All Time', value: 'all_time'},
  {label: 'Yearly', value: 'yearly'},
  {label: 'Monthly', value: 'monthly'},
  {label: 'Weekly', value: 'weekly'},
  {label: 'Daily', value: 'daily'},
]

const Config = () => {
  const [t] = useTranslation();
  const [saving, setSaving] = useState(false)
  const [resetingLeaderboard, setResetingLeaderboard] = useState(false)
  const [config, setConfig] = useState({})
  const [showModalMessage, setShowModalMessage] = useState(false)
  const [showModalYesNoModal, setShowModalYesNoModal] = useState(false)
  const [yesNoModalText, setYesNoModalText] = useState('')
  const [leaderboard, setLeaderboard] = useState(LEADEROARD_OPTIONS[0]);
  const [oldLeaderboard, setOldLeaderboard] = useState();

  useEffect(() => {
    loadConfig()
  }, [])

  const loadConfig = async () => {
    const result = await mainService.run('config', 'config', 'get', {})
    setConfig(result)
    setOldLeaderboard(result.leaderboard)
    const leaderBoard = LEADEROARD_OPTIONS.find(one => one.value == result.leaderboard)
    setLeaderboard(leaderBoard || LEADEROARD_OPTIONS[0])
  }

  const handleSelectChange = (e) => {
    if (e) {
      setLeaderboard(e)
      setConfig({...config, leaderboard: e.value })
    }
  }

  const resetLeaderboard = async (answer) =>  {
    if (answer === 'd13586174') {
      setResetingLeaderboard(true)
      try {
        await mainService.run('config', 'config', 'reset', {})
      } catch (e) {
      }
      setResetingLeaderboard(false)
    }
    setShowModalYesNoModal(false)
  }

  const saveData = async () =>  {
    setSaving(true)
    let time = 0
    if (oldLeaderboard != config.leaderboard) {
      setOldLeaderboard(config.leaderboard)
      setShowModalMessage(true)
      time = 2000
    }
    setTimeout(async () => {
      try {
        await mainService.run('config', 'config', 'update', config)

      } catch (e) {
      }
      setShowModalMessage(false)
      setSaving(false)
    }, time)
  }

  return (
    <div className={styles.wrapperManagement}>
      {showModalYesNoModal && <ModalYesNo show={showModalYesNoModal} handleYesNoAnswer={resetLeaderboard} question={yesNoModalText} />}
      {showModalMessage && <ModalMessage show={showModalMessage} text={'You Changed loaderboard period, Please wait the recalculation.'} />}
      <div className={styles.setup}>
        <Select label="Leaderboard period" additionalClass="leaderboardPeriod" selectClassname="selectForm" selectedOption={leaderboard} options={LEADEROARD_OPTIONS} handleChangeSelect={handleSelectChange}/>
        <Button loading={resetingLeaderboard} btnClass='btnFormRed' label={t('Reset Leaderboard')} onClick={()=>{setYesNoModalText('This will ignore all user action from past for leaderboard calculation. Are you sure that you want to reset the leaderboard?'); setShowModalYesNoModal(true);}} />
      </div>
      <div className={styles.footerOptions}>
        <Button loading={saving} btnClass='btnFormGreen' label={t('Save')} onClick={saveData} />
      </div>
    </div>
  )
};

export default Config;