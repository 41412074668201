import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import accounting from "accounting-js";
import withImages from "../../_components/core/withImages";

import PlayerProps from "../../_components/playerProps/playerProps";
import { ReactComponent as CloseIcon } from "../../_assets/images/icons/closeIcon.svg";
import { ReactComponent as ArrowDown } from "../../_assets/images/icons/arrowDownWhite.svg";
import avatarDefault from "../../_assets/images/player.png";
import Slides from "../../_components/slides/slides";
import Loading from "../../_components/Loading";
import Login from "../auth/loginComponent/login";
import PlayerPropsDetails from "../../_components/playerPropsDetails/playerPropsDetails";
import PlayerPropsHowToPlay from "../../_components/playerPropsDetails/playerPropsHowToPlay";

import storageService from "../../_services/storage.service";
import toastService from "../../_services/toastService";
import { mainActions } from "../../_store/actions/main.actions";
import mainService from "../../_services/main.service";
import { modalActions } from "../../_store/actions/modal.actions";
import { getPipeline } from "../../_utils";

import { sortPlayerProps, filterPlayerPropsByGame } from "../../_utils/contest";

import { CONTEST_PLAYER_PROPS } from "../../_utils/constants";

import styles from "./playerPicks.module.scss";
import Checkbox from "../../_components/checkbox/checkbox";

const defaultAmount = 5;

const mapGames = (games = [], t) => {
  return games.map((game) => {
    return {
      type: "game",
      value: t(game.name) || game.name,
      label: t(game.name) || game.name,
      id: game.id,
    };
  });
};

const prepareData = (selected = [], paymentAmount, userPlayerPropsId) => {
  const readyMap = { ...paymentAmount, type: "userPlayerProps", selected };
  if (userPlayerPropsId) readyMap.userPlayerPropsId = userPlayerPropsId;
  return readyMap;
};

const calculateWinAmount = (
  entryFee,
  multiplier,
  minAmount,
  maxAmount,
  creditToDolarRatio,
  paymentMethod
) => {
  if (paymentMethod === "credit") {
    minAmount = minAmount * creditToDolarRatio
    maxAmount = maxAmount * creditToDolarRatio
  }
  if (
    !isFinite(entryFee) ||
    entryFee < minAmount ||
    entryFee > maxAmount ||
    !multiplier
  )
    return 0;
  return paymentMethod === "cash" || paymentMethod === ""
    ? (entryFee * multiplier).toFixed(2)
    : ((entryFee / creditToDolarRatio) * multiplier).toFixed(2);
};

const PlayerPicks = (props) => {
  const [gameTypesFilter, setFilterGameTypes] = useState({});
  const [showMoreGames, setShowMoreGames] = useState(false);
  const [selected, setSelectedPlayerProps] = useState(
    storageService.getData("data", "selectedPlayerPicks", "all") || []
  );
  const [games, setGames] = useState(
    storageService.getData("data", "lobby_games", "all")
  );
  const store = useSelector((state) => state);

  const [entryFee, setAmount] = useState(defaultAmount.toFixed(2));
  const [limitConfig, setLimitConfig] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [wallet] = useState(
    storageService.getData("transactions", "wallet", "get", {
      id: store.auth.user ? store.auth.user.id : "-1",
    }) || {}
  );
  const {
    auth: { user },
  } = useSelector((state) => state);
  const [playerPicksData, setPlayerPicksData] = useState(
    storageService.getData("data", "playerPicks", "all") || []
  );

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");

  const {
    match: {
      params: { userPlayerPropsId },
    },
    images,
  } = props;

  const getData = async () => {
    setLoading(true);
    const now = new Date().getTime();

    const filter = [
      {
        from: { $gt: now },
        canceled: { $ne: true },
        finished: { $exists: false },
      },
    ];
    const result = await dispatch(
      mainActions.run(
        "data",
        "playerPicks",
        "all",
        { filter, sort: { from: 1 } },
        null,
        true
      )
    );
    const gamesData = await dispatch(mainActions.run("data", "game", "all"));
    const config = await dispatch(mainActions.run("config", "limit", "get"));
    setLimitConfig(config);
    const players =
      result && Array.isArray(result) ? sortPlayerProps(result) : [];
    setPlayerPicksData(players);
    storageService.setData("data", "playerPicks", "all", players);
    setGames(gamesData);
    setLoading(false);
  };

  const getUserPlayerProps = async () => {
    setLoading(true);
    const result = await dispatch(
      mainActions.run("data", "userPlayerProps", "get", {
        filter: [
          {
            id: userPlayerPropsId,
          },
        ],
      })
    );

    if (result?.paymentMethod) setPaymentMethod(result?.paymentMethod);
    if (result?.selected && Array.isArray(result.selected)) {
      setSelectedPlayerProps(result?.selected || []);
      setAmount(result.entryFee || result.entryCreditFee);
    }
  };

  useEffect(() => {
    if (userPlayerPropsId && user?.id) getUserPlayerProps();
  }, [userPlayerPropsId, user?.id]);

  useEffect(() => {
    getData();
    return () => {
      dispatch(modalActions.closeModal());
      storageService.setData("data", "selectedPlayerPicks", "all", []);
    };
  }, [refetch]);

  const showHowToPlay = () => {
    dispatch(
      modalActions.openModal(<PlayerPropsHowToPlay />, t("d38515927"), "lg")
    );
  };

  const showMoreDetails = () => {
    dispatch(
      modalActions.openModal(
        <PlayerPropsDetails playerPicksData={playerPicksData} />,
        "More details",
        "lg"
      )
    );
  };

  const removeFromFilter = (item) => {
    if (gameTypesFilter[item]) {
      const updatedFilter = { ...gameTypesFilter };
      delete updatedFilter[item];
      setFilterGameTypes(updatedFilter);
    }
  };

  const addToGameFilter = (item) => {
    const updatedFilter = { ...gameTypesFilter, [item.label]: item.label };

    setFilterGameTypes(updatedFilter);
  };

  const toggleMoreGames = () => {
    setShowMoreGames((prev) => !prev);
  };

  const handlePlayerCardClick = (playerPick, bet) => {
    const index = selected.findIndex((item) => item.id === playerPick.id);
    if (index === -1) {
      setSelectedPlayerProps((prev) => [...prev, { ...playerPick, bet }]);
      return;
    }

    if (selected[index].bet === bet) return;

    const updatedPlayers = selected.map((pl, idx) => {
      if (idx === index)
        return {
          ...pl,
          bet,
        };
      return pl;
    });

    setSelectedPlayerProps(updatedPlayers);
    storageService.setData(
      "data",
      "selectedPlayerPicks",
      "all",
      updatedPlayers
    );
  };

  const removeSelectedPlayer = (index) => {
    // const filteredPlayers = selected.filter((item) => item.player.id !== id);
    const filteredPlayers = [...selected];
    filteredPlayers.splice(index, 1);
    setSelectedPlayerProps(filteredPlayers);
    storageService.setData(
      "data",
      "selectedPlayerPicks",
      "all",
      filteredPlayers
    );
  };

  const handleChangeAmount = (e) => {
    let { value } = e.target;

    // remove not a number carracter
    if (isNaN(value)) {
      value = value.replace(/[^0-9\.]/g, "");
      if (value.split(".").length > 2) {
        value = value.replace(/\.+$/, "");
      }
    }
    // remove all caracters after second decimal
    if (
      Array.isArray(value.split(".")) &&
      value.split(".").length === 2 &&
      value.split(".")[1].length > 2
    ) {
      value = value.replace(/^(\d+.?\d{0,2})\d*$/, "$1");
    }

    setAmount(value);
  };

  const caroselIdsMap = {};
  (playerPicksData || []).forEach((item) => {
    if (item?.carosel && Array.isArray(item.carosel)) {
      item.carosel.forEach((item) => {
        caroselIdsMap[item] = item;
      });
    }
  });

  let playersProps = filterPlayerPropsByGame(playerPicksData, gameTypesFilter);

  const selectedPlayersMap = {};
  selected.forEach((item) => (selectedPlayersMap[item.id] = item.bet));

  const gamesData = useMemo(() => {
    return mapGames(games, t);
  }, [games]);

  const maximumDeposit = user?.playerPropsMaximumDeposit
    ? user.playerPropsMaximumDeposit
    : limitConfig?.playerPropsMaximumDeposit;

  const winAmount = calculateWinAmount(
    entryFee,
    limitConfig?.playerPropsMultiplier[
      (selected || []).filter((pick) => !pick?.canceled).length
    ],
    limitConfig?.playerPropsMinimumDeposit,
    maximumDeposit,
    limitConfig?.creditDolarRatio,
    paymentMethod
  );

  const disableFinishBtn =
    !isFinite(entryFee) ||
    !limitConfig?.playerPropsMultiplier[
      (selected || []).filter((pick) => !pick?.canceled).length
    ] ||
    entryFee > (paymentMethod === "credit" ?
    limitConfig.playerPropsMaximumDeposit * limitConfig.creditDolarRatio : limitConfig.playerPropsMaximumDeposit) ||
    entryFee < limitConfig.playerPropsMinimumDeposit ||
    (paymentMethod === "credit" &&
      entryFee <
        limitConfig.playerPropsMinimumDeposit * limitConfig.creditDolarRatio);

  const handleSubmit = async () => {
    if (!localStorage.getItem("user")) {
      sessionStorage.setItem(`playerPool_Entry`, { selected: selected });
      dispatch(modalActions.openModal(<Login />, "Login modal", "md"));
      return;
    }

    if (!paymentMethod && !userPlayerPropsId) {
      // "Please select a payment method"
      toastService.show("error", t("d38293220"));
      return;
    }
    const paymentAmount =
      paymentMethod === "cash"
        ? {
            entryFee: Number(entryFee),
          }
        : { entryCreditFee: Number(entryFee) };
    if (disableFinishBtn) return;
    setSubmitting(true);

    const readyData = prepareData(
      selected,
      { ...paymentAmount, paymentMethod: paymentMethod || "cash" },
      userPlayerPropsId
    );
    try {
      if (userPlayerPropsId) {
        await mainService.run("data", "userPlayerProps", "update", readyData);
      } else {
        if (window.gtag_report_finish_conversion) {
          window.gtag_report_finish_conversion()
        }
        await mainService.run("data", "userPlayerProps", "new", readyData);
      }
      dispatch(
        mainActions.agg(
          "wallet",
          "transactions",
          "user",
          "get",
          getPipeline("wallet", user.id),
          user.id
        )
      );
      setSubmitting(false);
      history.push({ pathname: "/contests", hash: "#upcoming" });
      toastService.show("success", t("d40747639"));
    } catch (err) {
      toastService.show("error", err);
      setSubmitting(false);
      // if one of the matches is canceld we are checking internalCode
      // and updeting storageService, state and force refe
      if (err?.response?.data?.error?.internalCode === 100001) {
        const { data = {} } = err.response.data.error;
        const canceledMatches = Object.keys(data).filter((key) => !!data[key]);
        if (canceledMatches.length) {
          const filteredSelected = selected?.filter(
            (item) => !canceledMatches.includes(item?.match?.id)
          );
          storageService.setData(
            "data",
            "selectedPlayerPicks",
            "all",
            filteredSelected
          );
          setSelectedPlayerProps(filteredSelected);
          setRefetch((prev) => !prev);
        }
      }
    }
  };

  const onPaymentChange = (e) => {
    const { value } = e.target;
    setPaymentMethod(value);
  };

  return (
    <div className="global-layout-content">
      {/* commented for now */}
      {/* <section className={styles.leftSideBanner}> */}
      {/* <span
          className={styles.navigationBtn}
          onClick={() => history.push("/loby")}
        >
          <ArrowIcon className={styles.arrowIcon} /> {t("d23525167")}
        </span> */}
      <div className="layout-content-left-banner">
        <Slides
          position="Side"
          ids={Object.keys(caroselIdsMap)}
          show={!!Object.keys(caroselIdsMap).length}
        />
      </div>
      {/* </section> */}

      <main className="layout-content landing-page">
        <section className="layout-content-center">
          {/* commented for now */}
          {/* <section className={styles.navigationWrapper}>
          <nav className={styles.navBtnWrapper}>
            <span
              className={styles.navigationBtn}
              onClick={() => history.push("/loby")}
            >
              <ArrowIcon className={styles.arrowIcon} /> {t("d23525167")}
            </span>
          </nav>
          <nav className={styles.navBtnWrapper}>
            <span
              className={styles.navigationBtn}
              onClick={() => history.push("/leaderboard")}
            >
              {t("d2282565")}
              <ArrowIcon className={styles.arrowIconRight} />
            </span>
          </nav>
        </section> */}
          <div className={styles.centerWrapper}>
            <div className={styles.leftSide}>
              <div className={styles.filteredTypesWrapper}>
                {Object.keys(gameTypesFilter).map((gameType) => (
                  <div
                    className={styles.filteredType}
                    key={gameType + "filter"}
                  >
                    <span>{gameTypesFilter[gameType]}</span>{" "}
                    <span
                      className={styles.closeIconWrapper}
                      onClick={() => removeFromFilter(gameType)}
                    >
                      <CloseIcon />
                    </span>
                  </div>
                ))}
              </div>
              <header className={styles.headerLeft}>
                <div>
                  <span className={styles.howToPlay} onClick={showHowToPlay}>
                    How to play
                  </span>
                  <span className={styles.details} onClick={showMoreDetails}>
                    More details
                  </span>
                </div>
                <div className={styles.dropdown} onClick={toggleMoreGames}>
                  <span className={styles.dropdownMoreGames}>
                    More games
                    <ArrowDown />
                  </span>
                  <ul
                    className={
                      showMoreGames
                        ? styles.dropdownContentOpen
                        : styles.dropdownContent
                    }
                  >
                    {gamesData.map((item) => (
                      <li
                        key={item.id}
                        className={
                          gameTypesFilter[item.value] ? styles.selected : ""
                        }
                        onClick={() => addToGameFilter(item)}
                      >
                        {item.label}
                      </li>
                    ))}
                  </ul>
                </div>
              </header>
              <div className={styles.playersWrapper}>
                {playersProps && playersProps.length ? (
                  <PlayerProps
                    playerProps={playersProps}
                    onClick={handlePlayerCardClick}
                    selectedPlayersMap={selectedPlayersMap}
                    addCustomStyle={true}
                  />
                ) : !loading ? (
                  <div className="message-info">
                    There are no players available now.
                  </div>
                ) : (
                  <Loading />
                )}
              </div>
            </div>
            <div className={styles.rightSide}>
              <section className={styles.currentEntryWrapper}>
                <header className={styles.currentEntryHeader}>
                  <div>
                    <span className={styles.entryTitle}>Current entry</span>
                  </div>
                  <div className={styles.currentEntrySubTitleWrrapper}>
                    <hr />
                    <span className={styles.currentEntrySubTitle}>
                      {selected.length}{" "}
                      {selected.length > 1 ? "players" : "player"} selected
                    </span>
                    <hr />
                  </div>
                </header>
                <div className={styles.selectedPlayers}>
                  {selected.map((item, index) => {
                    if (
                      !item ||
                      !item.player ||
                      !item.action ||
                      !item.from ||
                      !isFinite(item.value)
                    ) {
                      return null;
                    }
                    return (
                      <>
                        <SelectedPlayer
                          playerPick={item}
                          key={item.id}
                          player={item.player}
                          bet={item.bet}
                          image={
                            item.player?.image ||
                            images?.[`${item.player.id}_profileAvatar`] ||
                            avatarDefault
                          }
                          value={item.value}
                          removeSelectedPlayer={removeSelectedPlayer}
                          handlePlayerCardClick={handlePlayerCardClick}
                          match={item.match}
                          playerPropsId={item.playerPropsId}
                          index={index}
                          action={item.action}
                          game={item.game}
                          boosted={item.boosted}
                          vsTeam={item.match?.teams?.find(
                            (team) => team !== item.player.team
                          )}
                        />
                        {index < selected.length - 1 ? (
                          <hr className={styles.hrSeparator} />
                        ) : null}
                      </>
                    );
                  })}
                </div>
              </section>
              <div>
                {!userPlayerPropsId ? (
                  <div className={styles.checkboxDiv}>
                    <Checkbox
                      checkboxClass="checkboxInputClass"
                      name="paymentMetod"
                      value="credit"
                      isSelected={paymentMethod === "credit"}
                      onChange={onPaymentChange}
                      label={t("d96603721")}
                    />
                    <Checkbox
                      checkboxClass="checkboxInputClass"
                      name="paymentMetod"
                      value="cash"
                      isSelected={paymentMethod === "cash"}
                      onChange={onPaymentChange}
                      label={t("d96275128")}
                    />
                  </div>
                ) : null}
                {paymentMethod === "" ? (
                  <div>
                    <p className={styles.warningMsg}>
                      Please check payment method
                    </p>
                  </div>
                ) : null}
                <div className={styles.registerWrapper}>
                  <div className={styles.inputWrapperAmount}>
                    <label htmlFor="entryFee">
                      {t("d71557884")}
                      {paymentMethod === "cash" || paymentMethod === ""
                        ? " $"
                        : " "}{" "}
                    </label>
                    <span className={styles.dolarSign}>
                      {paymentMethod === "cash" || paymentMethod === ""
                        ? " $"
                        : " "}
                    </span>
                    {userPlayerPropsId ? (
                      <div className={styles.inputNumber}>{entryFee}</div>
                    ) : (
                      <input
                        id="entryFee"
                        name="entryFee"
                        className={styles.inputNumber}
                        type="text"
                        value={entryFee}
                        onChange={handleChangeAmount}
                      />
                    )}
                  </div>
                  <div className={styles.finishBtnWrapper}>
                    <button
                      disabled={disableFinishBtn}
                      className={styles.finishBtn}
                      onClick={handleSubmit}
                    >
                      {userPlayerPropsId ? t("d70225352") : t("d30907702")}
                      {submitting ? (
                        <span className={styles.spinner}>
                          <i
                            className="fa fa-spinner fa-spin"
                            aria-hidden="true"
                          ></i>
                        </span>
                      ) : null}
                    </button>
                  </div>
                  <div className={styles.inputWrapperWin}>
                    <label htmlFor="win">To win</label>
                    <input
                      id="win"
                      name="win"
                      className={styles.inputNumberWin}
                      type="text"
                      min="0"
                      value={accounting.formatMoney(winAmount)}
                      readOnly
                    />
                  </div>
                </div>
                <div>
                  {limitConfig?.playerPropsMultiplier[
                    (selected || []).filter((pick) => !pick?.canceled).length
                  ] ? (
                    paymentMethod &&
                    paymentMethod === "credit" &&
                    entryFee <
                      limitConfig?.playerPropsMinimumDeposit *
                        limitConfig?.creditDolarRatio ? (
                      <p className={styles.warningMsg}>
                        Minimum entry amount is{" "}
                        {limitConfig?.playerPropsMinimumDeposit *
                          limitConfig?.creditDolarRatio}
                      </p>
                    ) : paymentMethod &&
                      paymentMethod === "cash" &&
                      entryFee < limitConfig?.playerPropsMinimumDeposit ? (
                      <p className={styles.warningMsg}>
                        Minimum entry amount is{" "}
                        {limitConfig?.playerPropsMinimumDeposit} $
                      </p>
                    ) : null
                  ) : null}
                  {limitConfig &&
                  !limitConfig?.playerPropsMultiplier[
                    (selected || []).filter((pick) => !pick?.canceled).length
                  ] ? (
                    <p className={styles.warningMsg}>
                      Please select{" "}
                      {Object.keys(limitConfig.playerPropsMultiplier).join(
                        ", "
                      )}{" "}
                      players
                    </p>
                  ) : null}
                  {(
                    paymentMethod &&
                    paymentMethod === "credit" &&
                    entryFee > (limitConfig?.playerPropsMaximumDeposit *
                      limitConfig?.creditDolarRatio) ? (
                    <p className={styles.warningMsg}>
                    Maximum entry amount is{" "}
                    {limitConfig?.playerPropsMinimumDeposit *
                      limitConfig?.creditDolarRatio}
                    </p>
                    ) : paymentMethod &&
                    paymentMethod === "cash" &&
                    entryFee > limitConfig?.playerPropsMaximumDeposit ? (
                    <p className={styles.warningMsg}>
                    Maximum entry amount is{" "}
                    {limitConfig?.playerPropsMinimumDeposit} $
                    </p>
                    ) : null
                    )}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* commented for now */}
        {/* <section className={styles.rightSideBanner}>
        <span
          className={styles.navigationBtn}
          onClick={() => history.push("/leaderboard")}
        >
          {t("d2282565")}
          <ArrowIcon className={styles.arrowIconRight} />
        </span>
        
      </section> */}
      </main>
      <div className="layout-content-right-banner">
        <Slides
          position="Side"
          ids={Object.keys(caroselIdsMap)}
          show={!!Object.keys(caroselIdsMap).length}
        />
      </div>
    </div>
  );
};

export default withImages(PlayerPicks);

const SelectedPlayer = ({
  playerPick,
  player,
  bet,
  image,
  value,
  removeSelectedPlayer,
  handlePlayerCardClick,
  index,
  vsTeam,
  action,
  boosted,
}) => {
  const [t] = useTranslation();

  return (
    <article className={styles.selectedPlayerWrapper}>
      <div className={styles.selectedPlayerCard}>
        <div className={styles.selectedPlayerImageWrapper}>
          {playerPick?.dnp ? (
            <div className={styles.canceled}>
              <span>Did not play</span>
            </div>
          ) : null}
          <img src={image} className={styles.playerImg} />
        </div>
        <div className={styles.player}>
          <div className={styles.playerData}>
            <span className={styles.label}>{t("d2582874")}</span>
            <span className={styles.playerName}>{player.nick}</span>
            <span className={styles.team}>{player.team}</span>
          </div>
        </div>
        <div className={styles.nextEvent}>
          <span className={styles.label}>{`${t("d84431338")} ${t(
            "d72957670"
          )}`}</span>
          <span className={styles.time}>
            {playerPick?.match?.startAt
              ? moment(playerPick.match.startAt).format("MM/DD/YYYY")
              : ""}
          </span>
          <span className={styles.vsTeam}>vs {vsTeam}</span>
        </div>
        <div className={styles.smallScreenWrapper}>
          <span className={styles.playerName}>{player.nick}</span>
          <span className={styles.time}>
            {playerPick?.match?.startAt
              ? moment(playerPick.match.startAt).format("MM/DD/YYYY")
              : ""}
          </span>
          <span className={styles.vsTeam}>vs {vsTeam}</span>
        </div>
        <div className={styles.kills}>
          <span className={styles.label}>projected</span>
          <span className={styles.label}>{action}</span>
          <span
            className={
              boosted ? styles.numberOfKillsBoosted : styles.numberOfKills
            }
          >
            {value}
          </span>
        </div>
        <div className={styles.arrowWrapper}>
          <div
            className={
              bet === CONTEST_PLAYER_PROPS.BET_TYPES.OVER
                ? styles.overSelected
                : styles.over
            }
            onClick={() =>
              handlePlayerCardClick(
                playerPick,
                CONTEST_PLAYER_PROPS.BET_TYPES.OVER
              )
            }
          >
            <span className={styles.arrowUp}></span>
            <span>Over</span>
          </div>
          <div
            className={
              bet === CONTEST_PLAYER_PROPS.BET_TYPES.UNDER
                ? styles.underSelected
                : styles.under
            }
            onClick={() =>
              handlePlayerCardClick(
                playerPick,
                CONTEST_PLAYER_PROPS.BET_TYPES.UNDER
              )
            }
          >
            <span>Under</span>
            <span className={styles.arrowDown}></span>
          </div>
        </div>
        <span
          className={styles.closeIcon}
          onClick={() => removeSelectedPlayer(index)}
        >
          <CloseIcon />
        </span>
      </div>
    </article>
  );
};
