import React from 'react';
import styles from './forms.module.scss';
import DynamicForm from '../../../_components/dynamicForm/dynamicForm';
import { isObjectEmpty } from '../../../_utils';

const Forms = ({ history, form, editData, formMode, onSubmit, isImport }) => {
  return (
    <div className={styles.wrapper}>
      {!isObjectEmpty(form.fields) && <DynamicForm history={history} onSubmit={onSubmit} form={form} formMode={formMode} editData={editData} isImport={isImport}/>}
    </div>
  );
};

export default Forms;
