import React, { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Button from '../../../_components/button/button';
import styles from './events.module.scss';
// import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../../_store/actions/modal.actions';
import EventsAdd from './eventsAdd';
import { mainActions } from '../../../_store/actions/main.actions';
import ModalYesNo from '../../../_components/modal/modalYesNo';
import { getAllPlayers, getAllPlayersActions } from './util';

const EventsEdit = ({ data, onSubmit }) => {

  const store = useSelector(state => state);
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [events, setEvents] = useState([]);
  const [show, setShow] = useState(false);
  const [match, setMatch] = useState('');
  const [headers, setHeaders] = useState([]);
  const [players, setPlayers] = useState([]);
  const [playerActions, setPlayerActions] = useState({});
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [activeTab, setActiveTab] = useState('events');
  useEffect(() => {
    if (data) {
      handleMatchSelect(data);
    }
  }, [onSubmit, data]);

  useEffect(() => {
    if (store && store.modal.name) {
      if (store.modal.name === "EditEvent" || store.modal.name === "AddEvent") {
        if (!store.modal.show) {
          onSubmit();
        }
      }
    }
  }, [store && store.modal && store.modal.show]);

  const handleMatchSelect = (match) => {
    setEvents(match.events || [])
    const players = getAllPlayers(match);
    const playerActions = getAllPlayersActions(match, players);
    setPlayers(players)
    setPlayerActions(playerActions)
    const actions = data.game && data.game.rules ? Object.keys(data.game.rules).map(key => {
      return key;
    }) : [];

    setHeaders(actions)
    setMatch(match);
    setShow(true);
  };

  const _getPlayerNick = (actions, key) => {
    const action = actions.find(one => one[key])
    if (!action) return ''
    const playerId = action[key]
    return playerActions[playerId] && playerActions[playerId].player ? playerActions[playerId].player.nick : '-'
  }

  const openEditModal = (event) => {
    event.actions.forEach(action => {
      const key = Object.keys(action).find(key => key != 'points')
      if (key && playerActions[action[key]]) action[key] = playerActions[action[key]].player
    })
    dispatch(modalActions.openModal(<EventsAdd data={data} selectedMatch={match} editData={event} type="update" onSubmit={onSubmit} />, 'EditEvent', 'xlg'));
  };
  const openAddModal = () => {
    dispatch(modalActions.openModal(<EventsAdd data={data} selectedMatch={match} onSubmit={onSubmit} type="new" />, 'AddEvent', 'xlg'));
  };
  const openDeleteModal = async (e, id) => {
    e.preventDefault();
    setShowModalDelete(true);
    setModalData({ id });
  };
  const handleDelete = async (answer, data) => {
    const { id } = data;
    if (answer === t('d13586174')) {
      await dispatch(mainActions.run('events', 'game', 'delete', { id }, t('d65237007')));
      onSubmit();
      setShowModalDelete(false);
    } else {
      setShowModalDelete(false);
    }
  };
  const toggleTabContent = (tab) => {
    setActiveTab(tab)
  }
  return (
    <div>
      {showModalDelete && <ModalYesNo show={showModalDelete} handleYesNoAnswer={handleDelete} modalData={modalData} question='d54856307' />}
      {!show ? <>
      </> :
        <div className={styles.editWrapper}>
          <div className={styles.tabs}>
            <h5 className={activeTab === 'events' ? `${styles.active}` : `${styles.inactive}`} onClick={() => { toggleTabContent('events') }}>{t('d01008401')}</h5>
            <h5 className={activeTab === 'results' ? `${styles.active}` : `${styles.inactive}`} onClick={() => { toggleTabContent('results') }} >Final results</h5>
          </div>
          <div className={styles.tabContent}>
            {activeTab === 'results' ? <>
              {events && <div>
                <PerfectScrollbar className="greenScroll">
                  <div className={styles.tbList}>
                    <ul className={styles.head}>
                      <li><span> {t('d2582874')}</span></li>
                      {headers &&
                        <> {headers.map((action, i) => (
                          <li key={i}>
                            <span>{action}</span>
                          </li>
                        ))}
                        </>}
                    </ul>
                    <ul className={styles.body}>
                      {players.map((player, i) => (
                        <li key={'player-' + i}>
                          <span>{player.nick}</span>
                          {headers.map((key, i) => (
                            <React.Fragment key={'playerActions-' + i}>
                              <span key={i}>{playerActions[player.id] ? playerActions[player.id][key] || 0 : 0}</span>
                            </React.Fragment >
                          ))}
                        </li>
                      ))}
                    </ul>
                  </div>
                </PerfectScrollbar>
              </div>} </> :
              <div className={styles.multipleList}>
                <div className={styles.heading}>
                  <Button btnClass='btnFormGreen' label={t('d90167144')} onClick={openAddModal} />
                </div>

                {events &&
                  <div className={styles.items}>
                    <PerfectScrollbar className="greenScroll">
                      <ul className={styles.head}>
                        <li><span>{t('d915778')}</span></li>
                        {headers &&
                          <> {headers.map((action, i) => (
                            <li key={i}>
                              <span>{action}</span>
                            </li>
                          ))}
                          </>}
                        <li className={styles.headEdit}><span>{t('d323691769')}</span></li>
                      </ul>
                      <div className={styles.bodyWrapper}>
                        {events.map((event, i) => (
                          <ul className={styles.body} key={i}>
                            <li><span> {moment(event.timestamp).format('MM/DD hh:mmA')}</span></li>
                            {headers.map((action, i) => (
                              <li key={i}>
                                <span>{_getPlayerNick(event.actions, action)}</span>
                              </li>
                            ))}
                            <li className={styles.btnFixed}>
                              <div>
                                <Button btnClass='btnPrimary' label={<i className="fa fa-pencil-square-o" aria-hidden="true"></i>} onClick={() => { openEditModal(event) }} />
                                <Button btnClass='btnSecondary' label={<i className="fa fa-trash-o" aria-hidden="true"></i>} onClick={(e) => { openDeleteModal(e, event.id) }} />
                              </div>
                            </li>
                          </ul>))}
                      </div>
                    </PerfectScrollbar>
                  </div>
                }

              </div>}
          </div>
        </div>}
    </div>
  );
};

export default EventsEdit;
