/**
 * Created by laslo on 7/2/20.
 */
import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner'
import './style.scss'

const Loading = ({ className, height, width, custom, label, delay = 3000}) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => { setVisible(true)}, delay)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    visible && <div className="loader-wrapper">
      {label && <label className="loader-text">{label}</label>}
      <Loader
        className={className + custom ? " app-loader" : ""}
        type="Rings"
        color="#646b9b"
        height={height || 100}
        width={width || 100}
      />
    </div>
  );
}

export default Loading