import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import accounting from 'accounting-js';
import PropTypes from 'prop-types';
import arrowDown from '../../_assets/images/icons/navbar/arrowDown_icon.png';
import arrowUp from '../../_assets/images/icons/navbar/arrowUp_icon.png';
import { getPipeline } from '../../_utils';
import avatarDefault from '../../_assets/images/avatar.png';
import UserMenu from './userMenu';

import './navbar.scss';

import withImages from '../../_components/core/withImages';
import storageService from '../../_services/storage.service'
import { mainActions } from '../../_store/actions/main.actions';
import { sessionService } from '../../_sessionService/storage';
import { useTranslation } from 'react-i18next';

const AuthNav = ({ user, toggleAuthNav, showUserMenu, isMobile, acquireImages, images }) => {
  const store = useSelector(state => state);
  const [t] = useTranslation();
  const [avatarSrc, setAvatarSrc] = useState(sessionService.getImg(`${user.id}_avatar`));
  const [wallet, setWallet] = useState(storageService.getData('transactions', 'wallet', 'get', { id: user.id }) || {});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(mainActions.agg('wallet', 'transactions', 'user', 'get', getPipeline('wallet', user.id), user.id))
  }, []);

  useEffect(() => {
    if (store.main.transactions && store.main.transactions.wallet && store.main.transactions.wallet.data && store.main.transactions.wallet.data[user.id]) {
      const userWallet = store.main.transactions.wallet.data[user.id];
      setWallet(userWallet)
    }
  }, [store.main.transactions && store.main.transactions.wallet && store.main.transactions.wallet.data && store.main.transactions.wallet.data[user.id]]);

  useEffect(() => {
    if (user) {
      acquireImages([
        { name: `${user.id}_avatar`, width: 400, height: 400 }
      ]);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      setAvatarSrc(sessionService.getImg(`${store.auth.user.id}_avatar`) ? sessionService.getImg(`${store.auth.user.id}_avatar`) : images[`${user.id}_avatar`]);
    }
  }, [user && images[`${user.id}_avatar`]]);

  return (
    <div className="nav-right-auth">
      <div className="nav-user">
        <div className="nav-avatar">
          <img src={avatarSrc ? avatarSrc : avatarDefault} alt="" />
        </div>
        <div className="user-info">
          <span>{user.username}</span>
          <div className="nav-wallet">
            <div><small>{t('d96275128')}:<span>{accounting.formatMoney(wallet.amount || 0)}</span></small></div>
            <div><small> {t('d96603721')}:<span>{wallet.credit || 0}</span></small></div>
          </div>

        </div>
      </div>
      <div className="toggle-arrow">
        <div onClick={toggleAuthNav}>
          {!showUserMenu ? <img src={arrowDown} alt="arrow down" /> :
            <img src={arrowUp} alt="arrow up" />}
        </div>
        {!isMobile && showUserMenu ? <div className="user-menu-web">
          <UserMenu toggleAuthNav={toggleAuthNav} show={showUserMenu} />
        </div> : null}
      </div>
    </div>
  );
};

AuthNav.propTypes = {
  toggleAuthNav: PropTypes.func,
  showUserMenu: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default withImages(AuthNav);
