
const env = process.env.REACT_APP_ENVIROMENT || 'dev';
let url = '';
let wsUrl = '';
let stripeKey = '';
let paypalId
if (env == "dev"){
    url = "http://localhost:3000"
    wsUrl = "ws://localhost:3000"
    stripeKey = "pk_test_51Heba4BKrHXWvtGMmj5IsNqBMF8NDVR2vK3ctlt4omFKaybBBtYq4V0clq7B6fYXI27K3TVyh5ttEMZoWnWSjYhV001R7Zd9J9"
    paypalId = "ASEUncwE95oaIflsTp-8ZMoJXGHia6oZjeM65ZWvg1Jd3_NMn_0ePnH6Ta8F6H5L8EMoI6hDKwGfNXwu"
} else if (env == 'staging'){
    url = "https://edsp.eliteduels.gg:11761"
    wsUrl = "wss://edsp.eliteduels.gg:11761"
    stripeKey = "pk_test_51Heba4BKrHXWvtGMmj5IsNqBMF8NDVR2vK3ctlt4omFKaybBBtYq4V0clq7B6fYXI27K3TVyh5ttEMZoWnWSjYhV001R7Zd9J9"
    paypalId = "ASEUncwE95oaIflsTp-8ZMoJXGHia6oZjeM65ZWvg1Jd3_NMn_0ePnH6Ta8F6H5L8EMoI6hDKwGfNXwu"
} else if (env == "prod" ){
    url = "https://eliteduels.gg"
    wsUrl = "wss://eliteduels.gg"
    stripeKey = "pk_live_MPyB9vOlkKHNu00XXff490iP"
    paypalId = "AfgJ9IAv1gxEQgKbzamrn0jtEj6Ejqmw1VZPcjbBgYnvWlfFYCMVJfwdYUyEgMGWjWBUejGg3Ors7GhE"
} else if (env == "new_prod"){
    url = "https://tmp.eliteduels.gg"
    wsUrl = "wss://tmp.eliteduels.gg"
    stripeKey = "pk_test_51Heba4BKrHXWvtGMmj5IsNqBMF8NDVR2vK3ctlt4omFKaybBBtYq4V0clq7B6fYXI27K3TVyh5ttEMZoWnWSjYhV001R7Zd9J9"
    paypalId = "ASEUncwE95oaIflsTp-8ZMoJXGHia6oZjeM65ZWvg1Jd3_NMn_0ePnH6Ta8F6H5L8EMoI6hDKwGfNXwu"
}
export { url, wsUrl, stripeKey, paypalId, env };
