import { dataConstants } from '../constants';

const initialState = {
  loading: false
};

export const data = (state = initialState, { actionType, type, mode, sent, result, error }) => {
  const newState = { ...state };
  if (!newState[type]) newState[type] = { data: {}, list: [] };
  let typeData = newState[type];
  if (!typeData.data) typeData.data = {};
  if (!typeData.list) typeData.list = [];
  switch (actionType) {
    case dataConstants.DATA_REQUEST:
      newState[type] = { mode, sent, loading: true };
      break;
    case dataConstants.DATA_SUCCESS:
      if (result.total !== undefined && Array.isArray(result.rows)) {
        typeData.list = result.rows;
        typeData.total = result.total;
      } else if (sent.id) {
        typeData.data[sent.id] = result;
      } else {
        // added because UserContest agregate response for Results component return response without rows
        typeData.data = result;
      }
      newState[type] = { ...typeData, mode, sent, loading: false };
      break;
    case dataConstants.DATA_FAILURE:
      newState[type] = { mode, sent, loading: false, error };
      break;
    default:
      return newState;
  }
  return newState;
};
