import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import SmallTitle from '../../_components/smallTitle/smallTitle';
import styles from './profile.module.scss';
// import Input from '../../_components/input/input';
import Selector from '../../_components/selector/selector';
import Button from '../../_components/button/button';
import { useTranslation } from 'react-i18next';
import documentsService from '../../_services/documents.service';
import toastService from '../../_services/toastService';
import Download from '../../_components/download/download';
import close from '../../_assets/images/icons/close6710.svg';
const VerifyAccount = ({ userId }) => {
  const [t] = useTranslation();
  const refFileIdentification = useRef(null);
  const refFileResidence = useRef(null);
  const [selectedID, setSelctedId] = useState('');
  const [errorRes, setErrorRes] = useState(false);
  const [errorId, setErrorId] = useState(false);
  const [errorType, setErrorType] = useState(false);
  const [selectedResidence, setSelectedResidence] = useState('');
  const [file, setFile] = useState({});
  const [selectedFile, setSelectedFile] = useState({});
  const [infoText, setInfoText] = useState(false);
  const [update, setUpdate] = useState(true);
  const isValid = (type) => {
    return type === "application/pdf" || type === "image/png" || type === "image/jpg" || type === "image/jpeg"
  }
  const onFileChange = (e) => {
    setErrorRes(false);
    setErrorId(false);
    setErrorType(false);
    if (e.target && e.target.files[0] && isValid(e.target.files[0].type)) {
      const { name } = e.target;

      const file = e.target.files[0];
      setSelectedFile({ ...selectedFile, [name]: file.name })
      const data = new FormData();
      data.append('file', file);
      data.append('type', name);
      setFile(prevFile => ({ ...prevFile, [name]: data }));
    } else {
      setErrorType(true);
    }

  };
  const handleSelectChange = (selected, type) => {
    setErrorRes(false);
    setErrorId(false);
    if (type === 'd759510') {
      setSelctedId(selected);
    } else {
      setSelectedResidence(selected);
    }
  };
  const handleDelete = async () => {
    if (selectedFile['d759510']) {
      if (selectedID && file[selectedID.type]) {
        setSelctedId(null);
        setSelectedFile({ ...selectedFile, 'd759510': null });
        setFile({ ...file, 'd759510': null });
      }
    }
  }
  const handleSubmit = async () => {
    if (selectedFile['d759510']) {
      if (selectedID && file[selectedID.type]) {
        file[selectedID.type].append('subtype', selectedID.value);
        await documentsService.uploadDocument(file[selectedID.type]);
        toastService.show('success', t('d43334659'));
        setSelctedId(null);
        setSelectedFile({ ...selectedFile, 'd759510': null });
        setFile({ ...file, 'd759510': null });
      } else {
        setErrorId(true);
      }
    }

    if (selectedFile['d47956282']) {
      if (selectedResidence && file[selectedResidence.type]) {
        file[selectedResidence.type].append('subtype', selectedResidence.value);
        await documentsService.uploadDocument(file[selectedResidence.type]);
        toastService.show('success', t('d43334659'));
        setSelectedResidence(null);
        setSelectedFile({ ...selectedFile, 'd47956282': null });
        setFile({ ...file, 'd47956282': null });
      } else {
        setErrorRes(true);
      }
    }
    setUpdate(!update);
  };
  const triggerUploadBtn = (e, type) => {
    if (type === 'd759510') {
      if (selectedID) {
        refFileIdentification.current.click();
      } else {
        setErrorId(true);
      }
    } else {
      if (selectedResidence) {
        refFileResidence.current.click();
      } else {
        setErrorRes(true);
      }
    }
  };
  const handleTextDisplay = () => {
    setInfoText(!infoText);
  }
  const identification = [
    { value: 'd61504108', label: t('d61504108'), type: 'd759510' },
    { value: 'd63654046', label: t('d63654046'), type: 'd759510' },
    { value: 'd20106971', label: t('d20106971'), type: 'd759510' }
  ];
  const residence = [
    { value: 'd64117795', label: t('d64117795'), type: 'd47956282' },
    { value: 'd6443272', label: t('d6443272'), type: 'd47956282' },
    { value: 'd20106971', label: t('d20106971'), type: 'd47956282' }
  ];
  const info = [
    { id: 1, text: t('d73571727') }, { id: 2, text: t('d73852389') }, { id: 3, text: t('d74177296') }
  ];
  return (
    <div className={styles.verifyWrapper}>
      <p>{t('d90621870')}</p>
      <h5 className={styles.verifyH5}>{t('d88854793')}</h5>
      <div className={styles.info}>
        <h5 onClick={handleTextDisplay}> {t('d65664391')}</h5>
        <div className={infoText ? styles.text : styles.hidden}>
          <h5>{t('d74803607')}</h5>
          {info.map(item => (
            <li key={item.id}>{item.text}</li>
          ))}
          <h5>
            <Link to="/contact">{t('d77926439')}</Link> {t('d7873638')}
          </h5>
        </div>
      </div>
      <div className={styles.uploadWrapper}>
        <div className={styles.upload}>
          <div style={{width:"100%"}}>
            <h4>{t('d759510')}</h4>
            <h5>
              {t('d67213464')}
            </h5>
          </div>
          <div className={styles.uploadBtns}>
            <div>
              <Button label={t('d08822206')} onClick={(e) => { triggerUploadBtn(e, 'd759510') }} />
              <input type="file" onChange={(e) => { onFileChange(e) }} ref={refFileIdentification} name="d759510" />
              {errorId && !selectedFile.d759510 && <div className="input-err-msg">{t('d621915485')}</div>}
            </div>
            <div className={styles.selectWrapper}>
              {selectedFile.d759510 ? (<button className={styles.uploadedFile}>
                <div className={styles.left}><div className={styles.text}>{selectedID && selectedID.label}</div></div>
                <div className={styles.close}><span></span><img src={close} alt='close' onClick={handleDelete}/></div> 
              </button>) : (<Selector
                selectedOption={selectedID}
                selectClassname="selectLg"
                options={identification}
                handleChangeSelect={(e) => { handleSelectChange(e, 'd759510') }}
                placeholder={t('d465729339')} />)}
              {errorId && !selectedID && <div className="input-err-msg">{t('d621915485')}</div>}
            </div>
          </div>
        </div>
        <div style={{width:"10%"}}></div>
        <div className={styles.upload}>
          <div style={{width:"100%"}}>
            <h4>{t('d67521062')}</h4>
            <h5>
              {t('d67816073')}
            </h5>
          </div>
          <div className={styles.uploadBtns}>
            <div>
              <Button label={t('d08822206')} onClick={(e) => { triggerUploadBtn(e, 'd47956282') }} />
              <input type="file" onChange={(e) => { onFileChange(e) }} ref={refFileResidence} name="d47956282" />
              {errorRes && !selectedFile.d47956282 && <div className="input-err-msg">{t('d621915485')}</div>}
            </div>
            <div className={styles.selectWrapper}>
            {selectedFile.d47956282 ? (<button className={styles.uploadedFile}>
                <div className={styles.left}><div className={styles.text}>{selectedResidence && selectedResidence.label}</div></div>
                <div className={styles.close}><span></span><img src={close} alt='close' onClick={handleDelete}/></div> 
              </button>) : (<Selector
                selectedOption={selectedResidence}
                selectClassname="selectLg"
                options={residence}
                handleChangeSelect={(e) => { handleSelectChange(e, 'd47956282') }}
                placeholder={t('d465729339')} />)} 
              {errorRes && !selectedResidence && <div className="input-err-msg">{t('d621915485')}</div>}
            </div>
          </div>
        </div>
      </div>
      <div>{errorType && <em>({t('d23015468')})</em>}</div>
      <div className={styles.submit}>
        {/* <button className={styles.downloadBtn} onClick={() => { setDownload(true) }}>Download files</button> */}
        <button className={styles.submitBtn} onClick={handleSubmit}>{t("d58938009")}</button>
        
      </div>
      <Download userId={userId} update={update} />
    </div>
  );
}
export default VerifyAccount;