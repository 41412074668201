import { screenConstants } from '../constants';
import { sessionService } from '../../_sessionService/storage';

const getScreenSize = (width, height) => {
  return dispatch => {
    if (sessionService.get('screen')) {
      dispatch({ type: screenConstants.UPDATE_SCREEN_SIZE, width, height });
      sessionService.set('screen', { width, height });
    } else {
      dispatch({ type: screenConstants.GET_SCREEN_SIZE, width, height });
      sessionService.set('screen', { width, height });
    }
  };
};

export const screenActions = {
  getScreenSize
};
