import React from 'react';
import styles from './footer.module.scss';
// import LogoSmall from '../../_assets/images/logo/logo-sm.svg';
// import Logo from '../../_assets/images/logo/logo-lg.svg';
// import LinksSocial from './linksSocial';
// import locationIcon from '../../_assets/images/icons/location_icon.svg';
// import emailIcon from '../../_assets/images/icons/email_icon.svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import moment from 'moment';

const Footer = ({ ready }) => {
  const [t] = useTranslation();
  return !ready ? null : (
    <div className={`${styles.wrapper} container-wraper`}>
      <div className={styles.footer}>
        {/* <div className={`${styles.firstBlock}`}>
          <img src={Logo} alt="ElitDuels" className={styles.logoLg} />
          <img src={LogoSmall} alt="ElitDuels" className={styles.logoSm} />
          <LinksSocial />
        </div> */}
        <div className={styles.secondBlock}>
          <div className={styles.data}>
            <h4>{t('d8770332')}</h4>
            <Link className={styles.link} to="/terms_of_service"> <h5>{t('d88547068')}</h5></Link>
            <Link className={styles.link} to="/privacy_policy"><h5>{t('d89208680')}</h5></Link>
            <a href="mailto:support@eliteduels.com" target="_blank" rel="noopener noreferrer">
              <h5>{t('d38488972')}</h5>
            </a>
          </div>
          <div className={styles.data}>
            <h4>{t('d9043195')}</h4>
            <Link className={styles.link} to="/about"><h5>{t('d91012027')}</h5></Link>
            <Link className={styles.link} to="/withdraw_policy"><h5>{t('d91514961')}</h5></Link>
            {/* <Link className={styles.link} to="/contact"><h5>{t('d77926439')}</h5></Link> */}
          </div>
          <div className={styles.data}>
            <h4>{t('d04023548')}</h4>
            <Link className={styles.link} to="/how_to_play"><h5>{t('d38515927')}</h5></Link>
            <Link className={styles.link} to="/faq"><h5>{t('d39135131')}</h5></Link>
            <Link className={styles.link} to="/responsible_gaming"> <h5>{t('d05156311')}</h5></Link>
          </div>
        </div>
      </div>
      {/* <div className={styles.info}>
        <div className={styles.location}>
          <img src={locationIcon} alt="location" />
          <div className={styles.text}>
            <h5> Hamilton, ON, L9B 1V2 </h5>
            <h5> 393 Rymal Road West, Suite 403 </h5>
          </div>
        </div>
        <div className={styles.contact}>
          <img src={emailIcon} alt="mail" />
          <div className={styles.text}>
            <h5> <a href="mailto:support@eliteduels.com" target="_blank" rel="noopener noreferrer">support@eliteduels.com</a></h5>
          </div>
        </div>
      </div> */}
      <div className={styles.copyright}>
        <h5>
          &copy; {moment().year()} {t('d28166255')} <br />
          Proud member of the Fantasy Sports and Gaming Association 
        </h5>
      </div>
    </div>
  );
};

export default Footer;
