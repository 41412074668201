
import { routesConstants } from '../constants';
import { sessionService } from '../../_sessionService/storage';
const storage = sessionService.get('routes');
const initialRoute = storage ? storage : null;
const initialState = {
  route: initialRoute
};

export const routes = (state = initialState, action) => {

  let newData = {};
  switch (action.type) {
    case routesConstants.ROUTES_HISTORY:
      if (action.route) {
        if (state.route) {
          if (state.route.currentRoute.name !== action.route.name) {
            newData.prevRoute = { ...state.route.currentRoute };
            newData.currentRoute = { ...action.route };
          } else {
            newData = { ...state.route };
          }
        } else {
          newData.currentRoute = { ...action.route };
        }
        sessionService.set('routes', newData);
      }
      return {
        route: newData,
      };
    default:
      return state;
  }
};
