
import httpService from './http.service';

class ProfileService {
  async run(mode, data) {
    return httpService.apiRequest('post', '/api/profile', { mode, data });
  }
}

export default new ProfileService();
