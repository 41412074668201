import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PageHeading from "../../_components/pageHeading/pageHeading";
import Tabs from "../../_components/tabs/tabs";
import "./wallet.scss";
import WalletOverviewLeft from "../../_components/walletOverview/walletOverviewLeft";
import WalletOverviewRight from "../../_components/walletOverview/walletOverviewRight";
import WalletDepositLeft from "../../_components/walletDeposit/walletDepositLeft/walletDepositLeft";
import WalletDepositRight from "../../_components/walletDeposit/walletDepositRight/walletDepositRight";
import { useDispatch, useSelector } from "react-redux";
import { paymentActions } from "../../_store/actions/payment.actions";
import { mainActions } from "../../_store/actions/main.actions";
import { userActions } from "../../_store/actions/user.actions";
import ListWithStatus from "../../_components/listWithStatus/listWithStatus";
import CustomPagination from "../../_components/pagination/pagination";
import storageService from "../../_services/storage.service";
import { getPipeline } from "../../_utils/";
import Loading from "../../_components/Loading";
// import PerfectScrollbar from "react-perfect-scrollbar";

const Wallet = (props) => {
  const [t] = useTranslation();
  const tabs = {
    tab1: t("d80601451"),
    tab2: t("d21581168"),
    // tab3: t('d81503932'),
  };
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [wallet, setWallet] = useState(
    store.auth && store.auth.user && store.auth.user.id
      ? storageService.getData("transactions", "wallet", "get", {
          id: store.auth.user.id,
        })
      : null
  );
  const [transactionsData, setTransactionsData] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [activeTab, setActiveTab] = useState(tabs.tab1);
  const [user, setUser] = useState();
  const generateBrainTreeToken = async () => {
    dispatch(paymentActions.run("token"));
  };
  useEffect(() => {
    setUser(store.auth.user);
    if (
      props.history.location &&
      props.history.location.hash &&
      props.location.hash === "#deposit"
    ) {
      setActiveTab(tabs.tab2);
    }
  }, []);
  useEffect(() => {
    if (store.auth.user) {
      dispatch(userActions.refreshUser(store.auth.user.id));
      // generateBrainTreeToken();
      dispatch(
        mainActions.agg(
          "wallet",
          "transactions",
          "user",
          "get",
          getPipeline("wallet", store.auth.user.id),
          store.auth.user.id
        )
      );
    }
  }, [store.auth.user.id]);

  useEffect(() => {
    if (
      store.main.transactions &&
      store.main.transactions.wallet &&
      store.main.transactions.wallet.data &&
      store.main.transactions.wallet.data[store.auth.user.id]
    ) {
      const userWallet =
        store.main.transactions.wallet.data[store.auth.user.id];
      setWallet(userWallet);
    }
  }, [
    store.main.transactions &&
      store.main.transactions.wallet &&
      store.main.transactions.wallet.data &&
      store.main.transactions.wallet.data[store.auth.user.id],
  ]);

  useEffect(() => {
    if (wallet) {
      if (!wallet.transactions) wallet.transactions = [];
      setTransactionsData(wallet.transactions);
      const lastWin = wallet.transactions.find(
        ({ transactionType, amount }) =>
          transactionType === "WIN_PRIZE" && amount && amount > 0
      );
      const lastWinCredit = wallet.transactions.find(
        ({ transactionType, credit }) =>
          transactionType === "WIN_PRIZE" && credit && credit > 0
      );
      if (lastWin) {
        wallet.lastWin = lastWin.amount;
      }
      if (lastWinCredit) {
        wallet.latestWinCredit = lastWinCredit.credit;
      }
    }
  }, [wallet]);

  const changeTab = async (key) => {
    setActiveTab(key);
  };
  const onPageChange = async (current) => {
    setTransactions(current);
  };
  return (
    <div className="container-wrapper-fluid wallet-wrapper">
      <div style={{ width: "100%" }}>
        <PageHeading heading={t("d63704155")} subheading={t("d6471902")} />
      </div>
      <div style={{ width: "100%" }}>
        {!user ? (
          <Loading />
        ) : (
          <div>
            <div className="">
              <div className="tabs-wrapper">
                <Tabs tabs={tabs} activeTab={activeTab} changeTab={changeTab} />
              </div>
              {activeTab === t("d80601451") && (
                <div className="content-wrapper">
                  <div className="wallet-content">
                    <div>
                      <WalletOverviewLeft wallet={wallet} />
                    </div>
                    <div>
                      <WalletOverviewRight wallet={wallet} />
                    </div>
                  </div>
                  <div>
                    <div className="layout-content-right">
                      <h4 className="transaction-header">{t("d65102280")}</h4>

                      {transactionsData && transactionsData.length > 0 ? (
                        <div className="transaction-wrapper">
                          {/* <PerfectScrollbar className="purpleScroll"> */}
                          {transactions.map((el, i) => (
                            <ListWithStatus data={el} key={i} />
                          ))}
                          <CustomPagination
                            data={transactionsData}
                            defaultPageSize={10}
                            onChange={onPageChange}
                          />
                          {/* </PerfectScrollbar> */}
                        </div>
                      ) : (
                        <div className="message-info">{t("d0551662")}</div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {activeTab === t("d21581168") && (
                <div className="layout-content-right right-wrapper layout-50">
                  <div>
                    <WalletDepositLeft />
                  </div>
                  <div>
                    <WalletDepositRight />
                  </div>
                </div>
              )}
              {/* {activeTab === t('d81503932') && <div className="layout-content-right">
                <h4>{t('d65102280')}</h4>
                {transactionsData && transactionsData.length > 0
                  ? <div>
                    {transactions.map((el, i) => (
                      <ListWithStatus data={el} key={i} />
                    ))}
                    <CustomPagination data={transactionsData} defaultPageSize={10} onChange={onPageChange} />
                  </div> : <div className="message-info">
                    {t('d0551662')}
                  </div>}

              </div>} */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Wallet;
