
import { isDeviceMobile } from '../_utils';
const get = (name, type, mode, data = {}) => {
  let storage
  // comented checking isDeviceMobile that we can get data from session storage on mobile devices
  // if (!isDeviceMobile()) {
  //   storage = sessionStorage.getItem(`${name}_${type}`);
  // }
  storage = sessionStorage.getItem(`${name}_${type}`);

  let jsonData
  if (!storage) {
    jsonData = { data: {}, list: [], page: { total: 0, rows: [] } }
    try {
      sessionStorage.setItem(`${name}_${type}`, JSON.stringify(jsonData));
    } catch (err) {
      //Session storage quite exceeded
    }
  }
  else {
    jsonData = JSON.parse(storage);
  }

  jsonData.data = jsonData.data || {}
  jsonData.list = jsonData.list || []
  jsonData.page = jsonData.page || { total: 0, rows: [] }

  if (mode === 'get' && data.id) {
    if (jsonData.data[data.id]) return jsonData.data[data.id]
    return jsonData.list.find(one => one.id === data.id);
  } else if (mode === 'all' || mode === 'limit') {
    return jsonData.list || []
  } else if (mode === 'page') {
    return jsonData.page || { total: 0, rows: [] }
  } else if (mode === 'data') {
    return jsonData.data ? Object.values(jsonData.data)[0] : null
  }
  return;
};

const set = (name, type, mode, data) => {
  let storage = sessionStorage.getItem(`${name}_${type}`);
  let jsonData
  if (!storage) jsonData = { data: {}, list: [], page: { total: 0, rows: [] } }
  else {
    jsonData = JSON.parse(storage);
  }

  jsonData.data = jsonData.data || {}
  jsonData.list = jsonData.list || []
  jsonData.page = jsonData.page || { total: 0, rows: [] }

  if (mode === 'new') {
    jsonData.list.push(data);
    jsonData.data[data.id] = data
  } else if (data && (mode === 'update' || mode === 'get')) {
    const index = jsonData.list.find(one => one.id == data.id)
    if (index > -1) {
      const one = jsonData[index]
      jsonData.list[index] = { ...one, ...data };
    }
    jsonData.data[data.id] = { ...(jsonData.data[data.id] || {}), ...data }
  } else if (mode === 'delete') {
    const index = jsonData.list.find(one => one.id == data.id);
    if (index > -1) {
      jsonData.splice(index, 1);
    }
    if (jsonData.data[data.id]) {
      delete jsonData.data[data.id]
    }
  } else if (mode === 'all' || mode === 'limit') {
    jsonData.list = data
  } else if (mode === 'page') {
    jsonData.page = data
  }
  try {
    sessionStorage.setItem(`${name}_${type}`, JSON.stringify(jsonData));
  } catch (err) {
    //Session storage quite exceeded
  }
};

class StorageService {
  getData(name, type, mode, data) {
    return get(name, type, mode, data);
  }

  setData(name, type, mode, data) {
    // commented checking isDeviceMobile so that we can store data in Session storage on mobile devices
    // if (!isDeviceMobile()) return set(name, type, mode, data);
    return set(name, type, mode, data);
  }

  setRef(ref) {
    if (ref) {
      ref = decodeURIComponent(ref)
      if (ref.charAt(0) == '?') ref = ref.substr(1)
      const params = ref.split('&')
      const referr = params.find(one => one.split('=')[0] === 'ref')
      ref = referr.split('=')[1];
      sessionStorage.setItem(`ref`, ref);
    } else {
      sessionStorage.removeItem(`ref`)
    }
  }

  getRef() {
    return sessionStorage.getItem('ref');
  }
}

export default new StorageService();
