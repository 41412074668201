import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../_components/input/input';
import Selector from '../../../_components/selector/selector';
import { mainActions } from '../../../_store/actions/main.actions';
import { imageActions } from '../../../_store/actions/image.actions';
import DatePicker from "react-datepicker";
import Button from '../../../_components/button/button';
import MatchSelection from './MatchSelection/MatchSelection';
import TeamSelection from './TeamSelection/TeamSelection';
import queryString from 'query-string';
import './InfluencerForm.scss';
import toastService from '../../../_services/toastService';

const defaultContestValues = { publish: false, to: Date.now(), entryFee: 1, entryCreditFee: 1, type: 'contest', subtype: 'beatThePro' };

const InfluencerForm = (props) => {
    const [t] = useTranslation();
    const [games, setGames] = useState();
    const [matches, setMatches] = useState();
    const [teams, setTeams] = useState();

    const [contestType, setContestType] = useState();

    const [step, setStep] = useState(1);
    const [file, setFile] = useState(null)
    const [processed, setProcessed] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);

    const [contest, setContest] = useState(defaultContestValues);

    const store = useSelector(state => state);

    const dispatch = useDispatch();

    const { id } = queryString.parse(props.history.location.search);

    const typeDefaultValues = [ { label: t('d09094712'), code: 'd09094712' }, { label: t('d85876432'), code: 'd85876432'}, { label: t('d12792751'), code: 'd12792751' }];

    useEffect(() => {
        getGames();
    }, [])

    useEffect(() => {
        if(id) {
            getContest();
        }
    }, [id])

    const getContest = async () => {
        const editContest = await dispatch(mainActions.run('data', 'contest', 'get', {filter: [{ id }]}));
        const game = await dispatch(mainActions.run('data', 'game', 'get', {filter: [{ name: editContest.game }]}));
        const matches = await dispatch(mainActions.run('data', 'match', 'all', {filter: [{ id: {'$in': editContest.matches} }]}));

        game.label = game.name;
        editContest.game = game;
        setMatches(matches);
        setContestType({label: t(editContest.contestType), code: editContest.contestType});
        setContest(editContest);
    }

    useEffect(() => {
        if(matches && matches.length > 0) {
            makeTeam();
        } else {
            setTeams([]);
        }
    }, [matches])

    const makeTeam = () => {
        let teamsList = [];
        matches.map(m => {
            teamsList = [...teamsList, ...(m.team1 || []), ...(m.team2 || [])]
        })
        setTeams(teamsList);
        setContest({...contest, from: matches[0].startAt });
    }

    const getGames = async () => {
        const response = await dispatch(mainActions.run('data', 'game', 'all'));
        setGameNames(response);
    }

    const setGameNames = (gameList) => {
        const gameNamesList = []
        gameList.map(g => {
            gameNamesList.push({...g, label: g.name});
        })
        setGames(gameNamesList);
    }

    const handleInputChange = (e) => {
        const { value } = e.target;
        setContest({...contest, name: value });
    }

    const onSelectChange = (selectedValue) => {
        if(selectedValue.type === 'game') {
            setContest({...contest, game: selectedValue, cap: selectedValue.salaryCap });
        } else {
            setContest({...contest, contestType: selectedValue.code});
            setContestType(selectedValue);
        }
    }

    const onEndDateChange = (d) => {
        setContest({...contest, to: d });
    }

    const goBack = () => {
        setStep(step - 1);
    }
    
    const handleProcess = (e) => {
        e.preventDefault();
        setProcessed(true);
        if(matches && matches.length !== 0 && step === 2) {
            setStep(3);
        } else if (contest.game && contest.contestType && contest.name && contest.to) {
            setStep(2);
        }
    }

    const finish = async (team) => {
        const newContest = {...contest};
        newContest.captainCap = contest.game.captainCap;
        newContest.game = contest.game.name;
        newContest.matches = matches.map(m => m.id);
        newContest.maxEntries = 1000000000;
        newContest.totalEntries = 1000000000;
        newContest.team = team;
        newContest.prize = 0;
        newContest.publish = true
        newContest.createdBy = store.auth.user.id;

        const response = await dispatch(mainActions.run('data', 'contest', props.history.location.pathname.includes('edit') ? 'update' : 'new', newContest));
        !id && toastService.show('success', t('d40747639'));
        if (response?.id && file) {
            file.append('dataId', response.id);
            await dispatch(imageActions.uploadImage(`${response.id}_contestBackground`, 200, 200, file));
        }
        props.history.push('/influencer/contests');
    }

    const onFileChange = (e) => {
        const file = e.target.files[0];
        if (!file) return
        const name = e.target.name
        const data = new FormData();
        data.append('file', file);
        data.append('title', name);
        setFile(data)
        setPreviewImage(URL.createObjectURL(file))
    }

    return (
        <div>
            <div className="influencerWrapper">
                <div className="content">
                    {step === 1 && <>
                        <Input type="text" name="key" placeholder={t('d39251520')} value={contest.name} disabled={false} onChange={handleInputChange} label={t('d39251520')} />
                        {processed && !contest.name && <div className="input-err-msg">{t('d621915485')} {t('d465729339')}</div>}
                        {games && <Selector
                        selectClassname="selectForm"
                        options={games}
                        handleChangeSelect={onSelectChange}
                        placeholder={t('d465729339')}
                        label={t('d58873906')}
                        multiselect={false}
                        value={contest.game}
                        selectedOption={contest.game}
                        />}
                        {processed && !contest.game && <div className="input-err-msg">{t('d621915485')} {t('d465729339')}</div>}
                        {typeDefaultValues && <Selector
                        selectClassname="selectForm"
                        options={typeDefaultValues}
                        handleChangeSelect={onSelectChange}
                        placeholder={t('d234103673')}
                        label={t('d234103673')}
                        multiselect={false}
                        value={contestType}
                        selectedOption={contestType}
                        />}
                        {processed && !contest.contestType && <div className="input-err-msg">{t('d621915485')} {t('d234103673')}</div>}
                        <div className="date-picker-wrapper">
                            {<label htmlFor={t('d41831975')}>{t('d41831975')}</label>}
                            <DatePicker
                                selected={contest.to}
                                onChange={date => onEndDateChange(date.getTime())}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="time"
                                dateFormat="MMMM d, yyyy h:mm aa"
                                minDate={new Date()}
                            />
                            {processed && !contest.to && <div className="input-err-msg">{t('d621915485')} {t('d41831975')}</div>}
                        </div>
                        <div className="uploadImgWrapper">
                            <label htmlFor='contestBackground' className='inputLabel'>Contest background</label>
                            <div className='uploadImg'>
                            <input type="file" id="contestBackground" onChange={(e) => { onFileChange(e) }} name="contestBackground" />
                            {previewImage && <img src={previewImage} alt="Upload box" />}
                            </div>
                        </div>
                    </>
                    }
                    <div style={{ marginTop: '20px', width: "100%" }}>
                        {contest.game && step === 2 && <MatchSelection chosenGame={contest.game} alreadySelectedMatches={matches} setMatchesCallback={setMatches}></MatchSelection> }
                        { teams && teams.length > 0 && contest.contestType && contest.game && step === 3 &&  <TeamSelection chosenTeams={teams} contest={contest} onSubmit={finish}></TeamSelection> }
                        <br></br>
                        <div className='btnGroup'>
                            {step !== 1 && <Button btnClass='btnPrimary' label={t('d72876492')} onClick={goBack} />}
                            {step !== 3 && <Button btnClass='btnPrimary' label={t('d7862329')} onClick={handleProcess} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InfluencerForm;