import React from 'react';
import PropTypes from 'prop-types';
import styles from './pageHeading.module.scss';

const PageHeading = ({ heading, subheading, className }) => {
  return (
    <div className={`${styles.pageHeadingWrapper} ${className}`}>
      <h4>{subheading}</h4>
      {/* <h1>{heading}</h1> */}
    </div>
  );
};

PageHeading.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
};

export default PageHeading;
