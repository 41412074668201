import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Input from '../../input/input';
import Selector from '../../selector/selector';
import Button from '../../button/button';
import styles from './PlayerPropSelect.module.scss';

const PlayerPropSelect = ({ fields = {}, field = {}, selectData, prePullData: { games = [], gamePlayersMap, gameActionsMap } = {}, error, onChange }) => {
  const [t] = useTranslation();
  const [keyValueArray, setKeyValueArray] = useState([{ game: null, player: null, action: null, value: '' }])
  const [playerOption, setPlayerOption] = useState([]);
  const [playerOptionMap, setPlayerOptionMap] = useState({});
  const [actionOption, setActionOption] = useState([]);
  const [actionOptionMap, setActionOptionMap] = useState({});
  const [matches, setMatches] = useState([])
  const [matchOption, setMatchOption] = useState([]);
  const [matchOptionMap, setMatchOptionMap] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    setMatches(fields.matches || [])
  }, [fields.matches])

  useEffect(() => {
    let value = fields[field.dbname] || []
    if (value.length === 0) value.push({ match: null, player: null, action: null, value: '' })
    setKeyValueArray(value);
  }, [fields[field.dbname]]);

  useEffect(() => {
    const matchOptionList = []
    const matchMap = {}
    const playerOptionList = {}
    const playerMap = {}
    const actionOptionList = {}
    const actionMap = {}

    if (matches && selectData['data-match']) {
      matches.forEach(id => {
        const match = selectData['data-match'].find(one => one.id == id)
        if (!match) return
        const game = match.game
        const matchOpt = { label: match.name, value: match.id, obj: match }
        matchMap[match.id] = matchOpt
        matchOptionList.push(matchOpt)
        let matchPlayers = match.team1 || []
        if (match.team2) matchPlayers = [...matchPlayers, ...match.team2]
        const players = (gamePlayersMap || {})[game] || []
        const playerOptList = []
        playerMap[match.id] = {}
        players.forEach(one => {
          if (matchPlayers.indexOf(one.nick) > -1) {
            const option = {
              label: one.nick,
              value: one.nick,
              obj: one
            }
            playerMap[match.id][one.nick] = option
            playerOptList.push(option)
          }
        })
        playerOptionList[match.id] = playerOptList


        const actions = (gameActionsMap || {})[game] || []
        const actionOptList = []
        actionMap[game] = {}
        actions.forEach(name => {
          const option = { label: name, value: name }
          actionOptList.push(option)
          actionMap[game][name] = option
        })
        const fppmOption = { label: 'FPPM', value: 'points' }
        actionOptList.push(fppmOption)
        actionMap[game]['points'] = fppmOption
        actionOptionList[game] = actionOptList

      })
    }
    setMatchOptionMap(matchMap)
    setMatchOption(matchOptionList)
    setPlayerOptionMap(playerMap)
    setPlayerOption(playerOptionList);
    setActionOptionMap(actionMap)
    setActionOption(actionOptionList)

  }, [matches, gamePlayersMap, gameActionsMap])

  const onFocusOut = (index) => {
    const changingValue = keyValueArray[index]
    if (onChange && changingValue.player && changingValue.action && changingValue.value) onChange(field.dbname, keyValueArray)
  };

  const handleValueChange = (e, index) => {
    const { value, checked, name } = e.target;
    const changingValue = keyValueArray[index]
    if (name === 'value') {
      changingValue.value = Number(value)
    } else if (name === 'boosted') {
      changingValue.boosted = checked
    }
    setKeyValueArray([...keyValueArray])
  };

  const handleRemoveOneClick = (e, index) => {
    e.preventDefault();
    keyValueArray.splice(index, 1)
    setKeyValueArray([...keyValueArray])
    if (onChange) onChange(field.dbname, keyValueArray)
  };

  const handleAddNewClick = (e) => {
    e.preventDefault()
    keyValueArray.push({ game: null, player: null, action: null, value: '' })
    setKeyValueArray([...keyValueArray])
  };

  const handleSelectValueChange = (e, name, element = {}, index) => {
    const { value } = e;
    const changingValue = keyValueArray[index]
    if (name === 'match') {
      if (changingValue.match && changingValue.match.id != value){
        changingValue.action = null
        changingValue.player = null
      }
      changingValue.game = matchOptionMap[value].obj.game
      changingValue.match = matchOptionMap[value].obj
    } else if (name === 'action') {
      changingValue.action = value
    } else if (name === 'player') {
      changingValue.player = element.match && playerOptionMap[element.match.id] ? playerOptionMap[element.match.id][value].obj : null
    }
    setKeyValueArray([...keyValueArray])
    if (onChange) onChange(field.dbname, keyValueArray)
  };

  return (
    <div key={field.dbname + '-playerProp' } className={styles.playerPropWrapper}>
      {field.label && <label>{t(field.label)}</label>}
      <small>{t(field.description)}</small>
      {keyValueArray.length > 0 && <div className={styles.playerPropStyle} >
        {keyValueArray.map((element, i) => {
          return (
            <React.Fragment key={i}>
              <div className={styles.playerPropInput}>
                <div>
                  <Selector
                  selectClassname="selectForm"
                  options={matchOption}
                  label="Match"
                  name="match"
                  handleChangeSelect={e => handleSelectValueChange(e, 'match', element, i)}
                  placeholder={t('d465729339')}
                  selectedOption={element.match ? matchOptionMap[element.match.id] : ''}
                    />
                </div>
                <div>
                  <Selector
                    selectClassname="selectForm"
                    options={element.match ? playerOption[element.match.id] || [] : []}
                    label="Player"
                    name="player"
                    handleChangeSelect={e => handleSelectValueChange(e, 'player', element, i)}
                    placeholder={t('d465729339')}
                    selectedOption={element.match && playerOptionMap[element.match.id] ? playerOptionMap[element.match.id][element.player && element.player.nick] || '' : ''}
                  />
                </div>
                <div>
                  <Selector
                    selectClassname="selectForm"
                    options={actionOption[element.game] || []}
                    label="Action"
                    name="action"
                    handleChangeSelect={e => handleSelectValueChange(e, 'action', element, i)}
                    placeholder={t('d465729339')}
                    selectedOption={actionOptionMap[element.game] ? actionOptionMap[element.game][element.action]  || '': ''}
                      />
                </div>
                <div>
                  <label>Value</label>
                  <Input type="number" name="value" placeholder={t(field.label1)} value={element.value} disabled={field.label1Disabled} onChange={e => handleValueChange(e, i, field.dbname)} label={t(field.label1)} onFocusOut={() => onFocusOut(i)}/>
                </div>
                <div>
                  <label>Boosted</label>
                  <Input type="checkbox" name="boosted" value={element.boosted ? "1" : "0"} checked={element.boosted} onChange={e => handleValueChange(e, i, field.dbname)} onFocusOut={() => onFocusOut(i)}/>
                </div>
                {keyValueArray.length !== 1 &&
                <Button type="button" btnClass='btnSecondary' label={<i className="fa fa-minus" aria-hidden="true"></i>} onClick={(e) => handleRemoveOneClick(e, i)} />
                }
              </div>
              {
                keyValueArray.length - 1 === i &&
                <div className={styles.playerPropAdd}>
                  <Button type="button" btnClass='btnPrimary' label={<i className="fa fa-plus" aria-hidden="true"></i>} onClick={(e) => handleAddNewClick(e)} />
                </div>
              }

              {error && (!element.value && (element.value !== 0)) && field.required && <div className="input-err-msg">{t('d621915485')} {t(field.label1)}</div>}
              {error && (!element.key && (element.key !== 0)) && field.required && <div className="input-err-msg">{t('d621915485')} {t(field.label2)}</div>}
            </React.Fragment>
          )
        })}
      </div>}
    </div>)
};

export default PlayerPropSelect;
