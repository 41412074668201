import { combineReducers } from 'redux';

import { auth } from './auth.reducer';
import { modal } from './modal.reducer';
import { data } from './data.reducer';
import { main } from './main.reducer';
import { profile } from './profile.reducer';
import { image } from './image.reducer';
import { screen } from './screen.reducer';
import { language } from './language.reducer';
import { payment } from './payment.reducer';
import { event } from './event.reducer';
import { countries } from './countries.reducer';
import { forms } from './forms.reducer';
import { loader } from './loader.reducer';
import { routes } from './routes.reducer';
const rootReducer = combineReducers({
  auth,
  modal,
  data,
  image,
  screen,
  language,
  event,
  profile,
  payment,
  countries,
  forms,
  main,
  loader,
  routes
});

export default rootReducer;
