import React,{ useEffect } from 'react';
import styles from './prizesDisplay.module.scss';
import { useTranslation } from 'react-i18next';
import withImages from '../../_components/core/withImages'

const PrizesDisplay = ({ data = {}, skins = {}, prizeType = 'Money', acquireImages, images }) => {
    const [t] = useTranslation();
  useEffect(() =>{
    if (acquireImages) {
      const imagesToAcquire = []
      Object.keys(skins).forEach(key => {
        imagesToAcquire.push({name: `${key}_image`, width: 150, height: 150})
      })
      acquireImages(imagesToAcquire)
    }
  }, [])

  const _getMoneyPrizes = () => {
    return Object.keys(data).map((key, i) => {
      return (
        <li key={i} className={styles.prizes}>
          <span> {key} - </span>
          <span> ${data[key]} </span>
        </li>
      )
    })
  }

const _getSkinPrizes = () => {
  return Object.keys(data).map((key, i) => {
    const reward = data[key]
    return (
      <li key={i} className={styles.prizes}>
        <span>{key}</span>
        <span className={styles.skin}>
          <img src={images[reward + '_image']}/>
          <span >{skins[reward] ? skins[reward].name || '' : ''}</span>
        </span>
      </li>
    )
  })
}

  return (
      <div className={styles.wrapper}>
          <h4>{t('d64753840')}</h4>
          {data ? prizeType === 'Money' ? _getMoneyPrizes() : _getSkinPrizes() : (
                  <p>{t('d687112')}</p>
              )}
      </div>
  )
}

export default withImages(PrizesDisplay);