import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import accounting from "accounting-js";
import { displayDuration } from "../../_utils";
import { ReactComponent as EntryIcon } from "../../_assets/images/icons/entry.svg";
import { ReactComponent as PrizeIcon } from "../../_assets/images/icons/prize.svg";

import styles from "./contestCard.module.scss";

const getInitialDuration = (time) => {
  if (!time || time - new Date().getTime() < 0) return null;
  return moment.duration(time - new Date().getTime());
};

const ContestCard = React.forwardRef(({ contest, image, userEventId }, ref) => {
  const history = useHistory();
  const [t] = useTranslation();
  const {
    name,
    id,
    entered,
    totalEntries,
    contestType,
    from,
    to,
    prize,
    prizeType,
    entryFee,
  } = contest;
  const [duration, setDuration] = useState(getInitialDuration(from));
  useEffect(() => {
    let timer;
    if (from > new Date().getTime()) {
      timer = setInterval(() => {
        const interval = moment.duration(from - new Date().getTime());
        setDuration(interval);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, []);

  return (
    <article className={styles.cardWrapper} ref={ref}>
      <div
        className={styles.contestImage}
        // style={{
        //   backgroundImage: `linear-gradient(to top, rgba(51, 51, 51, 0.8) 0%, rgba(51, 51, 51, 0.5) 10%, rgba(51, 51, 51, 0) 32%),
        //   url(${image})`,
        // }}
      >
        <img src={image} className={styles.image} alt="" />
        <div className={styles.entries}>
          <span className={styles.entryIcon}>
            <EntryIcon />
          </span>
          <span>
            {entered} / {totalEntries}
          </span>
        </div>
        <div className={styles.contestType}>
          <span>{t(contestType)}</span>
        </div>
      </div>

      <div className={styles.contestBody}>
        <div className={styles.contestName}>
          <span>{name || ""}</span>
        </div>
        <div className={styles.contestFooter}>
          <div className={styles.time}>
            <div className={styles.date}>
              <span>{from ? moment(from).format("MM/DD/YYYY") : ""}</span>
            </div>
            <div className={styles.duration}>
              <span>
                {duration
                  ? displayDuration(duration)
                  : getInitialDuration(to)
                  ? "Live"
                  : "Finished"}
              </span>
            </div>
          </div>
          <div className={styles.prizeWrapper}>
            <div className={styles.prizeLabel}>
              <span>{t("d72545674")}</span>
            </div>
            <div className={styles.prize}>
              <span className={styles.prizeIcon}>
                <PrizeIcon />
              </span>
              {prizeType === "Skins" ? (
                <span>{t("Skins")}</span>
              ) : prize ? (
                <span>{prizeType === "Credit" ? accounting.formatNumber(prize) + ' C': accounting.formatMoney(prize)}</span>
              ) : (
                <span>0</span>
              )}
            </div>
            <div>
              <span>
                {t("d71557884")}: {accounting.formatMoney(entryFee)}
              </span>
            </div>
            {duration && displayDuration(duration) ? (
              <div
                className={styles.enter}
                onClick={() => {
                  if (userEventId && id) {
                    history.push(`/contests/draft/${id}/${userEventId}`);
                  } else if (id) {
                    history.push(`/lobby/draft/${id}`);
                  } else {
                    history.push(`/lobby/picks`);
                  }
                }}
              >
                <span>Enter</span>
              </div>
            ) : (
              <div
                className={styles.enter}
                onClick={() => {
                  if (userEventId && id) {
                    history.push({
                      pathname: `/contests/results/${id}/${userEventId}`,
                    });
                  } else {
                    history.push(`/lobby/picks`);
                  }
                }}
              >
                <span>{t("d95421823")} </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </article>
  );
});

PropTypes.ContestCard = {
  contest: PropTypes.object.isRequired,
  image: PropTypes.string,
};

export default ContestCard;
