import React, { useState, useEffect, useRef } from "react";
import "braintree-web";
import DropIn from "braintree-web-drop-in-react";

import { useTranslation } from "react-i18next";
import styles from "./walletDepositLeft.module.scss";
import Input from "../../input/input";
import Checkbox from "../../checkbox/checkbox";
import Button from "../../button/button";
import { useDispatch, useSelector } from "react-redux";
import toastService from "../../../_services/toastService";
import { paymentActions } from "../../../_store/actions/payment.actions";
import { mainActions } from "../../../_store/actions/main.actions";
import { getPipeline } from "../../../_utils";
import "./paymentComponent.scss";
import accounting from "accounting-js";
import Loading from "../../../_components/Loading";
import { Link, NavLink } from "react-router-dom";
import cardImg from "../../../_assets/images/icons/card.svg";
import uploadImg from "../../../_assets/images/icons/upload .svg";
import recycleImg from "../../../_assets/images/icons/recycle.svg";
import { useHistory } from "react-router-dom";
import credit from '../../../_assets/images/icons/credit.svg';

import DepositModal from "../../payment/depositModal";

const WalletDepositLeft = () => {
  const [t] = useTranslation();
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const node = useRef();
  const [user, setUser] = useState({});
  const [limit, setLimit] = useState({ creditDolarRatio : 1});
  const [processingDeposit, setProcessingDeposit] = useState(false);
  const [processingWithdraw, setProcessingWithdraw] = useState(false);
  const [withdrawData, setWithdrawData] = useState({
    withdrawAmount: "",
    paypalInfo: "",
    withdrawPolicy: false,
  });

  const [depositData, setDepositData] = useState({
    amount: "",
    depositPolicy: false,
  });

  const [token, setToken] = useState("");
  const [depositToggle, setDepositToggle] = useState(false);
  const [creditPoints, setCreditPoints] = useState("");
  const [wallet, setWallet] = useState();
  const history = useHistory();

  useEffect(() => {
    setUser(store.auth.user);
    getLimit();
  }, [store.auth.user]);

  let ourInstance;

  useEffect(() => {
    setToken(store.payment.token);
  }, [store.payment.token]);

  useEffect(() => {
    if (user && user.id) {
      dispatch(
        mainActions.agg(
          "wallet",
          "transactions",
          "user",
          "get",
          getPipeline("wallet", user.id),
          user.id
        )
      );
    }
  }, [user]);

  useEffect(() => {
    if (
      store.main.transactions &&
      store.main.transactions.wallet &&
      store.main.transactions.wallet.data &&
      store.main.transactions.wallet.data[user.id]
    ) {
      const userWallet = store.main.transactions.wallet.data[user.id];
      setWallet(userWallet);
    }
  }, [
    store.main.transactions &&
    store.main.transactions.wallet &&
    store.main.transactions.wallet.data &&
    store.main.transactions.wallet.data[user.id],
  ]);

  const getLimit = async () => {
    const response = await dispatch(mainActions.run("config", "limit", "get"));
    setLimit(response);
  };

  const handleChangeDeposit = (e) => {
    const target = e.target;
    const value =
      target.type === "checkbox" ? target.checked : target.value.trim();
    const name = target.name;
    setDepositData((depositData) => ({
      ...depositData,
      [name]: value,
    }));
  };

  const onChangeToggle = () => {
    if (
      depositData.amount !== "" &&
      +depositData.amount >= (limit ? limit.minimumDeposit : 0) &&
      +depositData.amount <= (limit ? limit.maximumDeposit : 0) &&
      depositData.depositPolicy
    ) {
      setDepositToggle(true);
      store.payment.depositLabel = false;
    } else {
      toastService.show("error", t("d5307604"));
    }
  };

  const handleClick = (e) => {
    if (
      node &&
      node.current &&
      node.current.contains &&
      node.current.contains(e.target)
    ) {
      return;
    }
    setDepositToggle(false);
  };

  const submitDeposit = async () => {
    try {
      setProcessingDeposit(true);
      const { nonce } = await ourInstance.requestPaymentMethod();
      await dispatch(
        paymentActions.run(
          "deposit",
          { amount: +depositData.amount, nonce: nonce },
          `username=${user.username}`
        )
      );
      toastService.show("success", t("d43334659"));
      setProcessingDeposit(false);
      dispatch(
        mainActions.agg(
          "wallet",
          "transactions",
          "user",
          "get",
          getPipeline("wallet", store.auth.user.id),
          store.auth.user.id
        )
      );
      setDepositData((depositData) => ({
        ...depositData,
        amount: "",
      }));
    } catch (error) {
      setDepositToggle(false);
      setProcessingDeposit(false);
    }
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleChangeWithdraw = (e) => {
    const target = e.target;
    const value =
      target.type === "checkbox" ? target.checked : target.value.trim();
    const name = target.name;
    setWithdrawData((withdrawData) => ({
      ...withdrawData,
      [name]: value,
    }));
  };

  const submitWithdraw = async () => {
    if (
      withdrawData.withdrawPolicy &&
      withdrawData.withdrawAmount &&
      withdrawData.paypalInfo &&
      +withdrawData.withdrawAmount >= (limit ? limit.minimumWithdraw : 0) &&
      +withdrawData.withdrawAmount <= (limit ? limit.maximumWithdraw : 0) &&
      validateEmail(withdrawData.paypalInfo)
    ) {
      try {
        setProcessingWithdraw(true);
        const response = await dispatch(
          paymentActions.run("withdraw", {
            paypalEmail: withdrawData.paypalInfo,
            amount: +withdrawData.withdrawAmount,
          })
        );
        setProcessingWithdraw(false);
        // dispatch(mainActions.agg('wallet', 'transactions', 'user', 'get', getPipeline('wallet', store.auth.user.id), store.auth.user.id))
        setWithdrawData((withdrawData) => ({
          ...withdrawData,
          withdrawAmount: "",
          paypalInfo: "",
        }));
        if (response === "success") {
          dispatch(
            mainActions.agg(
              "wallet",
              "transactions",
              "user",
              "get",
              getPipeline("wallet", store.auth.user.id),
              store.auth.user.id
            )
          );
          toastService.show("success", t("d65064530"));
        }
      } catch (error) {
        setProcessingWithdraw(false);
        console.log("error", error);
      }
    } else {
      toastService.show("error", t("d58226060"));
    }
  };

  const handleChangeCredit = (e) => {
    e.preventDefault();
    setCreditPoints(e.target.value);
  };

  const submitConvert = async () => {
    if (creditPoints && creditPoints < 2000 && creditPoints > 0) {
      try {
        const response = await dispatch(
          paymentActions.run("cashToVirtual", { cash: +creditPoints })
        );
        dispatch(
          mainActions.agg(
            "wallet",
            "transactions",
            "user",
            "get",
            getPipeline("wallet", store.auth.user.id),
            store.auth.user.id
          )
        );
        if (response === "success") {
          toastService.show(
            "success",
            `${t("d54404156")} ${creditPoints * 10} ${t("d8768678")}`
          );
        }
        setCreditPoints("");
      } catch (error) {
        toastService.show("error", t("d55166976"));
      }
    } else {
      toastService.show("error", t("d55166976"));
    }
  };

  const onPaymentModalClose = (changed) => {
    if (changed)
      dispatch(
        mainActions.agg(
          "wallet",
          "transactions",
          "user",
          "get",
          getPipeline("wallet", store.auth.user.id),
          store.auth.user.id
        )
      );
    setDepositToggle(false);
  };

  const handleBtnClick = () => {
    history.push("/profile");
  };

  return (
    <div className={styles.wrapper}>
      {depositToggle && (
        <DepositModal
          show={depositToggle}
          onClose={onPaymentModalClose}
          amount={depositData.amount}
          user={store.auth.user}
        />
      )}
      <div className={styles.container}>
        <h4>{t("d20333111")}</h4>
        <div className={styles.content}>
          <img src={cardImg} alt="Card icon" />
          <div className={styles.info}>
            <span>
              {t("d20814042")}
              <span className={styles.value}>
                {" "}
                {accounting.formatMoney(
                  limit && limit.minimumDeposit ? limit.minimumDeposit : 0
                )}
              </span>
            </span>
            <span>
              {t("d21201283")}
              <span className={styles.value}>
                {" "}
                {accounting.formatMoney(
                  limit && limit.maximumDeposit ? limit.maximumDeposit : 0
                )}
              </span>
            </span>
          </div>
          <div className={styles.form}>
            <Input
              type="text"
              name="amount"
              onChange={handleChangeDeposit}
              value={depositData.amount}
              placeholder={t("d81948217")}
            />
            <div className={styles.checkbox}>
              <Checkbox
                checkboxClass="checkboxLoginClass"
                label=""
                name="depositPolicy"
                onChange={handleChangeDeposit}
              />
              <span>
                {t("d22521463")}{" "}
                <Link to="/withdraw_policy">{t("d22024971")}</Link>
              </span>
            </div>
            <div className={styles.btn}>
              <Button
                label={t("d20333111")}
                btnClass="btnGreen"
                onClick={onChangeToggle}
              />
            </div>
          </div>
        </div>
      </div>
      {user && user.approved ? (
        <div className={styles.container} style={{ marginTop: "0px" }}>
          <h4>{t("d23702546")}</h4>
          <div className={styles.content}>
          <img src={cardImg} alt="Card icon" />
            <div className={styles.info}>
              <span>
                {t("d24141045")}
                <span className={styles.value}>
                  {" "}
                  {accounting.formatMoney(
                    limit && limit.minimumWithdraw ? limit.minimumWithdraw : 0
                  )}
                </span>
              </span>
              <span>
                {t("d24392074")}
                <span className={styles.value}>
                  {" "}
                  {accounting.formatMoney(
                    limit && limit.maximumWithdraw ? limit.maximumWithdraw : 0
                  )}
                </span>
              </span>
            </div>
            <div className={styles.form}>
              <div className={styles.input}>
                <div className={styles.amount}>
                  <Input
                    type="text"
                    name="withdrawAmount"
                    onChange={handleChangeWithdraw}
                    value={withdrawData.withdrawAmount}
                    placeholder={t("d56807038")}
                  />
                </div>
                <div className={styles.paypal}>
                  <Input
                    type="text"
                    name="paypalInfo"
                    onChange={handleChangeWithdraw}
                    value={withdrawData.paypalInfo}
                    placeholder={t("d83705779")}
                  />
                </div>
              </div>
              <div className={styles.checkbox}>
                <Checkbox
                  checkboxClass="checkboxLoginClass"
                  label=""
                  name="withdrawPolicy"
                  onChange={handleChangeWithdraw}
                />
                <span>
                  {t("d22521463")}{" "}
                  <Link to="/withdraw_policy">{t("d91514961")}</Link>
                </span>
              </div>
              <div className={styles.btn}>
                {!processingWithdraw ? (
                  <Button
                    label="Withdraw"
                    btnClass="btnGreenStroked"
                    onClick={submitWithdraw}
                  />
                ) : (
                  <Loading
                    className="payment-button-loader"
                    width={20}
                    height={20}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.container} style={{ marginTop: "11px" }}>
          {" "}
          <div className={styles.verificationWrapper}>
            <h4>{t("d23702546")}</h4>
            <div className={styles.content}>
              <img src={uploadImg} alt="Upload icon" />
              <div className={styles.upload}>
                <h4 className={styles.subtitle}>{t("d09673566")}.</h4>
                <ul className="documents">
                  <li>
                    <small>*{t("d10078162")}</small>
                  </li>
                  <li>
                    <small>*{t("d10367074")}</small>
                  </li>
                </ul>
                <p>{t("d82023280")}</p>
                {/* <Button label={t("d10658731")}> */}
                <div className={styles.btn}>
                  <Button
                    label="Upload"
                    btnClass="btnGreenStroked"
                    onClick={handleBtnClick}
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={styles.container}
        style={{ borderBottom: "none" }}
      >
        <h4>{t("d30863026")}</h4>
        <div className={styles.content}>
          <img src={recycleImg} alt="Recycle icons" />
          <div className={styles.info}>
            <p>{t("d32435844")}</p>
            <div>
              <span>
                Balance:
                <span className={styles.value}>
                  {" "}
                  {accounting.formatMoney(
                    wallet && wallet.amount ? wallet.amount : 0
                  )}{" "}
                </span>
              </span>
              <span>
                Credit:
                <span className={styles.credit}>
                  {" "}
                  {wallet && wallet.amount ? wallet.credit : 0}
                </span>
              </span>
            </div>
          </div>
          <div className={styles.formCredit}>
            <div className={styles.creditWrapper}>
              <input
                className={styles.input}
                type="text"
                name="creditPoints"
                onChange={handleChangeCredit}
                value={creditPoints}
                placeholder={t("d81948217")}
              />
              <span className={styles.equal}>=</span>
              <div className={styles.creditData}>
                <img src={credit} alt="Credit" />
                <h4>{creditPoints * (limit && limit.creditDolarRatio  ? limit.creditDolarRatio : 1)}</h4>
                {/* <span>{t("d8768678")}</span> */}
              </div>
            </div>
            <span className={styles.creditInfo}> $1 = { limit && limit.creditDolarRatio  ? limit.creditDolarRatio : 1 } {t("d8768678")} </span>
          </div>
            <div className={styles.btn}>
              <Button
                label={t("d674163584")}
                btnClass="btnGray"
                onClick={submitConvert}
              />
            </div>
        </div>
      </div>
    </div>
  );
};

export default WalletDepositLeft;
