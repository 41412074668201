import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { mainActions } from '../../../_store/actions/main.actions';
import styles from './events.module.scss';
import Button from '../../../_components/button/button';
import EventsViews from './eventsViews';
import { useTranslation } from 'react-i18next';
import CustomPagination from '../../../_components/pagination/serverPagination';
import moment from 'moment';
import { getPipeline } from '../../../_utils';
import Checkbox from '../../../_components/checkbox/checkbox';
import Loading from '../../../_components/Loading';
import SearchBar from '../../../_components/searchBar/searchBar';

const EventsManagement = () => {
  const dispatch = useDispatch();
  const [total, setTotal] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [eventsData, setEventsData] = useState({});
  const [matches, setMatches] = useState();
  const [action, setAction] = useState('');
  const [history, setHistory] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [t] = useTranslation();

  useEffect(() => {
    getMatches();
  }, []);

  useEffect(() => {
    getMatches();
  }, [pageNumber, pageSize, history]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    e.preventDefault();
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    getMatches();
  };

  const getMatches = async () => {
    const filter = [{ finalized: history ? true : false }]
    if (searchText) {
      filter.push( { name: { '$regex': searchText, '$options': 'i' }});
    }
    const res = await dispatch(mainActions.run('data', 'match', 'page', { filter, pageNumber, pageSize }));
    setMatches(res.rows);
    setTotal(res.total);
    return res;
  };

  const getData = async (id) => {
    const data = await dispatch(mainActions.agg('matchEvents', 'data', 'match', 'all', getPipeline('matchEvents', id)));
    setEventsData(data[0]);
  }

  const getEventsData = async (match, action) => {
    setLoading(true)
    try {
      await getData(match.id)
      if (action) setAction(action);
    } catch (err) { /**/ }
    setLoading(false)

  };
  const onSubmit = useCallback((submitData) => {
    getData(eventsData.id)
  });

  const goBack = () => {
    setEventsData({});
    getMatches();
  };
  const onPageChange = async (page, pageSize) => {
    setPageNumber(page);
    setPageSize(pageSize);
  };
  const onChange = (e) => {
    const { checked } = e.target;
    setHistory(checked);
  };

  const onCorrection = (id) => {
    getEventsData({ id }, 'publish')
  }

  const unpublish = async (el) => {
    await dispatch(mainActions.run('events', 'game', 'unpublish', { ...el }));
    await getMatches()
  }

  return (
    <div className={styles.wrapperManagement}>
      {loading ? <Loading className="admin-list-loader" /> : !Object.keys(eventsData).length ? <div>
      <div className={styles.header}>
        <div className={styles.headerSearch}>
          <SearchBar onChange={handleSearch} value={searchText} onClick={handleSearchSubmit} placeholder={t('d23178525')} />
        </div>
        <div className={styles.checkboxWrapper}>
          <Checkbox checkboxClass="checkboxInputClass"
            name='history'
            checked={history}
            onChange={onChange}
            label={'History'} />
        </div>
      </div>
        {matches && matches.length > 0 ? <div className={styles.tb}>
          <PerfectScrollbar className="purpleScroll">
            <div className={styles.head}>
              <div className={styles.items}>
                <small>#</small>
                <span className={styles.name}>{t('d23178525')}</span>
                <span className={styles.game}>{t('d22215523')}</span>
                <span className={styles.date}>{t('d465736704')}</span>
              </div>
            </div>
            {matches.map((el, i) => (
              <div key={el.id} className={styles.body}>
                <div className={styles.items}>
                  <small>{((pageNumber - 1) * pageSize) + i + 1}</small>
                  <span className={styles.name}>{el.name}</span>
                  <span className={styles.game}>{el.game}</span>
                  <span className={styles.date}>{moment(el.startAt).format('MM/DD hh:mmA')}</span>
                </div>

                <div className={styles.btnWrapperSmall}>
                  {!history ?
                    <>
                      <Button btnClass='btnFormGreen' label={t('d90167144')} onClick={() => { getEventsData(el, 'add'); }} />
                      <Button btnClass='btnFormGreen' label={t('d323691769')} onClick={() => { getEventsData(el, 'edit'); }} />
                      <Button btnClass='btnFormPurple' label={t('d42245345')} onClick={() => { getEventsData(el, 'publish'); }} />
                    </> :
                    <>
                      {el.finalized ? <Button btnClass='btnFormRed' label={t('Unfinalize')} onClick={() => { unpublish(el); }} /> : null }
                      <Button btnClass='btnFormPurple' label={t('View')} onClick={() => { getEventsData(el, 'view'); }} />
                    </>
                  }
                </div>
              </div>
            ))}
          </PerfectScrollbar>
        </div> : <div className={styles.msg}> <div className="message-info">
          {matches ? t('d58155026') : ''}
        </div> </div>}
        <CustomPagination
          total={total}
          data={matches}
          currentPage={pageNumber}
          currentPageSize={pageSize}
          defaultPageSize={10}
          onChange={onPageChange} />
      </div> :
        <EventsViews data={eventsData} toggle={goBack} action={action} onSubmit={onSubmit} onCorrection={onCorrection} />}
    </div>);
};

export default EventsManagement;