import React from 'react';
import styles from './responsibleGaming.module.scss';
import PageHeading from '../../../_components/pageHeading/pageHeading';
import { useTranslation } from 'react-i18next';

const ResponsibleGaming = () => {
    const [t] = useTranslation();

    const responsibleText = [
        // {
        //     id: 1,
        //     header: t('d80601451'),
        //     text: <p>{t('d30973033')}</p>
        // },
        // {
        //     id: 2,
        //     header: t('d31328595'),
        //     text: <p>{t('d31702003')} <a href="https://www.icrg.org/" target="_blank" rel="noopener noreferrer" >NCRG</a>. <br /> <br /> {t('d36287010')} </p>
        // },
        // {
        //     id: 3,
        //     header: t('d36796603'),
        //     text: <p> {t('d37078118')} <br /> <br /> {t('d37581280')} </p>
        // },
        // {
        //     id: 4,
        //     header: t('d38178171'),
        //     text: <p> {t('d38443965')} <a href="mailto:support@eliteduels.com" target="_blank" rel="noopener noreferrer">support@eliteduels.com</a> {t('d3952811')}  <br /> <br /> {t('d3982108')} </p>
        // },
        // {
        //     id: 5,
        //     header: t('d40265439'),
        //     text: <p>
        //         {/* {t('d40483689')} <br /> <br /> */}
        //         <a href="https://www.netnanny.com/">NetNanny</a> {t('d41211986')}
        //         < br />
        //         <a href="https://cybersitter.com/">CyberSitter</a>
        //         < br /> < br />
        //         {t('d57783828')}  <a href="mailto:support@eliteduels.com" target="_blank" rel="noopener noreferrer">support@eliteduels.com</a>
        //     </p>
        // },
        {
            id: 1,
            header: 'Responsible Gaming',
            text: <p>Like many other addictions, compulsive play includes uncontrollable urges, which, if left unchecked, could result in financial hardship.
                     Anyone could be susceptible to compulsive play regardless of age, gender, race, or financial status. Once detected, compulsive play can be successfully treated. 
                    The key is early detection in order to avoid adverse and dire consequences. If you feel you have a compulsive-play problem, we urge you to seek assistance.</p>
        },
        {
            id: 2,
            header: 'Compulsive-play warning signs include:',
            text: 
            <div className={styles.ulDiv}>
                <ul>
                    <li>Playing more in order to recoup or chase previous losses</li>
                    <li>Sacrificing time from family or work in order to participate in tournaments</li>
                    <li>Repeated inability to stop or control participating.</li>
                    <li>Borrowing money to participate or to pay off previous losses.</li>
                    <li>Neglecting one's family or oneself due to excessive play.</li>
                    <li>Lying or not being truthful about time or money lost to playing.</li>
                    <li>Selling or pawning personal property to play.</li>
                    <li>Feelings of hopelessness, depression, or suicide.</li>
                </ul>
            </div>
        },
        {
            id: 3,
            text: 
            <p>
                If you or someone you know is or may be experiencing a compulsive-play problem, it is important to remember that assistance, intervention, and treatment are available.
            </p>
        },
        {
            id: 4,
            header: 'Resources that are committed to providing professional and compassionate service to all interested persons include:',
            text: 
            <p>
               <label>Call: 1.800-426-2537 or access <a href="https://800gambler.org/" target="_blank" rel="noopener noreferrer" >www.800gambler.org</a> </label> <br />
               <label>Call: 1.800-522-4700 National Council on Problem Gambling or access <a href="https://www.ncpgambling.org/" target="_blank" rel="noopener noreferrer" >www.ncpgambling.org</a></label> <br />
               <label> If you are in Arizona, call the toll-free Arizona statewide helpline at 1-800-NEXTSTEP or text NEXT-STEP to 53342. You may also visit 
               <a href="https://problemgambling.az.gov/." target="_blank" rel="noopener noreferrer" > https://problemgambling.az.gov/.</a> </label> <br />
               <label>If you are concerned that a family member is exhibiting warning signs of compulsive play behavior, please contact EliteDuels’ <a href="mailto:support@eliteduels.com" target="_blank" rel="noopener noreferrer">Customer Support</a>  for assistance. 
               Eligible third parties may request that a DFS consumer be excluded from EliteDuels or request the setting of deposit and/or loss limits. 
               Proof of eligibility status will be requested in documentary form evidencing sole or joint financial responsibility for the source of any funds deposited with 
               EliteDuels for gameplay including: (i) proof that the requestor is jointly obligated on the credit or debit card associated with the DFS Consumer's account; (ii) 
               proof of legal dependency of the DFS consumer on the requestor under state or federal law; (iii) proof of the existence of a court order that makes the requestor wholly or partially obligated for the debts of the person for whom exclusion is requested; 
               or (iv) proof of the existence of a court order requiring the DFS consumer to pay unmet child support obligations. EliteDuels, in its sole discretion, may request additional documentary verification information to establish third-party eligibility.
               </label>
            </p>
        }
    ]
    return (
        <div className="container-wrapper">
            <PageHeading heading={t('d05156311')} subheading={t('d29083902')} />
            <div className={styles.wrapper}>
                {responsibleText.map(item => (
                    <div className={styles.content} key={item.id}>
                        <h4>{item.header}</h4>
                        {item.text}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ResponsibleGaming;