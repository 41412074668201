import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import CarouselItem from "../../_components/carouselItem/carouselItem";
import Carousel from "react-grid-carousel";

import styles from "./playerPropsDetails.module.scss";
const getDetails = (playerPicksData) => {
  const detailsMap = {};
  if (playerPicksData && Array.isArray(playerPicksData)) {
    playerPicksData.forEach((pick) => {
      const { match, canceled } = pick;
      if (match?.id)
        detailsMap[match.id] = {
          match,
          canceled,
        };
    });
  }
  return detailsMap;
};
const PlayerPropsDetails = ({ playerPicksData }) => {
  const [t] = useTranslation();
  const matchesMap = getDetails(playerPicksData);

  return (
    <div className={styles.globalWrapper}>
      <h4>Player prop info</h4>

      {Object.values(matchesMap).map((pick) => {
        const { match, canceled } = pick;
        if (!match) return null
        return (
          <div key={match.id} className={styles.wrapper}>
            <div className={styles.paragraphDiv}>
              <p className={styles.leftParagraph}>{t("d23178525")}</p>
              <p> {match?.name ? match.name: ''}</p>
            </div>
            <div className={styles.paragraphDiv}>
              <p className={styles.leftParagraph}>Start:</p>
              <p>
                {match?.startAt
                  ? moment(match.startAt).format("MM/DD/YYYY hh:mmA")
                  : ""}
              </p>
            </div>
            <div className={styles.paragraphDiv}>
              {" "}
              <p>Match:</p>
            </div>

            <div className={styles.matchDiv}>
              <div className={styles.matchWrapper} key={match.id}>
                {canceled ? (
                  <span className={styles.canceledMatch}>Canceled</span>
                ) : null}
                <Carousel.Item style={{ marginTop: "10px" }}>
                  <CarouselItem item={match} key={match.id} />
                </Carousel.Item>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PlayerPropsDetails;
