import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './navbar.scss';
import { userActions } from '../../_store/actions/user.actions';
import { useDispatch, useSelector } from 'react-redux';

const UserMenu = ({ toggleAuthNav, show }) => {
  const store = useSelector(state => state);
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(userActions.logout());
  };
  return (
    <div className={`toggle-menu ${show ? 'tg-show-menu' : 'tg-hide-menu'}`}>
      <ul>
        {store.auth.user && store.auth.user.role === 'master' && <li onClick={toggleAuthNav}>
          <NavLink to="/admin" className="nav-link"> <span>{t('d68422714')}</span><i className="arrow"></i></NavLink>
        </li>}
        <li onClick={toggleAuthNav}>
          <NavLink to="/profile" className="nav-link" ><span>{t('d2783357')}</span><i className="arrow"></i></NavLink>
        </li>
        <li onClick={toggleAuthNav}>
          <NavLink to="/how_to_play" className="nav-link"> <span>{t('d38515927')}</span><i className="arrow"></i></NavLink>
        </li>
        <li onClick={toggleAuthNav}>
          <NavLink to="/faq" className="nav-link"><span>{t('d39135131')}</span><i className="arrow"></i></NavLink>
        </li>
        <li onClick={toggleAuthNav}>
          <NavLink to="/contact" className="nav-link" ><span>{t('d40913853')}</span><i className="arrow"></i></NavLink>
        </li>
        <li onClick={toggleAuthNav}>
          <p className="nav-link" onClick={logout}><span>{t('d22532542')}</span></p>
        </li>
      </ul>
    </div>
  );
};

UserMenu.propTypes = {
  toggleAuthNav: PropTypes.func,
  show: PropTypes.bool
};

export default UserMenu;
