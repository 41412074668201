import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import accounting from 'accounting-js';
import 'react-perfect-scrollbar/dist/css/styles.css';
import styles from './tableResults.module.scss';
import withImages from '../../_components/core/withImages'
import avatarDefault from '../../_assets/images/whitePlayer.png';
import { getUsername } from '../../_utils/index';
import { modalActions } from '../../_store/actions/modal.actions';
import { useDispatch } from 'react-redux';
import UserTeamsPreview from './UserTeamsPreview/userTeamsPreview';

const tbHeading = ['standings', 'd036516', 'd71123719', 'd70724403'];

const TableStandings = ({ rules, standings, result, acquireImages, images }) => {
  const [t] = useTranslation();
  const store  = useSelector(state => state);
  const dispatch = useDispatch();
  const [prizeType, setPrizeType] = useState('Money')
  const [skinMap, setSkinMap] = useState({})

  const openPreviewUserModal = (data) => {
   dispatch(modalActions.openModal(<UserTeamsPreview rules={rules} data={data} result={result}/>, 'Teams preview', 'xlg'));
  };

  useEffect(() =>{
    if (acquireImages) {
      if (!result.contest.skins) result.contest.skins = {}
      const skinsToAcquire = []
      const imagesToAcquire = []
      Object.keys(result.contest.skins).forEach(key => {
        skinsToAcquire.push({name: `${key}_image`, width: 150, height: 150})
      })
      acquireImages(skinsToAcquire)
      standings.forEach(data => {
        imagesToAcquire.push({name: `${data.userId}_avatar`, width: 150, height: 150})
      })
      acquireImages(imagesToAcquire, true)
      setSkinMap(result.contest.skins)
      setPrizeType(result.contest.prizeType)
    }
  }, [])

  const skinModal = (imgSrc) => {
    if(imgSrc) {
      dispatch(modalActions.openModal(
        <div className={styles.imageModalDiv} > 
          <img src={imgSrc}/> 
        </div>
    ))
    }
  }

  return (
    <>
      <div className={styles.tbWrapper}>
        <div className={styles.tableCustom}>
          <div className={`${styles.tableHead} ${styles.standingsTableHead}`}>
            {tbHeading.map((head, i) =>
              <span key={i} className={`${i  === 0 ? styles.widthPlace : i === 1 ||  (prizeType === 'Skins' && i === 3) ? `${prizeType === 'Skins' ? styles.colWithImgHeader: styles.colWithImg}` : `${styles.width10}`}`}>{t(head)}</span>
            )}
          </div>

          <PerfectScrollbar className="purpleScroll">
            {standings.length > 0 &&
              <div className={styles.tableBodyStandings}>
                {standings.map((data, i) =>
                  <div key={i} className={store && store.auth && store.auth.user && store.auth.user.id === data.userId ? styles.greenBorder : ""} >
                    <>
                      <span className={`${styles.widthPlace}`}>{i + 1}</span>
                      <span className={styles.colWithImgSta}  onClick={() => openPreviewUserModal(data)}><img src={images[data.userId+'_avatar'] || avatarDefault} alt={getUsername(data.user)}/><span>{getUsername(data.user)}</span></span>
                      <span className={styles.width10}>{data.points ? data.points : '-'}</span>
                    { prizeType === 'Skins' && data.reward ?
                      <span  className={styles.skin}>
                        <img onClick={() => skinModal(images[data.reward + '_image'])} src={images[data.reward + '_image']}/>
                        <span >{skinMap[data.reward].name}</span>
                      </span>:
                      <span className={prizeType === 'Skins' ? styles.skin : styles.width10}>{prizeType === 'Credit' ? accounting.formatNumber(data.won ? data.won : 0) + ' C': accounting.formatMoney(data.won ? data.won : 0)}</span>
                    }
                    </>
                  </div>
                )}
              </div>
            }
          </PerfectScrollbar> 
        </div>
      </div>
    </>
  );
};

TableStandings.propTypes = {
  activeColor: PropTypes.string,
  scrollColor: PropTypes.string,
  activeTab: PropTypes.string,
  tbHeading: PropTypes.array,
  tbData: PropTypes.array,
};

export default withImages(TableStandings);
