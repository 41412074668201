import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { calculateStandings, getPipeline, isObjectEmpty } from "../../_utils";
import EventDetails from "../../_components/eventDetails/eventDetails";
import TablePlayers from "../../_components/tableResults/tablePlayers";
import TableStandings from "../../_components/tableResults/tableStandings";
import Loading from "../../_components/Loading";
import Tabs from "../../_components/tabs/tabs";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../_store/actions/main.actions";
import { loaderActions } from "../../_store/actions/loader.actions";
import storageService from "../../_services/storage.service";
import { modalActions } from "../../_store/actions/modal.actions";
import PrizesDisplay from "../../_components/prizesDisplay/prizesDisplay";
import SubLinks from "../../_components/subRoutes/subRoutesTitle";
import Iframe from "../../_components/iframe/iframe";
import styles from "./results.module.scss";
import coin from "../../_assets/images/icons/coin6800.svg";
import Slides from "../../_components/slides/slides";
import GameInfo from "../../_components/gameInfo/gameInfo";
import TopPerformers from "../../_components/topPerformers/topPerformers";

const Results = (props) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const store = useSelector((state) => state);
  const myTeamTbHead = [
    t("d09094712"),
    t("d2582874"),
    t("d84268922"),
    t("d5920881"),
    t("d59318009"),
    t("d58938009"),
    t("d84982639"),
    t("d85227599"),
    t("d59328500"),
    t("d71123719"),
  ];
  const myStandings = [
    t("d13453725"),
    t("d86984201"),
    t("d71123719"),
    t("d87474551"),
  ];
  const tabs = {
    tab1: "My team",
    tab2: "Standings",
  };
  const [loading, setLoading] = useState(false);
  const {
    match: {
      params: { id, userContestId },
    },
  } = props;
  // const [msg, setMsg] = useState('');
  const [activeTab, setActiveTab] = useState("My team");
  const [activeColor, setActiveColor] = useState(
    activeTab !== "Standings" ? "#00c84a" : "#8d29ff"
  );
  const [scrollColor, setScrollColor] = useState(
    activeTab !== "Standings" ? "greenScroll" : "purpleScroll"
  );
  const [tbHeading, setTbHeading] = useState(myTeamTbHead);
  const [result, setResult] = useState({});
  const [rules, setRules] = useState({});
  const storageResults = storageService.getData("data", "result", "get", {
    userContestId,
  });
  const [standings, setStandings] = useState(
    storageResults && storageResults.standings ? storageResults.standings : []
  );
  const [players, setPlayers] = useState([]);
  const [topActions, setTopActions] = useState([]);

  const changeTab = (key) => {
    // add API wth redux
    setActiveColor(key !== "Standings" ? "#00c84a" : "#8d29ff");
    setScrollColor(key !== "Standings" ? "greenScroll" : "purpleScroll");
    setTbHeading(key !== "Standings" ? myTeamTbHead : myStandings);
    setActiveTab(key);
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (store.event.data && store.event.data.type == "game") {
      const event = store.event.data;
      if (result && result.matches && result.matches.indexOf(event.matchId)) {
        fetchData();
      }
    }
  }, [store.event.data]);

  useEffect(() => {
    setLoading(store.loader[`result_${id}`]);
  }, [store.loader[`result_${id}`]]);

  const openPrizeModal = () => {
    let data, skins, prizeType;
    if (result && result.contest) {
      data = result.contest.prizes;
      prizeType = result.contest.prizeType;
      skins = result.contest.skins;
    }
    dispatch(
      modalActions.openModal(
        <PrizesDisplay data={data} skins={skins} prizeType={prizeType} />,
        "Prizes modal",
        "md"
      )
    );
  };

  const setData = (data) => {
    if (data) {
      if (data.players) {
        setPlayers(data.players);
      }
      data.id = id;
      if (data.contest) {
        if (data.contest.matchesData) {
          const playersMap = {};
          for (const one of data.contest.matchesData) {
            one.playersTeam1.forEach((p1) => (playersMap[p1.id] = p1));
            one.playersTeam2.forEach((p2) => (playersMap[p2.id] = p2));
          }
          data.contest.playersMap = playersMap;
        }
        setResult(data);
      }
      if (data.contest && data.contest.rules) {
        setRules(data.contest.rules);
      }
    }
  };

  const calcStandings = (data, standingsResult) => {
    if (
      data.contest &&
      data.players &&
      standingsResult.participants &&
      standingsResult.events
    ) {
      const calculatedResult = calculateStandings({
        ...data,
        ...standingsResult,
      });
      const playerMap = data.contest.playersMap || {}
      calculatedResult.topActionsPlayers.forEach(one => {
        one.player = playerMap[one.playerId]
      })
      setTopActions(calculatedResult.topActionsPlayers);
      const userId = store.auth.user.id;
      data.place =
        standingsResult.participants.findIndex((one) => one.userId === userId) +
        1;
      data.firstPlacePoints = standingsResult.participants[0].points;
      data.positionsPaid = calculatedResult.positionsPaid;
      data.playerPoints = calculatedResult.playerPoints;
      if (standingsResult.participants) {
        storageService.setData("data", "result", "get", {
          id,
          standings: standingsResult.participants,
        });
      }
    }
  };

  const fetchData = async () => {
    const data = await dispatch(
      mainActions.run(
        "data",
        "userContest",
        "results",
        { userId: store.auth.user.id, userContestId },
        null
      )
    );
    setData(data);
    fetchStandings(data);
  };

  const fetchStandings = async (contestData) => {
    dispatch(loaderActions.setLoading(`result_${id}`, true));
    try {
      if (contestData && contestData.contest && contestData.contest.matches) {
        const data = await dispatch(
          mainActions.run(
            "data",
            "userContest",
            "standings",
            {
              id,
              matches: contestData.contest.matches,
            },
            null
          )
        );
        calcStandings(contestData, data);
        setStandings(data.participants);

        const userId = store.auth.user.id;
        let userScore;
        data.participants.some((one) => {
          if (one.userId === userId) {
            userScore = one;
            return true;
          }
        });
        userScore = userScore || { points: 0, won: 0 };
        setResult({
          ...contestData,
          points: userScore.points,
          won: userScore.won,
        });
      }
    } catch (err) {} //just to catch
    dispatch(loaderActions.setLoading(`result_${id}`, false));
  };

  const caroserIds = result?.carosel || [];

  return (
    <div className="global-layout-content">
      <section className="layout-content-right-banner">
          <Slides position="Side" ids={caroserIds} show={!!caroserIds.length} />
        </section>
      <div className="layout-content">
        {result && result.contest && result.matches && (
          <>
            <div className="layout-content-center">
              <div className={styles.headerDivs}>
                <div className={styles.divHeader}>
                  <GameInfo result={result} />
                </div>
                <div className={styles.divHeader}>
                  <TopPerformers performers={topActions} />
                </div>
                <div className={styles.divHeaderIframe}>
                  <div
                    className={
                      result.contest.stream ? styles.iframeWrapper : styles.noIfraneStream
                    }
                  >
                    <Iframe streamUrl={result.contest.stream} isFromContestPage={true} />
                  </div>
                </div>
              </div>
              <div className={styles.wrapper}>
                {/* <div className={styles.left}>
                <EventDetails result={result} onClick={openPrizeModal} />
              </div> */}
                <div className={styles.right}>
                  {/* <div className={styles.header}></div> */}
                  <div className={styles.bottomTables}>
                    <div className={styles.TableStandings}>
                      {loading || standings.length === 0 ? (
                        <Loading />
                      ) : (
                        <TableStandings
                          rules={rules}
                          standings={standings}
                          result={result}
                        />
                      )}
                    </div>

                    <div className={styles.TablePlayers}>
                      <TablePlayers
                        rules={rules}
                        players={players}
                        result={result}
                        playerPoints={result.playerPoints}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}  
      </div>
      <section className="layout-content-right-banner">
          <Slides position="Side" ids={caroserIds} show={!!caroserIds.length} />
        </section>
    </div>
  );
};

export default Results;
