import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import accounting from "accounting-js";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import LivePlayerCard from "../livePlayerCard/livePlayerCard";
import ModalYesNo from "../../_components/modal/modalYesNo";
import { ReactComponent as CloseIcon } from "../../_assets/images/icons/closeIcon.svg";
import withImages from "../core/withImages";

import { UPCOMING } from "../../_utils/constants";

import styles from "./userPlayerProps.module.scss";
import { modalActions } from "../../_store/actions/modal.actions";
import PlayerPropsDetails from "../playerPropsDetails/playerPropsDetails";
import { useDispatch } from "react-redux";

const CANCEL_PLAYER_PROPS_MSG =
  "Are you sure that you want to cancel selected item?";

const extractPlayers = (selected = []) => {
  const playersMap = {};
  if (selected && Array.isArray(selected) && selected.length) {
    selected.forEach(({ player }) => {
      if (player && player.id) {
        playersMap[player.id] = player;
      }
    });
  }
  return playersMap;
};

const UserPlayerProps = React.forwardRef(
  (
    {
      playerProps = {},
      acquireImages,
      images = {},
      activeTab,
      handleCancelPlayerProps,
      showUsername
    },
    ref
  ) => {
    const [showModal, sestShowModal] = useState(false);
    const [t] = useTranslation();
    const { selected } = playerProps;

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
      const playerPropsMap = extractPlayers(selected);
      const players = Object.values(playerPropsMap);
      if (players && Array.isArray(players) && players.length)
        fetchImages(players);
    }, [playerProps]);

    const handleCancel = (answer) => {
      if (answer.toLowerCase() === "yes") {
        handleCancelPlayerProps(playerProps);
      }
      sestShowModal(false);
    };

    const fetchImages = (players) => {
      if (acquireImages) {
        const imagesToAcquire = [];
        players.forEach((player) => {
          imagesToAcquire.push({
            name: `${player.id}_profileAvatar`,
            width: 150,
            height: 150,
          });
        });
        acquireImages(imagesToAcquire);
      }
    };

    if (!selected || !Array.isArray(selected) || !selected.length) return null;

    const showMoreDetails = () => {
      dispatch(
        modalActions.openModal(
          <PlayerPropsDetails playerPicksData={playerProps?.selected || []} />,
          "More details",
          "lg"
        )
      );
    };

    const editUserPlayerProps = () => {
      if (playerProps?.id)
        history.push(`/picks/${playerProps.id}`);
    };

    return (
      <article className={styles.userPlayerProps} ref={ref}>
        {showModal && (
          <ModalYesNo
            show={showModal}
            handleYesNoAnswer={handleCancel}
            question={CANCEL_PLAYER_PROPS_MSG}
          />
        )}
        <header className={styles.moreDetailsHeader}>
          <div>
            {showUsername && <span className={styles.username}>
              {playerProps.username}
            </span>}
            <span className={styles.date}>
              {moment(playerProps.from).format("MMMM, DD YYYY")}
            </span>
            <span className={styles.time}>
              {moment(playerProps.from).format("HH:mm A")}
            </span>
          </div>
          <div className={styles.moreDetails}>
            <span className={styles.showMoreDetails} onClick={showMoreDetails}>
              More Details
            </span>
            {activeTab === UPCOMING ? (
              <>
                <span
                  className={styles.showMoreDetails}
                  onClick={editUserPlayerProps}
                >
                  {t("d323691769")}
                </span>
                <span
                  className={styles.deletePlayerProps}
                  onClick={() => sestShowModal(true)}
                >
                  <CloseIcon />
                </span>
              </>
            ) : null}
          </div>
        </header>
        <div className={styles.playerPickCakrdWrapper}>
          <div className={styles.cardWrapper}>
            {selected.map(
              ({ player, bet, correct, action, value, canceled, dnp, boosted }, index) => {
                if (!player || !player.id || !bet || !action) return null;
                return (
                  <LivePlayerCard
                    key={player.id + action}
                    nick={player.nick}
                    bet={bet}
                    value={value}
                    action={action}
                    image={images[`${player.id}_profileAvatar`]}
                    correct={correct}
                    boosted={boosted}
                    canceled={canceled}
                    dnp={dnp}
                  />
                );
              }
            )}
          </div>
          <div className={styles.betInfo}>
            <div className={styles.bet}>
              <span className={styles.betLabel}>{t("d71557884")}</span>
              <span>{playerProps && playerProps.entryFee ? accounting.formatMoney(playerProps.entryFee) : ' ' + playerProps.entryCreditFee }
                 </span>
            </div>
            <div
              className={
                playerProps && playerProps.won > 0 ? styles.won : styles.notWin
              }
            >
              {playerProps.canceled ? (
                <span className={styles.canceledLabel}>Canceled</span>
              ) : (
                <>
                  <span>{t("d70724403")}</span>
                  <span>
                    {playerProps && playerProps.winning
                      ? accounting.formatMoney(playerProps.winning)
                      : "$0"}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </article>
    );
  }
);

UserPlayerProps.propTypes = {
  playerProps: PropTypes.object.isRequired,
  acquireImages: PropTypes.func,
  images: PropTypes.object,
  activeTab: PropTypes.string.isRequired,
  handleCancelPlayerProps: PropTypes.func.isRequired,
  showUsername: PropTypes.boolean,
};

export default withImages(UserPlayerProps);
