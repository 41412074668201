import React from "react";
import PageHeading from "../../../_components/pageHeading/pageHeading";
import styles from "./privacyPolicy.module.scss";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const [t] = useTranslation();

  const privacyText = [
    {
        id: 1,
        header: t('d54548048'),
        text: <p> {t('d61538581')} <br /> <br />{t('d61952331')} </p>
    },
    {
        id: 2,
        header: t('d55173070'),
        text: <p>{t('d6224488')}</p>
    },
    {
        id: 3,
        header: t('d55496313'),
        text: <p>{t('d62644507')} <br /> <br /> {t('d63293021')} </p>
    },
    {
        id: 4,
        header: t('d55802308'),
        text: <p> {t('d6367610')} <br /> <br />  {t('d64091064')}</p>
    },
    {
        id: 5,
        header: t('d56192155'),
        text: <p> {t('d64754887')}<br /> <br /> {t('d65318237')}<br /> <br /> {t('d65647714')}<br /> <br /> {t('d65978841')}</p>
    },
    {
        id: 6,
        header: t('d56632244'),
        text: <p> {t('d66374925')} <br /> <br /> {t('d66817514')}</p>
    },
    {
        id: 7,
        header: t('d57072036'),
        text: <p>{t('d67281010')}</p>
    },
    {
        id: 8,
        header: t('d57518589'),
        text: <p> {t('d67693394')} <br /> <br /> {t('d68082872')} <br /> <br /> {t('d68427706')} <br /> <br />
            {t('d68931305')} <br /> <br /> {t('d69273686')} <br /> <br /> {t('d69601326')} </p>
    },
    {
        id: 9,
        header: t('d57846379'),
        text: <p>{t('d70095152')}</p>
    },
    {
        id: 10,
        header: t('d58125210'),
        text: <p> {t('d70673696')} <br /> <br /> {t('d71191294')} <br /> <br /> {t('d71561721')} </p>
    },
    {
        id: 11,
        header: t('d58482936'),
        text: <p> {t('d71927685')} <br /> <br /> {t('d72471827')}</p>
    },
    {
        id: 12,
        header: t('d58812027'),
        text: <p>{t('d72921571')}</p>
    },
    {
        id: 13,
        header: t('d59084591'),
        text: <p>{t('d73546838')}</p>
    },
    {
        id: 14,
        header: t('d59368321'),
        text: <p> {t('d74036224')} <br /> <br />
            {t('d1760556')} $51.505 {t('d17923463')} $51.51, {t('d1823558')} $51.491 {t('d18526985')} $51.49.
        <br /> <br />
            {t('d74857121')} <br /> <br />
            {t('d19143643')} $25<br /> <br />
            {t('d19383854')} $10 <br /> <br />
            {t('d19834546')} $35 <br /> <br />
            {t('d20066942')} $10 <br /> <br />
            {t('d20294732')} $20</p>
    },
    {
        id: 15,
        header: t('d59621773'),
        text: <p>{t('d15844636')}  <a href="mailto:support@eliteduels.com" target="_blank" rel="noopener noreferrer">support@eliteduels.com.</a> </p>

    },
    {
        id: 16,
        header: t('d59913722'),
        text: <p>{t('d77334726')}</p>
    },
    {
        id: 17,
        header: t('d60252856'),
        text: <p>{t('d16475324')}  <a href="mailto:support@eliteduels.com" target="_blank" rel="noopener noreferrer">support@eliteduels.com.</a> </p>

    },
];

    return (
        <div className="container-wrapper">
            <PageHeading heading={t('d29512458')} subheading={t('d29083902')} />
            <div className={styles.wrapper}>
                <p className={styles.header}>{t('d31693085')}</p>
                {privacyText.map(item => (
                    <div key={item.id}>
                        <h1>{item.header}</h1>
                        {item.text}
                    </div>
                ))}
            </div>
        </div>
    )
};

export default PrivacyPolicy;