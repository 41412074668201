import React, { useState, useEffect } from 'react';
import styles from './walletOverview.module.scss';
import BalanceChart from '../balanceChart/balanceChart';
import { useTranslation } from 'react-i18next';
import credit from '../../_assets/images/icons/credit.svg';

const WalletOverviewRight = ({ wallet = {} }) => {
  const [t] = useTranslation();
  const [chartValue, setChartValue] = useState(0);
  const getChartValue = ({ totalWinCredit = 0, credit = 0 }) => {
    const percentage = credit ? Math.round(100 * totalWinCredit / credit) : totalWinCredit ? 100 : 0
    setChartValue(percentage)
  }
  useEffect(() => {
    getChartValue(wallet);
  }, [wallet])

  return (
    <div className={styles.container}>
    <div className={styles.balanceWrapper}>      
      <h4>{t('d8768678')}</h4>
      <div className={styles.balance}>
        <img src={credit} alt="Credit" />
      <h4 className={styles.pointsValue}>
        {wallet.credit || 0}
      </h4>
      </div>
      </div>
      {/* <BalanceChart value={chartValue} color="#8D29FF" trailColor="#181c3a" /> */}
      <div className={styles.text}>
        <span>
          <h5>{t('d36314838')}:</h5><h5 className={styles.points}>{wallet.totalWinCredit || 0}</h5>
        </span>
        <span>
          <h5>{t('d3660521')}:</h5><h5 className={styles.points}>{wallet.credit || 0}</h5>
        </span>
        <span>
          <h5>{t('d36894622')}:</h5><h5 className={styles.points}>{wallet.latestWinCredit || 0}</h5>
        </span>
      </div>

    </div>
  );
};

export default WalletOverviewRight;
