import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts'
import mainService from '../../../_services/main.service';
import styles from './revenue.module.scss';
import { useTranslation } from 'react-i18next';
import DatePickerComp from '../../../_components/datePicker/datePickerComp';
import Input from '../../../_components/input/input';
import Button from '../../../_components/button/button';
import Select from '../../../_components/selector/selector';
import moment from 'moment';
import accounting from 'accounting-js';
import { ExportToCsv } from 'export-to-csv';

const DATE_OPTIONS = [
  { label: 'Day', value: 'day'},
  { label: '2 Days', value: '2day'},
  { label: '3 Days', value: '3day' },
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' },
  { label: 'Year', value: 'year' },
]

const getDatesSettings = (value) => {
  let increment = -1
  let incrementType = 'day'
  const dayIndex = value.indexOf('day')
  if (dayIndex > -1) {
    if (dayIndex > 0) increment = value == '2day' ? -2 : -3
  } else {
    incrementType = value
  }
  return { increment, incrementType}
}

const Revenue = () => {
  const [rows, setRows] = useState([]);
  const [from, setFrom] = useState(moment().startOf('month').toDate());
  const [to, setTo] = useState(moment().endOf('month').toDate());
  const [selectedOption, setSelectedOption] = useState(DATE_OPTIONS[0]);
  const [text, setText] = useState('');
  const [total, setTotal] = useState({ deposits: 0, win: 0, withdrawal: 0, entryFees: 0, conversions: 0, referral: 0, correction: 0, total: 0 });
  const [chartData, setChartData] = useState([]);
  const [t] = useTranslation();

  const setDate = (type, date) => {
    if (type === 'from'){
      setFrom(moment(date).startOf('day').toDate())
    } else if (type === 'to'){
      setTo(moment(date).endOf('day').toDate())
    }
  }

  const handleSelectChange = (e) => {
    if (e) {
      const { increment, incrementType } = getDatesSettings(e.value)
      setSelectedOption(e)
      const to = moment().endOf('day')
      setTo(to.toDate())
      setFrom(to.add(increment, incrementType).startOf('day').toDate())
    }
  }

  const handleTextChange = (e) => {
    e.preventDefault()
    const { value = '' } = e.target;
    setText(value.trim())
  }

  const exportData = () => {
    let headers = ['Username', 'Email', 'Country', 'Amount', 'Transaction Type', 'Date']
    let exportData = [headers]
    rows.forEach(t => {
      // result += t.username +', '+ t.email + ', ' + (t.country || 'Unknown') + ', ' + t.amount + ', ' + t.transactionType +', '+ new Date(t.createdAt).toLocaleString() +'\n';
      exportData.push([t.username, t.email, (t.country || 'Unknown'), t.amount, t.transactionType, new Date(t.createdAt).toLocaleString()])
    })
    const options = {
      fieldSeparator: ',',
      quoteStrings: '',
      decimalSeparator: '.',
      showLabels: false,
      showTitle: false,
      title: 'Revenue',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: headers,
      filename: 'Revenue_'+moment(from).format("MMM_Do_YY")+'_'+moment(to).format("MMM_Do_YY"),
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(exportData);
  }

  const getData = async (id) => {
    const filter = { createdAt: { $gte: from.getTime(), $lte: to.getTime() } }
    if (text && text.trim()) {
      filter['$or'] = [{ username: {'$regex' : `^${text.trim()}`, '$options' : 'i'} }, { email: {'$regex' : `^${text.trim()}`, '$options' : 'i'} }]
    }
    const data = await mainService.run('transactions', 'user', 'revenue', { filter } );
    setRows(data.rows || [])

    const total = { deposits: 0, win: 0, withdrawal: 0, entryFees: 0, conversions: 0, referral: 0, correction: 0, total: 0 }
    const { incrementType } = getDatesSettings(selectedOption.value)
    let dateFormat = 'YYYY-MM'
    if (incrementType.indexOf('day') > -1 ) {
      dateFormat = "HH:MM"
    } else if (incrementType === 'week') {
      dateFormat = 'dddd'
    } else if (incrementType === 'month') {
      dateFormat = 'MM-DD'
    }
    const chartResult = {}
    const chartUniqueKeys = []
    data.rows.forEach(t => {
      if (t.amount) {
        const key = moment(t.createdAt).format(dateFormat)
        if (!chartResult[key]) {
          chartUniqueKeys.push(key)
          chartResult[key] = { date: key, deposits: 0, winnings: 0, spent: 0, withdrawal: 0 }
        }
        const chartKeyData = chartResult[key]
        total.total += t.amount
        switch (t.transactionType) {
          case 'CONVERSION':
            total.conversions += t.amount
            chartKeyData.spent += t.amount
            break;
          case 'CORRECTION':
            total.correction += t.amount
            // chartKeyData.correction += t.amount
            break;
          case 'DEPOSIT':
            total.deposits += t.amount
            chartKeyData.deposits += t.amount
            break;
          case 'ENTRY_FEE':
            total.entryFees += t.amount
            chartKeyData.spent += t.amount
            break;
          case 'REFERRAL':
            total.referral += t.amount
            chartKeyData.winnings += t.amount
            break;
          case 'WIN':
            total.win += t.amount
            chartKeyData.winnings += t.amount
            break;
          case 'WINNING':
            total.win += t.amount
            chartKeyData.winnings += t.amount
            break;
          case 'WITHDRAW':
            total.withdrawal += t.amount
            chartKeyData.withdrawal += t.amount
            break
        }
      }
    })
    Object.keys(chartResult).forEach(key => {
      const tmp = chartResult[key]
      tmp.deposits = Math.abs(tmp.deposits)
      tmp.withdrawal = Math.abs(tmp.withdrawal)
      tmp.winnings = Math.abs(tmp.winnings)
      tmp.spent = Math.abs(tmp.spent)
    })
    setChartData(chartUniqueKeys.sort().map(key => chartResult[key]))
    setTotal(total)
  }

  return (
    <div className={styles.wrapperManagement}>
      <Select selectClassname="selectForm" multiselect={false} options={DATE_OPTIONS} selectedOption={selectedOption} handleChangeSelect={handleSelectChange}/>
      <DatePickerComp startDate={from} onChange={(date) => setDate('from', date)} label='From Date' noMinDate={true} hideTime={true}/>
      <DatePickerComp startDate={to} onChange={(date) => setDate('to', date)} label='To Date' noMinDate={true} hideTime={true}/>
      <Input type='text' onChange={handleTextChange} value={text} placeholder={t('Enter username or email')} label={t('Search text')}/>
      <Button btnClass='btnFormGreen' label={t('d601280868')} onClick={() => { getData() }} />
      <div className={styles.statistic}>
        <div>
          <span>Deposits</span>
          <span>{accounting.formatMoney(total.deposits)}</span>
        </div>
        <div>
          <span>Withdarawals</span>
          <span>{accounting.formatMoney(total.withdrawal)}</span>
        </div>
        <div>
          <span>Entry Fees</span>
          <span>{accounting.formatMoney(total.entryFees)}</span>
        </div>
        <div>
          <span>Winnings</span>
          <span>{accounting.formatMoney(total.win)}</span>
        </div>
        <div>
          <span>Corrections</span>
          <span>{accounting.formatMoney(total.correction)}</span>
        </div>
        <div>
          <span>Conversions</span>
          <span>{accounting.formatMoney(total.conversions)}</span>
        </div>
        <div>
          <span>Referral</span>
          <span>{accounting.formatMoney(total.referral)}</span>
        </div>
        <div>
          <span>Totals</span>
          <span>{accounting.formatMoney(total.total)}</span>
        </div>
      </div>
      <div className={styles.chartWrapper}>
        <LineChart
          width={800}
          height={400}
          data={chartData}
          margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
        >
        <YAxis scale={"linear"} domain={[0, 'auto']} yAxisId={0}/>
        <XAxis dataKey="date"/>
          <Tooltip />
          <CartesianGrid stroke="#404b5b" />
          <Line type="monotone" dataKey="deposits" strokeWidth={2} stroke="#17782e" yAxisId={0} />
          <Line type="monotone" dataKey="winnings" strokeWidth={2} stroke="#4152d5" yAxisId={0} />
          <Line type="monotone" dataKey="spent" strokeWidth={2} stroke="#da9335" yAxisId={0} />
          <Line type="monotone" dataKey="withdrawal" strokeWidth={2} stroke="#c70e0e" yAxisId={0} />
        </LineChart>
      </div>
    </div>

  );
};

export default Revenue;