import { mainConstants } from '../constants';
import mainService from '../../_services/main.service';
import storageService from '../../_services/storage.service';
import toastService from '../../_services/toastService';

const GET_MODES = ['get', 'all', 'page', 'id', 'limit'];

const agg = (dataType, name, type, mode, pipeline, id, dontStore, data = {}) => {
  data = { ...data, mode, pipeline, id }
  const request = () => ({ actionType: mainConstants.MAIN_REQUEST, mode, name, type: dataType, sent: data, id })
  const success = (result) => ({ actionType: mainConstants.MAIN_SUCCESS, mode, name, type: dataType, sent: data, result, id })
  const failure = (error) => ({ actionType: mainConstants.MAIN_FAILURE, mode, name, type: dataType, sent: data, id, error })

  return dispatch => {
    dispatch(request());
    // If we want to get data we check first in storage
    if (!dontStore) {
      const fromStorage = storageService.getData(name, dataType, mode, data);
      if (fromStorage) {
        dispatch(success(fromStorage));
      }
    }
    return mainService.run(name, type, 'agg', data)
      .then(
        result => {
          dispatch(success(result));
          if (!dontStore) storageService.setData(name, dataType, mode, result);
          return result;
        },
        error => {
          dispatch(failure(error));
        }
      );
  };
}

const run = (name, type, mode, data = {}, message, dontStore) => {
  const request = () => ({ actionType: mainConstants.MAIN_REQUEST, mode, name, type, sent: data })
  const success = (result) => ({ actionType: mainConstants.MAIN_SUCCESS, mode, name, type, sent: data, result })
  const failure = (error) => ({ actionType: mainConstants.MAIN_FAILURE, mode, name, type, sent: data, error })

  return dispatch => {
    dispatch(request());
    // If we want to get data we check first in storage
    if (!dontStore && GET_MODES.indexOf(mode) > -1) {
      const fromStorage = storageService.getData(name, type, mode, data);
      if (fromStorage) {
        dispatch(success(fromStorage));
      }
    }
    return mainService.run(name, type, mode, data)
      .then(
        result => {
          dispatch(success(result));
          if (!dontStore) storageService.setData(name, type, mode, result);
          if (message) toastService.show('success', message);
          return result;
        },
        error => {
          dispatch(failure(error));
        }
      );
  };
};

const runType = (dataType, name, type, mode, data = {}, message, dontStore) => {
  const request = () => ({ actionType: mainConstants.MAIN_REQUEST, mode, name, type: dataType, sent: data })
  const success = (result) => ({ actionType: mainConstants.MAIN_SUCCESS, mode, name, type: dataType, sent: data, result })
  const failure = (error) => ({ actionType: mainConstants.MAIN_FAILURE, mode, name, type: dataType, sent: data, error })

  return dispatch => {
    dispatch(request());
    // If we want to get data we check first in storage
    if (!dontStore && GET_MODES.indexOf(mode) > -1) {
      const fromStorage = storageService.getData(name, dataType, mode, data);
      if (fromStorage) {
        dispatch(success(fromStorage));
      }
    }
    return mainService.run(name, type, mode, data)
      .then(
        result => {
          dispatch(success(result));
          if (!dontStore) storageService.setData(name, dataType, mode, result);
          if (message) toastService.show('success', message);
          return result;
        },
        error => {
          dispatch(failure(error));
        }
      );
  };
};

export const mainActions = {
  run,
  agg,
  runType
};
