import { loaderConstants } from '../constants';

const setLoading = (name, loading) => {
  return dispatch => {
    dispatch({ type: loaderConstants.SET_LOADING, name, loading });
  }
};

export const loaderActions = {
  setLoading
};
