import React, { useEffect, useState } from 'react';
import styles from './howToPlay.module.scss';
import PageHeading from '../../../_components/pageHeading/pageHeading';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { mainActions } from '../../../_store/actions/main.actions';
import storageService from "../../../_services/storage.service";
import accounting from 'accounting-js';

const HowToPlay = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const store = useSelector(state => state);
    const [minFee, setMinFee] = useState(0.5)
    const [maxFee, setMaxFee] = useState(20)
    const [limitConfig, setLimitConfig] = useState(storageService.getData("config", "limit", "data") || {});

    const getGamesSelector = () => {
        dispatch(mainActions.run('data', 'game', 'all'));
    };

    const getConfig = async () => {
        let config = await dispatch(mainActions.run("config", "limit", "get"));
        config = config || {}
        setLimitConfig(config || {});
        setMinFee(config.playerPropsMinimumDeposit || 0.5)
        setMaxFee(config.playerPropsMaximumDeposit || 20)
    };

    const [games, setGames] = useState([]);
    useEffect(() => {
        getGamesSelector();
        getConfig()
    }, [])

    useEffect(() => {
        if (store.main.data && store.main.data.game && store.main.data.game.list) {
            setGames(store.main.data.game.list)
        }
    }, [store.main.data && store.main.data.game && store.main.data.game.list])

    const basicText = [t('d39194707'), t('d39452919'), t('d39743685'), t('d40061574'), t('d40372412'), t('d40642673'), t('d40913814'), t('d41198307')];
    const termsText = [t('d53591441'), t('d53867262'), t('d5411509'), t('d54391110'), t('d54703933'), t('d5494753')];

    const renderGameRules = (rules = []) => {
        return (
            <ul>
                {Object.keys(rules).map((key, i) => {
                    return (
                        <li key={key + '-' + i}>
                            <div className={styles.gameRule}>
                                <p className={styles.ruleKey}>{t(key)} </p>
                                <p className={styles.ruleValue}>{t(rules[key])} {t('d9202545')}</p>
                            </div>
                        </li>
                    )
                })
                }
            </ul>
        )
    }

    const renderGames = (games = []) => {
        return games.map(game => {
            return (
                <div key={game.id} className={styles.gamesWrapper}>
                    <h4>{game.name}</h4>
                    <div className={styles.gamesHeader}>
                        <p className={styles.gamesTitle}>
                            {t('d323727754')}
                        </p>
                        <p>{t('d58871498')}</p>
                    </div>
                    {game && game.rules && Object.keys(game.rules).indexOf('') === -1 ? (
                        renderGameRules(game.rules)
                    ) : <p className={styles.noRules}>{t('d91171550')}</p>}
                    <div className={styles.salaryPlay}>
                        <p className={styles.header}>
                            {t('d74765180')}
                        </p>
                        {game.salaryCap ? (
                            <p>{t('d85876432')}: {accounting.formatMoney(game.salaryCap)}</p>
                        ) :
                            <p>{t('d85876432')}: {t('d07708966')}</p>}
                        {game.playersToDraft ? (
                            <p>{t('d26288935')}: {game.playersToDraft}</p>
                        ) : (
                                <p>{t('d26288935')}: {t('d07708966')}</p>
                            )}
                        {game.minSalary ? (
                            <p>{t('d27988088')}: {accounting.formatMoney(game.minSalary)}</p>
                        ) :
                            <p>{t('d27988088')}: {t('d07708966')}</p>
                        }
                        {game.maxSalary ? (
                            <p>{t('d28786321')}: {accounting.formatMoney(game.maxSalary)}</p>
                        ) :
                            <p>{t('d28786321')}: {t('d07708966')}</p>}
                        {game.rounding ? (
                            <p>{t('d29192675')}: {game.rounding}</p>
                        ) : (
                                <p>{t('d29192675')}: {t('d07708966')}</p>
                            )}
                        {game.howToPlay ? (
                            <p>{t('d38515927')}: {game.howToPlay}</p>
                        ) : (
                                <p>{t('d38515927')}: {t('d07708966')}</p>
                            )}
                    </div>
                </div>
            )
        })
    }

    return (
        <div className={styles.howToPlayWrapper}>
            <PageHeading heading={t('d38515927')} subheading={t('d29083902')} />
            <div className={styles.wrapper}>
                <h4>Player Picks</h4>
                <div className={styles.content}>
                    <p>
                        Select between 2 and 4 available players. Minimum entry fee is ${minFee}, and maximum is ${maxFee}. If you wish to have a higher limit, please contact EliteDuels support. Select over or under the predicted player stat provided by EliteDuels. Once the over and under have been selected for your players, choose your entry fee between $0.50 and $100. Once you have selected your player picks, it will be viewable under “My Contests”. You can cancel your entry fee after being submitted. Once the first player pick begins, the entry can no longer be canceled. In order to win or receive a payout, you must be correct on all selections, example (3/3, 4/4, 5/5). You lose if you get any of the picks incorrect. Example (0/3, 0/4, 2/5 etc.).
                    </p>
                    <p className={styles.basics}>Payouts</p>
                    <ul className={styles.playerPickUl}>
                        {limitConfig && Object.keys((limitConfig.playerPropsMultiplier || {})).map(key => {
                            const multiplier = limitConfig.playerPropsMultiplier[key]
                            return(
                                <li key={"how-to-play-example-"+key}>{key + " Correct Picks = "+multiplier+"x entry fee "}<br />{" $10 = $"+(10 * multiplier)+" Win"}</li>
                            )}
                        )}

                    </ul>
                </div>
                <div className={styles.questions}>
                    <p>{t('d0028707')} <Link to="/faq" className={styles.link}> {t('d39135131')} </Link>  {t('d08772810')}  <Link to="/contact" className={styles.link}> {t('d40913853')}</Link></p>
                </div>
            </div>
        </div>
    )
}

export default HowToPlay;