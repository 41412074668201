import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Input from '../../../_components/input/input';
import '../auth.scss';
import Button from '../../../_components/button/button';
import { modalActions } from '../../../_store/actions/modal.actions';
import Register from '../registerComponent/register';
import ResetPassword from '../resetPasswordComponent/resetPassword';
import { userActions } from '../../../_store/actions/user.actions';

const Login = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);

  const [user, setUser] = useState({
    email: '',
    password: '',
  });

  const openRegisterModal = () => {
    dispatch(modalActions.openModal(<Register />, 'Register modal', 'xxl'));
  };

  const openResetModal = () => {
    dispatch(modalActions.openModal(<ResetPassword />, 'Reset password modal', 'md'));
  };

  const handleChange = e => {
    setError(false);
    const { name, value } = e.target;
    setUser(user => ({ ...user, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      dispatch(userActions.login(user));
    } else {
      setError(true);
    }
  };

  const validateForm = () => {
    return user.email && user.password;
  };

  return (
    <div>
      <form className="auth-form">
        <h4 className="auth-header">{t('d79153445')}</h4>
        <div className="form-body">
          <Input name="email" type="text" value={user.email} onChange={handleChange} placeholder={t('d84155573')} autofocus={true} />
          <Input name="password" type="password" value={user.password} onChange={handleChange} placeholder={t('d344129542')} />
          {error && <div className="input-err-msg">{t('d36751866')}</div>}
          <div className="form-remember">
            <p onClick={openResetModal} className="clickable-label">{t('d8011478')}</p>
          </div>
        </div>

        <Button
          label={t('d79153445')}
          btnClass={validateForm() ? 'btnPrimary' : 'btnSecondary'}
          fullWidth={true}
          type="submit"
          onClick={handleSubmit}
        />
        <div className="auth-footer">
          <span>
            {t('d82085457')}
          </span>
          <p onClick={openRegisterModal} className='clickable-label'>
            {t('d26582354')}
          </p>
        </div>
      </form>
    </div>
  );
};

export default Login;
