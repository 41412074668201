import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Input from '../../input/input';
import Button from '../../button/button';
import styles from './CheckList.module.scss';

const CheckListSelect = ({ fields = {}, field = {}, error, onChange }) => {
  const [t] = useTranslation();
  const [keyValueArray, setKeyValueArray] = useState([{ name: '', selected: true }])

  useEffect(() => {
    let value = fields[field.dbname] || []
    if (value.length === 0) value.push({ name: '', selected: true })
    setKeyValueArray(value);
  }, [fields[field.dbname]]);

  const onFocusOut = (index) => {
    const changingValue = keyValueArray[index]
    if (onChange && changingValue.name) onChange(field.dbname, keyValueArray)
  };

  const handleValueChange = (e, index) => {
    const { value, checked, name } = e.target;
    const changingValue = keyValueArray[index]
    if (name === 'name') {
      changingValue.name = value
    } else if (name === 'selected') {
      changingValue.selected = checked
    }

    setKeyValueArray([...keyValueArray])
  };

  const handleRemoveOneClick = (e, index) => {
    e.preventDefault();
    keyValueArray.splice(index, 1)
    setKeyValueArray([...keyValueArray])
    if (onChange) onChange(field.dbname, keyValueArray)
  };

  const handleAddNewClick = (e) => {
    e.preventDefault()
    keyValueArray.push({ name: '', selected: true })
    setKeyValueArray([...keyValueArray])
  };

  return (
    <div key={field.dbname + '-checkList' } className={styles.checkListWrapper}>
      {field.label && <label>{t(field.label)}</label>}
      <small>{t(field.description)}</small>
      {keyValueArray.length > 0 && <div className={styles.checkListStyle} >
        {keyValueArray.map((element, i) => {
          return (
            <React.Fragment key={i}>
              <div className={styles.checkListInput}>
                <div>
                  <label>{field.label1 || 'Name'}</label>
                  <Input type="text" name="name" placeholder={t(field.label1)} value={element.name} onChange={e => handleValueChange(e, i, field.dbname)} onFocusOut={() => onFocusOut(i)}/>
                </div>
                <div>
                  <label>{field.label2 || 'Selected'}</label>
                  <Input type="checkbox" name="selected" placeholder={t(field.label1)} value={element.selected ? "1" : "0"} checked={element.selected} onChange={e => handleValueChange(e, i, field.dbname)} onFocusOut={() => onFocusOut(i)}/>
                </div>
                {keyValueArray.length !== 1 &&
                <Button type="button" btnClass='btnSecondary' label={<i className="fa fa-minus" aria-hidden="true"></i>} onClick={(e) => handleRemoveOneClick(e, i)} />
                }
              </div>
              {
                keyValueArray.length - 1 === i &&
                <div className={styles.checkListAdd}>
                  <Button type="button" btnClass='btnPrimary' label={<i className="fa fa-plus" aria-hidden="true"></i>} onClick={(e) => handleAddNewClick(e)} />
                </div>
              }

              {error && (!element.value && (element.value !== 0)) && field.required && <div className="input-err-msg">{t('d621915485')} {t(field.label1)}</div>}
              {error && (!element.key && (element.key !== 0)) && field.required && <div className="input-err-msg">{t('d621915485')} {t(field.label2)}</div>}
            </React.Fragment>
          )
        })}
      </div>}
    </div>)
};

export default CheckListSelect;