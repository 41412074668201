import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import angleRight from '../../_assets/images/icons/angleRight.svg';
import PageHeading from '../../_components/pageHeading/pageHeading';
import { PrivateRoute } from '../../_routes/privateRoute';
import './influencer.scss';
import InfluencerForm from './InfluencerForm/InfluencerForm';
import InfluencerList from './InfluencerList/InfuencerList';

const InfluencerLanding = (props) => {
    const [t] = useTranslation();
    const store = useSelector(state => state);
    useEffect(() => {
      if (store.auth.user.role === 'user') {
        props.history.push('/lobby');
      }
    }, [props.history]);

  
    return (
      <div className="container-fluid-wrapper">
        <PageHeading heading={t("d68422714")} subheading={t("d54356639")} />
        <div className="influencerWrapper">
          {/* <div className="generateBtns">
            <NavLink to={`/influencer/contests`} key={`option-button-contest`} className="active"> {t('d1651632')} <img src={angleRight} alt="angle_right" /></NavLink>
          </div> */}
          <div className="content">

            { props.history.location.pathname === '/influencer/contests' ?
            <PrivateRoute path="/influencer/contests" component={InfluencerList} name="influencer contests" /> :
            props.history.location.pathname === '/influencer/contests/new' ?
            <PrivateRoute path="/influencer/contests/new" component={InfluencerForm} name="influencer contests" /> : 
            <PrivateRoute path="/influencer/contests/edit" component={InfluencerForm} name="influencer contests" /> } 
          </div>
        </div>
      </div >
    );
}

export default InfluencerLanding;