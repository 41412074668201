
import httpService from './http.service';

class LangService {
  async run(mode, data = {}, query) {
    const url = query ? `/api/language?${query}` : '/api/language';
    return httpService.apiRequest('post', url, { mode, data });
  }
}

export default new LangService();
