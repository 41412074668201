import React, { useState, useEffect } from 'react';
import styles from './contestInfoDisplay.module.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import accounting from 'accounting-js';

const _formatFeeText = (formater, fees = {}, multiplier = 1) => {
    const keys = Object.keys(fees).sort()
    let i = 1
    let text = ''
    for (const key of keys) {
        text += `${key} Entry = ${formater(multiplier*fees[key])}; `
        i++
    }
    return text
}

const ContestInfoDisplay = ({ contest, matches }) => {
    const [t] = useTranslation();
    const [contestData, setContestData] = useState({});
    const [matchesData, setMatchesData] = useState([]);

    const getMatchesData = async (data) => {
        await setMatchesData(data);
    }

    const getContestData = async (data) => {
        if (data.prizeType === 'Skins') {
            const skins = data.skins || {}
            if (data.prizes) {
                Object.keys(data.prizes).map((key, i) => {
                    data.prizes[key] = skins[data.prizes[key]].name || ''
                })
            }
        }
        await setContestData(data);
    }
    useEffect(() => {
        getContestData(contest)
    }, [contest])

    useEffect(() => {
        getMatchesData(matches)
    }, [matches])

    return (
        <div className={styles.wrapper}>
            <h4>{t('d86475838')}</h4>
            <div className={styles.content}>
                <div className={styles.info}>
                    <h5> {t('d39251520')}: </h5>
                    <p>{t(contestData.name)}</p>
                </div>
                <div className={styles.info}>
                    <h5> {t('d41312757')}: </h5>
                    <p>{moment(contestData.from).format("dddd, MMMM Do YYYY, h:mm:ss a")}</p>
                </div>
                <div className={styles.info}>
                    <h5> {t('d41831975')}: </h5>
                    <p>{moment(contestData.to).format("dddd, MMMM Do YYYY, h:mm:ss a")}</p>
                </div>
                <div className={styles.info}>
                    <h5> {t('d71557884')}: </h5>
                    <p>{_formatFeeText(accounting.formatMoney, contestData.fees, 1)}</p>
                </div>
                <div className={styles.info}>
                    <h5> {t('d44221901')}: </h5>
                    <p>{_formatFeeText(accounting.formatNumber, contestData.fees, contestData.creditDolarRatio)}</p>
                </div>
                <div className={styles.info}>
                    <h5> {t('d4914725')}: </h5>
                    <p>{contestData.maxEntries}</p>
                </div>
                {contestData.prizes && <div className={styles.rules}>
                    <h4>{t('d64753840')}</h4>
                    {Object.keys(contestData.prizes).map((key, i) => {
                        return (
                            <div key={i} className={styles.info}>
                                <h5> {key} </h5>
                                <p> {contestData.prizes[key]} </p>
                            </div>
                        )
                    })}
                </div>}
                {contestData.rules && <div className={styles.rules}>
                    <h4>{t('d31586433')} </h4>
                    {Object.keys(contestData.rules).map((key, i) => {
                        return (
                            <div className={styles.info} key={i}>
                                <h5> {t(key)}: </h5>
                                <p>{contestData.rules[key]} {t('d9202545')}</p>
                            </div>
                        )
                    })}
                </div>}
                {matchesData.map(item => (
                    <div className={styles.match} key={item.id}>
                        <h4>{item.name}</h4>
                        <div className={styles.matches}>
                            <h5>{item.teams[0]} </h5>
                            <b> vs </b>
                            <h5> {item.teams[1]} </h5>
                        </div>
                        <div className={styles.info}>
                            {/* <h5>{t('d41312757')}:</h5> */}
                            <p>{moment(item.startAt).format("dddd, MMMM Do YYYY, h:mm:ss a")}</p>
                        </div>
                    </div>
                ))}

            </div>

        </div>
    )
}

export default ContestInfoDisplay;