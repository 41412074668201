import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { imageActions } from "../../_store/actions/image.actions";
import imageService from "../../_services/image.service";
import { sessionService } from "../../_sessionService/storage";

const imageExists = (imageData) => {
  if (!imageData || !imageData.length) return false
  else if (imageData.indexOf('base64') <= imageData.length - 10) return false
  return true
}

const withImages = (OriginalComponent) => {
  const UpdatedComponent = React.forwardRef((props, ref) => {
    const store = useSelector((state) => state);
    const dispatch = useDispatch();
    const [imagesNames, useImageNames] = useState([]);
    const [images, setImages] = useState({});

    useEffect(() => {
      setImages(store.image);
    }, [store.image]);

    const pullImages = async (imagesData = [], additionalLoad) => {
      const notLoaded = []
      for (const image of imagesData) {
        const { name, width, height } = image;
        let imageData = sessionService.getImg(name);
        if (!imageExists(imageData)) {
          if (imageData) sessionService.destroy(name)
          imageData = null
        }
        if (!imageData) {
          const result = await imageService.getImage(width, height, name);
          if (result && result.data) {
            if (imageExists(result.data)) {
              imageData = result.data;
              sessionService.set(name, imageData);
            } else if (additionalLoad){
              notLoaded.push(image)
            }
          }
        }
        if (imageExists(imageData)) {
          dispatch(imageActions.pushImage(name, imageData));
        }
      }
      if (notLoaded.length) {
        await pullImages(notLoaded, true)
      }
    };

    const _delay = (amount) => {
      return new Promise(resolve => setTimeout(() => resolve(), amount))
    }

    const pullImagesAsync = async (images = []) => {
      let count = 0
      for (const { name, width, height } of images) {
        if (count == 20) {
          await _delay(1000)
          count = 0
        }
        setTimeout(() => {
          dispatch(imageActions.getImage(name, width, height))
        }, 50)
        count++
      };
    };

    const setImagesForLoad = (names, loadSync) => {
      if (loadSync) pullImages(names);
      else pullImagesAsync(names);
    };

    return (
      <OriginalComponent
        acquireImages={setImagesForLoad}
        images={images}
        {...props}
        ref={ref}
      />
    );
  });

  return UpdatedComponent;
};

export default withImages;
