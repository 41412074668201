import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { profileActions } from '../../../../_store/actions/profile.actions';
import SmallTitle from '../../../../_components/smallTitle/smallTitle';
import LineupTabs from '../../../../_components/lineupTabs/lineupTabs';
import LineupTable from '../../../../_components/lineupTable/lineupTable';
import EventLineup from '../../../../_components/eventLineup/eventLineup';
import Loading from '../../../../_components/Loading';
import * as _ from 'lodash';
import './TeamSelection.scss';
import toastService from '../../../../_services/toastService';

const TeamSelection = ({ chosenTeams, contest, onSubmit }) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [teams, setTeams] = useState(chosenTeams);
    const [players, setPlayers] = useState();
    const [tiers, setTiers] = useState([]);
    const [tierPlayers, setTierPlayers] = useState({});
    const [captainPlayers, setCaptainPlayers] = useState({});
    const [salaryCapPlayers, setSalaryCapPlayers] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [processing, setProcessing] = useState(false);
    const [allSelectedPlayers, setAllSelectedPlayers] = useState([]);
    const [playersForCompare, setPlayersForCompare] = useState([]);
    const [selectedData, setSelectedData] = useState({});
    const history = useHistory();
    const tbHeading = [
      t('d6777170'), t('d68274296'), t('d1125659')
    ];

    useEffect(() => {
      setTeams(chosenTeams);
    }, [chosenTeams]);

    useEffect(() => {
      teams && fetchPlayers();
    }, [teams]);

    const fetchPlayers = async () => {
      const players = await dispatch(profileActions.run('all', { query: { type: 'player', nick: {'$in': teams }}}));
      let matchPlayers = _.uniqBy(players, one => one.id);
      setPlayers(matchPlayers.sort((a, b) => Number(b.fppm || 0) - Number(a.fppm || 0)));
      setProcessing(false)
    }

    useEffect(() => {
      if (contest.contestType === 'd09094712') {
        // Contest type Tiers
        tiersLogic();
      } else if (contest.contestType === 'd12792751') {
        // Contest type Captain
        captainLogic();
      } else if (contest.contestType === 'd85876432') {
        // Contest type Salary cap
        salaryCapLogic();
      };
    }, [players]);
  
    useEffect(() => {
      if ((contest.contestType === 'd09094712')) {
        // Contest type Tiers edit
        tiersCaptainLogicEdit();
      }
    }, [tierPlayers]);
  
    useEffect(() => {
      if ((contest.contestType === 'd85876432')) {
        // Contest type Salary cap edit
        salaryCapLogicEdit();
      }
    }, [salaryCapPlayers]);
  
    useEffect(() => {
      if ((contest.contestType === 'd12792751')) {
        // Contest type Captain edit
        tiersCaptainLogicEdit();
      }
    }, [captainPlayers]);
  
    const tiersLogic = () => {
      if (players && players.length > 0 && contest.game.tiers) {
        const tierLimits = [];
        const tierKeys = Object.keys(contest.game.tiers).sort((a, b) => Number(a) - Number(b));
        tierKeys.forEach(key => tierLimits.push(contest.game.tiers[key]));
        setTiers(tierLimits);
        const count = players.length;
        let addeddCount = 0;
        const tierResult = {};
        tierLimits.forEach((tier, i) => {
          let tierPlayersCount;
          if (i === tierLimits - 1) {
            tierPlayersCount = players.count - addeddCount;
          } else {
            tierPlayersCount = Math.round(tier / 100 * count);
          }
          tierResult[i] = players.slice(addeddCount, addeddCount + tierPlayersCount);
          addeddCount += tierPlayersCount;
        });
        setTierPlayers(tierResult);
      }
    };

    const captainLogic = () => {
      if (players && players.length > 0) {
        const list = {
          0: [],
          1: []
        };
        players.forEach((player, i) => {
          if (player.salary > contest.game.captainCap) {
            list[0].push(player);
          } else {
            list[1].push(player);
          }
        });
        Object.keys(list).forEach(key => {
          list[key].sort((a, b) => b.salary - a.salary);
        });
        setCaptainPlayers(list);
        setTiers(['d64667885', 'd6777170']);
      }
    };
    const salaryCapLogic = () => {
      if (players && players.length > 0) {
        players.sort((a, b) => b.salary - a.salary);
        setSalaryCapPlayers(players);
      }
    };
  
    const tiersCaptainLogicEdit = () => {
      let listPlayers = {};
      if (contest.contestType === 'd09094712') {
        listPlayers = { ...tierPlayers };
      } else if (contest.contestType === 'd12792751') {
        listPlayers = { ...captainPlayers };
      }
      if (listPlayers && Object.keys(listPlayers).length > 0) {
        if (contest && history.location.pathname.includes('edit')) {
          const listSelectedPlayers = contest.team;
          const playersByTiers = [];
          listSelectedPlayers.forEach((el) => {
            _.find(listPlayers, (list, i) => {
              _.find(list, (found) => {
                if (found.id === el) {
                  playersByTiers.push({ tier: i, player: found });
                }
              });
            });
          });
          setAllSelectedPlayers(playersByTiers);
          setPlayersForCompare(playersByTiers);
        }
        setProcessing(false)
      }
    };
    const salaryCapLogicEdit = () => {
      if (salaryCapPlayers && salaryCapPlayers.length > 1) {
        if (contest && history.location.pathname.includes('edit')) {
          const listSelectedPlayers = contest.team;
          const playersByTiers = [];
          listSelectedPlayers.forEach((el) => {
            _.find(salaryCapPlayers, (list) => {
              if (list.id === el) {
                playersByTiers.push({ player: list });
              }
            });
          });
          setAllSelectedPlayers(playersByTiers);
          setPlayersForCompare(playersByTiers)
        }
        setProcessing(false)
      }
    };
  
    const changeTab = (idx) => {
      setActiveTab(idx);
    };
  
    const selectPlayer = (player) => {
      if ((contest.contestType === 'd09094712') || (contest.contestType === 'd12792751')) {
        if (activeTab < tiers.length - 1) {
          changeTab(+activeTab + 1);
        }
        setSelectedData({ tier: activeTab, player: player });
      } else if (contest.contestType === 'd85876432') {
        setSelectedData({ player: player });
      };
    };
  
    const onEnter = async (data) => {
      onSubmit( data.map(el => el.player.id));
      history.location.pathname.includes('edit') && toastService.show('success', t('d79057940'));
    };
  
    const compareSelectedPlayers = (data) => {
      setPlayersForCompare(data);
    }

    return (
      <div className="layout-content-reversed reversed">
      {<div className="layout-content-left left-wrapper">
        {((contest.contestType === 'd09094712') || (contest.contestType === 'd12792751')) &&
          <div className="contest-types">
            <LineupTabs tiers={tiers} activeTab={activeTab} changeTab={changeTab} contest={contest} contestType={contest.contestType} /></div>}
        {contest.contestType === 'd09094712' &&
          <LineupTable tbHeading={tbHeading} selectedPlayers={playersForCompare} tbData={tierPlayers[activeTab]} onClick={selectPlayer} contest={contest}/>}
        {contest.contestType === 'd85876432' && 
          <><div className="contest-types">
            <SmallTitle title={t('d4036504')}></SmallTitle>
          </div>
            <LineupTable tbHeading={tbHeading} selectedPlayers={playersForCompare} tbData={salaryCapPlayers} onClick={selectPlayer} contest={contest}/>
          </>}
        {contest.contestType === 'd12792751' &&
          <LineupTable tbHeading={tbHeading} selectedPlayers={playersForCompare} tbData={captainPlayers[activeTab]} onClick={selectPlayer} contest={contest} />}
      </div>}
      {<div className="layout-content-right">
          {!processing || allSelectedPlayers.length > 0 ?
            <EventLineup
              selectedData={selectedData}
              contest={contest}
              tiers={tiers}
              onEnter={onEnter}
              selectedEditData={allSelectedPlayers}
              contestType={contest.contestType}
              compare={compareSelectedPlayers} /> :
            !processing ? <div>
              { <h4>{t('d69342477')} <NavLink to={{ pathname: '/contests', hash: '#upcoming' }} className="nav-link"> {t('d10908779')}</NavLink></h4>}
              { <h4>{t('d39664522')}</h4> }
            </div> : <Loading />}
        </div>}
    </div>
    );
  };

export default TeamSelection;