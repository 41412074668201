import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import accounting from "accounting-js";
import styles from "./listWithStatus.module.scss";
import winImg from "../../_assets/images/icons/cup.svg";
import entryFeeImg from "../../_assets/images/icons/entryFee.svg";

const checkColor = (status) => {
  if (status === "d43334659") {
    return "greenColor";
  } else if (status === "d44335691") {
    return "purpleColor";
  } else {
    return "redColor";
  }
};
const checkStatus = (status) => {
  if (status === "d43334659") {
    return "completed";
  } else if (status === "d44335691") {
    return "pending";
  } else {
    return "failed";
  }
};

const checkType = (type) => {
  if (type === "WIN") {
    return winImg;
  } else if (type === "ENTRY_FEE") {
    return entryFeeImg;
  } else {
    return null;
  }
};

const checkAmount = (amount) => {
  if (Number(amount) > 0 ) {
    return "txtGreen";
  } else if (amount < 0) {
    return "txtOrange";
  } else {
    return "txtWhite"
  }
}

const  datesOnSameDay = (first, second) => {
  first = new Date(first);
  second = new Date(second);
  return first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate();
}

const ListWithStatus = ({ data }) => {
  const [t] = useTranslation();
  const activeColor = checkColor(data.status);
  const status = checkStatus(data.status);
  const img = checkType(data.transactionType);
  const amountColor = checkAmount(data.amount);
  return (
    <div className={`${styles.wrapper}`}>
        <div className={styles.date}>
          <span>{moment(data.createdAt).format('MM/DD/YYYY')} </span>
        </div>
      <div className={`${styles.item} ${styles[activeColor]}`}>
        <div className={styles.mobStatus}>
          <div>
            <span>{moment(data.createdAt).format("MM/DD/YYYY hh:mmA")} </span>
          </div>
          <div>
            <span className={styles.status}>{status}</span>
          </div>
          {/* <div>
            <i className="fa fa-info-circle" aria-hidden="true"></i>
          </div> */}
        </div>
        <div className={styles.info}>
          <div className={styles.action}>
            <img src={img} alt="" />
            <div className={styles.paymentInfo}>
              <span className={styles.type}>{data.paymentType ? t(data.paymentType) : "-"}</span>
              <p className={styles.infoP}>{data.info ? data.info : "-"}</p>
            </div>
          </div>
          <div className={styles.amount}>
            <p>{t("d56807038")}:</p>
            <span className={`${styles.dataAmount} ${styles[amountColor]}`}>{data.amount ? accounting.formatMoney(data.amount) : 0}</span>
          </div>
          <div className={styles.amount}>
            <p>{t("d8768678")}:</p>
            <span>{data.credit || 0}</span>
          </div>
          <div className={styles.status}>
            <span>{status}</span>
            {/* <i className="fa fa-info-circle" aria-hidden="true"></i> */}
          </div>
          <div className={styles.time}>
            <small>{moment(data.createdAt).format('hh:mmA')}</small>
          </div>
        </div>
      </div>
    </div>
  );
};

ListWithStatus.propTypes = {
  data: PropTypes.object,
};

export default ListWithStatus;
