import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './datePickerComp.scss';
import PropTypes from 'prop-types';
import { DATE_FORMAT, DATE_FORMAT_NO_TIME } from '../../_utils/constants'

const DatePickerComp = ({ startDate, onChange, name, label, noMinDate = false, hideTime = false }) => {
    return (
        <div className="date-picker-wrapper">
            {label && <label htmlFor={name}>{label}</label>}
            <DatePicker
                selected={startDate}
                onChange={date => onChange(date, name)}
                showTimeSelect={!hideTime}
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
                dateFormat={hideTime ? DATE_FORMAT_NO_TIME : DATE_FORMAT}
                minDate={noMinDate ? null : new Date()}
            />
        </div>
    )
}

DatePickerComp.propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string
}

export default DatePickerComp;