import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './pagination.scss';
import 'rc-pagination/assets/index.css';
import Selector from '../selector/selector';
import Pagination from 'rc-pagination';
import PropTypes from 'prop-types';

const PAGE_OPTIONS = ['2', '4', '6', '8', '10', '20'].map(value=> ({ value: Number(value), label: value}))

const CustomPagination = ({ el_GR, data = [], defaultPageSize = 6, onChange }) => {
  const [t] = useTranslation();
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(defaultPageSize)

  const NextIcon = () => {
    return (
      <span className="navigation-span">{t('d84431338')}</span>
    );
  };

  const PreviousIcon = () => {
    return (
      <span className="navigation-span">{t('d84983469')}</span>
    );
  };

  const onPageSizeChange = (option) => {
    const pageSize = option.value
    setCurrentPageSize(pageSize)
    onChange(data.slice((currentPage-1)*pageSize, currentPage * pageSize), (currentPage-1)*pageSize, currentPage)
  };

  const onPaginationChange = (page, pageSize) => {
    setCurrentPage(page)
    onChange(data.slice((page-1)*pageSize, page * pageSize), (page-1)*pageSize, page)
  }

  useEffect(() => {
    if (data && data.length > 0){
      onChange(data.slice(0, currentPageSize), (currentPage-1)*currentPageSize, currentPage)
    } else {
      onChange([], 0)
    }
  }, [data])

  return !data || data.length === 0  || ( data.length < currentPageSize && defaultPageSize === currentPageSize )? null : (
    <div className="pagination-wrapper">
      <Pagination
        className="pagination-component"
        total={data.length}
        locale={{ el_GR }}
        defaultPageSize={defaultPageSize}
        pageSize={currentPageSize}
        nextIcon={NextIcon}
        prevIcon={PreviousIcon}
        defaultCurrent={1}
        current={currentPage}
        onChange={onPaginationChange}
        showLessItems
      />
      <Selector selectClassname="paginator-pagesize-selector selectForm"
        selectedOption={PAGE_OPTIONS.find(one => one.value === currentPageSize)}
        options={PAGE_OPTIONS}
        handleChangeSelect={(e) => onPageSizeChange(e)} />
    </div>
  );
};

CustomPagination.prototypes = {
  total: PropTypes.number,
  defaultPageSize: PropTypes.number,
  onChange: PropTypes.func
};

export default CustomPagination;
