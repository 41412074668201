export const userConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  UPDATE_USER: 'UPDATE_USER',

  LOGOUT: 'USERS_LOGOUT',

  REMEMBER_USER: 'REMEMBER_USER',
  FORGOT_USER: 'FORGOT_USER',

  SET_ROUTE: 'SET_ROUTE',

  REFERRAL_REQUEST: 'REFERRAL_REQUEST',
  REFERRAL_SUCCESS: 'REFERRAL_SUCCESS',
  REFERRAL_FAILURE: 'REFERRAL_FAILURE',

  REFRESH_REQUEST: 'REFRESH_REQUEST',
  REFRESH_SUCCESS: 'REFRESH_SUCCESS',
  REFRESH_FAILURE: 'REFRESH_FAILURE',

  RESET_LINK_REQUEST: 'RESET_LINK_REQUEST',
  RESET_LINK_SUCCESS: 'RESET_LINK_SUCCESS',
  RESET_LINK_FAILURE: 'RESET_LINK_FAILURE',

  FORGOT_REQUEST: 'FORGOT_REQUEST',
  FORGOT_SUCCESS: 'FORGOT_SUCCESS',
  FORGOT_FAILURE: 'FORGOT_FAILURE',

  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

  CONTACT_FORM_REQUEST: 'CONTACT_FORM_REQUEST',
  CONTACT_FORM_SUCCESS: 'CONTACT_FORM_SUCCESS',
  CONTACT_FORM_FAILURE: 'CONTACT_FORM_FAILURE',

  EMAIL_TEMPLATE_REQUEST: 'EMAIL_TEMPLATE_REQUEST',
  EMAIL_TEMPLATE_SUCCESS: 'EMAIL_TEMPLATE_SUCCESS',
  EMAIL_TEMPLATE_FAILURE: 'EMAIL_TEMPLATE_FAILURE'
};
