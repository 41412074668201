import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Button from '../../../_components/button/button';
import styles from './events.module.scss';
import { mainActions } from '../../../_store/actions/main.actions';
import EventsMatch from './eventsMatch';
import { useTranslation } from 'react-i18next';
import Selector from '../../../_components/selector/selector';
import { isObjectEmpty } from '../../../_utils';
import { modalActions } from '../../../_store/actions/modal.actions';

const EventsAdd = ({ data, selectedMatch, editData, onSubmit, type, eventMode }) => {

  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [event, setEvent] = useState({});
  const [addData, setAddData] = useState({});
  const [show, setShow] = useState(false);
  const [match, setMatch] = useState('');
  const [selectedLocation, setSelectedLocation] = useState({});
  const [selectedPeriod, setSelectedPeriod] = useState({});
  useEffect(() => {
    if (data) {
      handleMatchSelect(data);
    }
  }, [data]);

  useEffect(() => {
    if (editData) {
      setSelectedLocation(editData.location);
      setSelectedPeriod(editData.period);
      let selectedEvent = {};
      if (editData.actions) {
        editData.actions.forEach(element => {
          selectedEvent = { ...selectedEvent, ...element };
        });
        setEvent(selectedEvent);
      }
    }
  }, [editData]);

  const handleMultiInput = (e, action, player) => {
    if (event[action] && event[action].id === player.id) {
      const filterEvent = { ...event };
      Object.entries(filterEvent).filter(([key]) => {
        if (action === key) {
          delete filterEvent[key];
          delete filterEvent.points;
        }
      }
      );
      return setEvent(filterEvent);
    } else {
      setEvent({ ...event, [action]: player });
    }
  };

  const handleSubmit = () => {
    const arrEvent = [];
    Object.keys(event).forEach(key => {
      const player = event[key]
      arrEvent.push({ player, [key]: player.id, points: +data.game.rules[key]});
    });

    const submitData = {
      actions: arrEvent,
      type: 'game',
      matchId: match.id,
      game: match.game.name,
      eventMode: eventMode ? eventMode : null
    };
    if (type === 'new') {
      submitData.timestamp = moment().valueOf();
    }
    if (type === 'update') {
      submitData.id = editData.id;
    }
    if (!isObjectEmpty(selectedPeriod)) {
      submitData.period = selectedPeriod;
    }
    if (!isObjectEmpty(selectedLocation)) {
      submitData.location = selectedLocation;
    }
    if (type === 'new') {
      dispatch(mainActions.run('events', 'game', 'new', submitData, t('d40747639')));
    } else {
      dispatch(mainActions.run('events', 'game', 'update', submitData, t('d41166397')));
      dispatch(modalActions.closeModal());
    }

    if (onSubmit) onSubmit(submitData);
    setEvent({});
  };

  const handleMatchSelect = (match) => {
    const actions = Object.keys(data.game.rules).map(key => {
      return key;
    });
    const teamsMap = {};

    match.teams.forEach((team, i) => {
      teamsMap[team] = { name: team, players: match['playersTeam' + (i + 1)] || [] };
    })
    const teams = Object.keys(teamsMap).map(key => teamsMap[key])
    const list = {
      teams,
      actions
    };
    if (data.locations) {
      const locations = Object.entries(data.locations).map(([key, value]) => {
        return { label: value, value: key };
      });
      list.locations = locations;
      setSelectedLocation(locations[0]);
    }

    if (data.periods) {
      const periods = Object.entries(data.periods).map(([key, value]) => {
        return { label: value, value: key };
      });
      list.periods = periods;
      setSelectedPeriod(periods[0]);
    }
    setMatch(match);
    setShow(true);
    setAddData(list);
  };

  const handleSelectChange = (selected, type) => {
    if (type === 'locations') {
      setSelectedLocation(selected);
    } else {
      setSelectedPeriod(selected);
    }
  };
  return (
    <div className="modal-event-custom">
      <PerfectScrollbar className="greenScroll">
        {!show ? <>
        </> :
          <div>

            {addData && <div className={styles.addWrapper}>
            <div className={styles.title}>
              <h5><span>{t('d22215523')}:</span>{data.game ? data.game.name : '-'}</h5>
              <h5><span>{t('d42026764')}:</span>{match.name ? match.name : "-"} </h5>
            </div>
              <div className={styles.selectWrapper}>
                {addData.locations && <Selector
                  selectClassname="selectLg"
                  options={addData.locations}
                  handleChangeSelect={(e) => { handleSelectChange(e, 'locations') }}
                  placeholder={t('d465729339')}
                  selectedOption={selectedLocation}
                  label={t('d64038779')} />}
                {addData.periods && <Selector
                  selectClassname="selectLg"
                  options={addData.periods}
                  selectedOption={selectedPeriod}
                  handleChangeSelect={(e) => { handleSelectChange(e, 'periods') }}
                  placeholder={t('d465729339')}
                  label={t('d64741142')} />}

              </div>

              <div className={styles.list}>
                {addData.actions && <div className={styles.actions}>  {addData.actions.map((action, i) => (
                  <div key={i} >
                    <b>{action}</b>
                    {addData.teams.map((teamData, key) => (
                      <div key={key}>
                        {i === 0 ? <span className={styles.teamName}>{teamData.name}</span> : <span className={`${styles.teamName} ${styles.teamNameHidden}`}>_</span>}
                        <ul>
                          {teamData.players.map((player, i) => (
                            <li key={i}>
                              <input type="button" value={player.nick} onClick={(e) => {
                                handleMultiInput(e, action, player);
                              }} className={event[action] && event[action].id === player.id ? `${styles.active} ${styles.groupInput}` : `${styles.groupInput}`} />
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                ))}
                </div>}
                <div className={styles.btnWrapper}>
                  <span>
                  <Button btnClass='btnPrimary' label={'Submit'} onClick={handleSubmit} />
                  </span>
  
                </div>
              </div>

            </div>}

          </div>}

      </PerfectScrollbar>
    </div>
  );
};

export default EventsAdd;
