import React, { useState } from 'react';
import styles from './lineupTabs.module.scss';
import useWindowDimensions from '../../_utils/screenSize';
import { useTranslation } from 'react-i18next';
import SmallTitle from '../smallTitle/smallTitle';

const LineupTabs = ({ tiers, changeTab, activeTab, contest, contestType }) => {
  const { width } = useWindowDimensions();
  const [t] = useTranslation();
  // const [captainToDraft] = useState((contest && contest.game && contest.game.captainToDraft) ? contest.game.captainToDraft : 0);
  // const [playersToDraft] = useState((contest && contest.game && contest.game.playersToDraft) ? contest.game.playersToDraft : 0);
  return (
    <div className={styles.container}>
      <SmallTitle title={t('d4036504')}></SmallTitle>
      {/* {contestType === 'd09094712' && <small className={styles.msgPlayers}>{t('d73543825')}. </small>}
      {contestType === 'd85876432' && <small className={styles.msgPlayers}>{t('d71211885')} {playersToDraft || 0}.</small>}
      {contestType === 'd12792751' && <small className={styles.msgPlayers}>{t('d71211885')} {playersToDraft || 0}. {t('d71964352')} {captainToDraft}. {t('d97917174')} {+playersToDraft + (+captainToDraft)}.</small>} */}
      {contestType === 'd09094712' && <ul>
        {tiers.map((value, i) => (
          <div key={i}>
          <li key={i} className={`${activeTab === i ? `${styles.active}` : ''}`} onClick={() => { changeTab(i); }}>
            {width <= 1024 ? (
              <h5> T{i + 1} </h5>
            ) : <h5> {t('d09094712')} {i + 1} </h5>}
          </li></div>
        ))}
      </ul>}
      {contestType === 'd12792751' && <ul>
        {tiers.map((value, i) => (
          <div key={i}><li key={i} className={`${activeTab === i ? `${styles.active}` : ''}`} onClick={() => { changeTab(i); }}>
            <h5> {t(value)}</h5>
          </li></div>
        ))}
      </ul>}
    </div>
  );
};

export default LineupTabs;
