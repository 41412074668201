export const getFirstLastName = (user) => {
  if (!user) return ''
  if (Array.isArray(user)){
    if (user.length === 0) return ''
    user = user[0]
  }
  const { firstName, lastName } = user
  return (firstName ? firstName : '') + ' ' + (lastName ? lastName: '')
};

export const getUsername = (user) => {
  if (!user) return ''
  if (Array.isArray(user)){
    if (user.length === 0) return ''
    user = user[0]
  }
  return user.username ? user.username : ''
};

