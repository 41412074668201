import { paymentConstants } from '../constants';

const initialState = {
  token: null,
  tokenProgress: false,
  deposit: '',
  depositProgress: false,
  depositLabel: false,
  withdraw: '',
  withdrawProgress: false,
  credit: '',
  convertProgress: false,
  transactions: {}
};

export const payment = (state = initialState, action) => {
  switch (action.type) {
    case paymentConstants.TOKEN_REQUEST:
      return {
        ...state,
        tokenProgress: true,
      };
    case paymentConstants.TOKEN_SUCCESS:
      return {
        ...state,
        token: action.token,
      };
    case paymentConstants.TOKEN_FAILURE:
      return {
        ...state,
      };
    case paymentConstants.DEPOSIT_REQUEST:
      return {
        ...state,
        depositProgress: true,
        depositLabel: true,
      };
    case paymentConstants.DEPOSIT_SUCCESS:
      return {
        ...state,
        deposit: action.deposit,
      };
    case paymentConstants.DEPOSIT_FAILURE:
      return {
        ...state,
      };
    case paymentConstants.WITHDRAW_REQUEST:
      return {
        ...state,
        withdrawProgress: true,
      };
    case paymentConstants.WITHDRAW_SUCCESS:
      return {
        ...state,
        withdraw: action.withdraw,
      };
    case paymentConstants.WITHDRAW_FAILURE:
      return {
        ...state
      };
    case paymentConstants.CONVERT_REQUEST:
      return {
        ...state,
        convertProgress: true,
      };
    case paymentConstants.CONVERT_SUCCESS:
      return {
        ...state,
        credit: action.credit,
      };
    case paymentConstants.CONVERT_FAILURE:
      return {
        ...state,
      };
    case paymentConstants.TRANSACTION_REQUEST:
      return {
        ...state,
      };
    case paymentConstants.TRANSACTION_SUCCESS:
      return {
        ...state,
        transactions: action.transactions
      };
    case paymentConstants.TRANSACTION_FAILURE:
      return {
        ...state,
        transactions: {}
      };
    default:
      return state;
  }
};
