
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import styles from './modal.module.scss';
import Button from '../button/button';

const ModalMessage = ({ show, modalData, text }) => {
  const [modalIsOpen] = useState(show);
  const [t] = useTranslation();
  return (
    <Modal
      isOpen={modalIsOpen}
      contentLabel="Custom modal"
      className={`${styles.customModal} ${styles.md} ${styles.modalYesNo}`}
      overlayClassName={styles.customOverlay}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
    >
      <div className={styles.modalBody}>
        <h4 className={styles.title}> {t(text)}</h4>
      </div>
    </Modal>
  );
};

ModalMessage.propTypes = {
  show: PropTypes.bool,
  content: PropTypes.object,
};

export default ModalMessage;