import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './subRoutes.module.scss';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import back from '../../_assets/images/icons/back6489.svg';

const SubLinks = () => {
  const store = useSelector(state => state);
  const history = useHistory();

  const returnBack = () => {
    history.goBack();
  }

  return (
    <div className={styles.back}>
          <img src={back} alt='back' onClick={returnBack}/>
    </div>
    // <div className={styles.wrapper} style={{marginTop:"100px"}}>
    //   <h5>
    //     <NavLink to={store.routes.route.prevRoute.path} className={styles.link}>{store.routes.route.prevRoute.name}</NavLink>
    //   </h5>
    //   <i className="fa fa-angle-right" aria-hidden="true"></i>
    //   <h5>
    //     {store.routes.route.currentRoute.name}
    //   </h5>
    // </div>
  );
};


export default SubLinks;
