import React, { useState } from 'react';
import mainService from '../../../_services/main.service';
import styles from './transactions.module.scss';
import { useTranslation } from 'react-i18next';
import DatePickerComp from '../../../_components/datePicker/datePickerComp';
import Input from '../../../_components/input/input';
import Button from '../../../_components/button/button';
import moment from 'moment';
import { ExportToCsv } from 'export-to-csv';

const Transactions = () => {
  const [result, setResult] = useState([]);
  const [from, setFrom] = useState(moment().startOf('month').toDate());
  const [to, setTo] = useState(moment().endOf('month').toDate());
  const [text, setText] = useState('');
  const [t] = useTranslation();

  const setDate = (type, date) => {
    if (type === 'from'){
      setFrom(moment(date).startOf('day').toDate())
    } else if (type === 'to'){
      setTo(moment(date).endOf('day').toDate())
    }
  }

  const handleTextChange = (e) => {
    e.preventDefault()
    const { value = '' } = e.target;
    setText(value.trim())
  }

  const getData = async (id) => {
    const filter = { createdAt: { $gte: from.getTime(), $lte: to.getTime() } }
    if (text && text.trim()) {
      filter['$or'] = [{ username: {'$regex' : `^${text.trim()}`, '$options' : 'i'} }, { email: {'$regex' : `^${text.trim()}`, '$options' : 'i'} }]
    }
    const data = await mainService.run('transactions', 'user', 'report', { filter } );
    let headers = ['Username', 'Email', 'Country', 'Amount', 'Transaction Type', 'Date']
    let result = [headers]
    data.rows.forEach(t => {
      // result += t.username +', '+ t.email + ', ' + (t.country || 'Unknown') + ', ' + t.amount + ', ' + t.transactionType +', '+ new Date(t.createdAt).toLocaleString() +'\n';
      result.push([t.username, t.email, (t.country || 'Unknown'), t.amount, t.transactionType, new Date(t.createdAt).toLocaleString().replace(', ', ' ')])
    })
    const options = {
      fieldSeparator: ',',
      quoteStrings: '',
      decimalSeparator: '.',
      showLabels: false,
      showTitle: false,
      title: 'Transactions',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: headers,
      filename: 'Transactions_'+moment(from).format("MMM_Do_YY")+'_'+moment(to).format("MMM_Do_YY"),
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(result);

  }

  return (
    <div className={styles.wrapperManagement}>
      <DatePickerComp startDate={from} onChange={(date) => setDate('from', date)} label='From Date' noMinDate={true} hideTime={true}/>
      <DatePickerComp startDate={to} onChange={(date) => setDate('to', date)} label='To Date' noMinDate={true} hideTime={true}/>
      <Input type='text' onChange={handleTextChange} value={text} placeholder={t('Enter username or email')} label={t('Search text')}/>
      <Button btnClass='btnFormGreen' label={t('d0733608')} onClick={() => { getData() }} />
    </div>);
};

export default Transactions;