import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting-js';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import styles from './lineupTable.module.scss';
import plus from '../../_assets/images/icons/plus.svg';
import avatarDefault from '../../_assets/images/whitePlayer.png';
import boostedAvatar from '../../_assets/images/boostedPlayer.png'
import teamDefault from '../../_assets/images/team.png';
import { useTranslation } from 'react-i18next';
import { modalActions } from '../../_store/actions/modal.actions';
import { useDispatch } from 'react-redux';
import { sessionService } from "../../_sessionService/storage";
import PlayerDisplay from '../playerDisplay/playerDisplay';
import withImages from '../core/withImages';
import Button from '../../_components/button/button';
import useWindowDimensions from '../../_utils/screenSize';
import whitePlr from './../../_assets/images/whitePlayer.png'

const LineupTable = ({ tbHeading, tbData = [], onClick, contest, selectedPlayers = [], acquireImages, images,  }) => {

  let data = null;
  if (tbData && tbData.length > 0) {
    data = tbData;
  }
  const [t] = useTranslation();
  const { width } = useWindowDimensions();
  const [isSmall, setIsSmall] = useState((width >= 500) ? false : true);
  const dispatch = useDispatch();
  const [table, setTable] = useState(tbData);
  const openContestInfo = (data) => {
    dispatch(modalActions.openModal(<PlayerDisplay data={data} />, 'Player modal', 'md'));
  };

  useEffect(() => {
    if (width >= 500) {
      setIsSmall(false);
    } else {
      setIsSmall(true);
    }
  }, [width]);

  useEffect(() => {
    if (data) {
      if(data.length > 0) {
        images = []
        const  playerImages = tbData.concat(selectedPlayers).map(one => ({name: `${one.id}_profileAvatar`, width: 150, height: 100}))
        const  teamImages = tbData.concat(selectedPlayers).map(one => ({name: (one.game+'_'+one.team).replace(/\s+/g, '_')+'_logo', width: 150, height: 150}))
        setTimeout(() => {
          acquireImages([...playerImages, ...teamImages])
        }, 50)
      }
      tbData.concat(selectedPlayers).forEach(one => one.avatar = sessionService.getImg(`${one.id}_profileAvatar`))
      setTable(tbData)
    }
    setTable(tbData || [])
  }, [data]);

  useEffect(() => {
    if (tbData && tbData.length > 0) {
      let changed = false;
      tbData.concat(selectedPlayers).forEach(one => {
        const avatar = images[`${one.id}_profileAvatar`]
        if (one.avatar != avatar) {
          one.avatar = avatar
          changed= true
        }
      })
      if (changed) compareArrays()
    }
  }, [images]);

  useEffect(() => {
    if (selectedPlayers && selectedPlayers.length > 0) {
      compareArrays();
    }
  }, [selectedPlayers, tbData]);

  const compareArrays = () => {
    const list = [...tbData];
    selectedPlayers.map(el => {
      list.filter(player => {
        if (player.id === el.player.id) {
          list.splice(list.indexOf(el.player), 1);
        }
      });
    });
    setTable(list);
  };
  const selectPlayer = (data) => {
    onClick(data);
  };
  return (
    <div className={styles.tbWrapper}>
        { <div className={styles.tableCustom}>
          <div className={styles.tableHead}>
          {tbHeading.map((head, i) =>
              <React.Fragment key={i}>
                {head === t('d6777170') && <span className={styles.width40}>{head}</span>}
                {head === t('d68274296') && <span className={styles.width40} >{head}</span>}
                {(head !== t('d68274296') && head !== t('d6777170')) && head !== t("d21583920") && <span className={styles.width10}>{head}</span>}
                {head === t('d21583920') && <span className={styles.width40}>{head}</span>}
              </React.Fragment>
            )}
            <span className={styles.trIcon}>select</span>
          </div>

          <div className={styles.playerSelectContainer} style={{height: "300px"}}>
            <PerfectScrollbar className="purpleScroll">
              <div className={styles.tableBody}> 
                {table && table.length > 0 && table.map((data) => {
                  const isBoosted = contest && Array.isArray(contest.boosted) && contest.boosted.includes(data.id)
                  const imgUrl = images[data.id+'_profileAvatar']
                  return (<div className={styles.gridTable} key={data.id}>
                    <span className={styles.colWithImg} onClick={() => openContestInfo(data)}>
                      {isBoosted && imgUrl && <span className={styles.boostedIcon} >B</span> }
                    <img className={isBoosted ? (imgUrl ? styles.boostedWithImg : styles.boostedImg ): styles.playerImg} src={isBoosted ? imgUrl ? imgUrl : boostedAvatar : imgUrl || avatarDefault} />                      
                    </span>
                    <span><h5 className={styles.nick} onClick={() => openContestInfo(data)}>{data.nick}</h5></span>
                    <span>
                        <img className={styles.teamImg} src={images[`${(data.game+'_'+data.team).replace(/\s+/g, '_')}_logo`] || teamDefault} />
                    </span>
                    <span><h5>{accounting.formatMoney(data.salary ? data.salary : 0)}</h5></span>
                    <span><h5 className={`${styles.colorGreen}`}>{data.fppm}</h5></span>
                    {contest && (contest.from > new Date().getTime()) && <span className={styles.trIcon}><Button label={t('d90167144')} onClick={(e) => { selectPlayer(data) }}/></span>}
                  </div>)
                  }
                )}
              </div>
            </PerfectScrollbar>
          </div>
        </div>}
    </div>
  );
};

LineupTable.propTypes = {
  tbHeading: PropTypes.array,
  tbData: PropTypes.array,
  onClick: PropTypes.func
};

export default withImages(LineupTable);


