import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Input from '../../../_components/input/input';
import '../auth.scss';
import Button from '../../../_components/button/button';
import Login from '../loginComponent/login';
import { modalActions } from '../../../_store/actions/modal.actions';
import { userActions } from '../../../_store/actions/user.actions';
import { mainActions } from '../../../_store/actions/main.actions';
import storageService from "../../../_services/storage.service";
import Checkbox from '../../../_components/checkbox/checkbox';
import { generateMonths } from '../../../_json/months';
import { generateDays } from '../../../_json/days';
import { generateYears } from '../../../_json/years';
import Selector from '../../../_components/selector/selector';
import { countriesActions } from '../../../_store/actions/countries.actions';
import { isObjectEmpty } from '../../../_utils';
import Step1 from './registerSteps/step1';
import Step2 from './registerSteps/step2';
import Step3 from './Step3';
import Step4 from './Step4';
import ProgressBar from '../../../_components/progressBar/progressBar';

import userService from '../../../_services/user.service';
import documentsService from '../../../_services/documents.service';
import { sessionService } from '../../../_sessionService/storage';

const countriesMap = {}
const mapCountries = (countries = []) => {
  return countries.map((el) => {
    countriesMap[el.country] = el
    return {
      label: el.country+(!el.allow ? ' - Not Allowed' : ''),
      data: el,
      isDisabled: !el.allow
    };
  });
};

const mapProvinces = (provinces = []) => {
  return provinces.map((el) => {
    return {
      label: el.name+(!el.selected ? ' - Not Allowed' : ''),
      data: el,
      isDisabled: !el.selected
    };
  });
};

const mapInfluencers = (influencers = []) => {
  return [{ label: '-', value: null}, ...influencers.map((el) => {
    return {
      label: el.name,
      value: el.name
    };
  })];
};

const CountrySelectStyles = {
  option: (styles, { data }) => {
    return {
      ...styles,
      color: !data.data.allow ? '#912323!important' : null,
      cursor: !data.data.allow ? 'not-allowed' : 'default'
    }
  }
};

const ProvinceSelectStyles = {
  option: (styles, { data }) => {
    return {
      ...styles,
      color: !data.data.selected ? '#912323!important' : null,
      cursor: !data.data.selected ? 'not-allowed' : 'default'
    }
  }
};

// const regex = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/gm
const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
const validateEmail = (email = '') => {
  return regex.test(email)
};

const progresBarSteps = {
  1: 25,
  2: 50,
  3: 75,
  4: 102
}

const months = {
  January: '01',
  February: '02',
  March: '03',
  April: '04',
  May: '05',
  June: '06',
  July: '07',
  August: '08',
  Septembar: '09',
  October: '10',
  November: '11',
  December: '12',
}

const checkYear = (user) => {
  let myDate = `${user.day > 9 ? user.day : `0${user.day}`}-${months[user.month]}-${user.year}`;
  myDate = myDate.split("-");
  let newDate = new Date( myDate[2], myDate[1] - 1, myDate[0]);

  let lessYears = new Date(new Date().setFullYear(new Date().getFullYear() - 18))
  const timestamp18YearsAgo = lessYears.getTime();

  const res = timestamp18YearsAgo > newDate.getTime()
  return res
}


const validateFirstStep = (user) => {
  return (user.username &&
      user.email &&
      validateEmail(user.email) &&
      user.password &&
      user.confirmPassword &&
      user.password === user.confirmPassword &&
      user.conditions
    )   
}

const validateSecondStep = (user, countryProvinces) => {
  return (user.day &&
  user.month &&
  user.year &&
  !isObjectEmpty(user.country) &&
  !(countryProvinces && !user?.province)) &&
  user.conditions && 
  checkYear(user);
}

// const validateUploadFile = (error, uploadResponse) => {
//   if (error || !uploadResponse) return false
//   return true
// }

const validateStep4 = (user, file) => {
  if (!user.conditions || (!user.skipUploadFile && !file)) return false
  return true
}

const Register = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  // const history = useHistory();
  const store = useSelector(state => state);
  const [error, setError] = useState(false);
  const [months, setMonths] = useState([]);
  const [days, setDays] = useState([]);
  const [years, setYears] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [ref, setRef] = useState('');
  const [influencers, setInfluencers] = useState([]);
  // const [showCounty, setShowCounty] = useState(false);
  const [countries, setCountries] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [currentStep, setCurrentStep] = useState(1)
  const [credentialError, setCredentialError] = useState(false)
  const [file, setFile] = useState(null)
  const [user, setUser] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    country: {},
    day: '',
    month: '',
    year: '',
    province: '',
    conditions: false,
    subscribe: false,
    type: 'user',
    role: 'user',
    active: true,
    influencer: null,
    skipEnterFullName: false,
    skipUploadFile: false
  });

  const loadInfluencers = async () => {
    const result = await dispatch(mainActions.run('profiles', 'influencer', 'all', { filter: { tier: 1 }}))
    setInfluencers(mapInfluencers(result))
  }

  useEffect(() => {
    setYears(generateYears());
    setMonths(generateMonths());
    setDays(generateDays(null));
    loadInfluencers()
    dispatch(countriesActions.get('get'));
    const ref = storageService.getRef()
    setRef(ref)
    if (ref) {
      setUser({ ...user, influencer: ''+atob(ref || '') })
    }

  }, []);

  useEffect(() => {
    if (countries.length < 1) {
      const list = mapCountries(store.countries.list);
      setCountries(list);
    }
  }, [store.countries, store.countries.list]);

  const openLoginModal = () => {
    dispatch(modalActions.openModal(<Login />, 'Login modal', 'md'));
  };

  const handleChange = e => {
    setError(false);
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value.trim();
    const name = target.name;
    setCredentialError(false)
    setUser(user => ({ ...user, [name]: value }));
  };
  const handleSelectChange = (selected, type) => {
    setError(false);
    if (type === 'countries') {
      setUser({ ...user, country: { ...user.country, name: selected.label, code: selected.data.data.ISOCode }, province: '' })
      selected && selected.data && selected.data.provinces ? setProvinces(mapProvinces(selected.data.provinces)) : setProvinces([])
    } else if (type === 'provinces') {
      setUser({ ...user, province: selected.label })
    } else if (type === 'influencers') {
      setUser({ ...user, influencer: selected.value })
    }
    // if (type === "county") {
    //     setUser({ ...user, province: { ...user.province, code: selected.value, name: selected.label } })
    // }
    if (type === 'day') {
      setUser({ ...user, day: selected.label });
    }
    if (type === 'month') {
      setDays(generateDays(selected.value, moment().year()));
      setUser({ ...user, month: selected.label });
    }
    if (type === 'year') {
      setDays(generateDays(user.month, selected.value));
      setUser({ ...user, year: selected.label });
    }
  };

  const checkingEmailAndUsername = async (user) => {
    try {
      await userService.validateData({email:user.email, username: user.username}) 
      return true
    } catch (err) {
      setError(true)
      setCredentialError(true)
      return false
    }
  }

  const uploadFile = async () => {
    try {
      await documentsService.uploadDocument(file);
    } catch (error) {
      setError(true)
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = true
    if(currentStep === 1) {
      isValid = validateFirstStep(user)
      if (!isValid) {
        setError(true)
        return; 
      }
     const validation =  await checkingEmailAndUsername(user)
     if(validation) {
      setError(false);
      setCredentialError(false)
      setCurrentStep(2);
      return;
     } 
    } else if (currentStep === 2) {
        isValid = validateSecondStep(user, countryProvinces)
        if (!isValid) {
          setError(true);
          return;
        }
        setError(false)
        setCurrentStep(3)
        return
      }
      else if(currentStep === 3) {
        if (user.skipEnterFullName && user.conditions) {
          setError(false);
          setCurrentStep(4);
          return;
        } 
        if (!user.firstName || !user.lastName || !user.conditions) {
          setError(true);
          return
        }
        setCurrentStep(4)
        return
      }
      else if (currentStep === 4){
        isValid = validateStep4(user, file)
        if (!isValid) {
          setError(true)
          return
        }
        if (window.gtag_report_register_conversion) {
          window.gtag_report_register_conversion()
        }
        await dispatch(userActions.register(user))
        
        if (file && !user.skipUploadFile) {
          // we are wiating 500 miliseconds to get user data from the storage so that
          // we can get correct fingerprint
          setTimeout(async() => {
            await uploadFile()
          }, 500)
        }
      }
  };

  const allRequiredFieldSelected = () => {
    return user.username &&
      user.email &&
      user.password &&
      user.confirmPassword &&
      user.password === user.confirmPassword &&
      user.day &&
      user.month &&
      user.year &&
      !isObjectEmpty(user.country) &&
      user.conditions === true &&
      !(countryProvinces && !user?.province) &&
      !(!user.skipUploadFile && !file)
  };
  // const validateForm = () => {
  //   if (!allRequiredFieldSelected()) {
  //     setError(true);
  //     return false;
  //   } else {
  //     setError(false);
  //     setIsValid(true);
  //     return true;
  //   }
  // };

  const handleUploadFile = (file) => {
    setFile(file)
  };

  const countryProvinces = countriesMap[user?.country?.name]?.provinces?.filter(province => !!province.selected)

  const renderSteps = () => {
    switch(currentStep) {
      case 1: {
        return (
          <Step1 user={user}
            handleChange={handleChange}
            error={error}
            credentialError={credentialError}
            isValidEmail={validateEmail(user.email)}
          />
        )
      }
      case 2: {
        return (
          <Step2 CountrySelectStyles={CountrySelectStyles}
            countries={countries}
            handleChangeSelect={(e) => {
              handleSelectChange(e, "countries");
            }}
            error={error}
            user={user}
            contryObjectEmpty={isObjectEmpty(user.country)}
            ProvinceSelectStyles={ProvinceSelectStyles}
            provinces={provinces}
            handleChangeSelectProv={(e) => {
              handleSelectChange(e, "provinces");
            }}
            countryProvinces={countryProvinces}
            months={months}
            handleChangeSelectMonth={(e) => { handleSelectChange(e, 'month') }}
            days={days}
            handleChangeSelectDay={(e) => { handleSelectChange(e, 'day') }}
            years={years}
            handleChangeSelectYear={(e) => { handleSelectChange(e, 'year')}}
            selectedOption={provinces.find(obj => obj.label === user.province) || ''}
            emptyMonth={isObjectEmpty(user.month)}
            emptyDay={isObjectEmpty(user.day)}
            emptyYear={isObjectEmpty(user.year)}
            isOlder={checkYear(user)}
          />
        )
      }
      case 3: {
        return (<Step3 user={user} handleChange={handleChange} error={error} />);
      }
      case 4: {
        return (
          <Step4
            user={user}
            handleChange={handleChange}
            handleUploadFile={handleUploadFile}
            handleSelectChange={handleSelectChange}
            file={file}
            error={error}
          />
        )
      }
      default: {
        return
      }
    }
  }

  return (
    <div>
      <form className="auth-form" onSubmit={handleSubmit}>
        <div className='steps'>
          <h4 className="auth-header">Step {currentStep} of 4</h4>
          <ProgressBar done={progresBarSteps[currentStep].toString()} className="progressBar" />
        </div>
        <div className="form-body">
          {renderSteps()}

        </div>

        <div className="register-checkbox-wrapper">
            <div className="register-checkbox">
              <Checkbox checkboxClass="checkboxLoginClass" label="" name="conditions" onChange={handleChange} />
            </div>
            <div>
              <span>{t('d41965404')} <Link to="/terms_of_service" target="_blank"> <span className='clickable-link'>{t('d88547068')}</span></Link> {t('d42234895')} <Link to="/privacy_policy" target="_blank"><span className='clickable-link'>{t('d89208680')} </span></Link> {t('d43481845')}</span>
            </div>
          </div>
          {error && !user.conditions && <div className="input-err-msg">{t('d43933842')}</div>}

          <div className="register-checkbox-wrapper">
            <div className="register-checkbox">
              <Checkbox checkboxClass="checkboxLoginClass" label="" name="subscribe" onChange={handleChange} />
            </div>
            <div>
              <span>{t('d44261139')}</span>
            </div>
          </div>
        <div className="btn-wrapper">
          <Button
            label="previous"
            btnClass={isValid && !error ? "btnPrimary" : "btnSecondary"}
            fullWidth={true}
            disabled={currentStep === 1}
            customStyle={currentStep===1 ? {background: '#8399c2'} : {}}
            onClick={(e) => {
              e.preventDefault()
              if(currentStep === 1) return
              setCurrentStep(prev => {
                return --prev
              })
            }}
          />
          <Button
            label={currentStep === 4 ? t('d26582354') : 'next'}
            btnClass={currentStep === 4 && !error && allRequiredFieldSelected() ? "btnPrimary" : "btnSecondary"}
            fullWidth={true}
            type="submit"
          />
        </div>
        <div className="auth-footer">
          <span className="auth-label">{t('d1288411')}? </span>
          <p className="clickable-label" onClick={openLoginModal}>{t('d98662278')}!</p>
        </div>
      </form>
    </div>

  );
};

export default Register;
