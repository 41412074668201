import React, { useState } from 'react';
import styles from './walletDepositRight.module.scss';
import { useTranslation } from 'react-i18next';

const WalletDepositRight = () => {
  const [t] = useTranslation();
  const data = [
    {
      id: 1,
      title: t('d74373544'),
      text: <p>{t('d7080556')}</p>
    },
    {
      id: 2,
      title: t('d69933643'),
      text: <p>{t('d73055296')} <br /> <br /> {t('d7519698')} <br /> {t('d75535250')} <br /> <br /> {t('d75867375')} <br />  <br />  {t('d76167876')} <br /> {t('d76511917')}</p>
    },
    {
      id: 3,
      title: t('d8153634'),
      text: <p>{t('d81904492')}</p>
    },
    {
      id: 4,
      title: t('d79895980'),
      text: <p>{t('d81111406')}</p>
    },
    {
      id: 5,
      title: t('d78558265'),
      text: <p>{t('d79165391')}</p>
    },
    {
      id: 6,
      title: t('d77536021'),
      text: <p>{t('d77846827')}</p>
    },
  ];

  const [displayed, setDisplayed] = useState('');
  return (
    <div className={styles.wrapper}>
      {data.map(item => {
        return (
          <div className={styles.container} key={item.id}>
            <div className={styles.data}>
              <div className={styles.header}  onClick={() => displayed === item.id ?
                  setDisplayed('') : setDisplayed(item.id)
                }>
                <div className={styles.expand}>
                  {displayed === item.id ? '-' : '+'}
                </div>
                <h5 className={styles.title}>
                  {item.title}
                </h5>
              </div>
              <div className={displayed === item.id ? styles.displayed : styles.hidden}>
                {item.text}
              </div>
            </div>
          </div>
        );
      })}

    </div>
  );
};

export default WalletDepositRight;
