import React, { useState } from 'react';
import styles from './contact.module.scss';
import PageHeading from '../../../_components/pageHeading/pageHeading';
import { useTranslation } from 'react-i18next';
import Input from '../../../_components/input/input';
import Button from '../../../_components/button/button';
import staticMap from '../../../_assets/images/staticmap.png';
import { useDispatch } from 'react-redux';
import { userActions } from '../../../_store/actions/user.actions';
import LinksSocial from '../../../_components/footer/linksSocial';
import toastService from '../../../_services/toastService';

const Contact = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [contactData, setContactData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleChange = e => {
        const { name, value } = e.target;
        setContactData(contactData => ({ ...contactData, [name]: value }))
    }

    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    const sendForm = async () => {
        try {
            if (validateEmail(contactData.email)) {
                const response = await dispatch(userActions.sendContactInfo(contactData))
                if (response === 'success') {
                    toastService.show('success', t('d55546200'));
                    setContactData({
                        name: '',
                        email: '',
                        subject: '',
                        message: ''
                    })
                }
            } else {
                toastService.show('error', t('d46723456'));
            }
        } catch (error) {
            console.log('error', error);
        }

    }
    const submitContact = (e) => {
        e.preventDefault();
        sendForm();
    }
    return (
        <div className={styles.contactWrapper}>
            <PageHeading heading={t('d30655333')} subheading={t('d77926439')} />
            <div className={styles.wrapper}>
                <form className={styles.form} onSubmit={submitContact}>
                    <Input name="name" type="text" value={contactData.name} onChange={handleChange} placeholder={t('d82635619')} />
                    <Input name="email" type="text" value={contactData.email} onChange={handleChange} placeholder={t('d44003993')} />
                    <Input name="subject" type="text" value={contactData.subject} onChange={handleChange} placeholder={t('d26154530')} />
                    <textarea className={styles.textarea} name="message" value={contactData.message} onChange={handleChange}
                        placeholder={t('d63006153')} />
                    <Button type="submit" btnClass="btnPrimary" label={t('d33545245')} />
                </form>

                <div className={styles.content}>
                    <img src={staticMap} alt="googleMap" />
                    <div className={styles.info}>
                        <div className={styles.location}>
                            <h4>{t('d60928380')}</h4>
                            <p>393 Rymal Road West, Suite 403</p>
                            <p>Hamilton, ON L9B 1V2</p>
                        </div>

                        <div className={styles.contact}>
                            <a href="mailto:support@eliteduels.com" target="_blank" rel="noopener noreferrer">support@eliteduels.com</a>
                            <p>{t('d61204593')} - {t('d61411309')} 9AM - 9PM EST</p>
                        </div>
                    </div>
                    <div className={styles.social}>
                        <h4>{t('d60682560')}</h4>
                        <LinksSocial />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Contact;