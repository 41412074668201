import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import MainWrapper from './mainWrapper';
import { sessionService } from '../_sessionService/storage';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={props => {
      return sessionService.isAuth()
        ? <MainWrapper currentPage={rest.name}>
          <Component {...props} {...rest} />
        </MainWrapper>
        : <Redirect to={{
          pathname: '/lobby',
          state: { redirectTo: rest.location.pathname, redirect: true }
        }} />;
    }}
    />
  );
};
