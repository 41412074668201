import React, { useEffect, useState } from 'react';
import styles from './download.module.scss';
import documentsService from '../../_services/documents.service';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Loading from '../Loading';
import { profileActions } from '../../_store/actions/profile.actions';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../button/button';
import ModalYesNo from '../../_components/modal/modalYesNo';
import { userActions } from '../../_store/actions/user.actions';

const Download = ({ userId, update, emailTemplate }) => {

  const dispatch = useDispatch();
  const store = useSelector(state => state);
  const [t] = useTranslation();
  const history = useHistory();
  const [files, setFiles] = useState([]);
  const [src, setSrc] = useState('');
  const [modalData, setModalData] = useState();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showFile, setShowFile] = useState(false);
  const [recipient, setRecipient] = useState('');
  useEffect(() => {
    if (userId) {
      getDocuments(userId);
    }
  }, [update]);
  useEffect(() => {
    if (userId && store.auth.user.role !== 'user' && history.location.pathname !== '/profile') {
      getRecipient();
    }
  }, [emailTemplate]);

  const getDocuments = async (id) => {
    setLoading(true);
    const files = await documentsService.getDocForUser(id);
    setLoading(false);
    setFiles(files);
  };

  const getRecipient = async () => {
    const data = await dispatch(profileActions.run('get', { id: userId }));
    setRecipient(data);
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    const data = {
      email: recipient.email,
      recipientName: recipient.username,
      type: emailTemplate.template.type,
      title: (t(emailTemplate.template.title)),
      subject: (t(emailTemplate.template.subject)),
      message: (t(emailTemplate.template.message))
    };
    await dispatch(userActions.sendEmailTemplate(data));
  };
  const downloadFile = async (file) => {
    setShowFile(true)
    const { documentType, documentSubtype, userId, fileName, fileType } = file;
    const BufferStream = await documentsService.downloadocument(documentType, documentSubtype, userId);
    const base64data = btoa([].reduce.call(new Uint8Array(BufferStream), function (p, c) { return p + String.fromCharCode(c) }, ''));
    const src = `data:${fileType};base64,${base64data}`;
    if (base64data) {
      setSrc(src);
      setShowFile(false)
    }
  };

  const openDeleteDialog = (file) => {
    setModalData(file)
    setShowModalDelete(true)
  }
  const handleDelete = async (answer, file) => {
    setShowModalDelete(false)
    setModalData(null)
    if (answer === t('d13586174')) {
      const {documentId} = file
      await documentsService.deleteDocForUser(documentId, userId);
      const newFiles = files.filter(one => one.id != file.id)
      setFiles(newFiles)
    }
  }
  return (
    <div className={styles.wrapper}>
      {showModalDelete && <ModalYesNo show={showModalDelete} handleYesNoAnswer={handleDelete} modalData={modalData} question='d54856307' />}
      <h5>{t("d65322845")}</h5>
      {files && loading && files.length === 0 ? <Loading /> : <div>
        {files && files.length > 0 ? <ul>
          {files.map(file => (
            <li key={file.id}><span  onClick={() => { downloadFile(file) }}><span>{t(file.documentType)}</span>: <small>{t(file.documentSubtype)}</small> </span>| <i className="fa fa-trash-o" style={{ color: '#ff2626' }} aria-hidden="true" onClick={() => openDeleteDialog(file) }></i></li>
          ))}
        </ul> :
          <div className="message-info" >{t('d58155026')}</div>}
      </div>}
      {store.auth.user && store.auth.user.role !== 'user' && history.location.pathname !== '/profile' && files && files.length > 0  && <div className={styles.notify}>
        <div><p>{t('d5452664')} </p>    <Button btnClass="btnFormGreen"
          label={t('d1280664')}
          onClick={sendEmail} /></div>

      </div>}
      {!showFile && src.length === 0 ? null : !showFile ? <div style={{ height: '100vh', marginTop: '20px' }}>
        <object data={src} style={{ width: '100%', maxWidth: '100%', height: '100%', maxHeight: '100%' }}></object>
      </div> : <Loading />}

    </div>
  );
};
export default Download;
