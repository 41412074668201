
import { screenConstants } from '../constants';
const screenSize = JSON.parse(localStorage.getItem('screen'));
const initialWidth = screenSize ? screenSize.width : null;
const initialHeight = screenSize ? screenSize.height : null;
const initialState = {
  width: initialWidth,
  height: initialHeight,
  changedScreen: initialWidth ? false : true
};

export const screen = (state = initialState, action) => {
  switch (action.type) {
    case screenConstants.GET_SCREEN_SIZE:
      return {
        width: action.width,
        height: action.height,
        changedScreen: state.width === action.width ? false : true
      };
    case screenConstants.UPDATE_SCREEN_SIZE:
      return {
        width: action.width,
        height: action.height,
        changedScreen: state.width === action.width ? false : true
      };
    default:
      return state;
  }
};
