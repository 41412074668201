import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styles from './Import.module.scss';
import Forms from '../../../_pages/admin/forms/forms';
import Button from '../../../_components/button/button';
import mainService from '../../../_services/main.service';
import greenLeftArrow from '../../../_assets/images/icons/greenLeftArrow.svg';
import { useTranslation } from 'react-i18next';
import ModalYesNo from '../../../_components/modal/modalYesNo';
import { parseFormFromXLSXFile } from '../../../_utils';

const Import = ({ form, onClose }) => {
  const { collection, type, actions } = form;
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [rows, setRows] = useState(null);
  const [relations, setRelations] = useState({});
  const [checktype, setChecktype] = useState(type);
  const [editData, setEditData] = useState({});
  const [editDataIndex, setEditDataIndex] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState('list');
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [tbHead, setTbHead] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    toggleView('list');
    loadRelations()
    getHeaders(form);
  }, [form]);


  const _loadRelationData = async ({dataName, dataType, selectLabel, selectField, selectFilter}) => {
    try {
      const result = await mainService.run(dataName, dataType, 'all')
      const mapping = {}
      if (!selectField) selectField = selectLabel

      result.forEach(one => {
        let filterKey = ''
        if (selectFilter && one[selectFilter]) filterKey = one[selectFilter]+'-'
        mapping[filterKey+one[selectLabel]] = one[selectField]
      })
      return { key: [dataName+'-'+dataType], value: mapping}
    } catch (e) {}
  }

  const closeModal = () => {
    if (onClose) onClose()
  }

  const loadRelations = async () => {
    const relationMapping = {}
    for(const field of form.fields) {
      if (field.dataName && field.dataType) {
        const { key, value } = await _loadRelationData(field)
        relationMapping[key] = value
      } else if (field.type === 'select' && field.defaultValue) {
        const mapping = {}
        field.defaultValue.forEach(one => mapping[t(one)] = one)
        relationMapping[field.dbname+'-default-select'] = mapping
      }
    }
    setRelations(relationMapping)
  }

  const toggleView = async (view) => {
    setEditData({});
    setVisible(view);
  };

  const getEditData = async (e, el, idx) => {
    e.preventDefault();
    setEditDataIndex(idx)
    setEditData(el);
    setVisible('form');
  };

  const openDeleteModal = async (e, type, id, name, idx) => {
    e.preventDefault();
    setShowModalDelete(true);
    setModalData({ type, id, name });
  };
  const handleDelete = async (answer, data) => {
    const { id, type, name, idx } = data;
    if (answer === t('d13586174')) {
      rows.splice(idx, 1)
      setRows(rows)
      await toggleView('list');
      setShowModalDelete(false);
    } else {
      setShowModalDelete(false);
    }
  };

  const onSubmit = (data) => {
    validateForm(data)
    rows[editDataIndex] = data
    setRows([...rows])
    toggleView('list');
  };
  const getHeaders = (form) => {
    const header = form.fields.filter(field => {
      if (field.isColumn) {
        return field;
      }
    });

    setTbHead(header);
  };

  const saveAll = async (proceed) => {
    if (!proceed) {
      const hasInvalid = rows.some(one => !one.isValid)
      if (hasInvalid) {
        return setShowModalConfirm(true)
      }
    }
    setShowModalConfirm(false)
    const toSave = []
    const invalid = []
    rows.forEach(one => {
      if (one.isValid) {
        delete one.isValid
        toSave.push(one)
      } else {
        invalid.push(one)
      }
    })
    for (const row of toSave) {
      try {
        await mainService.run(form.collection, form.type, 'new', row)
      } catch (e) {
        console.log(e)
        invalid.unshift(row)
      }
    }

    setRows(invalid)
  }

  const validateForm = (data) => {
    const requiredMap = {};
    let isValid = true;
    form.fields.forEach(el => {
      if (el.required) requiredMap[el.dbname] = el.required;
      if (el.type === 'date' && form.type === 'contest') {
        if ((data.from && data.to) && data.to < data.from) {
          isValid = false;
        } else {
          isValid = true;
        }
      } else if (el.required && (el.type === 'keyValue' || el.type === 'keySelect')) {
        const obj = data[el.dbname]
        if (!obj) isValid = false
        else {
          Object.keys(obj).forEach(key => isValid = key && isValid && obj[key])
        }
        if (!isValid) {
          return false
        }
      }
    });
    if (isValid) {
      const arrValid = Object.entries(requiredMap).map(([key]) => {
        return checkField(data, key);
      });
      isValid = arrValid.every(v => v === true);
    }
    data.isValid = isValid
  };

  const checkField = (data, fieldKey) => {
    let isValid;
    const validArr = Object.keys(data).some(key => {
      if (fieldKey === key) {
        if (Array.isArray(data[key])) {
          data[key].forEach(obj => {
            const valid = !Object.keys(obj).some(x => (obj[x] === null || obj[x] === '' || (Array.isArray(obj) && obj.length === 0)));
            isValid = valid;
            return isValid;
          });
        } else {
          const valid = !(data[key] === null || data[key] === '');
          isValid = valid;
          return isValid;
        }
      }
      return isValid;
    });
    return validArr;
  };

  const getFieldValue = (type, value) => {
    if (value === null || value === undefined) return 'N/A';
    let result = value;
    switch (type) {
      case 'date':
        result = moment(value).format('MM/DD hh:mmA');
        break;
      case 'checkbox':
        result = value ? 'd13586174' : 'd13276142';
        break;
    }
    if (type === 'date') {
      return result;
    } else if (type === 'link') {
      return result;
    }
    return t(''+result);
  };

  const getListValue = (field, el) => {
    const { type, map, dbname } = field;
    let value
    if (map) {
      const { path, property } = map;
      let data = el;
      if (path) {
        const depth = path.split('.');
        depth.forEach(subPath => data = data ? data[subPath] : {});
      }
      if (data) value = data[property];
    } else {
      value = el[dbname];
    }
    return getFieldValue(type, value);
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    var reader = new FileReader();

    // Closure to capture the file information.
    reader.onload = (evt) => {
      const result = parseFormFromXLSXFile(evt.target.result, form, t)
      for(const field of form.fields) {
        if (field.dataName && field.dataType) {
          const mapping = relations[field.dataName+'-'+field.dataType]
          if (mapping) {
            for (const row of result) {
              const rowFieldValue = row[field.dbname]
              if (rowFieldValue) {
                let filterKey = ''
                if (field.selectFilter && row[field.selectFilter]) filterKey = row[field.selectFilter]+'-'
                if (field.type === 'multiselect') {
                  const values = row[field.dbname].split(',')
                  const mappedValues = values.map(one => mapping[filterKey + one]).filter(one => !!one)
                  console.log(mappedValues)
                  row[field.dbname] = mappedValues
                } else {
                  row[field.dbname] = mapping[filterKey + row[field.dbname]]
                }
              }
            }
          }
        } else if (field.defaultValue) {
          const mapping = relations[field.dbname+'-default-select']
          if (mapping) {
            for (const row of result) {
              const rowFieldValue = row[field.dbname]
              if (rowFieldValue) {
                row[field.dbname] = mapping[row[field.dbname]]
              }
            }
          }
        }
      }
      for (const row of result) {
        validateForm(row)
      }
      setRows(result)
    }

    // Read in the image file as a data URL.
    reader.readAsBinaryString(file);

  };
  return (<div className={styles.wrapper}>
    {showModalDelete && <ModalYesNo show={showModalDelete} handleYesNoAnswer={handleDelete} modalData={modalData} question='d54856307' />}
    {showModalConfirm && <ModalYesNo show={showModalConfirm} handleYesNoAnswer={() => saveAll(true)} question='Only valid records will be saved. Do you wont to continue?' />}
    <div className={styles.header}>
      {visible === 'list' ?
        <>
          <label>Choose import file</label>
          <input type="file" onChange={onFileChange} />
        </> :
        <>
        <div className={styles.goBack}>
          <span onClick={() => { toggleView('list') }}><img src={greenLeftArrow} alt="Go back" /> {t('d72876492')}</span>
        </div>
        </>}

    </div>
    {visible === 'list' ?
      <div className={styles.tb}>
        <PerfectScrollbar className="purpleScroll">
          {/*{loading || !rows ? <Loading className="admin-list-loader" /> : null}*/}
          <div className={styles.head}>
            <div>
              <b>#</b>
              {tbHead.map((field, i) => (
                <span key={i} style={field.listStyle}>
                  {t(field.columnLabel || field.label)}
                </span>
              ))}
            </div>
          </div>

          {rows && rows.length > 0 && (checktype === type) && <ul className={styles.body}>
            {rows.map((el, i) => (
              <li key={'import-row-'+i+'-'+el.id} className={el.isValid ? '' : styles.invalid}>
                <div>
                  <b>{(pageNumber -1) * pageSize + i + 1}</b>
                  {form.fields.map((field, i) => {
                    const fieldValue = getListValue(field, el)
                    return field.isColumn ?
                    <React.Fragment key={i}>
                      <span key={`field-dbname-${field.id}`} style={field.listStyle}>{fieldValue}
                      </span>

                    </React.Fragment>
                    : null })}
                </div>
                <div className={styles.btnWrapperIcons}>
                  {actions.indexOf('edit') > -1 && <Button btnClass='btnPrimary' label={<i className="fa fa-pencil-square-o" aria-hidden="true"></i>} onClick={(e) => { getEditData(e, el, i) }} />}
                  {actions.indexOf('delete') > -1 && <Button btnClass='btnSecondary' label={<i className="fa fa-trash-o" aria-hidden="true"></i>} onClick={(e) => { openDeleteModal(e, form.type, el.id, el.name) }} />}
                </div>
              </li>
            ))}

          </ul> }
        </PerfectScrollbar>
      </div> : <div>
        <Forms form={form} editData={editData} onSubmit={onSubmit} isImport={true}/>
      </div>}
    {visible === 'list' && <div className={styles.footer}>
      <Button btnClass='btnFormGreen' label={`Save`} onClick={() => saveAll()} />
      <Button btnClass='btnFormGreen' label={`Close`} onClick={() => closeModal()} />
    </div> }
  </div>);
};

export default Import;
