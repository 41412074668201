import React, { useState, useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './carousel.scss';
import useWindowDimensions from '../../_utils/screenSize';
import SmallTitle from '../../_components/smallTitle/smallTitle';

const responsive = {
  desktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 4,
    slidesToSlide: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 3,
    slidesToSlide: 3
  },
  mobile: {
    breakpoint: { max: 700, min: 400 },
    items: 2,
    slidesToSlide: 2,
  },
  mobileSm: {
    breakpoint: { max: 400, min: 0 },
    items: 1,
    slidesToSlide: 1,
  }
};

const MultiCarousel = ({ children, title, numItems }) => {
  const { width } = useWindowDimensions();
  const getDeviceType = (width) => {
    if (width <= 400) {
      setDeviceType('mobileSm');
    } else if (width > 400 && width <= 700) {
      setDeviceType('mobile');
    } else if (width > 700 && width <= 1024) {
      setDeviceType('tablet');
    } else {
      setDeviceType('desktop');
    }
  };
  const [deviceType, setDeviceType] = useState('');

  useEffect(() => {
    getDeviceType(width);
  }, [width]);

  return (
    <div className="carouselItemWraper">
      <div className="carouselTitle">
        <SmallTitle title={title}><span>{numItems}</span></SmallTitle>
      </div>
      <Carousel
        ssr
        deviceType={deviceType}
        responsive={responsive}
      >
        {children}
      </Carousel>
    </div>
  );
};

export default MultiCarousel;
