
const PIPELINES = {
  leaderboard: [
    { $match: { type: 'contest', to: { $lt: new Date().getTime() } } },
    {
      $lookup:
      {
        from: 'data',
        let: { contest_id: '$id' },
        pipeline: [
          {
            $match:
            {
              $expr:
              {
                $and:
                  [
                    { $eq: ['$type', 'userContest'] },
                    { $eq: ['$contestId', '$$contest_id'] }
                  ]
              }
            }
          },
          {
            $lookup:
            {
              from: "profiles",
              localField: "userId",
              foreignField: "id",
              as: "user"
            }
          },
        ],
        as: 'participants'
      }
    },
    { $match: { 'participants.0': { $exists: true } } },
    {
      $lookup:
      {
        from: "playerpoints",
        localField: "matchId",
        foreignField: "matches",
        as: "events"
      }
    },
    { $match: { 'events.0': { $exists: true } } },
  ],
  wallet: (id) => [
    { $match: { userId: id, status: "d43334659" } },
    { $project: { userId: 1, amount: 1, credit: 1, win: { $cond: [{ $eq: ['$transactionType', "WIN_PRIZE"] }, "$amount", 0] }, winCredit: { $cond: [{ $eq: ['$transactionType', "WIN_PRIZE"] }, "$credit", 0] } } },
    { $group: { _id: "$userId", "amount": { $sum: "$amount" }, "credit": { $sum: "$credit" }, "totalWin": { $sum: "$win" }, "totalWinCredit": { $sum: "$winCredit" } } },
    { $addFields: { id: "$_id" } },
    {
      "$lookup": {
        "from": "transactions",
        "as": "transactions",
        "let": { "userId": "$_id" },
        "pipeline": [
          {
            "$match": {
              "$expr": { "$eq": ["$$userId", "$userId"] }
            }
          },
          { "$sort": { "createdAt": -1 } },
        ]
      }
    }
  ],
  /*result: ({ userId, id }) => [
    { $match: { userId, id } },
    {
      $lookup:
      {
        from: "data",
        localField: "contestId",
        foreignField: "id",
        as: "contests"
      }
    },
    {
      $replaceRoot: { newRoot: { $mergeObjects: [{ 'contest': { $arrayElemAt: ['$contests', 0] } }, '$$ROOT'] } }
    },
    {
      $lookup:
      {
        from: "profiles",
        localField: "team",
        foreignField: "id",
        as: "players"
      }
    },
    {
      $lookup:
      {
        from: 'data',
        let: { contest_id: '$contestId' },
        pipeline: [
          {
            $match:
            {
              $expr:
              {
                $and:
                  [
                    { $eq: ['$type', 'userContest'] },
                    { $eq: ['$contestId', '$$contest_id'] }
                  ]
              }
            }
          },
          {
            $lookup:
            {
              from: "profiles",
              localField: "userId",
              foreignField: "id",
              as: "user"
            }
          },
        ],
        as: 'participants'
      }
    },
    {
      $lookup:
      {
        from: "playerpoints",
        localField: "contest.matches",
        foreignField: "matchId",
        as: "events"
      }
    },
    { $project: { 'contests': 0 } },
    { $set: { id: '$contestId' } }
  ],*/

  /*userContestData: ({ contestId, userId, id }) => [
    { $sort: { from: 1 } },
    { $match: { id: contestId } },
    {
      $lookup:
      {
        from: 'data',
        pipeline: [
          {
            $match:
            {
              $expr:
              {
                $and:
                  [
                    { $eq: ['$type', 'userContest'] },
                    { $eq: ['$contestId', contestId] },
                    { $eq: ['$userId', userId] },
                    { $eq: ['$id', id] }
                  ]
              }
            }
          }
        ],
        as: 'userContests'
      }
    },
    {
      $lookup:
      {
        from: 'data',
        let: { game: '$game' },
        pipeline: [
          {
            $match:
            {
              $expr:
              {
                $and:
                  [
                    { $eq: ['$type', 'game'] },
                    { $eq: ['$name', '$$game'] }
                  ]
              }
            }
          }
        ],
        as: 'games'
      }
    },
    {
      $lookup:
      {
        from: 'data',
        let: { matches: '$matches',  game: '$game' },
        pipeline: [
          {
            $match:
            {
              $expr:
              {
                $and:
                  [
                    { $eq: ['$type', 'match'] },
                    { $in: ['$id', '$$matches'] }
                  ]
              }
            }
          },
          {
            $lookup:
            {
              from: 'profiles',
              let: { team1: '$team1' },
              pipeline: [
                {
                  $match:
                  {
                    $expr:
                    {
                      $and:
                        [
                          { $eq: ['$type', 'player'] },
                          { $eq: ['$game', '$$game'] },
                          { $in: ['$nick', '$$team1'] }
                        ]
                    }
                  }
                },
                {
                  $lookup:
                  {
                    from: "playerpoints",
                    localField: "id",
                    foreignField: "userId",
                    as: "points"
                  }
                }
              ],
              as: 'playersTeam1'
            }
          },
          {
            $lookup:
            {
              from: 'profiles',
              let: { team2: '$team2' },
              pipeline: [
                {
                  $match:
                  {
                    $expr:
                    {
                      $and:
                        [
                          { $eq: ['$type', 'player'] },
                          { $eq: ['$game', '$$game'] },
                          { $in: ['$nick', '$$team2'] }
                        ]
                    }
                  }
                },
                {
                  $lookup:
                  {
                    from: "playerpoints",
                    localField: "id",
                    foreignField: "userId",
                    as: "points"
                  }
                }
              ],
              as: 'playersTeam2'
            }
          }
        ],
        as: 'matches'
      }
    },
    {
      $replaceRoot: { newRoot: { $mergeObjects: ['$$ROOT', { 'userContest': { $arrayElemAt: ['$userContests', 0] } }, { 'game': { $arrayElemAt: ['$games', 0] } }] } }
    },
    { $project: { games: 0 } },
    {
      $lookup:
      {
        from: 'data',
        pipeline: [
          {
            $match:
            {
              $expr:
              {
                $and:
                  [
                    { $eq: ['$type', 'userContest'] },
                    { $eq: ['$contestId', contestId] },
                    { $eq: ['$userId', userId] }
                  ]
              }
            }
          }
        ],
        as: 'userMaxEntries'
      }
    },
    {
      $lookup:
      {
        from: 'data',
        pipeline: [
          {
            $match:
            {
              $expr:
              {
                $and:
                  [
                    { $eq: ['$type', 'userContest'] },
                    { $eq: ['$contestId', contestId] },
                  ]
              }
            }
          }
        ],
        as: 'userTotalEntries'
      }
    },
  ],*/
  landing: (filter) => [
    { $sort: { from: 1 } },
    { $match: { $and: filter } },
    {
      $lookup:
      {
        from: 'data',
        let: { game: '$game' },
        pipeline: [
          {
            $match:
            {
              $expr:
              {
                $and:
                  [
                    { $eq: ['$type', 'game'] },
                    { $eq: ['$name', '$$game'] }
                  ]
              }
            }
          }
        ],
        as: 'games'
      }
    },
    {
      $lookup:
      {
        from: 'data',
        let: { matches: '$matches' },
        pipeline: [
          {
            $match:
            {
              $expr:
              {
                $and:
                  [
                    { $eq: ['$type', 'match'] },
                    { $in: ['$id', '$$matches'] }
                  ]
              }
            }
          },
          {
            $lookup:
            {
              from: 'profiles',
              let: { game: '$game', team1: '$team1' },
              pipeline: [
                {
                  $match:
                  {
                    $expr:
                    {
                      $and:
                        [
                          { $eq: ['game', '$$game'] },
                          { $in: ['$nick', '$$team1'] }
                        ]
                    }
                  }
                }
              ],
              as: 'playersTeam1'
            }
          },
          {
            $lookup:
            {
              from: 'profiles',
              let: { game: '$game', team2: '$team2' },
              pipeline: [
                {
                  $match:
                  {
                    $expr:
                    {
                      $and:
                        [
                          { $eq: ['game', '$$game'] },
                          { $in: ['$nick', '$$team2'] }
                        ]
                    }
                  }
                }
              ],
              as: 'playersTeam2'
            }
          },
        ],
        as: 'matches'
      }
    },
    {
      $lookup:
      {
        from: 'data',
        let: { contest_id: '$id' },
        pipeline: [
          {
            $match:
            {
              $expr:
              {
                $and:
                  [
                    { $eq: ['$type', 'userContest'] },
                    { $eq: ['$contestId', '$$contest_id'] },
                  ]
              }
            }
          }
        ],
        as: 'totalUserEntries'
      }
    },
    {
      $replaceRoot: { newRoot: { $mergeObjects: ['$$ROOT', { 'game': { $arrayElemAt: ['$games', 0] } }] } }
    },
    { $project: { games: 0 } }
  ],

  /*userEvents: ({ userId, query }) => [
    { $match: { userId, ...query } },
    {
      $lookup:
      {
        from: 'data',
        let: { contest_id: '$contestId' },
        pipeline: [
          {
            $match:
            {
              $expr:
              {
                $and:
                  [
                    { $eq: ['$type', 'userContest'] },
                    { $eq: ['$contestId', '$$contest_id'] }
                  ]
              }
            }
          }
        ],
        as: 'userTotalEntries'
      }
    },
    {
      $lookup:
      {
        from: 'data',
        let: { contest_id: '$contestId' },
        pipeline: [
          {
            $match:
            {
              $expr:
              {
                $and:
                  [
                    { $eq: ['$type', 'contest'] },
                    { $eq: ['$id', '$$contest_id'] },
                  ],
              }
            }
          },
          {
            $lookup:
            {
              from: 'data',
              let: { game: '$game' },
              pipeline: [
                {
                  $match:
                  {
                    $expr:
                    {
                      $and:
                        [
                          { $eq: ['$type', 'game'] },
                          { $eq: ['$name', '$$game'] }
                        ]
                    }
                  }
                }
              ],
              as: 'games'
            }
          },
          {
            $replaceRoot: { newRoot: { $mergeObjects: ['$$ROOT', { 'game': { $arrayElemAt: ['$games', 0] } }] } }
          },
        ],
        as: 'contests'
      }
    },
    {
      $replaceRoot: { newRoot: { $mergeObjects: [{ 'contest': { $arrayElemAt: ['$contests', 0] } }, '$$ROOT'] } }
    },
    { $project: { contests: 0 } },
    { $sort: { from: -1 } }
  ],*/

  contestEvents: (id) => [
    { $match: { type: 'contest', id }},
    {
      $lookup:
      {
        from: 'data',
        let: { game: '$game' },
        pipeline: [
          {
            $match:
            {
              $expr:
              {
                $and:
                  [
                    { $eq: ['$type', 'game'] },
                    { $eq: ['$name', '$$game'] }
                  ]
              }
            }
          }
        ],
        as: 'games'
      }
    },
    {
      $lookup:
      {
        from: 'data',
        let: { matches: '$matches' },
        pipeline: [
          {
            $match:
            {
              $expr:
              {
                $and:
                  [
                    { $eq: ['$type', 'match'] },
                    { $in: ['$id', '$$matches'] }
                  ]
              }
            }
          },
          {
            $lookup:
            {
              from: 'profiles',
              let: { game: '$game', team1: '$team1' },
              pipeline: [
                {
                  $match:
                  {
                    $expr:
                    {
                      $and:
                        [
                          { $eq: ['$game', '$$game'] },
                          { $in: ['$nick', '$$team1'] }
                        ]
                    }
                  }
                }
              ],
              as: 'playersTeam1'
            }
          },
          {
            $lookup:
            {
              from: 'profiles',
              let: { game: '$game', team2: '$team2' },
              pipeline: [
                {
                  $match:
                  {
                    $expr:
                    {
                      $and:
                        [
                          { $eq: ['$game', '$$game'] },
                          { $in: ['$nick', '$$team2'] }
                        ]
                    }
                  }
                }
              ],
              as: 'playersTeam2'
            }
          },
        ],
        as: 'matches'
      }
    },
    {
      $lookup:
      {
        from: "events",
        localField: "id",
        foreignField: "contestId",
        as: "events"
      }
    },
    {
      $replaceRoot: { newRoot: { $mergeObjects: ['$$ROOT', { 'game': { $arrayElemAt: ['$games', 0] } }] } }
    },
    { $project: { games: 0 } }
  ],
  matchEvents: (id) => [
    { $match: { type: 'match', id }},
    {
      $lookup:
      {
        from: 'data',
        let: { game: '$game' },
        pipeline: [
          {
            $match:
            {
              $expr:
              {
                $and:
                  [
                    { $eq: ['$type', 'game'] },
                    { $eq: ['$name', '$$game'] }
                  ]
              }
            }
          }
        ],
        as: 'games'
      }
    },
    {
      $lookup:
      {
        from: 'profiles',
        let: { game: '$game', team1: '$team1' },
        pipeline: [
          {
            $match:
            {
              $expr:
              {
                $and:
                  [
                    { $eq: ['$game', '$$game'] },
                    { $in: ['$nick', '$$team1'] }
                  ]
              }
            }
          }
        ],
        as: 'playersTeam1'
      }
    },
    {
      $lookup:
      {
        from: 'profiles',
        let: { game: '$game', team2: '$team2' },
        pipeline: [
          {
            $match:
            {
              $expr:
              {
                $and:
                  [
                    { $eq: ['$game', '$$game'] },
                    { $in: ['$nick', '$$team2'] }
                  ]
              }
            }
          }
        ],
        as: 'playersTeam2'
      }
    },
    {
      $lookup:
      {
        from: "events",
        localField: "id",
        foreignField: "matchId",
        as: "events"
      }
    },
    {
      $replaceRoot: { newRoot: { $mergeObjects: ['$$ROOT', { 'game': { $arrayElemAt: ['$games', 0] } }] } }
    },
    { $project: { games: 0 } }
  ]
}


export const getPipeline = (type, params) => {

  const pipeline = PIPELINES[type]
  if (!pipeline) return [{ $match: { type: 'NOTHING' } }]
  if (typeof pipeline === 'function') return pipeline(params)
  return pipeline
}