import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './datePickerComp.scss';
import PropTypes from 'prop-types';

const DatePickerBirth = ({ startDate, onChange, name, label }) => {
    return (
        <div className="date-picker-wrapper">
            {label && <label htmlFor={name}>{label}</label>}
            <DatePicker
                selected={startDate}
                onChange={date => onChange(date, name)}
            />
        </div>
    )
}

DatePickerBirth.propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string
}

export default DatePickerBirth;