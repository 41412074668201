import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { sessionService } from './_sessionService/storage';
const defaultLang = sessionService.getDefaultLang();
const langStorage = sessionService.get(`lang_${defaultLang}`);
const initialLangJson = langStorage ? langStorage.lang : null;
i18n
  .use(initReactI18next)
  .init(
    {
      resources: {
        [defaultLang]: {
          translation: initialLangJson
        }
      },
      lng: defaultLang,
      // debug: true,
      keySeparator: false,
      interpolation: {
        escapeValue: false,
      },
    }
  );

export default i18n;
