import { langConstants } from '../constants';

const langStorage = JSON.parse(localStorage.getItem('lang'));
const initialLang = langStorage ? langStorage.name : 'en';
const initialLangJson = langStorage ? langStorage.lang[initialLang] : null;
const initialState = {
  lang: initialLangJson,
  name: initialLang,
  crc: ''
};
export const language = (state = initialState, action) => {
  switch (action.type) {
    case langConstants.LANG_REQUEST:
      return {
        ...state
      };
    case langConstants.LANG_SUCCESS:
      return {
        ...state,
        name: action.name,
        lang: action.lang,
        crc: action.crc
      };
    case langConstants.LANG_STORAGE:
      return {
        ...state,
        name: action.name,
        lang: action.lang,
        crc: action.crc
      };
    case langConstants.LANG_FAILURE:
      return { ...state };
    default:
      return state;
  }
};
