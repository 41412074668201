import React, { useState, useEffect } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import styles from './events.module.scss';
import EventsAdd from './eventsAdd';
import EventsEdit from './eventsEdit';
import { useTranslation } from 'react-i18next';
import greenLeftArrow from '../../../_assets/images/icons/greenLeftArrow.svg';
import { isObjectEmpty } from '../../../_utils';
import EventsPublish from './eventsPublish';
const EventsViews = ({ data, action, toggle, onSubmit, onCorrection }) => {

  const [t] = useTranslation();
  const [activeView] = useState(action);
  const [newData, setNewData] = useState({});
  useEffect(() => {
    setNewData(data);
  }, [data]);

  return (
    <div>
      {(activeView === 'add' || activeView === 'edit' || activeView === 'publish' || activeView === 'view') && <div className={styles.goBack}>
        <span onClick={toggle}><img src={greenLeftArrow} alt="Go back" /> {t('d72876492')}</span>
      </div>}
      {!isObjectEmpty(newData) && <>
        {activeView === 'add' && <EventsAdd data={newData} onSubmit={onSubmit} type="new" eventMode="live"/>}
        {activeView === 'edit' && <EventsEdit data={newData} onSubmit={onSubmit} />}
        {(activeView === 'publish' || activeView === 'view') && <EventsPublish data={newData} onSubmit={onSubmit} toggle={toggle} action={action} onCorrection={onCorrection}/>}
      </>}
    </div>
  );
};
export default EventsViews;