import { loaderConstants } from '../constants';

const initialState = {};

export const loader = (state = initialState, { type, name, loading }) => {
  switch (type) {
    case loaderConstants.SET_LOADING:
      return {
        ...state,
        [name]: loading
      };
    default:
      return state;
  }
};
