import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Button from '../../../button/button';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import toastService from '../../../../_services/toastService';

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#fff',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '14px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#181c3a',
      },
      '::placeholder': {
        color: '#87bbfd',
      },
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee',
    },
  },
};

const CardCheckoutForm = ({ onPayment }) => {
  const [t] = useTranslation();

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.log('[error]', error);
      toastService.show('error', error.message);
    } else {
      toastService.show("success", t("d67237631"));
    }
    onPayment(paymentMethod)
  };

  return (
    <div className={styles.main}>
      <CardElement options={CARD_OPTIONS} className="stripeWrapper" />
      <div className={styles.footer}>
        <Button label={t('d57723614')} btnClass='btnGray' disabled={!stripe} onClick={handleSubmit} />
      </div>
    </div>
  );
};

CardCheckoutForm.propTypes = {
  onPayment: PropTypes.func,
};

export default CardCheckoutForm;