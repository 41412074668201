import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { PublicRoute } from './publicRoute';
import { PrivateRoute } from './privateRoute';
import Landing from '../_pages/landing/landing';
import UnderConstruction from '../_pages/underConstruction/underConstruction';
import ResetPassword from '../_pages/auth/resetPasswordComponent/resetPassword';
import Event from '../_pages/event/event';
import UserEvents from '../_pages/userEvents/userEvents';
import Profile from '../_pages/profile/profile';
import Results from '../_pages/results/results';
import AdminResults from '../_pages/admin/results/results';
import Wallet from '../_pages/wallet/wallet';
import Referral from '../_pages/referral/referral';
import Leaderboard from '../_pages/leaderboard/leaderboard';
import ChangePassword from '../_pages/auth/resetPasswordComponent/changePassword';
import AdminLanding from '../_pages/admin/admin';
import InfluencerLanding from '../_pages/influencer/influencer';
import TermsOfUse from '../_pages/static/termsOfUse/termsOfUse';
import WithdrawPolicy from '../_pages/static/withdrawPolicy/withdrawPolicy';
import ResponsibleGaming from '../_pages/static/responsibleGaming/responsibleGaming';
import About from '../_pages/static/about/about';
import Contact from '../_pages/static/contact/contact';
import Faq from '../_pages/static/faq/faq';
import HowToPlay from '../_pages/static/howToPlay/howToPlay';
import PlayerPicks from '../_pages/playerPicks/playerPicks';
import PrivacyPolicy from '../_pages/static/privacyPolicy/privacyPolicy';

const Routes = () => {
  return (
    <Switch>
      <PublicRoute path="/lobby" component={Landing} exact name="lobby" />
      <PublicRoute path="/lobby/draft/:id" component={Event} name="draft" />
      <PublicRoute path="/lobby/picks" component={PlayerPicks} name="draft" />
      <PublicRoute path="/picks/:userPlayerPropsId" component={PlayerPicks} name="draft" exact/>
      {/* <PrivateRoute path="/contests/draft/:id" component={Event} name="draft" /> */}
      <PrivateRoute path="/contests/draft/:id/:userContestId" component={Event} name="draft" exact/>
      <PrivateRoute path="/contests/results/:id/:userContestId" component={Results} name="results" />
      <PrivateRoute path="/admin/results/:id" component={AdminResults} name="admin results" />
      <PublicRoute path="/reset-password" component={ResetPassword} name="reset password" />
      <PublicRoute path="/underConstruction" component={UnderConstruction} name="under construction" />
      <PublicRoute path="/privacy_policy" component={PrivacyPolicy} name="privacy policy" />
      <PublicRoute path="/terms_of_service" component={TermsOfUse} name="terms of service" />
      <PublicRoute path="/withdraw_policy" component={WithdrawPolicy} name="withdraw policy" />
      <PublicRoute path="/responsible_gaming" component={ResponsibleGaming} name="gaming" />
      <PublicRoute path="/about" component={About} name="about" />
      <PublicRoute path="/contact" component={Contact} name="contact" />
      <PublicRoute path="/faq" component={Faq} name="faq" />
      <PublicRoute path="/how_to_play" component={HowToPlay} name="how to play" />
      {/* Leaderboard is commented because now app don't need that */}
      {/* <PublicRoute path="/leaderboard" component={Leaderboard} name="leaderboard" /> */}
      <PrivateRoute path="/contests" component={UserEvents} name="contests" />
      <PrivateRoute path="/profile" component={Profile} name="profile" />
      <PrivateRoute path="/wallet" component={Wallet} name="wallet" />
      <PrivateRoute path="/referral" component={Referral} name="referral" />
      <PrivateRoute path="/admin" component={AdminLanding} name="admin" />
      <PrivateRoute path="/influencer/contests" component={InfluencerLanding} name="influencer" />
      <PublicRoute path="/reset/:token/:email" component={ChangePassword} name="change password" />
      <Redirect from="*" to="/lobby" />
    </Switch>
  );
};

export { Routes };
