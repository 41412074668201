import React, { useState, useEffect, Suspense } from 'react';
import { HashRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './App.scss';
import { Routes } from './_routes/routes';
import Navbar from './_components/navbar/navbar';
import useWindowDimensions from './_utils/screenSize';
import { screenActions } from './_store/actions/screen.actions';
import { languageActions } from './_store/actions/language.actions';
import { mainActions } from './_store/actions/main.actions';
import mainService from './_services/main.service';
import { loaderActions } from './_store/actions/loader.actions';
import { sessionService } from './_sessionService/storage';
import { showGameNotification } from './_services/notification.service';
import storageService from './_services/storage.service';
import { history } from './_utils'
import Socket from './_sockets';
import { stripeKey } from './environment'

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
const stripePromise = loadStripe(stripeKey);

const App = () => {
  const defaultLang = sessionService.getDefaultLang();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const store = useSelector(state => state);
  const [ready, setReady] = useState(false)
  const [matches, setMatches] = useState({})

  const { width, height } = useWindowDimensions();
  useEffect(() => {
    history.listen((location, action) => {
      document.querySelector('body').scrollTo(0, 0);
    });
    dispatch(screenActions.getScreenSize(width, height));
    dispatch(languageActions.run('get', { lang: defaultLang }, `lang=${defaultLang}`));
    loadLeaderBoard();
  }, []);

  useEffect(() => {
    if (store.event.data) {
      if (store.event.data.type == 'game') {
        const team = matches[store.event.data.matchId]
        if (team) {
          showGameNotification(store.event.data, team, t);
        }
      } else if (store.event.data.type == 'image'){
        const { name } = store.event.data
        sessionService.removeItemsByCrc([name]);
      }
    }
  }, [store.event.data]);


  useEffect(() => {
    if (store.main.data && store.main.data.userMatches && store.main.data.userMatches.list) {
      const matches = {}
      store.main.data.userMatches.list.forEach(userContest => {
        if (userContest.matches) {
          userContest.matches.forEach(matchId => matches[matchId] = userContest.team)
        }
      })
      setMatches(matches)
    }
  }, [store.main.data && store.main.data.userMatches && store.main.data.userMatches.list]);

  const loadLeaderBoard = async () => {
    dispatch(loaderActions.setLoading('leaderboard', true))
    try {
      const res = await dispatch(mainActions.run("data", "leaderboard", "all", { sort: { loyality: -1} }));
      const best = res.slice(0, 3);
      storageService.setData('data', 'leaderboard', 'all', res);
      storageService.setData('data', 'best', 'all', best);
      dispatch(mainActions.runType("userMatches", "data", 'userContest', 'all', { filter: { from: { $lte: new Date().getTime() } } }))
    } catch (err) {/*not to break setting loader to false */ console.log(err)}
    dispatch(loaderActions.setLoading('leaderboard', false))
  }

  const loadUsers = async (list = []) => {
    const ids = []
    list.forEach(one => {
      if (!one.user) ids.push(one.userId)
    })
    if (ids.length > 0) {
      const users = await mainService.run('profiles', "user", 'all', {
        filter: {id: {$in: ids}},
        projections: {_id: 0, id: 1, username: 1, win: 1, gamesPlayed: 1, totalEarnings: 1}
      })
      const usersMap = {}
      users.forEach(one => usersMap[one.id] = one)

      list.forEach(one => {
        if (!one.user) one.user = usersMap[one.userId]
      })
    }
  }

  useEffect(() => {
    if (store.language && store.language.lang) {
      setReady(!!store.language.lang)
    }
  }, [store.language]);

  return (
    <div className="app">
      <Socket />
      <Elements stripe={stripePromise}>
        <Suspense fallback={"add loader here"}>
          <main className="app-wrapper">
            <HashRouter history={history}>
              <Navbar ready={ready} />
              <Routes />
            </HashRouter>
          </main>
        </Suspense>
      </Elements>
    </div>
  );
};

export default App;
