import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Input from "../../../_components/input/input";
import Checkbox from "../../../_components/checkbox/checkbox";

import phoneImage from "../../../_assets/images/phoneImage.svg";

const Step3 = ({ user, handleChange, error }) => {
  const [t] = useTranslation();
  return (
    <div className="step1-body">
      <div className="form-info-step2">
        <h3>Enter full name</h3>
        <Input
          name="firstName"
          value={user.firstName}
          onChange={handleChange}
          placeholder={t("d471724")}
          autofocus={true}
        />
        {error && !user.skipEnterFullName && !user.firstName ? (
          <div className="input-err-msg">
            Please enter first name or skip this step
          </div>
        ) : null}
        <Input
          name="lastName"
          value={user.lastName}
          onChange={handleChange}
          placeholder={t("d478789")}
        />

        {error && !user.skipEnterFullName && !user.lastName ? (
          <div className="input-err-msg">
            Please enter last name or skip this step
          </div>
        ) : null}
        <div className="register-checkbox-wrapper">
          <div className="register-checkbox">
            <Checkbox
              checkboxClass="checkboxLoginClass"
              label="Do it later"
              name="skipEnterFullName"
              isSelected={user.skipEnterFullName}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="imageDivStep2">
        <h1>Daily Fantasy made Easy</h1>
        <h4>Sign up now to claim your limited time offer!</h4>
        <img src={phoneImage} />
      </div>
    </div>
  );
};

Step3.propTypes = {
  user: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
};

export default Step3;
