import { loyalityRules } from './loyality'
const POINTS = 'points'

export const calculateWallet = (user, transactions = []) => {
  user.amount = 0
  user.credit = 0
  transactions.forEach(one => {
    if (one.amount) user.amount += one.amount
    if (one.credit) user.credit += one.credit
  })
  user.amount = _round(user.amount, 2)
  user.credit = _round(user.credit, 0)
}


export const calculateUserPoints = (team = [], points = []) => {
  let fppm = 0
  points.forEach(({ userId, points = 0 }) => {
    if (userId && team.indexOf(userId) > -1) {
      fppm += points
    }
  })
  return fppm
};

const _calculateParticipantPrizes = (prosTeamPoints, participants, sum) => {
  let prize = sum*0.8;
  let winningStake = 0
  let stake;
  participants.forEach(p => {
    if(p.points > prosTeamPoints) {
      stake = p.entryCreditFee ? p.entryCreditFee : p.entryFee;
      winningStake = winningStake + stake;
    }
  })

  participants.forEach(p => {
    if(p.points > prosTeamPoints) {
      stake = p.entryCreditFee ? p.entryCreditFee : p.entryFee;
      p.won = _round(stake/winningStake*prize, 2);
    }
  })
}

const _calculateProsTeamPoints = (contest, playerPoints) => {
  let points = 0;
  contest.team.forEach(id => {
    const playerResult = playerPoints[id]
    if (playerResult) {
      points += playerResult[POINTS]
    }
  })
  points = _round(points, 2);
  contest.points = points;
  return points;
}

export const calculateStandings = (result, ignoreTotal) => {
  const { contest, participants, players = [], events } = result
  result.points = 0
  const { rules = {}, prizes = {}, prizeType /*, prize */} = contest
  Object.keys(rules).forEach(key => rules[key] = rules[key] ? Number(rules[key]) : 0)
  if (prizeType !== 'Skins') {
    Object.keys(prizes).forEach(key => prizes[key] = prizes[key] ? Number(prizes[key]) : 0)
  }
  const playerPoints = {}
  const maxActionMap = {}
  events.forEach(({ userId, action, points = 0 }) => {
    if (userId) {
      let playerData = playerPoints[userId]
      if (!playerData){
        playerData = { [POINTS]: 0 }
        playerPoints[userId] = playerData
      }
      if (!playerData[action]) playerData[action] = 1
      else playerData[action]++
      playerData[POINTS] += Number(points)
      if (!maxActionMap[POINTS]) maxActionMap[POINTS] = { playerId: null, action: POINTS, value: 0 }
      if (!maxActionMap[action]) maxActionMap[action] = { playerId: null, action, value: 0 }
      if (maxActionMap[action].value < playerData[action]) {
        maxActionMap[action].value = playerData[action]
        maxActionMap[action].playerId = userId
      }
      if (maxActionMap[POINTS].value < playerData[POINTS]) {
        maxActionMap[POINTS].value = playerData[POINTS]
        maxActionMap[POINTS].playerId = userId
      }
    }
  })
  players.forEach(player => {
    const playerResult = playerPoints[player.id]
    if (playerResult) {
      Object.keys(playerResult).forEach(action => {
          if (!maxActionMap[action]) maxActionMap[action] = { player: null, action, value: 0 }
          if (maxActionMap[action].value < playerResult[action]) {
            maxActionMap[action].value = playerResult[action]
            maxActionMap[action].player = player
        }
      })
      player.result = playerResult
      result.points += playerResult[POINTS]
    }
  })
  result.topActionsPlayers = Object.keys(maxActionMap).map(action => maxActionMap[action])

  let sum = 0;
  participants.forEach(userContest => {
    userContest.points = 0
    userContest.won = 0
    userContest.playerPoints = {}
    const stake = userContest.entryCreditFee ? userContest.entryCreditFee : userContest.entryFee;
    sum = sum + stake;
    userContest.team.forEach(id => {
      const playerResult = playerPoints[id]
      if (playerResult) {
        userContest.points += playerResult[POINTS]
        userContest.playerPoints[id] = playerResult
      }
    })
    userContest.points = _round(userContest.points, 2)
  })

  participants.sort(_sortByPoints)

  if(contest.subtype === 'beatThePro') {
    const prosTeamPoints = _calculateProsTeamPoints(contest, playerPoints);
    _calculateParticipantPrizes(prosTeamPoints, participants, sum);
  } else {
    const prizesPerPlaces = _convertPrizes(prizes, prizeType !== 'Skins')
    if (prizeType === 'Skins') {
      const winners = _calculateNonSharedPlaces(participants, prizesPerPlaces.length - 1)
      winners.forEach(one => one.reward = prizesPerPlaces[one.place])
      result.positionsPaid = winners.length
    } else {
      const placeNumbers = _calculatePlaces(participants, prizesPerPlaces.length - 1)
      result.positionsPaid =_calculatePrizes(participants, prizesPerPlaces, placeNumbers)
    }
    if (prizeType !== 'Skins' && !ignoreTotal) {
      const user = participants.find(one => one.userId === result.userId)
      if (user) {
        result.won = user.won || 0
        result.reward = user.reward || 0
        result.points = user.points
      }
      result.total = participants.reduce((sum, one) => sum + (one.points || 0), 0)
    }
  }

  result.playerPoints = playerPoints

  if (!ignoreTotal) {
    const user = participants.find(one => one.userId === result.userId)
    if (user) {
      result.won = user.won || 0
      result.points = user.points
    }
    result.total = participants.reduce((sum, one) => sum + (one.points || 0), 0)

  }
  return result
};

export const calculateLeaderboard = (receivedfinishedContests = [], loyality = [], ) => {
  const usersMap = {}
  const users = []
  const loyalityCalcs = loyalityRules(loyality)

  // const LoyalityMap

  receivedfinishedContests.forEach(contest => {
      const {events, participants} = contest
      if (events && events.length > 0) {
        calculateStandings({events, participants, contest}, true)
        _calculateLoyalityPoints(usersMap, users, participants, loyalityCalcs)
        contest.calculated = true
      }
  })

  return users
};

const _sortByPoints = (a, b) => b.points - a.points
const _sortByCreation = (a, b) => b.createdAt - a.createdAt

const _calculateNonSharedPlaces = (participants, places) => {
  const winners = []
  let place = 0
  let placesCount = 1
  let lastPoints = -1
  let samePointSpaces = { start: 0, places: []}
  participants.slice(0, places).forEach(one => {
    if (one.points !== lastPoints) {
      if (samePointSpaces.places.length > 0){
        const { start, places } = samePointSpaces
        places.sort(_sortByCreation)
        places.forEach((one, idx) => one.place = start + idx)
      }
      place+= placesCount
      placesCount = 1
      lastPoints = one.points
      samePointSpaces.start = place
      samePointSpaces.places = [one]
    } else {
      samePointSpaces.places.push(one)
      placesCount++
    }
    if (samePointSpaces.places.length > 0){
      const { start, places } = samePointSpaces
      places.sort(_sortByCreation)
      places.forEach((one, idx) => one.place = start + idx)
    }
    winners.push(one)
  })
  return winners
}

const _calculatePlaces = (participants, places) => {
  const placeNumbers = {}
  let place = 0
  let placesCount = 1
  let lastPoints = -1
  participants.slice(0, places).forEach(one => {
    if (one.points !== lastPoints) {
      place+= placesCount
      placesCount = 1
      lastPoints = one.points
    } else placesCount++
    if (!placeNumbers[place]) placeNumbers[place] = 1
    else placeNumbers[place]++
    one.place = place
  })

  return placeNumbers
}

const _calculatePrizes = (participants, prizesPerPlaces, placeNumbers) => {

  const prizesDistribution = {}

  let winningPositions = 0
  for(let i = 1; i< prizesPerPlaces.length; i++){
    const count = placeNumbers[i]
    if (count){
      winningPositions += count
      prizesDistribution[i] = 0
      for(let j=0; j< count; j++){
        prizesDistribution[i] += prizesPerPlaces[i+j]
      }
      prizesDistribution[i] = prizesDistribution[i] / count
    }
  }

  const rewardedParticipiants = Math.max(winningPositions, participants.length)
  let positionsPaid = 0
  for(let i = 0; i< rewardedParticipiants; i++){
    const participiant = participants[i]
    participiant.won = prizesDistribution[participiant.place]
    if (participiant.won) positionsPaid++
  }
  return positionsPaid
}


const _calculateLoyalityPoints = (userMap, users, participants, loyalityCalcs) => {
  participants.forEach(one => {
    let user = userMap[one.userId]
    if (!user) {
      user = { ...one, loyality: 0 }
      users.push(user)
      userMap[one.userId] = user
    }
    loyalityCalcs.forEach(calc => calc(user))
  })
}

const _convertPrizes = (prizes = {}, isNumber = true) => {
  const prizesList = []
  Object.keys(prizes).forEach(key => {
    const placesRange = key.split('-')
    let from = Number(placesRange[0])
    const to = Number(placesRange[1] || placesRange[0])
    while (from <= to) {
      prizesList[from] = isNumber ? Number(prizes[key]) : prizes[key]
      from++
    }
  })
  return prizesList;
}

const _round = (value, places) => {
  const number = Number(value + ('e+' + places))
  return +(Math.round(number) + ('e-' + places))
}