import React from "react";
import PageHeading from "../../../_components/pageHeading/pageHeading";
import styles from "./termsOfUse.module.scss";
import { useTranslation } from "react-i18next";

const TermsOfUse = () => {
  const [t] = useTranslation();

  const termsText = [
    // {
    //     id: 1,
    //     header: t('d54548048'),
    //     text: <p> {t('d61538581')} <br /> <br />{t('d61952331')} </p>
    // },
    // {
    //     id: 2,
    //     header: t('d55173070'),
    //     text: <p>{t('d6224488')}</p>
    // },
    // {
    //     id: 3,
    //     header: t('d55496313'),
    //     text: <p>{t('d62644507')} <br /> <br /> {t('d63293021')} </p>
    // },
    // {
    //     id: 4,
    //     header: t('d55802308'),
    //     text: <p> {t('d6367610')} <br /> <br />  {t('d64091064')}</p>
    // },
    // {
    //     id: 5,
    //     header: t('d56192155'),
    //     text: <p> {t('d64754887')}<br /> <br /> {t('d65318237')}<br /> <br /> {t('d65647714')}<br /> <br /> {t('d65978841')}</p>
    // },
    // {
    //     id: 6,
    //     header: t('d56632244'),
    //     text: <p> {t('d66374925')} <br /> <br /> {t('d66817514')}</p>
    // },
    // {
    //     id: 7,
    //     header: t('d57072036'),
    //     text: <p>{t('d67281010')}</p>
    // },
    // {
    //     id: 8,
    //     header: t('d57518589'),
    //     text: <p> {t('d67693394')} <br /> <br /> {t('d68082872')} <br /> <br /> {t('d68427706')} <br /> <br />
    //         {t('d68931305')} <br /> <br /> {t('d69273686')} <br /> <br /> {t('d69601326')} </p>
    // },
    // {
    //     id: 9,
    //     header: t('d57846379'),
    //     text: <p>{t('d70095152')}</p>
    // },
    // {
    //     id: 10,
    //     header: t('d58125210'),
    //     text: <p> {t('d70673696')} <br /> <br /> {t('d71191294')} <br /> <br /> {t('d71561721')} </p>
    // },
    // {
    //     id: 11,
    //     header: t('d58482936'),
    //     text: <p> {t('d71927685')} <br /> <br /> {t('d72471827')}</p>
    // },
    // {
    //     id: 12,
    //     header: t('d58812027'),
    //     text: <p>{t('d72921571')}</p>
    // },
    // {
    //     id: 13,
    //     header: t('d59084591'),
    //     text: <p>{t('d73546838')}</p>
    // },
    // {
    //     id: 14,
    //     header: t('d59368321'),
    //     text: <p> {t('d74036224')} <br /> <br />
    //         {t('d1760556')} $51.505 {t('d17923463')} $51.51, {t('d1823558')} $51.491 {t('d18526985')} $51.49.
    //     <br /> <br />
    //         {t('d74857121')} <br /> <br />
    //         {t('d19143643')} $25<br /> <br />
    //         {t('d19383854')} $10 <br /> <br />
    //         {t('d19834546')} $35 <br /> <br />
    //         {t('d20066942')} $10 <br /> <br />
    //         {t('d20294732')} $20</p>
    // },
    // {
    //     id: 15,
    //     header: t('d59621773'),
    //     text: <p>{t('d15844636')}  <a href="mailto:support@eliteduels.com" target="_blank" rel="noopener noreferrer">support@eliteduels.com.</a> </p>

    // },
    // {
    //     id: 16,
    //     header: t('d59913722'),
    //     text: <p>{t('d77334726')}</p>
    // },
    // {
    //     id: 17,
    //     header: t('d60252856'),
    //     text: <p>{t('d16475324')}  <a href="mailto:support@eliteduels.com" target="_blank" rel="noopener noreferrer">support@eliteduels.com.</a> </p>

    // },
    {
      id: 1,
      text: (
        <p>
          <label>Effective Date: December 13, 2022</label>
          <label>
            Welcome to the EliteDuels! EliteDuels (“<b>EliteDuels</b>”) is a
            fantasy sports platform that offers skill-based contests where each
            of your entries has a chance to win (the “Contest”). Each entry is
            comprised of multiple player proposition selections. The more
            selections you combine the greater the payout! Before using
            EliteDuels, please take some time to carefully read our Terms of
            Service below (“<b>Terms,” or “Agreement</b>”). The Terms below
            constitutes a binding contract between you and EliteDuels.
          </label>
          <label>
            IMPORTANT NOTICE: PLEASE READ THIS AGREEMENT CAREFULLY, IT CONTAINS
            A BINDING ARBITRATION PROVISION AND A CLASS ACTION WAIVER THAT
            AFFECTS YOUR LEGAL RIGHTS AND REMEDIES AS DETAILED IN THE SECTION
            TITLED ARBITRATION AND CLASS ACTION WAIVER BELOW. PLEASE READ
            CAREFULLY.
          </label>
        </p>
      ),
    },
    {
      id: 2,
      header: "1. Acceptance of Terms.",
      text: (
        <div>
          <p>
            By using the website{" "}
            <a href="https://eliteduels.gg/">www.EliteDuels.gg</a> (including
            all the areas available through such website, collectively, the “
            <b>Site</b>”), and/or by creating an account and using the various
            fantasy sports services (collectively, such services, including any
            new features and applications, together with the Site, the “
            <b>Services</b>”) offered by EliteDuels INC. (together with their
            parents, subsidiaries, affiliates, agents, representatives,
            consultants, employees, officers, and directors – collectively “
            <b>EliteDuels,” “we,” “us,” “our,” and/or “Company</b>” ) you, the
            user (collectively “Users” or “you”), acknowledge and agree to these
            legally binding Terms. You also agree to the EliteDuels Privacy
            Policy located at{" "}
            <a href="https://eliteduels.gg/">www.EliteDuels.gg</a> ( “
            <b>Privacy Policy</b>”) and all other operating rules, game rules,
            policies, and procedures that may be published on the Services by
            EliteDuels, which are herein incorporated by reference.
          </p>
          <p>
            BY CLICKING THE “AGREE” BUTTON, YOU (A) ACKNOWLEDGE THAT YOU HAVE
            READ AND UNDERSTOOD THIS AGREEMENT; (B) REPRESENT THAT YOU ARE OF
            LEGAL AGE TO ENTER INTO A BINDING AGREEMENT; AND (C) ACCEPT THIS
            AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS. IF YOU
            DO NOT AGREE TO THESE TERMS, DO NOT USE THE SERVICES, DEACTIVATE
            YOUR ACCOUNTAND DELETE ALL ELITEDUELS APPLICATIONS AND PLATFORMS
            FROM YOUR DEVICES.
          </p>
        </div>
      ),
    },
    {
      id: 3,
      header: "2. Modification to Terms.",
      text: (
        <div>
          <p>
            Except as it relates to provisions regarding lawsuit, binding
            arbitration, and waiver of class action rights, EliteDuels reserves
            the right, at its sole discretion, to modify or replace the Terms at
            any time. The most current version of these Terms will be available
            via our Site and App. You shall be responsible for reviewing and
            becoming familiar with any such modifications. If a revision to the
            Terms, in our sole discretion, is material, we will notify you Site
            notification and/or by contacting you through the email address
            associated with your account. The Parties agree that any means of
            notification as described herein are reasonable and conspicuous. Use
            of the Services by you after any modification to the Terms
            constitutes your acceptance of the as modified Terms in full.
          </p>
          <p>
            For the avoidance of doubt, ongoing Contests (as further defined
            below) shall be subject to the version of the Terms that is in
            effect at the start of the Contest. It is in EliteDuels’ sole
            discretion to determine whether applicable actions are governed by
            the Terms such as they were in effect during any relevant Contest or
            if the most recent version of the Terms control. Further, all
            actions and matters specifically related to fraud,
            misrepresentation, cheating, theft, or other conduct EliteDuels
            deems to be improper shall be governed by the most recent version of
            these Terms. It is your responsibility to check the Terms
            periodically for changes. Your continued use of the Services,
            including, but not limited to, signing into your EliteDuels account,
            following the posting of any changes to the Terms means you accept
            those new terms. In addition, some services offered through the Site
            may be subject to additional terms and conditions adopted by
            EliteDuels. Your use of those services means you agree to be subject
            to those additional terms and conditions, which are herein
            incorporated into these Terms by reference.
          </p>
          <p>
            EliteDuels reserves the right to modify, suspend, or discontinue the
            Services (including, but not limited to, the availability of any
            text, graphics, sounds, files, software or other information found
            on the Site (collectively, “<b>Content</b>”), feature, or database),
            whether temporarily or permanently at any time for any reason. You
            agree that EliteDuels shall not be liable to you or to any third
            party for any modification, suspension, or discontinuation of the
            Services. EliteDuels may also impose limits on certain features and
            services or restrict your access to parts or all of the Services
            without notice or liability.
          </p>
        </div>
      ),
    },
    {
      id: 4,
      header: "3. License Grant.",
      text: (
        <div>
          <p>
            Subject to the terms of this Agreement, EliteDuels grants you a
            limited, non-exclusive, and nontransferable license to:
          </p>
          <ul>
            <li>
              download, install, and use the App for your personal,
              non-commercial use on handheld and mobile devices owned or
              otherwise controlled by you (collectively, “Device”) strictly in
              accordance with the App’s documentation; and
            </li>
            <li>
              access, stream, download, and use on such Device, the Services
              made available in or otherwise accessible through the Site or App,
              strictly in accordance with this Agreement.
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: 5,
      header: "4. Reservation of Rights.",
      text: (
        <div>
          <p>
            You acknowledge and agree that the Services are provided under
            license as described in this Agreement, and not sold, to you. You do
            not acquire any ownership interest in the Services under this
            Agreement, or any other rights thereto other than to use the
            Services in accordance with the license granted, and subject to all
            terms, conditions, and restrictions, under this Agreement. You may
            not (a) modify the Content or use them for any commercial purpose,
            or any public display, performance, sale or rental; (b) decompile,
            reverse engineer, or disassemble software Content except and only to
            the extent permitted by applicable law; (c) remove any copyright or
            other proprietary notices from the Content; (d) transfer the Content
            to another person. EliteDuels and its licensors and service
            providers reserve and retain their entire right, title, and interest
            in and to the Services, including all copyrights, trademarks, and
            other intellectual property rights therein or relating thereto,
            except as expressly granted to you in this Agreement. EliteDuels
            shall have the right to assert and enforce the provisions of this
            section directly on its own behalf.
          </p>
          <p>
            EliteDuels may terminate this license at any time if you are in
            breach of these Terms. Upon termination, you agree to immediately
            destroy any copies of the Content in your possession.
          </p>
        </div>
      ),
    },
    {
      id: 6,
      header: "5. EliteDuels Accou",
      text: (
        <div>
          <p>
            You may view Content on the Site without registering for an account,
            but as a condition of using certain aspects of the Services,
            including entering Contests, depositing and withdrawing funds or
            redeeming promo or bonus funds, you are required to create a
            EliteDuels account (“<b>Account</b>,”). You must be at least
            eighteen (18) years of age to open an Account, unless you reside in
            an Eligible Jurisdiction (as further defined below) that requires a
            higher age of eligibility.
          </p>
          <p>
            To sign up for an Account, you must submit a username, password,
            mailing address, phone number, driver’s license, date of birth, and
            social security number. You must provide accurate, complete, and
            updated contact information, identification details, and any other
            form of authentication that EliteDuels may request in its sole
            discretion, including personal identifying information necessary for
            tax reporting purposes. To enter Contests that require you to make
            deposits, you may be required to submit your billing information. If
            your account information changes, then you must provide us with
            accurate and complete information.
          </p>
          <p>
            You may not use a username that promotes a commercial venture or a
            username that EliteDuels in its sole discretion deems offensive or
            otherwise a violation of its Terms. EliteDuels may require you to
            change your username or may unilaterally change your username.
          </p>
          <p>
            <b>Account Misuse, Multiaccounting, Account Sharing </b> <br /> You
            agree that the sole and specific purpose of creating an account on
            EliteDuels is to participate in fantasy sports Contests. EliteDuels
            shall be entitled to suspend, limit, or terminate your account if we
            determine, in our sole discretion, that you are depositing funds
            without the intention of using them in Contests or for the Service.
            In such circumstances, we may also report such activity to relevant
            authorities.
          </p>
          <p>
            Each user may establish only one (1) Account. For avoidance of
            doubt, users may not “co-own” accounts. Further, users may not use
            another person’s account other than their own. Users also may not
            direct or control or otherwise use another user’s account as a proxy
            for their own account or to make contest entries. Additionally,
            users may not use, direct, or control any account other than their
            own for any purpose, including, but not limited to: avoiding account
            limits, suspensions, responsible gaming related limitations, evade
            any other account restriction either self- imposed or imposed by
            EliteDuels, or to abuse promotional or bonus offerings, such as
            referral bonuses, for which each account is eligible. All such
            aforementioned account misuses or duplications shall collectively be
            referred to as “multiaccounting”. Multiaccounting is a violation of
            these Terms and a violation of state law in many of the states in
            which EliteDuels’ operates. In the event EliteDuels, in its sole and
            unlimited discretion, reasonably determines that you have violated
            these Terms by multiaccounting, then, in addition to any other
            rights that EliteDuels may have at law, equity or created herein,
            EliteDuels reserves the right to: (i) suspend or terminate any or
            all of your accounts (and all other accounts associated with the
            multiaccounting), (ii) cancel any pending or in-progress entries
            made on all offending accounts; (iii) terminate, withhold, or revoke
            the awarding of any prizes, including prizes that you have already
            withdrawn from your Account; (iv) seize or remove all promotional
            balances, bonuses or free entries previously awarded to each
            account(s); and (v) seize all real-money funds in the offending
            account(s) or earned by the offending user(s) during the course of
            multiaccounting.
          </p>
          <p>
            YOU AGREE TO INDEMNIFY, RELEASE AND TO HOLD HARMLESS ELITEDUELS, ITS
            PARENTS, SUBSIDIARIES, AFFILIATES AND AGENTS, AS WELL AS THE
            OFFICERS, DIRECTORS, EMPLOYEES, SHAREHOLDERS AND REPRESENTATIVES OF
            ANY OF THE FOREGOING ENTITIES, FROM ANY AND ALL LIABILITY, CLAIMS OR
            ACTIONS OF ANY KIND WHATSOEVER, INCLUDING BUT NOT LIMITED TO
            INJURIES, DAMAGES, OR LOSSES TO PERSONS AND PROPERTY WHICH MAY BE
            SUSTAINED IN CONNECTION WITH YOUR MULTIACCOUNTING ACTIVITIES WHICH
            ARE VIOLATIONS OF THESE TERMS OF SERVICE AND APPLICABLE STATE AND
            FEDERAL LAW
          </p>
          <p>
            You are solely responsible for maintaining the confidentiality of
            your account and password and for restricting access to your
            computer, and you agree to accept responsibility for all activities,
            charges, and damages that occur under your account. It shall be a
            violation of these Terms to allow any other person to use your
            account to participate in any Contest. If you discover any
            unauthorized use of your account, or other known account-related
            security breach, you must report it to EliteDuels immediately. You
            agree that you are responsible for anything that happens through
            your account until you close your account or prove that your account
            security was compromised due to no fault of your own.
            <b>
              {" "}
              EliteDuels cannot and will not be liable for any loss or damage
              arising from your failure to comply with this section.
            </b>
          </p>
        </div>
      ),
    },
    {
      id: 7,
      header: "6. Eligibility for Contests.",
      text: (
        <div>
          <p>
            Generally, you may have access to the Site in any state, territory,
            or jurisdiction. However, in order to enter Contests, you must be
            physically located within one of the states, territories, and/or
            jurisdictions in which EliteDuels operates and is made accessible
            for consumer play (collectively, “Eligible Jurisdictions,” and each
            an “Eligible Jurisdiction”). A full list of Eligible Jurisdictions,
            which may be updated from time to time at EliteDuels’ sole
            discretion, can be found at{" "}
            <a href="https://eliteduels.gg/">www.EliteDuels.gg</a> “Excluded
            Jurisdiction(s)” shall mean all states, territories, and/ or
            jurisdictions that are not Eligible Jurisdictions.
          </p>
          <p>
            You must also be at least eighteen (18) years of age to participate
            in Contests or win prizes offered through EliteDuels Contests. You
            are responsible for consulting each Eligible Jurisdiction’s rules to
            determine the minimum age of eligibility and abide by those
            restrictions.
          </p>
          <div>
            <p>
              By depositing money or entering a Contest, you are representing
              and warranting that:
              <ul>
                <li>
                  you are 18 years of age or older (or the minimum age for
                  permissible use in your local jurisdiction);
                </li>
                <li>
                  you are a citizen or resident of Canada, the United States of
                  America and any Eligible Jurisdictions and that you have an
                  address in the Eligible Jurisdiction;
                </li>
                <li>
                  at the time of deposit or game entry, you are physically
                  located in an Eligible Jurisdiction in which participation in
                  the Contest is not prohibited by applicable law or these
                  Terms;
                </li>
                <li>
                  you are not listed on any government list of prohibited or
                  restricted parties;
                </li>
                <li>
                  you will abide at all times by these Terms and any other
                  agreements between you and EliteDuels regarding your use of
                  the Service or participation in games;
                </li>
                <li>
                  when depositing funds or entering a paid Contest, you are not
                  physically located in any Excluded Jurisdictions; and
                </li>
                <li>
                  you are not subject to backup withholding tax because: (a) you
                  are exempt from backup withholding, or (b) you have not been
                  notified by the Internal Revenue Service (IRS) that you are
                  subject to backup withholding as a result of a failure to
                  report all interest or dividends, or (c) the IRS has notified
                  youthat you are no longer subject to backup withholding.
                </li>
              </ul>
            </p>
            <p>
              If EliteDuels determines that you do not meet the eligibility
              requirements of this section, then you are not authorized to use
              the Service. EliteDuels may require you to provide proof that you
              are eligible to participate according to this section prior to
              receiving a prize. This includes by requesting that you fill out
              an affidavit of eligibility or other verification information. If
              EliteDuels otherwise determines that you do not meet the
              eligibility requirements of this section, in addition to any
              rights that EliteDuels may have in law or equity, EliteDuels
              reserves the right to terminateyour account, withhold or revoke
              the awarding of any prizes associated with your account or limit
              your ability to withdraw. In such a situation, EliteDuels may pay
              out any withheld or revoked prizes to the other entrants in the
              relevant Contest in a manner consistent with the prize structure
              of the Contest, to be precisely determined by EliteDuels in its
              sole discretion. EliteDuels also reserves the right to withhold
              revoked prizes to use in furtherance of its fraud prevention or
              anti-money laundering efforts.
            </p>
            <p>
              We also may conduct investigations for Terms compliance, including
              anti-fraud checks on playing patterns and deposits prior to
              processing a withdrawal, and we may request additional information
              before permitting a withdrawal. As long as there are no pending
              investigations on your Account, you may close your account and
              withdraw your deposits and/or winnings at any time and for any
              reason.
            </p>
          </div>
        </div>
      ),
    },
    {
      id: 8,
      header: "7. Contests.",
      text: (
        <div>
          <p>
            After you create an account, you will be able to visit the Site and
            view the games available for entry (“Contests”). While some Contests
            may be free to enter, certain Contests on the Site will require an
            entry fee.It is your responsibility to know and understand the rules
            and required entry fees for any given Contest. Rules for contests,
            which may be updated from time to time at EliteDuels’ sole
            discretion, may be found here.
          </p>
          <p>
            Please note that you must meet all eligibility requirements and
            otherwise comply with any other applicable rules to be eligible to
            win a prize. In particular, due to restrictions in certain states,
            territories, and/or jurisdictions, residents of the Excluded
            Jurisdictions are not eligible to participate in our paid entry
            Contests.
          </p>
          <p>
            <b>Entry Fee</b> <br />
            Entry fees may vary from Contest to Contest. When you choose to
            enter a Contest and complete the entry process, the requisite entry
            fee will be debited from your EliteDuels account. All payments are
            final. No refunds will be issued.
          </p>
          <p>
            <b>Conditions for Entry</b> <br /> To be eligible to enter any
            Contest or receive any prize, you may be required to provide
            EliteDuels with additional documentation and/or information to
            verify your identity as the account holder, and to provide proof
            that all eligibility requirements are met. In the event of a dispute
            as to the identity or eligibility of an account holder, EliteDuels
            will, in its sole and absolute discretion, utilize certain
            information collected by EliteDuels to assist in verifying the
            identity and/or eligibility of such account holder.
          </p>
          <p>
            In the event of a dispute regarding the identity of the person
            submitting an entry, the entry will be deemed submitted by the
            person named on the profile of the username the entry was submitted,
            or if possession of the username itself is contested and in
            EliteDuels’ opinion sufficiently uncertain, the name in which the
            email address on file was registered with the email service
            provider. EliteDuels reserves the right not to award a prize to an
            individual it believes in its sole discretion did not submit the
            winning entry.
          </p>
          <p>
            Any EliteDuels employee (“<b> Employee</b>”) and{" "}
            <b>Immediate Family Members</b>
            (an “Immediate Family Member” means any domestic partner and
            relative of the Employee who resides at an Employee’s residence,
            including but not limited to parents, grandparents, in-laws,
            children, siblings, and spouses) are not permitted to play in any
            Contest that requires an entry fee.
          </p>
          <p>
            By entering a Contest, entrants agree to be bound by these rules and
            the decisions of EliteDuels, which shall be final and binding in all
            respects. If the entrant engages in conduct or otherwise utilizes
            any information EliteDuels deems to be improper, unfair or otherwise
            adverse to the operation of the Contest or is in any way detrimental
            to other entrants, EliteDuels at its sole discretion may disqualify
            any entrant from a Contest, refuse to award benefits or prizes, and
            require the return of any prizes.
          </p>
          <p>These Terms prohibit entering a Contest if the entrant is:</p>
          <ul>
            <li>
              A EliteDuels Employee or an Immediate Family Member of an Employee
            </li>
            <li>
              An employee or operator of any daily fantasy site including any
              that charges entrance fees or offers prizes, and any immediate
              family member of any such person;
            </li>
            <li>
              Accessing or has had access to any pre-release, confidential
              information or other information that is not available to all
              other entrants of a Contest and that provides the entrant an
              advantage in such a Contest, including any information from any
              daily fantasy sport site or information from a sports governing
              body (“<b>Insider Data</b>”);
            </li>
            <li>
              An employee of a sponsor, consultant, or supplier of EliteDuels or
              any other daily fantasy sports Contest provider that has access to
              Insider Data or otherwise receives an advantage in the entrant’s
              participation in a Contest;
            </li>
            <li>
              An employee, operator or consultant to a sports governing body
              where such employee, operator or consultant is prohibited from
              participating in applicable Contests by such governing body;
            </li>
            <li>
              A professional or collegiate athlete, sports agent, coach, team
              owner, team employee, referee or league official or employee, or
              an immediate family member of a professional or amateur athlete,
              sports agent, coach, team owner, team employee, referee or league
              official or employee if the Contest is in the sport in which they
              are associated; or
            </li>
            <li>
              A person prohibited from participating pursuant to court order.
            </li>
          </ul>
          <p>
            Users further acknowledge that the forfeiture and/or return of any
            prize shall in no way prevent EliteDuels from pursuing criminal or
            civil proceedings in connection with such conduct.
          </p>
          <p>
            <b>Contest of Skill – Paid Entry Contests</b> <br /> Contests
            offered through the Services are Contests of skill. Each of our
            Contests are governed by specific rules, as may be modified from
            time to time, which are set forth in the Contest official rules and
            are herein incorporated into these Terms by reference. Winners are
            determined by the objective criteria described in the Contest
            deadline, roster, rules, scoring, and any other applicable
            documentation associated with the Contest. From all entries received
            for each Contest, winners are determined by the individuals who use
            their skill and knowledge of relevant sports information and fantasy
            sports rules to accumulate the most points according to the
            corresponding scoring rules. The Services may not be used for any
            form of illicit or illegal gambling or sports betting.
          </p>
          <p>
            <b>Contest Results</b> <br /> Prizes will only be awarded if a
            Contest is run. We reserve the right to cancel Contests at any time.
            In the event of a cancellation, all entry fees will be refunded to
            the customer except as specifically provided in these Terms. After
            each Contest ends, the winners are announced (generally by the
            following day) but remain subject to final verification.
          </p>
          <p>
            Once winners are notified and prizes are awarded, the scoring
            results will not be changed regardless of any official adjustments
            made by the professional leagues, though we reserve the right to
            make adjustments based on errors or irregularities in the
            transmission of information to us from our stats provider or in our
            calculation of results.
          </p>
          <p>
            <b>Prizes</b> <br /> Contest prize payouts will be published with
            the creation of each new Contest (“Contest Prize Table”). Prizes
            will be awarded as soon as reasonably practicable following the
            conclusion of each Contest. The prizes available for each Contest
            are published in the respective Contest Prize Table.
          </p>
          <p>
            No substitution or transfer of prize is permitted except at our sole
            discretion. All prizes are awarded AS IS and WITHOUT WARRANTY OF ANY
            KIND, express or implied, (including, without limitation, any
            implied warranty of merchantability or fitness for a particular
            purpose) by us. If any legal authority challenges the award and/or
            your receipt of a prize for winning a Contest, EliteDuels reserves
            the right in its sole discretion to revoke, cancel,suspend,
            substitute, or modify the award of such prize. In all disputes
            arising out of the determination of the winner of EliteDuels
            Contests, EliteDuels is the sole judge and its actions are final and
            binding.
          </p>
          <p>
            You acknowledge and agree that should we, in our sole and absolute
            discretion, determine that you did not comply with and or adhere to
            the Terms, in any way, we may disqualify you from any Contest
            entered, in which case any prizes awarded to you as a result of said
            Contest will be immediately forfeited. You agree to cooperate with
            our efforts to reverse payments.
          </p>
          <p>
            All monetary winnings will be deposited directly into your Account.
            Any amounts that are mistakenly credited as winnings to your Account
            remain our property and will automatically be transferred from your
            Account upon confirmation of the error. Any winnings mistakenly
            credited to your Account that have been withdrawn by you before
            confirmation of the error will constitute a debt owed by you.
          </p>
          <p>
            <b>
              Contest Statistics, Live Scoring and Third Party Information
              Providers
            </b>{" "}
            <br /> To the extent that EliteDuels offers “live” statistics before
            or during gameplay and/or Contest entry, all “live” statistics,
            scoring and other information provided through EliteDuels and
            related information sources are unofficial. Further, during certain
            Contests, EliteDuels may be reliant on third party operators to
            provide “live scoring” of certain sporting events and matches. At
            times, you may not be able to see or access the most up-to-date
            information or “live scoring” for the relevant sporting event or
            match. Regardless of the reason for the delay in information,
            EliteDuels shall not be liable for any such delay in provision of
            information or “live scoring.” “Live scoring,” information,
            statistics and their respective components are offered for
            informational and/or entertainment purposes only and are not used to
            determine the results of Contests. Neither EliteDuels nor its
            third-party providers warrant or make any representations of any
            kind with respect to the information provided through the App and/or
            Site and related information sources. EliteDuels and its third party
            providers shall not be responsible or liable for the accuracy,
            usefulness, or availability of any information transmitted or made
            available via the Site and related information sources, shall not be
            responsible or liable for any error or omissions in that
            information, nor shall EliteDuels be responsible for any errors or
            losses you suffer or any other issues that result therefrom.
          </p>
          <p>
            <b>Stat Corrections</b> <br /> EliteDuels takes every effort to
            ensure accurate, up-to-date scoring is applied across all Contests.
            However, EliteDuels does not guarantee the accuracy of any scoring
            or stat corrections. Official scores and results will be posted at
            the conclusion of the sporting event or competition once it has been
            declared official and all official box scores and statistics are
            posted. Stat corrections posted by either EliteDuels’ third-party
            data provider or the official league statistic provider more than
            thirty (30) minutes following the official conclusion of the
            sporting event or competition will not be retroactively applied to
            entries and not be taken into consideration for determining how to
            score the Contest.
          </p>
          <p>
            <b>Full Rules</b> <br /> In instances where there may be a direct
            conflict between these Terms and our “Full Rules” (located on the
            Platform’s “Player Picks” page), the Full Rules control.
          </p>
        </div>
      ),
    },
    {
      id: 9,
      header: "8. Contest Disqualification and Cancellation.",
      text: (
        <div>
          <p>
            Once your entry is submitted you may only cancel (1) within five (5)
            mins of submission, if no players have started, entry is $5.00 or
            greater and it's not a Second Half (2H) or Fourth Quarter (Q4)
            entry; or (2) one player in the entry is marked as Do Not Play
            (DNP), no players have started, entry is $5.00 or greater and it's
            not a 2H or Q4 entry.
          </p>
          <p>
            <b>
              NO REFUND All payments are final. No refunds will be issued. In
              the event of a dispute regarding the identity of the person
              submitting an entry, the entry will be deemed submitted by the
              person in whose name the account was registered.
            </b>
          </p>
          <p>
            <b>Disqualification and Cancellation by EliteDuels </b> <br />{" "}
            Participation in each Contest must be made only as specified in the
            Terms. Failure to comply with these Terms will result in
            disqualification and, if applicable, prize forfeiture.
          </p>
          <p>
            EliteDuels, in its sole discretion, may disqualify you from a
            Contest or the entire Service, refuse to award fantasy points or
            prizes and require the return of any prizes, or suspend, limit, or
            terminate your account if you engage in conduct EliteDuels deems, in
            its sole discretion, to be improper, unfair, fraudulent or otherwise
            adverse to the operation of the Service or in any way detrimental to
            other users. Improper conduct includes, but is not limited to:
            falsifying personal information, including payment information,
            required to use the Service or claim a prize;violating eligible
            payment method terms, including the terms of any cash rewards
            payment card, violating any of these rules, accumulating points or
            prizes through unauthorized methods such as unauthorized scripts or
            other automated means; tampering with the administration of the
            Service or trying to in any way tamper with the computer programs
            associated with the Service; obtaining other entrants’ information
            and spamming other entrants; and abusing the Service in any way; or
            otherwise violating these Terms. You acknowledge that the forfeiture
            and/or return of any prize shall in no way prevent EliteDuels from
            informing the relevant authorities, and/ or pursuing criminal or
            civil proceedings in connection with such conduct.
          </p>
          <p>
            We reserve the right, in our sole discretion, to cancel or suspend
            any Contest (or any portion thereof) for any reason whatsoever,
            including but not limited to, the safeguarding of the
            administration, security, fairness, integrity or proper operation of
            the Contest (or any portion thereof). This section specifically
            includes when a Contest Prize Table has been tampered with. We may
            provide you with notification of such cancellations or suspensions,
            but will not be obliged to do so. In the event that a Contest is
            cancelled altogether, no Contest entry fee will be payable by you
            and any amounts that were to be used for the purpose of entering the
            Contest will once again be made available to you via your account.
          </p>
        </div>
      ),
    },
    {
      id: 10,
      header: "9. Deposits and Withdrawals.",
      text: (
        <div>
          <p>
            <b>Deposits</b> <br /> To participate in Contests, you will be
            required to deposit funds into your Account (“Deposit”) by any of
            the methods permitted on the Services. Such funds will be deposited
            into your Account upon actual receipt of the funds by EliteDuels.
            Minimum and maximum limits may be applied to deposits into your
            Account, depending upon your history with the Services, the method
            of deposit, state, territory, and/or jurisdiction-imposed deposit
            limits, and other factors as determined solely by EliteDuels. We are
            not a bank and funds are not insured by any governmental agency. No
            interest is payable on amounts on deposit in your Account. All
            payments into your Account must be from a payment source on which
            you are the named account holder. It shall be a violation of these
            Terms for you to submit payment using any payment method that you
            are not the named account holder. In addition, to be eligible to
            deposit money into your Account and play in Contests that award
            prizes, you must not be subject to backup withholding tax from the
            IRS or other government authority.
          </p>
          <p>
            By depositing funds or entering paid Contests, you agree to provide
            us with a valid mailing address, date of birth, and any other
            information we may require in order to run appropriate identity
            checks and comply with applicable rules and regulations. If
            necessary, you may be required to provide appropriate documentation
            that allows us to verify you. While your account is pending
            verification, you may be able to deposit funds into your account and
            participate in Contests, but you will not be able to withdraw any
            funds from your account until verification is complete. If we are
            unable to verify you, we reserve the right to suspend your account
            and withhold any funds until such time as we have been able to
            successfully verify you.
          </p>
          <p>
            We also may conduct checks for Terms compliance, including
            anti-fraud checks on playing patterns and deposits prior to
            processing a withdrawal, and we may request additional information
            before permitting a withdrawal. If EliteDuels requests that an
            entrant completes and executes such an affidavit and the entrant
            fails to do so within ten (10) days, or EliteDuels otherwise
            determines that the entrant does not meet the eligibility
            requirements or is not in compliance with these Terms, EliteDuels
            reserves the right to terminate the entrant’s account and withhold
            or revoke the awarding of any prizes associated with such account.
            In such a situation, EliteDuels may pay out any withheld orrevoked
            prizes to the other entrants in the relevant Contest in a manner
            consistent with the rules of the Contest.
          </p>
          <p>
            <b>Charge Backs</b> 
            <br /> If any deposit is charged back, any winnings generated from
            EliteDuels Contests shall be invalidated, forfeited and deducted
            from your Account balance. In addition, the amount of the initial
            deposit will be invalidated, forfeited and deducted from the Account
            balance. In the event that, following such deductions, your Account
            balance is in a negative balance, you expressly acknowledge and
            agree that such negative balance shall constitute an uncontestable
            debt payable by you to us, due and payable immediately. EliteDuels
            reserves the right to close your account – without notice – should a
            deposit be charged back.
          </p>
          <p>
            <b>Credit Card Removal </b> <br /> Users will have two months from
            the date Users create their account to provide a preferred method of
            Deposit via their Account. Users may request that a previously
            provided method of payment is removed from their account no more
            than once (1) in any calendar month, and no more than five (5) times
            over any twelve-month span. Additionally, EliteDuels may remove any
            method of Deposit at any time at EliteDuels’ sole discretion and
            determination.
          </p>
          <p>
            <b>Account for Deposited Funds</b> <br /> When you make a deposit,
            or earn winnings, your deposits and winnings after Contests are
            completed and held in a separate, segregated account (the
            “Segregated Account”) held by Company. Vendors, players, and
            creditors shall be aware that the funds in the Segregated Account do
            not belong to Company and are not available to creditors of the
            Company. These funds belong to you, subject to review for evidence
            of fraud, verification or other prohibited conduct as described
            above, and EliteDuels may not use them to cover its operating
            expenses or for other purposes.
          </p>
          <p>
            Players who believe that funds held by or their accounts with
            EliteDuels have been misallocated, compromised or otherwise
            mishandled, may register a complaint with EliteDuels online by
            e-mailing support@eliteduels.com. EliteDuels shall use its best
            efforts to respond to such complaints within ten (10) days. If
            EliteDuels determines that the relief requested in the complaint
            will not be granted, its response will set forth the specific
            reasons therefore. If more information is required for the Company
            to process the complaint, the response will note the form and nature
            of the necessary additional information needed.
          </p>
          <p>
            <b>Bonuses and Promotions </b> <br /> From time to time, EliteDuels
            may provide you with promotional discounts or bonuses – for example,
            as anincentive to use the Services, to establish a EliteDuels
            Account, or to refer others to sign up with EliteDuels. Such
            promotional programs may be governed by their own terms and
            conditions which will be presented at the time of such promotions.
            Unless otherwise provided, promotional discounts are
            non-transferrable.
          </p>
          <p>
            Any bonuses or promotions that you do not apply to a Contest (i.e.
            use) within ninety (90) days of receipt of the bonuses or promotions
            shall automatically terminate and become null and void, at
            EliteDuels’ sole, unlimited discretion. Any existing bonuses or
            promotions you received prior to the Effective Date of these Terms
            shall remain valid for an additional sixty (60) days from the
            Effective Date before such bonuses and promotions shall
            automatically terminate and become null and void.
          </p>
          <p>
            <b>Withdrawals</b> <br /> You may request a withdrawal of funds from
            your available cash balance in your Account at any time. The minimum
            withdrawal is $10.00 U.S. dollars. Account holders with pending
            deposits must wait until those funds clear before requesting a
            withdrawal. Withdrawals shall only be provided via a check payable
            to you, or a PayPal transfer to your account. EliteDuels does not
            permit withdrawals to a bank card or credit card.
          </p>
          <p>
            Entrants may withdraw their cash prize awards as well as cash
            deposits by using the “Withdrawal” option on the Site. Entrants may
            be requested to complete an affidavit of eligibility and a
            liability/publicity release (unless prohibited by law) and/or
            appropriate tax forms and forms of identification as reasonably
            requested by EliteDuels in order to complete the withdrawal of
            prizes. We also may conduct checks for Terms compliance, including
            anti-fraud checks on playing patterns and deposits prior to
            processing a withdrawal, and we may request additional information
            either before permitting a withdrawal or at any point in time after
            a withdrawal if EliteDuels unilaterally determines that any such
            withdrawal(s) potentially violated either these Terms or any
            relevant federal, state, local, or international laws or
            regulations. Failure to comply with this requirement may result in
            disqualification and forfeiture of any prizes as well as all monies
            withdrawn via the EliteDuels Platform in perpetuity.
            Disqualification or forfeiture of any prizes and or seizure of any
            funds previously withdrawn by you may also occur if it is determined
            any such entrant did not comply with these Terms in any manner.
          </p>
          <p>
            Processing of requested funds back may take up to five (5) business
            days; provided, however, EliteDuels reservesthe right to freeze your
            Account and/or delay a request for withdrawal of funds pending
            completion of any investigation of reported or suspected abuse by a
            user requesting withdrawal of funds. If you do not receive your
            withdrawal within five (5) business days, please contact customer
            support. Checks for withdrawal requests are processed within 14
            business days, and are sent via Canada Post.
          </p>
          <p>
            If your Account is closed by us for a violation of the Terms, we
            reserve the right to determine whether to declare as void any
            transaction placed by you. If a prize has been awarded on a closed
            account due to fraud, EliteDuels may withhold the prize, provided
            that the prize is then awarded to another participant in the Contest
            who would have won the prize had that user not participated.
            Further, if you have already withdrawn funds, whether prizes or
            otherwise (e.g. other monies not won via Contests), that were, in
            the sole determination of EliteDuels, the result of a violation of
            these Terms and/or federal, state, local, or international law or
            regulation, then you grant EliteDuels the right to debit the bank
            account to which these funds were deposited without reservation and
            until EliteDuels recoups, in whole, any and all monies that you
            improperly withdrew. In such an event, we will first use your
            Account funds to defray the costs of administration and enforcement
            of the Terms.
          </p>
          <p>
            You shall, at all times, maintain an account at a bank that is a
            member of the Federal Reserve ACH System (the “Bank Account”). You
            expressly authorize us to debit and/or credit the Bank Account
            according to these Terms. You further authorize us, as well as our
            third party payment processors and all related banks, to process
            electronic funds transfers through the Bank Account you designate.
            You represent and warrant that you shall, at all times, maintain a
            sufficient balance in your Bank Account to cover all obligations
            owed to us pursuant to these Terms and further expressly waive all
            rights to dispute any attachment or other debt collection efforts
            undertaken by us and/or our assigns, agents, and partners in
            instances where you do not maintain a sufficient balance in your
            Bank Account to cover all obligations owed to us.{" "}
            <b>
              {" "}
              Further, you expressly agree that we, and/or our third party
              payment processors and related banks, may debit any such Bank
              Account held by, or on, your behalf, in order to satisfy any of
              your obligations to us that arise out of or in any way relate to
              these Terms, the Site, App, or your use of EliteDuels Services,
              including, without limitation, Deposits and Withdrawals. This
              authorization shall survive the termination of these Terms and
              shall continue in perpetuity until all of your obligations to us
              are paid in full, including, but not limited to, those obligations
              described in these Terms.{" "}
            </b>{" "}
            If you wish to revoke your authorization for us to debit the Bank
            Account, you must submit that request, in writing, in the manner
            required for providing notice to EliteDuels as set forth in this
            Agreement, at least 24 hours prior to the origination of any entry
            or transaction permitted by this Agreement. You may not submit any
            such notice revoking authorization for us to debit the Bank Account
            at any point in time after originating the withdrawal.
          </p>
          <p>
            <b>U.S. Dollars </b> <br /> All deposits and withdrawals will be
            denominated in U.S. dollars unless otherwise expressly stated. If
            you make a deposit that is denominated in a currency other than U.S.
            dollars, please be aware that the funds will be converted into U.S.
            dollars by our Payment Processors (defined below) or your financial
            institution and you may be charged a service fee for such
            conversion. Please also be aware that our Payment Processors and/or
            your financial institution may use different conversion rates for
            deposit and refund transactions. The conversion rate used is not
            under our control and we recommend that you contact your financial
            institution directly for more information.
          </p>
          <p>
            <b>Taxation</b> <br /> All taxes associated with the receipt of any
            prize are the sole responsibility of the winner. In the event that
            the awarding of any prizes to winners of Contests is challenged by
            any legal authority, EliteDuels reserves the right in its sole
            discretion to determine whether or not to award such prizes.
          </p>
          <p>
            Each year all winners who have won $600 or more over the previous
            year must provide updated address and social security details to
            EliteDuels. These details will be used to allow EliteDuels to comply
            with tax regulations and may be shared with appropriate tax
            authorities. It is the policy of the Site, and in compliance with
            United States Internal Revenue Service regulations, EliteDuels may
            be required to submit a Form 1099-MISC, Miscellaneous Income (Form
            1099) with the Internal Revenue Service (IRS) or other appropriate
            form to any person who wins in excess of $600 (USD) on the Site
            (winnings less entry fees) in any given year. Depending on the
            jurisdiction in which you reside, EliteDuels may require you to
            complete a Form W-9, Request for Taxpayer Identification Number
            (TIN) and Certification and/or additional tax forms. This
            information will be used to file the Form 1099 with the IRS.
            EliteDuels reserves the right to withhold (from your existing
            account balance and/or from future net winnings) any amount required
            to be withheld by law. You remain solely responsible for filing and
            paying all federal and other taxes in accordance with the laws that
            apply in your state, territory, jurisdiction, province, and/or
            country of residence. Further, it is your sole responsibility to
            provide EliteDuels, upon request, with all personal information
            necessary to submit a Form 1099-MISC. If you fail to provide all
            requested information and therefore prevent EliteDuels from filing
            all pertinent tax forms, including the Form 1099-MISC, then you bear
            all responsibility, indemnify, and hold harmless EliteDuels from any
            resulting liability, lawsuit, judgement, or any other action
            stemming from this failure. EliteDuels does not provide tax advice,
            nor should any statements in this agreement or on the Service be
            construed as tax advice.
          </p>
          <p>
            Third-Party Payment Processor EliteDuels uses third-party electronic
            payment processors and financial institutions (“Payment
            Processor(s)”) to process deposits and/or payments for services or
            products offered via the Services. The information that we provide
            to and receive from these Payment Processors and the manner in which
            such information is used and disclosed is described in further
            detail in the Privacy Policy. You irrevocably authorize us, as
            necessary, to instruct such Payment Processors to handle payments
            and you irrevocably agree that EliteDuels may give such instructions
            on your behalf in accordance with your requests as submitted through
            the Services. You agree to be bound by the terms and conditions of
            each applicable Payment Processor, and in the event of a conflict
            between these Terms and the Payment Processors' terms and
            conditions, then these Terms shall prevail. You further agree that
            EliteDuels is not liable for any loss caused by any unauthorized use
            of your credit card or other method of payment by a third party in
            connection with your use of the Services, except as a result of the
            gross negligence of EliteDuels or its employees.
          </p>
        </div>
      ),
    },
    {
      id: 11,
      header: "10. Indemnification.",
      text: (
        <div>
          <p>
            You agree to indemnify, release and to hold harmless EliteDuels, its
            parents, subsidiaries, affiliates and agents, as well as the
            officers, directors, employees, shareholders and representatives of
            any of the foregoing entities, from any and all liability, claims or
            actions of any kind whatsoever, including but not limited to
            injuries, damages, or losses to persons and property which may be
            sustained in connection with participation in the Contest, the
            receipt, ownership, use or misuse of any prize or while preparing
            for, participating in and/or travelling to or from any prize related
            activity, any claims based on publicity rights, defamation, or
            invasion of privacy, as well as any claims, judgments prosecutions,
            regulatory or administrative actions taken by any local, state or
            federal government as a result of your negligent, reckless or
            intentional use of EliteDuels and its platforms to violate any
            local, state or federal law or regulation. EliteDuels may, in its
            sole and absolute discretion, require the account holder to execute
            a separate release of claims similar to the one listed above in this
            paragraph as a condition of being awarded any prize or receiving any
            payout.
          </p>
        </div>
      ),
    },
    {
      id: 12,
      header: "11. Publicity.",
      text: (
        <div>
          <p>
            By creating an Account, you consent to EliteDuels’ and its service
            providers’ and business partners’ use of your name, image, voice,
            likeness, location, Contest entries and photograph in connection
            with the development, production, distribution and/or exploitation
            (including marketing and promotion) of the selected Contest and/or
            other EliteDuels Contests and EliteDuels generally, unless otherwise
            prohibited by law. EliteDuels and its business partners reserve the
            right to make public statements about the entrants and winner(s),
            on-air, on the Internet, or otherwise, prior to, during, or
            following the Contest. Entrants agree that EliteDuels may announce
            any winner's name on-air or on any of its websites or any other
            location at any time in connection with the marketing and promotion
            of EliteDuels or other Contests or games operated by EliteDuels. You
            agree that participation in and (where applicable) the winning of a
            prize in connection with a Contest constitute complete compensation
            for your obligations under this paragraph, and you agree not to seek
            to charge a fee or impose other conditions on the fulfillment of
            these obligations. The rules specific to certain Contests may
            contain additional publicity obligations or may require a written
            signature on a separate publicity waiver.
          </p>
          <p>
            YOU FURTHER ACKNOWLEDGE THAT IF YOU ARE A WINNER IN A PUBLIC
            CONTEST, YOUR IDENTIFYING INFORMATION MAY BE DISCLOSED TO THIRD
            PARTIES INCLUDING, WITHOUT LIMITATION, HAVING YOUR NAME OR USERNAME
            PLACED ON A WINNERS' LIST.
          </p>
        </div>
      ),
    },
    {
      id: 13,
      header: "12. Availability of the Services.",
      text: (
        <div>
          <p>
            You acknowledge that there may be interruptions in service or events
            that are beyond our control. While we use reasonable efforts to keep
            the Services accessible, the Services may be unavailable from time
            to time for any reason including, without limitation, system down
            time for routine maintenance. You further understand that there may
            be interruptions in service or events on third-party sites that may
            affect your use of the Services and that are beyond our control to
            prevent or correct. Interruptions in the Services that are beyond
            our control shall not serve as a basis to demand a full or partial
            refund of any prepaid fees.
          </p>
          <p>
            EliteDuels may limit access, via technological means, to the
            Services in the Excluded Jurisdictions. If you attempt to deposit
            money into an Account or play in any Contest in which a prize is
            awarded while located in an Excluded Jurisdiction, you will be in
            violation of the law of such Excluded Jurisdiction and these Terms,
            and subject to having your Account suspended or terminated. You
            hereby agree that we cannot be held liable if laws applicable to you
            restrict or prohibit your participation. We make no representations
            or warranties, implicit or explicit, as to your legal right to
            participate in any service offered nor shall any person affiliated,
            or claiming affiliation, with us have authority to make any such
            representations or warranties. We do not intend that the Site or App
            and the services offered thereon to be used by persons are present
            in jurisdictions in which the playing of fantasy sports Contests may
            be prohibited or restricted.
          </p>
        </div>
      ),
    },
    {
      id: 14,
      header: "13. Use of Personally Identifiable Information.",
      text: (
        <div>
          <p>
            You acknowledge that when you download, install, or use the App, or
            access the Services through another device, EliteDuels may use
            automatic means (including, for example, cookies and web beacons) to
            collect information aboutyour devices and about your use of the
            Services. You also may be required to provide personally
            identifiable information about yourself as a condition to
            downloading, installing, or using the Services. All information we
            collect through or in connection with this Services is subject to
            the Privacy Policy located at{" "}
            <a href="https://eliteduels.gg/">www.EliteDuels.gg</a> By
            downloading, installing, using, and providing information to or
            through the Services, you consent to all actions taken by us with
            respect to your information in compliance with the Privacy Policy.
          </p>
          <p>
            You further acknowledge that by providing your cell phone number to
            EliteDuels you consent to receive non- marketing and informational
            text messages from time to time.
          </p>
        </div>
      ),
    },
    {
      id: 15,
      header: "14. Carrier Fees.",
      text: (
        <div>
          <p>
            Use of the Services may involve transmission of data through your
            carrier or service provider's network. You are responsible for all
            carrier, text/SMS, data, or other related fees or charges you incur
            from your carrier or service provider in connection with or related
            to your use of the Services. EliteDuels assumes no liability or
            responsibility for the payment of any charges you may incur.
          </p>
        </div>
      ),
    },
    {
      id: 16,
      header: "15. Updates for the App.",
      text: (
        <div>
          <p>
            EliteDuels may from time to time in its sole discretion develop and
            provide App updates, which may include upgrades, bug fixes, patches,
            other error corrections, and/or new features (collectively,
            including related documentation, “Updates”). Updates may also modify
            or delete in their entirety certain features and functionality. You
            agree that EliteDuels has no obligation to provide any Updates or to
            continue to provide or enable anyparticular features or
            functionality. Based on your Device settings, when your Device is
            connected to the internet either:
          </p>
          <div className={styles.updatesForTheApp}>
            <p>
              (a) the App will automatically download and install all available
              Updates; or
            </p>
            <p>
              (b) you may receive notice of or be prompted to download and
              install available Updates.
            </p>
          </div>
          <p>
            You shall promptly download and install all Updates and acknowledge
            and agree that the App or portions thereof may not properly operate
            should you fail to do so. You further agree that all Updates will be
            deemed part of the App and be subject to all terms and conditions of
            this Agreement.
          </p>
        </div>
      ),
    },
    {
      id: 17,
      header: "16. Rules and Conduct.",
      text: (
        <div>
          <p>
            As a condition of use, you promise not to use the Services for any
            purpose that is prohibited by the Terms or federal, state, local, or
            international laws or regulations. The Services are provided only
            for your own personal, non- commercial use. You are responsible for
            all of your activity in connection with the Services.
          </p>
          <p>
            <b>
              You understand and agree that you will not use the Services to
              engage in the prohibited conduct below:
            </b>
          </p>
          <div className={styles.updatesForTheApp}>
            <p>
              (a) copy the Services, except as expressly permitted by this
              license;
            </p>
            <p>(b) sell or otherwise transfer your Account;</p>
            <p>
              (c) modify, translate, adapt, or otherwise create derivative works
              or improvements, whether or not patentable, of the Services;
            </p>
            <p>
              (d) reverse engineer, disassemble, decompile, decode, or otherwise
              attempt to derive or gain access to the source code of the Site or
              App or any part thereof;
            </p>
            <p>
              (e) remove, delete, alter, or obscure any trademarks or any
              copyright, trademark, patent, or other intellectual property or
              proprietary rights notices from the Site or App, including any
              copy thereof;
            </p>
            <p>
              (f) rent, lease, lend, sell, sublicense, assign, distribute,
              publish, transfer, or otherwise make available the Site or App, or
              any features or functionality of the Site or App, to any third
              party for any reason;
            </p>
            <p>
              (g) remove, disable, circumvent, or otherwise create or implement
              any workaround to any copy protection,rights management, or
              security features in or protecting the Site or App;
            </p>
            <p>
              (h) use your account to impersonate any person, or misrepresent
              your identity or affiliation with anyperson or organization;
            </p>
            <p>
              (i) use the App in any way that violates any applicable federal,
              state, local, or international law or regulation (including,
              without limitation, any laws regarding the export of data or
              software to and from the US or other countries);
            </p>
            <p>
              (j) engage in any other conduct that restricts or inhibits
              anyone’s use or enjoyment of the Site or App, or which, as
              determined by EliteDuels, may harm EliteDuels or users of the Site
              or App or expose them toliability;
            </p>
            <p>
              (k) use the Site or App in any manner that could disable,
              overburden, damage, or impair the Site or App, or interfere with
              any other party’s use of the Site or App, including their ability
              to engage in real time activities through the Site or App;
            </p>{" "}
            <p>
              (l) use any robot, spider, or other automatic device, process, or
              means to access the Site or App for any purpose, including
              monitoring or copying any of the material on the Site or App;
            </p>
            <p>
              (m) introduce any viruses, Trojan horses, worms, logic bombs, or
              other material that is malicious or technologically harmful;
            </p>
            <p>
              (n) attempt to gain unauthorized access to, interfere with,
              damage, or disrupt any parts of the Site or App, the server on
              which any portion of the Site or App is stored, or any server,
              computer, or database connected to the Site or App;
            </p>
            <p>
              (o) attack the Site or App via a denial-of- service attack or a
              distributed denial-of- service attack; or
            </p>
            <p>
              (p) otherwise attempt to interfere with the proper working of the
              Site or App.
            </p>
          </div>
          <p>
            If for any reason, EliteDuels determines that you have failed to
            follow these rules, we reserve the right to prohibit any and all
            current or future use of the Services (or any portion thereof) by
            you. If we have reason to suspect, or learn that anyone is violating
            these Terms, we may investigate and/or take legal action as
            necessary including bringing a lawsuit for damages caused by the
            violation. We reserve the right to investigate and take appropriate
            legal action, including without limitation, cooperating with and
            assisting law enforcement or government agencies in any resulting
            investigations of illegal conduct.
          </p>
          <p>
            ANY ATTEMPT BY AN ENTRANT OR ANY OTHER INDIVIDUAL TO DELIBERATELY
            DAMAGE THE SITE OR APP OR UNDERMINE THE LEGITIMATE OPERATION OF ANY
            CONTEST IS A VIOLATION OF CRIMINAL AND/OR CIVIL LAWS AND SHOULD SUCH
            AN ATTEMPT BE MADE, ELITEDUELS RESERVES THE RIGHT TO SEEK DAMAGES
            AND OTHER REMEDIES FROM ANY SUCH PERSON TO THE FULLEST EXTENT
            PERMITTED BY LAW.
          </p>
        </div>
      ),
    },
    {
      id: 18,
      header: "17. Term and Termination.",
      text: (
        <div>
          <p>
            The term of Agreement commences when you acknowledge your acceptance
            and will continue in effect until terminated by you or EliteDuels as
            set forth in this section (“<b>Term</b>”)
          </p>
          <p>
            You may cancel your account at any time through your account
            settings or by sending us an email at the contact information at the
            bottom of the page. Unless EliteDuels is in breach of this Agreement
            and does not cure saidbreach within thirty (30) days of receiving
            written notice from you of an actual breach, identifying
            specifically the nature of the breach, you are not entitled to any
            refunds.
          </p>
          <p>
            EliteDuels may suspend or cancel your account without notice or
            refund to you if you violate this Agreement. If your account is
            cancelled, EliteDuels reserves the right to remove your account
            information along with any account settings from our servers with no
            liability or notice to you. Once your account information and
            account settings are removed, you will not be able to recover this
            data and you may lose access to all of your Content (except that
            Content stored/published to third-party websites will remain on said
            third-party websites pursuant to those website’s terms and
            conditions).
          </p>
          <p>
            EliteDuels may terminate this Agreement at any time without notice.
            The Agreement shall be terminated immediately if EliteDuels ceases
            to support the Services, which EliteDuels may do in its sole
            discretion.
          </p>
          <p>
            Termination will not limit any of EliteDuels’ rights or remedies at
            law or in equity.
          </p>
          <p>
            Following termination of your account, however, your license to use
            EliteDuels’ Content automatically terminates, and EliteDuels has no
            obligation to provide you with use of the Services. All provisions
            of these Terms that by their nature should survive termination shall
            survive termination, including, without limitation, ownership
            provisions, warranty disclaimers, indemnity, and limitations of
            liability. You acknowledge and understand that our rights regarding
            any User Content (as further defined below) before termination shall
            survive termination.
          </p>
        </div>
      ),
    },
    {
      id: 19,
      header: "18. Advertisements and Third-Party Sites.",
      text: (
        <div>
          <p>
            The Services may contain third party advertisements and/or
            sponsorships. The advertisers and sponsors that provide these
            advertisements or sponsorships are solely responsible for insuring
            that the materials submitted for inclusion on the Services are
            accurate and that they comply with all applicable laws. We are not
            responsible for the acts or omissions of any sponsor or advertiser.
          </p>
          <p>
            Additionally, the Services may permit you to link to other websites
            or resources on the internet. Links on the Services to third party
            websites, if any, are provided only as a convenience to you. If you
            use these links, you will leave the Services. The inclusion or
            integration of third-party services or links does not imply control
            of, endorsement by, or affiliation with EliteDuels. Your dealings
            with third parties are solely between you and such third parties.
            You agree that EliteDuels will not be responsible or liable for any
            content, goods or services provided on or through these outside
            websites or for your use or inability to use such websites. You will
            use these links at your own risk.
          </p>
          <p>
            Without limiting the foregoing, your correspondence or business
            dealings with, participation in promotions of or purchases from,
            third parties found on or through the use of the Services, including
            payment for and delivery of related goods or services, and any other
            terms, conditions, warranties or representations associated with
            such dealings, are solely between you and such third party. You
            agree that EliteDuels shall not be responsible or liable for any
            loss or damage of any sort incurred as the result of any such
            dealings or as the result of the presence of such advertisers on the
            Services.
          </p>
        </div>
      ),
    },
    {
      id: 20,
      header: "19. RESPONSIBLE GAMING",
      text: (
        <div>
          <p>
            <b>
              The Company reserves the right, in its sole discretion, to make
              the determination whether you are interacting with the platform in
              a responsible way in accordance with Company policies and
              procedures. The Company shall also permit the Users to set
              responsible gaming limits and to self-exclude. Notwithstanding the
              foregoing, Company reserves the right, in its sole discretion, to
              impose stricter responsible gaming limits than those imposed by
              the Users and exclude such Users .
            </b>
          </p>
        </div>
      ),
    },
    {
      id: 21,
      header: "20. EliteDuels Intellectual Property and User Content.",
      text: (
        <div>
          <p>
            <b>Intellectual Property Ownership. </b> EliteDuels shall have the
            appropriate license to or shall own all right, title, and interest,
            including all related intellectual property rights, in and to the
            EliteDuels technology, the Services. You agree that EliteDuels shall
            own all right, title, and interest to any suggestions, ideas,
            enhancement requests, feedback, recommendations, or other
            information provided by you to EliteDuels relating to the Services.
            This Agreement is not a sale and does not convey to you any rights
            of ownership in or related to the Services, the EliteDuels
            technology, or the intellectual property rights owned by EliteDuels.
            The EliteDuels name, the EliteDuels logo, and the product names
            associated with the Services are trademarks of EliteDuels or its
            licensors, and no right or licenseis granted to use them.
          </p>
          <p>
            User Content and Copyright Policy. The Services contains areas in
            which you may post or upload user-generated content, comments,
            video, photos, messages, other materials or items (collectively,
            “User Content”). You are solely responsible for your use of any User
            Content you submit.
          </p>
          <p>
            By submitting any User Content, you agree that you will not upload,
            post or otherwise transmit any User Content that (a) violates or
            infringes in any way upon the rights of others, including any
            statements which may defame, harass, stalk or threaten others; (b)
            you know to be false, misleading or inaccurate; (c) contains blatant
            expressions of bigotry, racism, racially or ethnically offensive
            content, hate speech, abusiveness, vulgarity or profanity; (d)
            contains or advocates pornography or sexually explicit content,
            pedophilia, incest, bestiality, or that is otherwise obscene or
            lewd; (e) violates any law or advocates or provides instruction on
            dangerous, illegal, or predatory acts, or discusses illegal
            activities with the intent to commit them; (f) advocates violent
            behavior; (g) poses a reasonable threat to personal or public
            safety; (h) contains violent images of killing or physical abuse
            that appear to have been captured solely, or principally, for
            exploitative, prurient, or gratuitous purposes; (i) is protected by
            copyright, trademark, trade secret, right of publicity or other
            proprietary right without the express permission of the owner of
            such copyright, trademark, trade secret, right of publicity or other
            proprietary right; (j) contains any unsolicited or unauthorized
            advertising or promotional materials with respect to products or
            services, “junk mail”, “spam”, “chain letters”, “pyramid schemes”,
            or any other form of solicitation; or (l) uses the name or likeness
            of an identifiable natural person without such person’s consent.
          </p>
          <p>
            ELITEDUELS RESERVES THE RIGHT TO REMOVE ANY USER CONTENT FOR ANY
            REASON AT ITS SOLE DISCRETION.
          </p>
          <p>
            Reporting Claims of Copyright Infringement. We take claims of
            copyright infringement seriously. We will respond to notices of
            alleged copyright infringement that comply with applicable law. If
            you believe any materials accessible on or from the Services
            infringe your copyright, you may request removal of those materials
            (or access to them) from the Services by submitting written
            notification to our copyright agent designated below. In accordance
            with the Online Copyright Infringement Liability Limitation Act of
            the Digital Millennium Copyright Act (17 U.S.C. § 512) (“<b>DMCA</b>
            ”), the written notice (the “<b>DMCA Notice</b>”) must substantially
            include the following:
          </p>
          <div className={styles.updatesForTheApp}>
            <p>(1) Your physical or electronic signature.</p>
            <p>
              (2) Identification of the copyrighted work you believe to have
              been infringed or, if the claim involvesmultiple works on the
              Services, a representative list of such works.
            </p>

            <p>
              (3) Adequate information by which we can contact you (including
              your name, postal address, telephonenumber, and, if available,
              email address).
            </p>

            <p>
              (4) Identification of the material you believe to be infringing in
              a sufficiently precise manner to allow us tolocate that material.
            </p>

            <p>
              (5) A statement that you have a good faith belief that use of the
              copyrighted material is not authorized bythe copyright owner, its
              agent, or the law.
            </p>

            <p>
              (6) A statement that the information in the written notice is
              accurate.
            </p>
            <p>
              (7) A statement, under penalty of perjury, that you are authorized
              to act on behalf of the copyright owner.
            </p>
          </div>
          <p>
            If you fail to comply with all of the requirements of Section
            512(c)(3) of the DMCA, your DMCA Notice may not be effective. Please
            be aware that if you knowingly materially misrepresent that material
            or activity on the Servicesis infringing your copyright, you may be
            held liable for damages (including costs and attorneys' fees) under
            Section 512(f) of the DMCA.
          </p>
          <p>
            Our designated copyright agent to receive DMCA Notices and
            Counter-Notices (as further defined below) may be contacted at{" "}
            <a
              href="mailto:support@eliteduels.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              support@eliteduels.com
            </a>
          </p>
          <p>
            Counter-Notification Procedures. If you believe that material you
            posted on the Services was removed or access to itwas disabled by
            mistake or misidentification, you may file a counter-notification
            with us (a “Counter-Notice”) by submitting written notification to
            our copyright agent designated below. Pursuant to the DMCA, the
            Counter-Notice must substantially include the following:
          </p>
          <div className={styles.updatesForTheApp}>
            <p>(1) Your physical or electronic signature.</p>
            <p>
              (2) An identification of the material that has been removed or to
              which access has been disabled and thelocation at which the
              material appeared before it was removed or access disabled.
            </p>

            <p>
              (3) Adequate information by which we can contact you (including
              your name, postal address, telephone number, and, if available,
              email address).
            </p>

            <p>
              (4) A statement under penalty of perjury by you that you have a
              good faith belief that the material identified above was removed
              or disabled as a result of a mistake or misidentification of the
              material to be removed or disabled.
            </p>

            <p>
              (5) A statement that you will consent to the jurisdiction of the
              Federal District Court for the judicial district in which your
              address is located (or if you reside outside the United States for
              any judicial district in which the Services may be found) and that
              you will accept service from the person (or an agent of that
              person) who provided the Services with the complaint at issue.
            </p>
          </div>
          <p>
            The DMCA allows us to restore the removed content if the party
            filing the original DMCA Notice does not file a court action against
            you within ten business days of receiving the copy of your
            Counter-Notice. Please be aware that if you knowingly materially
            misrepresent that material or activity on the Services was removed
            or disabled by mistake or misidentification, you may be held liable
            for damages (including costs and attorneys' fees) under Section
            512(f) of the DMCA.
          </p>
          <p>
            It is our policy in appropriate circumstances to disable and/or
            terminate the accounts of users who are repeat infringers.
          </p>
        </div>
      ),
    },
    {
      id: 22,
      header: "21. Warranty Disclaimer.",
      text: (
        <div>
          <p>
            ELITEDUELS DOES NOT WARRANT THAT YOUR ACTIVITIES OR USE OF THE SITE
            OR APP IS LAWFUL IN ANY PARTICULAR JURISDICTION AND, IN ANY EVENT,
            ELITEDUELS SPECIFICALLY DISCLAIMS SUCH WARRANTIES. YOU UNDERSTAND
            THAT BY USING ANY OF THE FEATURES OF THE SITE OR APP, YOU ACT AT
            YOUR OWN RISK, AND YOU REPRESENT AND WARRANT THAT YOUR ACTIVITIES
            ARE LAWFUL IN EVERY JURISDICTION WHERE YOU ACCESS OR USE THE SITE OR
            APP OR THE CONTENT. FURTHER, ELITEDUELS AND ITS PARENTS,
            SUBSIDIARIES, AND AFFILIATES DISCLAIM ANY EXPRESS OR IMPLIED
            WARRANTIES INCLUDING, WITHOUT LIMITATION, NONINFRINGEMENT,
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND TITLE.
          </p>
          <p>
            YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES AND THE
            MATERIALS CONTAINED HEREIN ARE PROVIDED ON AN “AS IS” AND “AS
            AVAILABLE” BASIS. EXCEPT AS OTHERWISE EXPRESSLY PROVIDED IN THESE
            TERMS, ELITEDUELS AND ITS AFFILIATED COMPANIES AND THEIR RESPECTIVE
            OFFICERS, DIRECTORS, EMPLOYEES, AND OTHER REPRESENTATIVES EXPRESSLY
            DISCLAIM ALL WARRANTIES OF ANY KIND PERTAINING TO THE SERVICES AND
            THE MATERIALS HEREIN, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
            LIMITED TO, THE IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY, SECURITY,
            ACCURACY, AVAILABILITY, USE OF REASONABLE CARE AND SKILL, AND
            NON-INFRINGEMENT, AS WELL AS WARRANTIES ARISING BY USAGE OF TRADE,
            COURSE OF DEALING, AND COURSE OF PERFORMANCE. ELITEDUELS MAKES NO
            WARRANTY THAT (I) THE SERVICES WILL MEET YOUR REQUIREMENTS, (II) THE
            SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE OR
            FREE OF VIRUSES OR BUGS, (III) THE RESULTS THAT MAY BE OBTAINED FROM
            THE USE OF THE SERVICES WILL BE ACCURATE OR RELIABLE, AND (IV) ANY
            ERRORS IN OR ON THE SERVICES WILL BE CORRECTED. ANY MATERIAL,
            CONTENT, OR INFORMATION DOWNLOADED OR OTHERWISE OBTAINED AND/OR USED
            THROUGH THE SERVICES IS DONE AT YOUR OWN DISCRETION AND RISK AND YOU
            WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR
            LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL,
            CONTENT OR INFORMATION. NO ADVICE OR INFORMATION, WHETHER ORAL OR
            WRITTEN, OBTAINED BY YOU FROM ELITEDUELS ON OR THROUGH THE SERVICES
            SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THESE TERMS AND
            CONDITIONS.
          </p>
        </div>
      ),
    },
    {
      id: 23,
      header: "22. Limitation of Liability.",
      text: (
        <div>
          <p>
            EXCEPT AS OTHERWISE SPECIFICALLY PROVIDED IN NO EVENT SHALL
            ELITEDUELS, NOR ITS DIRECTORS, EMPLOYEES, AGENTS, PARTNERS,
            SUPPLIERS, OR CONTENT PROVIDERS, BE LIABLE UNDER CONTRACT, TORT,
            STRICT LIABILITY, NEGLIGENCE, OR ANY OTHER LEGAL OR EQUITABLE THEORY
            WITH RESPECT TO THE SERVICE (I) FOR ANY LOST PROFITS, DATA LOSS,
            COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR SPECIAL,
            INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND
            WHATSOEVER, SUBSTITUTE GOODS OR SERVICES (HOWEVER ARISING), (II) FOR
            ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE (REGARDLESS OF THE
            SOURCE OF ORIGINATION), OR (III) FOR ANY DIRECT DAMAGES IN EXCESS OF
            (IN THE AGGREGATE) FIVE THOUSAND U.S. DOLLARS ($5,000.00). SOME
            STATES OR COUNTRIES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
            INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS AND
            EXCLUSIONS MAY NOT APPLY TO YOU. IN THESE JURISDICTIONS, ELITEDUELS’
            LIABILITY WILL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
          </p>
        </div>
      ),
    },
    {
      id: 24,
      header: "23. Dispute Resolution, Arbitration, and Class Action Waiver.",
      text: (
        <div>
          <p>
            PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT
            YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT
          </p>
          <p>
            <b>Informal Resolution </b> <br /> It is EliteDuels’ goal that the
            Services meet your expectations and live up to our promises to you.
            However, there may be instances when you feel that EliteDuels has
            not fulfilled its obligations or you may have a different type of
            problem or dispute that needs special attention. In those instances,
            EliteDuels is committed to working with you to reach a reasonable
            resolution that satisfies you; however, we can only do this if we
            know about and understand your issue. Therefore, for any problem or
            dispute that you may have with EliteDuels, you acknowledge and agree
            that you will first give EliteDuels an opportunity to resolve your
            problem or dispute. To initiate the dispute resolution process, you
            must first send us a written description of your problem or dispute
            within thirty (30) days of the Services at issue (the “
            <b>Informal Resolution Request</b>”) by sending an email to
            support@eliteduels.com
          </p>
          <p>
            You further agree to negotiate with EliteDuels in good faith about
            your problem or dispute. While EliteDuels’ is hopeful this process
            will lead to resolution, this informal resolution process shall last
            no more than sixty (60) days from the date the Informal Resolution
            Request was received by EliteDuels. If, in EliteDuels’ sole
            discretion, it determines that no informal resolution is reached
            during this sixty (60) day window, then EliteDuels and you agree to
            the arbitration provisions below.
          </p>
          <p>
            BINDING ARBITRATION Arbitration Proceeding. Any dispute, claim or
            controversy arising out of or relating to this Agreement or the
            breach, termination, enforcement, interpretation or validity
            thereof, including the determination of the scope or applicability
            of these Agreement to arbitrate, shall be determined by arbitration.
            The arbitration shall be administered by JAMS pursuant to its
            Comprehensive Arbitration Rules and Procedures and in accordance
            with the Expedited Procedures in those Rules. Judgment on the Award
            must be entered in Ontario, Canada. ANY ARBITRATION UNDER THESE
            TERMS SHALL TAKE PLACE ON AN INDIVIDUAL BASIS. CLASS ACTION AND
            CLASS ARBITRATIONS ARE NOT PERMITTED. This clause shall not preclude
            parties from seeking provisional remedies in aid of arbitration from
            a court of appropriate jurisdiction.
          </p>
          <p>Location. All arbitrations shall take place in Ontario, Canada.</p>
          <p>
            Class Action Waiver. You further agree that any arbitration shall be
            conducted in your individual capacity only and not as a class action
            or other representative action, and you expressly waive your right
            to file a class action or seek relief on a class basis. YOU AND
            ELITEDUELS AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY
            IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS
            MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. YOU
            UNDERSTAND THAT BY AGREEING TO THESE TERMS, YOU WAIVE YOUR RIGHT TO
            PARTICIPATE IN CLASS ACTIONS. If any court or arbitrator determines
            that the class action waiver set forth in this paragraph is void or
            unenforceable for any reason or that an arbitration can proceed on a
            class basis, then the arbitration provision set forth above shall be
            deemed null and void in its entirety and the parties shall be deemed
            to have not agreed to arbitrate disputes.
          </p>
          <p>
            Exceptions to Arbitration. You and EliteDuels agree that the
            following claims are not subject to the above provisions concerning
            negotiations and binding arbitration: (a) any claim seeking to
            enforce or protect, or concerning the validity of, any of
            EliteDuels’ intellectual property rights; (b) any claim related to,
            or arising from, allegations of theft, fraud, misrepresentation,
            piracy, invasion of privacy or unauthorized use; and (c) any claim
            for EliteDuels’ equitable relief. In addition to the foregoing,
            EliteDuels may assert an individual action in small claims court for
            claims that are within the scope of such court’s jurisdiction in
            lieu of arbitration.
          </p>
          <p>
            30-Day Right to Opt-Out. You have the right to opt-out and not be
            bound by the arbitration and class action waiver provisions set
            forth above by sending written notice of your decision to opt-out to
            the address listed below. The notice must be sent within 30 days of
            your first use of the Service, otherwise you shall be bound to
            arbitrate disputes in accordance with the terms of those paragraphs.
            If you opt-out of these arbitration provisions, EliteDuels also will
            not be bound by them.
          </p>
        </div>
      ),
    },
    {
      id: 25,
      header: "24. Assignment; Change in Control.",
      text: (
        <div>
          You may not assign this Agreement in whole or in part, for any reason.
          This Agreement will be binding upon and will inure to the benefit of
          the parties and their heirs, executors, administrators, successors,
          and assigns. EliteDuels may assign this Agreement or delegate any of
          its rights or obligations hereunder, or any part thereof, to any third
          party, including its successor in interest, without requiring your
          written consent.
        </div>
      ),
    },
    {
      id: 26,
      header: "25. Entire Agreement; Severability.",
      text: (
        <div>
          <p>
            These Terms and other referenced material constitute the entire
            agreement between you and EliteDuels with respect to the Services,
            and supersede all prior or contemporaneous agreements,
            representations, warranties, and understandings (whether oral,
            written or electronic) between you and EliteDuels with respect to
            the Services and govern the future relationship. If a court in any
            final, unappealable proceeding holds any provision of these Terms or
            its application to any person or circumstance invalid, illegal or
            unenforceable, the remainder of these Terms, shallnot be affected,
            and shall be valid, legal and enforceable to the fullest extent
            permitted by law.
          </p>
        </div>
      ),
    },
    {
      id: 27,
      header: "26. Geographic Limits of Service.",
      text: (
        <div>
          <p>
            EliteDuels make no representation that materials contained on the
            Services or products described or offered are appropriate or
            available for use in jurisdictions outside the Eligible
            Jurisdictions or that these Terms comply with the laws of any other
            country other than the United States and Canada. Accessing and
            participating in the Services is prohibited from territories where
            the Content and participation is illegal. If you access the Services
            from other locations, you do so at your own initiative and are
            responsible for compliance with local laws. You agree that you will
            not access the Services from any jurisdiction where the Content and
            participation are illegal, and that you, and not EliteDuels, are
            responsible for compliance with applicable local laws.
          </p>
          <p>
            EliteDuels reserves the right, at any time in our sole discretion,
            to limit the availability and accessibility of the Services to any
            person, geographic area, or jurisdiction we so desire.
          </p>
        </div>
      ),
    },
    {
      id: 28,
      header: "27. Governing Law.",
      text: (
        <div>
          <p>
            These Terms (and any further rules, policies, or guidelines
            incorporated by reference) shall be governed by and construed in
            accordance with the laws of the province of Ontario and Canada,
            without giving effect to any principles of conflicts of law, and
            without application of the Uniform Computer Information Transaction
            Act or the United Nations Convention of Controls for International
            Sale of Goods.
          </p>
          <p>
            You, regardless of location or country of residence, whether within
            or outside of the United States or Canada, agree that EliteDuels and
            its Services are deemed passive and do not give rise to personal
            jurisdiction over EliteDuels or its parents, subsidiaries,
            affiliates, successors, assigns, employees, agents, directors,
            officers, or shareholders, either specific or general, in any
            jurisdiction other than the Province of Ontario. You agree that any
            action at law or in equity arising out of or relating to these
            Terms, or your use or non-use of the Services, shall be filed only
            in the provincia courts located in Ontario and you hereby consent
            and submit to the personal jurisdiction of such courts for the
            purposes of litigating any such action. You irrevocably waive any
            right you may have to trial by jury in any dispute, action, or
            proceeding.
          </p>
        </div>
      ),
    },
    {
      id: 29,
      header: "28. Limitation of Time to File Claims.",
      text: (
        <div>
          <p>
            ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
            TO THIS AGREEMENT OR THE SERVICES MUST BE COMMENCED WITHIN ONE (1)
            YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE SUCH CAUSE OF
            ACTION OR CLAIM IS PERMANENTLY BARRED.
          </p>
        </div>
      ),
    },
    {
      id: 30,
      header: "29. No Third-Party Beneficiaries.",
      text: (
        <div>
          <p>
            You agree that, except as otherwise expressly provided in these
            Terms, there shall be no third-party beneficiaries to the Terms.
          </p>
        </div>
      ),
    },
    {
      id: 31,
      header: "30. No Waiver.",
      text: (
        <div>
          <p>
            No failure to exercise, and no delay in exercising, on the part of
            either party, any right or any power hereunder shall operate as a
            waiver thereof, nor shall any single or partial exercise of any
            right or power hereunder preclude further exercise of that or any
            other right hereunder. In the event of a conflict between this
            Agreement and any applicable purchase or other terms, the terms of
            this Agreement shall govern.
          </p>
        </div>
      ),
    },
    {
      id: 32,
      header: "31. Force Majeure",
      text: (
        <div>
          <p>
            The failure of EliteDuels to comply with any provision of these
            Terms due to an act of God, hurricane, war, fire, riot, earthquake,
            terrorism, pandemic, act of public enemies, actions of governmental
            authorities outside of the control of Company (excepting compliance
            with applicable codes and regulations) or other force majeure event
            will not be considered a breach of these Terms
          </p>
        </div>
      ),
    },
    {
      id: 33,
      header: "32. Notice Policy and Your Consent.",
      text: (
        <div>
          <p>
            Under these Terms you are contracting with Company. For the
            avoidance of doubt, we are entering into this Agreement as principal
            and not as agent for any other EliteDuels company. Subject to any
            permitted assignment, the obligations owed by us under this
            Agreement shall be owed to you solely by us and the obligations owed
            by you under this Agreement shall be owed solely to us.
          </p>
          <p>
            EliteDuels may give notice by means of a general notice on the
            Services, electronic mail to your e-mail address on record in
            EliteDuels’ account information, or by written communication sent by
            first class mail or pre-paid post to your address on record in
            EliteDuels’ account information. Such notice shall be deemed to have
            been given upon the expiration of 48 hours after mailing or posting
            (if sent by first class mail or pre-paid post) or 12 hours after
            sending (if sent by email). Except as otherwise provided in this
            Agreement, you may give notice to EliteDuels (such notice shall be
            deemed given when received by EliteDuels) at any time by any of the
            following: letter delivered by nationally recognized overnight
            delivery service or first-class postage prepaid mail to EliteDuels
            at the addresses listed below.
          </p>
        </div>
      ),
    },
    {
      id: 34,
      header: "Contact.",
      text: (
        <div>
          <p>
            If you have any questions regarding these Terms, please contact us
            at support@eliteduels.com or by mail at the address below:
          </p>
          <div className={styles.updatesForTheApp}>
            <p>EliteDuels Inc.</p>
            <p>393 Rymal Road</p>
            <p>Suite 405</p>
            <p>Hamilton, Ontario, Canada</p>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="container-wrapper">
      <PageHeading heading={t("d29512458")} subheading={t("d29083902")} />
      <div className={styles.wrapper}>
        {/* <p className={styles.header}>{t('d31693085')}</p> */}
        <p className={styles.header}> EliteDuels Terms of Service</p>
        <p className={styles.header}>
          PLEASE READ THESE TERMS CAREFULLY BEFORE USING THIS SITE
        </p>
        {termsText?.map((item) => (
          <div key={item?.id}>
            <h1>{item?.header}</h1>
            {item?.text}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TermsOfUse;
