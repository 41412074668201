import React, { useState, useCallback, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import PageHeading from "../../_components/pageHeading/pageHeading";
import Selector from "../../_components/selector/selector";
import CardNew from "../../_components/card/cardNew";
import CustomPagination from "../../_components/pagination/serverPagination";
import Tabs from "../../_components/tabs/tabs";
import Loading from "../../_components/Loading";
import { mainActions } from "../../_store/actions/main.actions";
import { imageActions } from "../../_store/actions/image.actions";
import storageService from "../../_services/storage.service";
import "./userEvents.scss";
import { LIVE, UPCOMING, HISTORY } from "../../_utils/constants";
import mainService from "../../_services/main.service";
import box from "../../_assets/images/icons/box.svg";
import Slides from "../../_components/slides/slides";
import LiveContests from "../../_components/liveContests/liveContests";
import UserPlayerProps from "../../_components/userPlayerProps/userPlayerProps";
import { ReactComponent as ArrowIcon } from "../../_assets/images/icons/whiteRightArrow.svg";

import { getPipeline } from "../../_utils";

const DEFAULT_PAGE_SIZE = 4;
const CANCEL_SUCCESS_MSG = "Successfully canceled";

const getCarouselIdsFrom = (contests) => {
  const ids = [];
  if (contests && Array.isArray(contests)) {
    contests.forEach((contest) => {
      const { carosel } = contest;
      if (carosel && Array.isArray(carosel)) {
        ids.push(...carosel);
      }
    });
  }
  return ids;
};

const UserEvents = (props) => {
  const [t] = useTranslation();
  const tabs = {
    tab1: LIVE,
    tab2: UPCOMING,
    tab3: HISTORY,
  };

  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageNumberPlayerProps, setPageNumberPlayerProps] = useState(1);
  const [contests, setContests] = useState(
    storageService.getData("data", `userContest${tabs.tab1}`, "all")
  );
  const [total, setTotal] = useState(0);
  const [totalPlayerProps, setTotalPlayerProps] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingPlayerProps, setLoadingPlayerProps] = useState(true);
  const [activeTab, setActiveTab] = useState(
    props.history.location &&
      props.history.location.hash &&
      props.history.location.hash === "#upcoming"
      ? tabs.tab2
      : tabs.tab1
  );
  const [games, setGames] = useState([]);
  const [selected, setSelected] = useState();
  const [searchText, setSearchText] = useState("");
  const [userPlayerPropsData, setUserPlayerPropsData] = useState([]);
  const [reload, setReload] = useState(false);
  let timeout, timeoutSelect;

  const getGames = async () => {
    const gamesArray = await mainService.run("data", "game", "all", {
      projections: { name: 1, _id: 0 },
    });
    const nameArray = [{ value: 0, label: t("d50773805") }];
    for (const element of gamesArray) {
      if (element.name) {
        nameArray.push({ value: element.name, label: element.name });
      }
    }
    setGames(nameArray);
  };

  useEffect(() => {
    if (games && games.length === 0) {
      getGames();
    }
  }, []);

  // useEffect(() => {
  //   getData();
  // }, [searchText, selected]);
  const getData = async () => {
    setLoading(true);
    const filter = [];

    // let query = {}
    const now = new Date().getTime();
    if (selected && selected.value && selected.value !== 0) {
      filter.push({ game: selected.value });
    }
    switch (activeTab) {
      case LIVE:
        filter.push({ from: { $lt: now }, to: { $gt: now }, won: {$exists: false} });
        break;
      case UPCOMING:
        filter.push({ from: { $gt: now }, won: {$exists: false} });
        break;
      case HISTORY:
        filter.push({$or: [{ to: { $lt: now } }, {won: {$exists: true}}]});
        break;
    }
    filter.push({ userId: store.auth.user.id });
    if (searchText) {
      filter.push({ name: { $regex: `${searchText}`, $options: "i" } });
    }
    try {
      /*const result = await dispatch(mainActions.agg('userContest', 'data', 'userContest', 'page', getPipeline('userEvents', {
        userId: store.auth.user.id,
        query
      }), null, true, {pageNumber, pageSize}));
      setContests(result.rows)
      setTotal(result.total);*/

      // COMMENTED THIS LINE BECAUSE OF THE INFINITE SCROLL
      // setContests(null);
      // setTotal(null);
      fetchData(filter);
    } catch (err) {}
    // setLoading(false)
  };

  const fetchData = async (filter) => {
    // const result = await dispatch(
    //   mainActions.run(
    //     "data",
    //     "userContest",
    //     "userContests",
    //     {
    //       pageNumber,
    //       pageSize,
    //       projections: { contests: 0 },
    //       sort: { from: -1 },
    //       filter,
    //     },
    //     null,
    //     true
    //   )
    // );
    setTotal(0);
    setContests((prev) => {
      return [...prev];
    });
    setLoading(false);
  };

  const fetchUserPlayerProps = async () => {
    const filter = [];

    // let query = {}
    const now = new Date().getTime();
    if (selected && selected.value && selected.value !== 0) {
      filter.push({ game: selected.value });
    }
    switch (activeTab) {
      case LIVE:
        filter.push({
          from: { $lt: now },
          canceled: { $exists: false },
          finished: {$ne: true}
        });
        break;
      case UPCOMING:
        filter.push({ from: { $gt: now }, canceled: { $exists: false }, won: {$exists: false} , finished: {$exists: false}});
        break;
      case HISTORY:
        filter.push({ $or: [{ canceled: true }, {finished: true} ] });
        break;
    }
    filter.push({ userId: store.auth.user.id });
    if (searchText) {
      filter.push({ name: { $regex: `${searchText}`, $options: "i" } });
    }
  
    setLoadingPlayerProps(true);
    const userPlayerProps = await dispatch(
      mainActions.run(
        "data",
        "userPlayerProps",
        "page",
        {
          pageNumber: pageNumberPlayerProps,
          pageSize: DEFAULT_PAGE_SIZE,
          projections: { contests: 0 },
          sort: { from: -1 },
          filter,
        },
        null,
        true
      )
    );

    setUserPlayerPropsData((prev) => [...prev, ...userPlayerProps.rows]);
    setTotalPlayerProps(userPlayerProps.total);
    setLoadingPlayerProps(false);
    if (reload) setReload(false);
  };

  useEffect(() => {
    getData(null);
  }, [activeTab, pageNumber, pageSize]);

  useEffect(() => {
    fetchUserPlayerProps();
  }, [activeTab, pageNumberPlayerProps, reload]);

  useEffect(() => {
    setTotal(null);
    setPageNumber(1);
    setPageNumberPlayerProps(1);
    setTotalPlayerProps(null);
    setUserPlayerPropsData([]);
    setContests([]);
  }, [activeTab]);

  const onPageChange = useCallback((page, pageSize) => {
    setPageNumber(page);
    setPageSize(pageSize);
  }, []);

  const changeTab = useCallback((type) => {
    setActiveTab(type);
    setPageNumber(1);
  }, []);

  const getGameBg = useCallback((gameImg, width, height) => {
    dispatch(imageActions.getImage(gameImg, width, height));
  }, []);

  const handleSelectChange = async (select) => {
    await handleSelect(select);
  };

  const handleSelect = async (select) => {
    clearTimeout(timeoutSelect);
    timeoutSelect = setTimeout(() => {
      setSelected(select);
    }, 1000);
  };

  const handleChange = async (e) => {
    e.preventDefault();
    await handleSearchChange(e.target.value);
  };

  const handleSearchChange = async (search) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setSearchText(search);
    }, 1000);
  };

  const handleCancelPlayerProps = async (data) => {
    try {
      await dispatch(
        mainActions.run(
          "data",
          "userPlayerProps",
          "cancel",
          {
            id: data.id,
            playerPropsId: data.playerPropsId,
            name: data.name,
          },
          CANCEL_SUCCESS_MSG
        )
      );
      setReload(true);
      setUserPlayerPropsData([]);
      dispatch(
        mainActions.agg(
          "wallet",
          "transactions",
          "user",
          "get",
          getPipeline("wallet", store?.auth?.user?.id),
          store?.auth?.user?.id
        )
      );
    } catch (err) {}
  };
  const contestObserver = useRef();
  const lastContestCardDivElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (contestObserver.current) contestObserver.current.disconnect();
      const hasMore = contests.length < total;
      contestObserver.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore) {
            setPageNumber((prev) => prev + 1);
          }
        },
        { threshold: 0.5 }
      );
      if (node) contestObserver.current.observe(node);
    },
    [loading, contests.length, pageNumber]
  );

  const playerPropsObserver = useRef();
  const lastPlayerPropsCarddDivElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (playerPropsObserver.current) playerPropsObserver.current.disconnect();
      const hasMore = userPlayerPropsData.length < totalPlayerProps;
      playerPropsObserver.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore) {
            setPageNumberPlayerProps((prev) => prev + 1);
          }
        },
        { threshold: 0.5 }
      );
      if (node) playerPropsObserver.current.observe(node);
    },
    [loading, userPlayerPropsData.length, pageNumberPlayerProps]
  );

  return (
    <div className="global-layout-content">
 {/* <section className="layout-content-left-banner"> */}
        {/* button don't need now */}
        {/* <span className="navigation-btn" onClick={() => history.push("/loby")}>
          <ArrowIcon className="arrow-icon" /> {t("d23525167")}
        </span> */}
        <div className="layout-content-left-banner">
          <Slides position="Side" show={true} />
        </div>
      {/* </section> */}
    <div className="layout-content landing-page"> 
      {/* <SearchBar onChange={handleInputText} value={searchText} onClick={handleSearchSubmit} /> */}
      <div className="layout-content-center">
        <section className="navigation-wrapper">
          <nav className="nav-btn-wrapper">
            {/* button don't need now */}
            {/* <span
              className="navigation-btn"
              onClick={() => history.push("/loby")}
            >
              <ArrowIcon className="arrow-icon" /> {t("d23525167")}
            </span> */}
          </nav>
          <nav className="nav-btn-wrapper">
            {/* button don't need now */}
            {/* <span
              className="navigation-btn"
              onClick={() => history.push("/leaderboard")}
            >
              {t("d2282565")}
              <ArrowIcon className="arrow-icon-right" />
            </span> */}
          </nav>
        </section>
        <div className="top">
          <div className="block">
            <div style={{ height: "20px" }}></div>
            <PageHeading
              className={"headingMyContest"}
              heading={t("d95895288")}
              subheading={t("d95895288")}
            />
            <div style={{ height: "70px" }}>
              <div className="tabs" style={{ width: "100%" }}>
                <Tabs tabs={tabs} activeTab={activeTab} changeTab={changeTab} />
              </div>
            </div>
          </div>
        </div>
        <div className="bottom">
          {/* <div className={"search"}>
            {games.length > 0 && <div style={{ width: "30%", float: "left" }}>
              <Selector
                selectClassname="selectSm"
                options={games}
                handleChangeSelect={handleSelectChange}
                placeholder={t('d465729339')}
                value={selected} />
            </div>}
            {activeTab === HISTORY && <div className={"content"}>
              <div className={"searchIcon"}>
                <i className="fa fa-search" aria-hidden="true"></i>
              </div>
              <div className={"searchText"}>
                <input type="text" className={"input"} onChange={handleChange} placeholder={t("d601280868")} />
              </div>
            </div>}
          </div> */}
          <div className="live-player-props">
            <div className="player-props-wrapper">
              <header className="player-props-header">
                <hr />
                <span>{t(activeTab)} Player Picks</span>
                <hr />
              </header>
              <div className="player-props">
                {loadingPlayerProps ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      Loading...
                      <i
                        className="fa fa-spinner fa-spin"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </div>
                ) : null}
                {userPlayerPropsData && userPlayerPropsData.length > 0 ? (
                  userPlayerPropsData.map((playerProps, index) => {
                    if (!playerProps || !playerProps.id) return null;
                    if (userPlayerPropsData.length - 1 === index) {
                      return (
                        <UserPlayerProps
                          key={playerProps.id}
                          ref={lastPlayerPropsCarddDivElementRef}
                          playerProps={playerProps}
                          activeTab={activeTab}
                          handleCancelPlayerProps={handleCancelPlayerProps}
                        />
                      );
                    }
                    return (
                      <UserPlayerProps
                        key={playerProps.id}
                        playerProps={playerProps}
                        activeTab={activeTab}
                        handleCancelPlayerProps={handleCancelPlayerProps}
                      />
                    );
                  })
                ) : (
                  <div className="message-info">
                    <img src={box} alt="box"></img>
                    <span>{t("d58155026")}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>  
    </div>
    {/* <section className="right-side-banner-wrapper"> */}
        {/* button don't need now */}
        {/* <span
          className="navigation-btn"
          onClick={() => history.push("/wallet")}
        >
          {t("d21581168")}
          <ArrowIcon className="arrow-icon-right" />
        </span> */}
        <div className="layout-content-right-banner">
          <Slides position="Side" show={true} />
        </div>
      {/* </section> */}
    </div>
  );
};
export default UserEvents;
