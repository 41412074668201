import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import './selector.scss';

const Selector = ({ styles, selectClassname, selectedOption, handleChangeSelect, options, placeholder, label, multiselect = false, additionalClass, isDisabled }) => {
  const selectorClass = selectClassname.split('\'').join('');
  const selectorAdditionalClass = additionalClass ? additionalClass.split('\'').join('') : '';
  return (
    <div className={`${selectorAdditionalClass}`}>
      {label && <label className={`selectLabel ${selectorClass}`}> {label} </label>}
      <Select
        styles={styles}
        isMulti={multiselect}
        className={selectorClass}
        classNamePrefix={selectorClass}
        value={selectedOption}
        defaultValue={selectedOption}
        onChange={handleChangeSelect}
        options={options}
        isDisabled={isDisabled}
        placeholder={placeholder}
      />
    </div>
  );
};

Selector.propTypes = {
  selectClassname: PropTypes.string,
  handleChangeSelect: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  multiselect: PropTypes.bool
};

export default Selector;
