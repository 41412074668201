import { countriesConstants } from '../constants';
import { sessionService } from '../../_sessionService/storage';
import countriesService from '../../_services/countries.service';

const get = (mode, data, query) => {
  const request = () => { return { type: countriesConstants.COUNTRIES_REQUEST }; };
  const success = (countries) => { return { type: countriesConstants.COUNTRIES_SUCCESS, countries }; };
  const failure = (error) => { return { type: countriesConstants.COUNTRIES_FAILURE, error }; };
  return dispatch => {
    if (mode === 'get') {
      dispatch(request());
      const fromStorage = sessionService.get('countries');
      if (fromStorage) {
        dispatch(success(fromStorage));
      }
      countriesService.run(mode, data, query)
        .then(
          res => {
            dispatch(success(res));
            sessionService.set('countries', res);
          },
          error => {
            dispatch(failure(error));
          }
        );
    }
  };
};

export const countriesActions = {
  get
};
