import React from "react";
import { useTranslation } from "react-i18next";
import Selector from "../../../../_components/selector/selector";
import phoneImage from "./../../../../_assets/images/phoneImage.svg";

const Step2 = ({
  CountrySelectStyles,
  countries,
  handleChangeSelect,
  error,
  user,
  contryObjectEmpty,
  ProvinceSelectStyles,
  provinces,
  handleChangeSelectProv,
  countryProvinces,
  handleChangeSelectMonth,
  months,
  days,
  years,
  handleChangeSelectDay,
  handleChangeSelectYear,
  emptyMonth,
  emptyDay,
  emptyYear,
  isOlder,
}) => {
  const [t] = useTranslation();

  return (
    <div className="step1-body">
      <div className="form-info-step2">
        <h1>{t("d92207518")}</h1>
        <div className="countryDiv">
          {/* <span className="birthday_label">{t('d92207518')}</span> */}
          <Selector
            styles={CountrySelectStyles}
            selectClassname="selectLg"
            options={countries}
            handleChangeSelect={handleChangeSelect}
            placeholder={t("d8538260")}
            selectedOption={countries.find(
              (obj) => obj.label === user.country.name
            )}
          />
          {error && contryObjectEmpty && (
            <div className="input-err-msg">{t("d39726178")}</div>
          )}
          <Selector
            styles={ProvinceSelectStyles}
            selectedOption={
              provinces.find((obj) => obj.label === user.province) || ""
            }
            selectClassname="selectLg"
            options={provinces}
            handleChangeSelect={handleChangeSelectProv}
            placeholder={t("Province")}
          />
          {error && countryProvinces && !user?.province && (
            <div className="input-err-msg">Province is required</div>
          )}

          <div className="date-wrapper-step2">
            <div className="date-wrapper-selector">
              <Selector
                selectClassname="selectLg"
                options={months}
                handleChangeSelect={handleChangeSelectMonth}
                placeholder={t("d493738")}
                selectedOption={months.find((obj) => obj.label === user.month)}
              />
              {error && emptyMonth && (
                <div className="input-err-msg">{t("d40106885")}</div>
              )}
            </div>

            <div className="date-wrapper-selector">
              <Selector
                selectClassname="selectLg"
                options={years}
                handleChangeSelect={handleChangeSelectYear}
                placeholder={t("d422325")}
                selectedOption={years.find((obj) => obj.label === user.year)}
              />
              {(error && emptyYear && (
                <div className="input-err-msg">{t("d41615781")}</div>
              )) ||
                (error && !isOlder && (
                  <div className="input-err-msg">
                    {"You must have 18 years"}
                  </div>
                ))}
            </div>

            <div className="date-wrapper-selector">
              <Selector
                selectClassname="selectLg"
                options={days}
                handleChangeSelect={handleChangeSelectDay}
                placeholder={t("d86256703")}
                selectedOption={days.find((obj) => obj.label === user.day)}
              />
              {error && emptyDay && (
                <div className="input-err-msg">{t("d41343041")}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="imageDivStep2">
        <h1>Daily Fantasy made Easy</h1>
        <h4>Sign up now to claim your limited time offer!</h4>
        <img src={phoneImage} />
      </div>
    </div>
  );
};

export default Step2;
