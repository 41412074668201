import React, { useState } from 'react';
import mainService from '../../../_services/main.service';
import styles from './userPlayerPicks.module.scss';
import { useTranslation } from 'react-i18next';
import DatePickerComp from '../../../_components/datePicker/datePickerComp';
import Input from '../../../_components/input/input';
import Button from '../../../_components/button/button';
import moment from 'moment';
import { HISTORY } from "../../../_utils/constants";
import UserPlayerProps from "../../../_components/userPlayerProps/userPlayerProps";

const UserPlayerPicks = () => {
  const [result, setResult] = useState([]);
  const [from, setFrom] = useState(moment().startOf('month').toDate());
  const [to, setTo] = useState(moment().endOf('month').toDate());
  const [text, setText] = useState('');
  const [t] = useTranslation();

  const setDate = (type, date) => {
    if (type === 'from'){
      setFrom(moment(date).startOf('day').toDate())
    } else if (type === 'to'){
      setTo(moment(date).endOf('day').toDate())
    }
  }

  const handleTextChange = (e) => {
    e.preventDefault()
    const { value = '' } = e.target;
    setText(value.trim())
  }

  const getData = async (id) => {
    const filter = { from: { $gte: from.getTime(), $lte: to.getTime() } }
    if (text && text.trim()) {
      filter['$or'] = [{ username: {'$regex' : `^${text.trim()}`, '$options' : 'i'} }, { email: {'$regex' : `^${text.trim()}`, '$options' : 'i'} }]
    }
    const data = await mainService.run('data', 'userPlayerProps', 'all', { filter } );
    setResult(data)
  }

  const renderPlayerProps = () => {
    return result.map(one => one && one.id && <UserPlayerProps
        playerProps={one}
        activeTab={HISTORY}
        handleCancelPlayerProps={()=> {
        }}
        showUsername={true}
      />
    )
  }

  return (
    <div className={styles.wrapperManagement}>
      <DatePickerComp startDate={from} onChange={(date) => setDate('from', date)} label='From Date' noMinDate={true} hideTime={true}/>
      <DatePickerComp startDate={to} onChange={(date) => setDate('to', date)} label='To Date' noMinDate={true} hideTime={true}/>
      <Input type='text' onChange={handleTextChange} value={text} placeholder={t('Enter username or email')} label={t('Search text')}/>
      <Button btnClass='btnFormGreen' label={'Load'} onClick={() => { getData() }} />
      <div>
        {renderPlayerProps()}
      </div>
    </div>);
};

export default UserPlayerPicks;