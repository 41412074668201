export const base64toBlob = (base64) => {
  // Split the base64 string in data and contentType
  const block = base64.split(";");
  // Get the content type of the image
  let contentType = block[0].split(":")[1];// In this case "image/gif"
  // get the real base64 content of the file
  const base64Data = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."
  contentType = contentType || '';
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
};
