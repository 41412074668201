import React from "react";
import { useTranslation } from "react-i18next";
import Input from "../../../../_components/input/input";
import phoneImage from "./../../../../_assets/images/phoneImage.svg";

const Step1 = ({
  user,
  handleChange,
  error,
  credentialError,
  isValidEmail
}) => {
  const [t] = useTranslation();

  return (
    <div className="step1-body">
      <div className="form-info">
        <h1>{t("d26582354")}</h1>
        <Input
          name="username"
          value={user.username}
          onChange={handleChange}
          placeholder={t("d344071578")}
          autofocus={true}
        />
        {error && !user.username && (
          <div className="input-err-msg">{t("d37417709")}</div>
        )}
        <Input
          name="email"
          value={user.email}
          onChange={handleChange}
          placeholder={t("d44003993")}
        />
        {error && !user.email && (
          <div className="input-err-msg">{t("d37661831")}</div>
        )}
        {error && !isValidEmail && <div className="input-err-msg">{"Not valid email"}</div>}
        {credentialError && user.username && user.email && (
          <div className="input-err-msg">{"Username or Email already exists"}</div>
        )}

        <Input
          name="password"
          type="password"
          value={user.password}
          onChange={handleChange}
          placeholder={t("d344129542")}
        />
        {error && !user.password && (
          <div className="input-err-msg">{t("d3794363")}</div>
        )}

        <Input
          name="confirmPassword"
          type="password"
          value={user.confirmPassword}
          onChange={handleChange}
          placeholder={t("d85062793")}
        />
        {error && !user.confirmPassword && (
          <div className="input-err-msg">{t("d38218003")}</div>
        )}
        {error && user.confirmPassword !== user.password && (
          <div className="input-err-msg">{t("d38453969")}</div>
        )}
      </div>
      <div className="imageDiv">
        <h1>Daily Fantasy made Easy</h1>
        <h4>Sign up now to claim your limited time offer!</h4>
        <img src={phoneImage} />
      </div>
    </div>
  );
};

export default Step1;
