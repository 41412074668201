
export const imageConstants = {
  IMG_STORAGE: 'IMG_FROM_STORAGE',
  IMG_REQUEST: 'IMG_REQUEST',
  IMG_SUCCESS: 'IMG_SUCCESS',
  IMG_FAILURE: 'IMG_FAILURE',

  IMG_UPLOAD_REQUEST: 'IMG_UPLOAD_REQUEST',
  IMG_UPLOAD_SUCCESS: 'IMG_UPLOAD_SUCCESS',
  IMG_UPLOAD_FAILURE: 'IMG_UPLOAD_FAILURE',

  IMG_DELETE_REQUEST: 'IMG_DELETE_REQUEST',
  IMG_DELETE_SUCCESS: 'IMG_DELETE_SUCCESS',
  IMG_DELETE_FAILURE: 'IMG_DELETE_FAILURE',
};
