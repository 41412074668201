import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import toastService from './toastService';
import avatarDefault from '../_assets/images/player.png';
import { sessionService } from '../_sessionService/storage';
import { imageActions } from '../_store/actions/image.actions';

const MAIN_MESSAGE_TIME = 800
const TEXT_MESSAGE_TIME = 800

const ToastGreenWithTitle = ({ text, style = 'green' }) => {
  return (
    <div className={"toast-with-title-"+style}>
      <h1>{text}</h1>
    </div>
  );
};

const MainNotif = ({ player, action, image }) => {
  return <div className="toast-with-images">
    <div>
      <div className="img-box">
        <img src={image} />
      </div>
      <h4>{player.nick}</h4>
    </div>
  </div>;
};

const ToastWithImages = ({ messages, data }) => {
  const [text, setText] = useState('');
  const [style, setStyle] = useState('');
  const [image, setImage] = useState(sessionService.getImg(`${data.player.id}_profileAvatar`) || avatarDefault)
  const dispatch = useDispatch();

  const getImg = async (name) => {
    const img = await dispatch(imageActions.getImage(name, 400, 400))
    if (img) {
      setImage(img)
    }
  }

  useEffect(() => {
    if (!image) getImg(`${data.player.id}_profileAvatar`)
  }, [data])

  const setNextMessage = (delay = 0) => {
    if (messages && messages.length > 0) {
      setTimeout(() => {
        const message = messages.shift();
        setText(message.text);
        setStyle(message.style);
        setNextMessage(message.time);
      }, TEXT_MESSAGE_TIME + delay);
    }
  };

  useEffect(() => {
    let delay;
    if (data && Object.keys(data).length > 0) delay = MAIN_MESSAGE_TIME;
    else delay = -TEXT_MESSAGE_TIME;
    setNextMessage(delay);
  }, []);

  return (text ? <ToastGreenWithTitle text={text} style={style} /> : <MainNotif {...data} image={image} />);
};

export const showGameNotification = ({ type, actions }, team, t) => {
  if (type === 'game') {
    actions.forEach(action => {
      const { player, points, ...data } = action
      if (player && team.indexOf(player.id) > -1) {
        const action = Object.keys(data)[0]
        const style = points > 0 ? 'green' : 'red'
        const pointsText = (points > 0 ? '+' : '') + points + ' ' + t('d58871498')
        const messages = [{ text: t(action), style }, { text: pointsText, style, time: 400 }]
        toastService.show('info', <ToastWithImages messages={messages} data={{ player }}/>, {
          className: 'toast-dark',
          autoClose: 2800
        });
      }
    })

  }
};
