import { profileConstants } from '../constants';

const initialState = {
  loading: false,
  data: {},
  list: []
};

export const profile = (state = initialState, { type, mode, result, error }) => {
  const newState = { ...state };
  switch (type) {
    case profileConstants.PROFILE_REQUEST:
      return { ...state, loading: true };
    case profileConstants.PROFILE_SUCCESS:
      if (Array.isArray(result)) {
        newState.list = result;
      } else {
        // newState.list[result.id] = result;
        newState.data = result;
      }
      return { ...newState, loading: false };
    case profileConstants.PROFILE_FAILURE:
      return { ...state, loading: false, error };
    default:
      return newState;
  }
  // return newState;
};
