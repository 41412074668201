import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { url, env } from "../../environment";
import { ReactComponent as Twitch } from "../../_assets/images/social/twitchIcon.svg";

import styles from "./iframe.module.scss";

const TWITCH_PLAYER = "https://player.twitch.tv/?channel=";

const getStreamUrl = (stramUrl) => {
  // prepearing parent for the twich in expected format:
  // <iframe src="https://player.twitch.tv/?channel=esl_csgo&parent=www.example.com" ></iframe>
  const prepareParent = url?.split("//")[1]?.split(":")[0];
  const prepareChanel = stramUrl?.split("//")[1]?.split("/")[1] || "";
  return `${TWITCH_PLAYER}${prepareChanel}&parent=${prepareParent}`;
};

const Iframe = forwardRef(({ streamUrl = "", isFromContestPage }, ref) => {
  if (!streamUrl)
    return (
      <div className={styles.noLink}>
        <Twitch width="100" height="100" />
      </div>
    );
  const style = isFromContestPage ? { borderRadius: "0.5rem" } : {};
  return (
    <>
      <iframe
        ref={ref}
        className={styles.responsiveIfreme}
        style={style}
        src={getStreamUrl(streamUrl)}
        frameBorder="0"
        allowFullScreen={true}
        scrolling="no"
      />
    </>
  );
});

export default Iframe;

PropTypes.propTypes = {
  url: PropTypes.string.isRequired,
};
