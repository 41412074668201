import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import logoSm from '../../_assets/images/logo/logo-sm.svg';
import toggleIcon from '../../_assets/images/icons/navbar/toggleBtn_icon.svg';
import './navbar.scss';

const ToggleMobNav = ({ toggleLinkNav }) => {
  return (
    <div className="toggle-container container-fluid-wrapper">
      <div className="toggle-items container-wrapper">
        <NavLink to="/" ><img src={logoSm} alt="EliteDuels" /></NavLink>
        <img src={toggleIcon} alt="EliteDuels" onClick={toggleLinkNav} />
      </div>
    </div>
  );
};

ToggleMobNav.propTypes = {
  toggleLinkNav: PropTypes.func
};

export default ToggleMobNav;
