import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './lineupElement.module.scss';
import PropTypes from 'prop-types';
// import crownImg from '../../_assets/images/icons/crown.svg';
import redXImg from '../../_assets/images/icons/close6710.svg';
import accounting from 'accounting-js';
import avatarDefault from '../../_assets/images/player.png';
import PlayerDisplay from '../playerDisplay/playerDisplay';
import { modalActions } from '../../_store/actions/modal.actions';
import { useDispatch } from 'react-redux';
const LineupElement = ({ element, remove, contestType, contest }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const openContestInfo = (data) => {
    dispatch(modalActions.openModal(<PlayerDisplay data={data} />, 'Player modal', 'md'));
  };
  return (
    <div className={ contest && contest.boosted && Array.isArray(contest.boosted) && contest.boosted.includes(element.player.id) ? styles.containerGreen : styles.container} >
      <div className={styles.wrapper}>
        {contestType === 'd09094712' ? <div className={styles.tier}>
          <p>T {+element.tier + 1}</p>
        </div> : null}
        {contestType === 'd12792751' ? <div className={styles.tier}>
          {element.tier == '0' ? <h5>C</h5> : <h5></h5>}
        </div> : null}
        <div className={styles.image}>
          {/* <img src={element.player.avatar ? element.player.avatar : avatarDefault} alt="profile-img" onClick={() => openContestInfo(element.player)} /> */}
          <div className={styles.hoverOptions}>
            {/* <div className={styles.captain}>
              <img src={crownImg} alt="crown" />
            </div> */}
            {contest && (contest.from > new Date().getTime()) && <div className={styles.delete} onClick={() => { remove(element); }}>
              <img src={redXImg} alt="delete" />
            </div>}
          </div>
        </div>
        <div className={styles.info}>
          <h5 onClick={() => openContestInfo(element.player)}> {element.player.nick} </h5>
        </div>
      </div>
    </div>
  );
};

LineupElement.propTypes = {
  element: PropTypes.object,
  remove: PropTypes.func
};

export default LineupElement;
