import React from 'react';
import styles from './about.module.scss';
import PageHeading from '../../../_components/pageHeading/pageHeading';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player'
import { ReactComponent as MatchIcon } from '../../../_assets/images/icons/match.svg';
import { ReactComponent as PlayerIcon } from '../../../_assets/images/icons/player.svg';
import { ReactComponent as EntriesIcon } from '../../../_assets/images/icons/entriesIcon.svg';
import { ReactComponent as DollarIcon } from '../../../_assets/images/icons/dollar.svg';
import { ReactComponent as FacebookIcon } from '../../../_assets/images/social/facebookIcon.svg';
import { ReactComponent as InstagramIcon } from '../../../_assets/images/social/instagramIcon.svg';
import { ReactComponent as TwitterIcon } from '../../../_assets/images/social/twitterIcon.svg';
import { ReactComponent as YoutubeIcon } from '../../../_assets/images/social/youtubeIcon.svg';
import { ReactComponent as PodcastIcon } from '../../../_assets/images/social/podcast.svg';

const About = () => {
    const [t] = useTranslation();
    return (
        <div className="container-wrapper">
            <PageHeading heading={t('d70287708')} subheading={t('d9043195')} />
            <div className={styles.wrapper}>
                <div className={styles.info}>
                    <div className={styles.aboutTxt}>
                        <h1>{t('d70418237')} {t('d71415999')}</h1>
                        <p>{t('d72523849')}</p>
                    </div>
                    <div className={styles.video}>
                        <ReactPlayer url="https://www.youtube.com/watch?v=GHkeT0L_xqM&feature=emb_title" />
                    </div>
                </div>
                <div className={styles.playersInfo}>
                    <h4>{t('d76514046')}</h4>
                    <div className={styles.images}>
                        <div className={styles.singleImg}>
                            <EntriesIcon />
                            <p>{t('d84205978')}</p>
                        </div>
                        <div className={styles.singleImg}>
                            <PlayerIcon />
                            <p>{t('d84632641')}</p>
                        </div>
                        <div className={styles.singleImg}>
                            <MatchIcon />
                            <p>{t('d88252122')}</p>
                        </div>
                        <div className={styles.singleImg}>
                            <DollarIcon />
                            <p>$30,000+ {t('d95292865')}</p>
                        </div>
                    </div>
                </div>

                <div className={styles.socialsInfo}>
                    <h4>{t('d96365924')}</h4>
                    <div className={styles.images}>
                        <a href="https://www.facebook.com/eliteduels/" target="_blank" rel="noopener noreferrer" className={styles.singleImg}>
                            <FacebookIcon />
                            <p>{t('d43692576')}</p>
                        </a>
                        <a href="https://www.instagram.com/eliteduels/" target="_blank" rel="noopener noreferrer" className={styles.singleImg}>
                            <InstagramIcon />
                            <p>{t('d99708831')}</p>
                        </a>
                        <a href="https://twitter.com/eliteduels" target="_blank" rel="noopener noreferrer" className={styles.singleImg}>
                            <TwitterIcon />
                            <p>{t('d43681799')}</p>
                        </a>
                        <a href="https://www.youtube.com/channel/UCyzi4bxkqDzaseeByxt9dxg" target="_blank" rel="noopener noreferrer" className={styles.singleImg}>
                            <YoutubeIcon />
                            <p>{t('d00154335')}</p>
                        </a>
                        <a href="http://www.buzzsprout.com/270537" target="_blank" rel="noopener noreferrer" className={styles.singleImg}>
                            <PodcastIcon />
                            <p>{t('d0032961')}</p>
                        </a>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default About;