
import moment from 'moment';
export const generateMonths = () => {
  const monthsArr = moment.months();
  const months = [];
  monthsArr.forEach(el => {
    months.push({
      value: el, label: el
    });
  });
  return months;
};
