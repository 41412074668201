import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from './cardNew.module.scss';
import accounting from 'accounting-js';
import Button from '../../_components/button/button';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { LIVE, UPCOMING, HISTORY, TRANSACTIONS } from '../../_utils/constants';

const CardNew = ({ type, data, activeTab, entriesNumber, id, userEventsId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [t] = useTranslation();

  return (
    <div className={styles.card}>
      <div className={styles.top}>
        <div className={styles.left}>
          <div style={{ display: "table-cell", verticalAlign: "middle", width: "100%", height: "10px" }}>
            <h5 className={styles.name}>{data.name}</h5>
            { data.subtype === 'beatThePro' && <span className={styles.beatThePro}> { t('d27625835') } </span> }
          </div>
        </div>
        <div className={styles.right}>
          <div style={{ display: "table-cell", verticalAlign: "middle" }}>
            <div className={styles.game}>
              <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                <span>{data.gameName}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.up}>
          <div className={styles.start}>
            <p className={styles.text}>{t('d71557884')}</p>
            <p className={styles.number}>{accounting.formatMoney(data.entryFee)}</p>
          </div>
          <div className={styles.center}>
            <p className={styles.text}>{t('d88318091')}</p>
            <p className={styles.number}>{entriesNumber ? entriesNumber : 0}/{data.team ? '∞' : data.totalEntries}</p>
          </div>
          <div className={styles.end}>
            <p className={styles.text}>{t('d72545674')}</p>
            {data.prizeType === 'Skins' ? <p className={styles.skinText}>{t('Skins')}</p> :
            data.prize ? <p className={styles.number}>{accounting.formatMoney(data.prize)}</p>
              : <p className={styles.number}>0</p>}
          </div>
        </div>
        <div className={styles.down}>
          <div style={{ height: "100%", display: "table", width: "50%" }}>
            <div style={{ display: "table-cell", verticalAlign: "middle", width: "100%", height: "10px" }}>
              <span>{moment(data.from).format('MM/DD hh:mmA')}</span>
            </div>
          </div>
          <div style={{ height: "100%", display: "table", width: "50%" }}>
            <div style={{ display: "table-cell", verticalAlign: "middle", width: "100%", height: "10px" }}>
              {type === 'twoRows' && <Button label={t("d28344393")} btnClass="btnPrimary"
                onClick={() => { history.push(`/lobby/draft/${data.id}`); }} />}
              {type === 'threeRows' && activeTab === UPCOMING && <Button label={t("d95167251")} btnClass="btnPrimary"
                onClick={() => {
                  history.push({
                    pathname: `/contests/draft/${data.id}/${userEventsId}`,
                    state: { id: id, userEventsId: userEventsId }
                  });
                }} />}
              {type === 'threeRows' && activeTab !== UPCOMING && <Button label={t("d95421823")} btnClass="btnPrimary"
                onClick={() => {
                  history.push({
                    pathname: `/contests/results/${data.id}/${userEventsId}`
                  });
                }} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardNew;