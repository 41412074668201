import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LobbyIcon } from '../../_assets/images/icons/navbar/joystick_icon.svg';
import { ReactComponent as ContestIcon } from '../../_assets/images/icons/navbar/contest_icon.svg';
import { ReactComponent as WalletIcon } from '../../_assets/images/icons/navbar/wallet_icon.svg';
import { ReactComponent as AwardIcon } from '../../_assets/images/icons/navbar/award_icon.svg';
import { ReactComponent as InviteIcon } from '../../_assets/images/icons/navbar/invite_icon.svg';
// import { ReactComponent as NewsIcon } from '../../_assets/images/icons/navbar/news_icon.svg';

import './navbar.scss';
import { useSelector } from 'react-redux';

const LinksNav = ({ ready, show, toggleLinkNav }) => {
  const [t] = useTranslation();
  const store = useSelector(state => state);
  return (
    <div className={`toggle-menu ${show ? 'tg-show-menu' : 'tg-hide-menu '}`}>
      {ready ? <ul>
        <li onClick={toggleLinkNav}>
          <NavLink to="/lobby" className="nav-link" > <span>{t('d23525167')}</span> </NavLink>
        </li>
        <li onClick={toggleLinkNav}>
          <NavLink to="/contests" className="nav-link"> <span>{t('d95895288')}</span></NavLink>
        </li>
        {/* <li onClick={toggleLinkNav}>
          <NavLink to="/leaderboard" className="nav-link">  <span>{t('d2282565')}</span></NavLink>
        </li> */}
        <li onClick={toggleLinkNav}>
          <NavLink to="/wallet" className="nav-link">  <span>{t('d21581168')}</span></NavLink>
        </li>
        <li onClick={toggleLinkNav}>
          <NavLink to="/referral" className="nav-link"> <span>{t('d23718725')}</span></NavLink>
        </li>
        {/* <li onClick={toggleLinkNav}>
            <NavLink to="/contact" className="nav-link"> <i className="fa fa-phone" aria-hidden="true"></i><span>{t('d77926439')}</span></NavLink>
          </li> */}
        {store.auth.user && store.auth.user.role === 'influencer' && <li onClick={toggleLinkNav}>
          <NavLink to="/influencer/contests" className="nav-link"> <ContestIcon /><span>{t('d27625835')}</span></NavLink>
        </li>}
      </ul> : null}
    </div>
  );
};

LinksNav.propTypes = {
  toggleLinkNav: PropTypes.func,
  show: PropTypes.bool
};

export default LinksNav;
