import React, { useState, useEffect } from 'react';
import mainService from '../../../_services/main.service';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CustomPagination from '../../../_components/pagination/serverPagination';
import moment from 'moment';
import accounting from 'accounting-js'
import styles from './Transactions.module.scss';

const _getType = (type) => {
  return type
}

const Transactions = ({ fields = {}, field = {}, error, onChange }) => {
  const [t] = useTranslation();
  const [result, setResult] = useState()
  const [total, setTotal] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(()=> {
    if (fields.userId) {
      getData()
    }
  }, [fields.userId])

  useEffect(() => {
    getData();
  }, [pageNumber, pageSize]);

  const getData = async () => {
    if (fields.userId) {
      setResult(null)
      const filter = {userId: fields.userId}
      const data = await mainService.run('transactions', 'user', 'page', {
        filter,
        pageNumber,
        pageSize,
        sort: {createdAt: -1}
      });
      setTotal(data.total)
      setResult(data.rows)
      return data
    }
  }

  const onPageChange = async (page, pageSize) => {
    setPageNumber(page);
    setPageSize(pageSize);
  };

  return (
    <div key={field.dbname + '-transactions' } className={styles.transactionsWrapper}>
      {result && result.length > 0 ? <div className={styles.tb}>
        <PerfectScrollbar className="purpleScroll">
          <div className={styles.head}>
            <div className={styles.items}>
              <small>#</small>
              <span className={styles.date}>{t('Date')}</span>
              <span className={styles.type}>{t('Transaction Type')}</span>
              <span className={styles.name}>{t('Payment Type')}</span>
              <span className={styles.name}>{t('Status')}</span>
              <span className={styles.amount}>{t('Amount')}</span>
              <span className={styles.amount}>{t('Credits')}</span>
            </div>
          </div>
          {result.map((el, i) => (
            <div key={el.id} className={styles.body}>
              <div className={styles.items}>
                <small>{((pageNumber - 1) * pageSize) + i + 1}</small>
                <span className={styles.date}>{moment(el.createdAt).format('MM-DD-YYYY hh:mm:ss A')}</span>
                <span className={styles.type}>{_getType(el.transactionType)}</span>
                <span className={styles.name}>{t(el.paymentType)}</span>
                <span className={styles.name}>{t(el.status)}</span>
                <span className={styles.amount}>{accounting.formatMoney(el.amount || 0)}</span>
                <span className={styles.amount}>{accounting.formatMoney(el.credit || 0)}</span>
              </div>
            </div>
          ))}
        </PerfectScrollbar>
      </div> : <div className={styles.msg}> <div className="message-info">
      {result ? t('No Transactions for user filters') : ''}
      </div> </div>}
      <CustomPagination
        total={total}
        data={result}
        currentPage={pageNumber}
        currentPageSize={pageSize}
        defaultPageSize={10}
        onChange={onPageChange} />
    </div>)
};

export default Transactions;