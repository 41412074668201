import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Filter.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { mainActions } from '../../../_store/actions/main.actions';
import Selector from '../../../_components/selector/selector';
import DatePicker from "react-datepicker";
import Button from '../../../_components/button/button';
import Input from '../../../_components/input/input';
import Checkbox from '../../../_components/checkbox/checkbox';

const Filter = ({ onSubmit, onClose, selectedFilters }) => {
    const [t] = useTranslation();
    const [games, setGames] = useState();
    const [contestType, setContestType] = useState(selectedFilters.contestType);
    const [fields, setFields] = useState(selectedFilters);
    const dispatch = useDispatch();

    const typeDefaultValues = [ { label: t('d09094712'), code: 'd09094712' }, { label: t('d85876432'), code: 'd85876432'}, { label: t('d12792751'), code: 'd12792751' }];
    
    useEffect(() => {
        getGames();
    }, [])

    useEffect(() => {
        setContestType(selectedFilters.contestType);
    }, [selectedFilters])

    const getGames = async () => {
        const response = await dispatch(mainActions.run('data', 'game', 'all'));
        setGameNames(response);
    }

    const setGameNames = (gameList) => {
        const gameNamesList = []
        gameList.map(g => {
            gameNamesList.push({...g, label: g.name});
        })
        setGames(gameNamesList);
    }

    const handleInputChange = (e) => {
        const { value } = e.target;
        setFields({...fields, name: value });
    }

    const onSelectChange = (selectedValue) => {
        if(selectedValue.type === 'game') {
            setFields({...fields, game: selectedValue });
        } else {
            setFields({...fields, contestType: selectedValue});
            setContestType(selectedValue);
        }
    }

    const onEndDateChange = (d) => {
        setFields({...fields, from: d });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

       const filters = [];

       Object.keys(fields).forEach(key => {
           if(key === 'contestType') {
            fields[key] && filters.push({ [key]: fields[key].code })
           } else if(key === 'publish') {
            fields[key] !== undefined && filters.push({ [key]: fields[key] })
           } else if(key === 'game') {
            fields[key] && filters.push({ [key]: fields[key].name })
           } else {
            fields[key] && filters.push({ [key]: fields[key] })
           }
       });
    
        onSubmit(filters, fields);
      };
    
      const clearFilters = () => {
        setFields({})
        onSubmit([], {});
      }

      const onChange = (e) => {
        setFields({...fields, publish: e.target.checked})
      }

    return (
        <form className={styles.dynamicFilters}>
            <div className={styles.filterFields}>
                <>
                <Input type="text" name="key" placeholder={t('d39251520')} value={fields.name} disabled={false} onChange={handleInputChange} label={t('d39251520')} />
                    {games && <Selector
                    selectClassname="selectForm"
                    options={games}
                    handleChangeSelect={onSelectChange}
                    placeholder={t('d465729339')}
                    label={t('d58873906')}
                    multiselect={false}
                    value={fields.game}
                    selectedOption={fields.game}
                    />}
                    {typeDefaultValues && <Selector
                    selectClassname="selectForm"
                    options={typeDefaultValues}
                    handleChangeSelect={onSelectChange}
                    placeholder={t('d234103673')}
                    label={t('d234103673')}
                    multiselect={false}
                    value={contestType}
                    selectedOption={contestType}
                    />}
                    <div className="date-picker-wrapper">
                        {<label htmlFor={t('d49977315')}>{t('d49977315')}</label>}
                        <DatePicker
                            selected={fields.from}
                            onChange={date => onEndDateChange(date)}
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            timeCaption="time"
                            dateFormat="MMMM d, yyyy h:mm aa"
                        />
                    </div>
                    <br></br>
                    <div className={styles.checkboxWrapper}>
                        <Checkbox checkboxClass="checkboxInputClass"
                            name='publish'
                            checked={fields.publish}
                            isSelected={fields.publish}
                            onChange={onChange}
                            label={t('d76823011')} />
                        </div>
                </>
            </div>
            <div className={styles.btnWrapper}>
                <Button btnClass='btnFormRed' label={t('d57298318')} onClick={clearFilters} />
                <Button btnClass='btnFormGreen' label={t('d27628599')} onClick={handleSubmit} />
                <Button btnClass='btnFormPurple' label={t('d465751263')} onClick={onClose} />
            </div>
        </form>
    );
};

export default Filter;
