import { countriesConstants } from '../constants';

const initialState = {
  list: []
};

export const countries = (state = initialState, action) => {
  switch (action.type) {
    case countriesConstants.COUNTRIES_REQUEST:
      return {
        ...state
      };
    case countriesConstants.COUNTRIES_SUCCESS: {
      return {
        ...state,
        list: action.countries
      };
    }
    case countriesConstants.COUNTRIES_FAILURE:
      return { ...state };
    default:
      return state;
  }
};
