import moment from 'moment';
export const generateDays = (month, year) => {
  const days = [];
  if (!month) {
    const monthDays = 31;
    for (let i = 1; i <= monthDays; i++) {
      days.push({
        value: i, label: JSON.stringify(i)
      });
    }
  } else {
    const monthNum = moment().month(month).format('M');
    const monthDays = moment([year, (monthNum - 1)]).daysInMonth();
    for (let i = 1; i <= monthDays; i++) {
      days.push({
        value: i, label: JSON.stringify(i)
      });
    }
  }

  return days;
};
