import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { calculateStandings, getPipeline, isObjectEmpty } from '../../../_utils';
import EventDetails from '../../../_components/eventDetails/eventDetails';
import TablePlayers from '../../../_components/tableResults/tablePlayers';
import TableStandings from '../../../_components/tableResults/tableStandings';
import Loading from '../../../_components/Loading';
import Tabs from '../../../_components/tabs/tabs';
import { useDispatch, useSelector } from 'react-redux';
import { mainActions } from '../../../_store/actions/main.actions';
import { loaderActions } from '../../../_store/actions/loader.actions';
import storageService from '../../../_services/storage.service'
import { modalActions } from '../../../_store/actions/modal.actions';
import PrizesDisplay from '../../../_components/prizesDisplay/prizesDisplay';
import SubLinks from '../../../_components/subRoutes/subRoutesTitle';
import styles from './results.module.scss';
import coin from '../../../_assets/images/icons/coin6800.svg'

const AdminResults = (props) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const store = useSelector(state => state);
  const myTeamTbHead = [
    t('d09094712'), t('d2582874'), t('d84268922'), t('d5920881'), t('d59318009'), t('d58938009'), t('d84982639'), t('d85227599'), t('d59328500'), t('d71123719'),
  ];
  const myStandings = [
    t('d13453725'), t('d86984201'), t('d71123719'), t('d87474551')
  ];
  const tabs = {
    tab2: "Standings"
  };
  const [loading, setLoading] = useState(false);
  const { match: { params: { id } } } = props;
  // const [msg, setMsg] = useState('');
  const [activeTab, setActiveTab] = useState('My team');
  const [activeColor, setActiveColor] = useState(activeTab !== 'Standings' ? '#00c84a' : '#8d29ff');
  const [scrollColor, setScrollColor] = useState(activeTab !== 'Standings' ? 'greenScroll' : 'purpleScroll');
  const [tbHeading, setTbHeading] = useState(myTeamTbHead);
  const [result, setResult] = useState({});
  const [rules, setRules] = useState({});
  const storageResults = storageService.getData('data', 'cotest-result', 'get', { id })
  const [standings, setStandings] = useState(storageResults && storageResults.standings ? storageResults.standings : []);
  const [players, setPlayers] = useState([]);

  const changeTab = (key) => {
    // add API wth redux
    setActiveColor(key !== 'Standings' ? '#00c84a' : '#8d29ff');
    setScrollColor(key !== 'Standings' ? 'greenScroll' : 'purpleScroll');
    setTbHeading((key !== 'Standings' ? myTeamTbHead : myStandings));
    setActiveTab(key);
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (store.event.data && store.event.data.type == 'game') {
      const event = store.event.data
      if (result && result.matches && result.matches.indexOf(event.matchId)){
        fetchData()
      }

    }
  }, [store.event.data]);

  useEffect(() => {
    setLoading(store.loader[`result_${id}`])
  }, [store.loader[`result_${id}`]]);

  const openPrizeModal = () => {
    let data, skins, prizeType
    if (result && result.contest) {
      data = result.contest.prizes
      prizeType = result.contest.prizeType
      skins = result.contest.skins
    }
    dispatch(modalActions.openModal(<PrizesDisplay data={data} skins={skins} prizeType={prizeType} />, 'Prizes modal', 'md'));
  };

  const setData = (data) => {
    if (data) {
      data.id = id
      if (data.contest) {
        if (data.contest.matchesData) {
          const playersMap = {}
          for (const one of data.contest.matchesData) {
            one.playersTeam1.forEach(p1 => playersMap[p1.id] = p1)
            one.playersTeam2.forEach(p2 => playersMap[p2.id] = p2)
          }
          data.contest.playersMap = playersMap
        }
        setResult(data)
      }
      if (data.contest && data.contest.rules) {
        setRules(data.contest.rules)
      }
    }
  }

  const calcStandings = (data, standingsResult) => {
    if (data.contest && data.players && standingsResult.participants && standingsResult.events) {
      const calculatedResult = calculateStandings({ ...data, ...standingsResult })
      const userId = store.auth.user.id
      data.place = standingsResult.participants.findIndex(one => one.userId === userId) + 1
      data.firstPlacePoints = standingsResult.participants[0].points
      data.positionsPaid = calculatedResult.positionsPaid
      data.playerPoints = calculatedResult.playerPoints
      if (standingsResult.participants) {
        storageService.setData('data', 'result', 'get', {id, standings: standingsResult.participants});
      }
    }
  }

  const fetchData = async () => {
    const data = await dispatch(mainActions.run('data', 'contest', 'results', { id }, null));
    setData(data);
    fetchStandings(data)
  }

  const fetchStandings = async (contestData) => {
    dispatch(loaderActions.setLoading(`result_${id}`, true))
    try {
      if (contestData && contestData.contest && contestData.contest.matches) {
        const data = await dispatch(mainActions.run('data', 'userContest', 'standings', {
          id,
          matches: contestData.contest.matches
        }, null));
        calcStandings(contestData, data)
        setStandings(data.participants)

        setResult({...contestData, points: -1, won: -1 })
      }
    } catch (err) {} //just to catch
    dispatch(loaderActions.setLoading(`result_${id}`, false))
  }

  return (
    <div className="layout-content">
      {result && result.contest && result.matches && <><div className="left"><SubLinks /></div><div className="layout-content-right">
      <div className="title">
        <div className="text">
          <div style={{display:"flex"}}>
            <h5>{result && result.contest && result.contest.name}</h5>
            <div className="game">
              <div style={{display:"table-cell", verticalAlign:"middle"}}>
              {result && result.game && <span>{result.game}</span>}
              </div>
            </div>
          </div>
          <p>Game: {result && result.game}</p>
        </div>
      </div>
      <p className={styles.par}>{t("d66712361")}</p>
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <EventDetails result={result} onClick={openPrizeModal} isAdmin={true} />
        </div>
        <div className={styles.right}>
          <div className={styles.header}>
            <Tabs tabs={tabs} activeTab={activeTab} changeTab={changeTab} />
            <div style={{display:"flex", minWidth:"200px"}}>
              <div className={styles.image}><span></span><img src={coin} alt='coin'/></div>
              <div className={styles.text}><span onClick={openPrizeModal}>{t("d95796142")}</span></div>
            </div>
          </div>
          {loading || !standings ? <Loading label="Please wait while calculating results" delay="1000"/> : <TableStandings rules={rules} standings={standings} result={result} />}
        </div>
      </div>
      </div></>}
    </div>
  );
};

export default AdminResults;
