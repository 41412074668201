import { isDeviceMobile } from '../_utils';
import * as publicIp from 'react-public-ip'
import { detect } from 'detect-browser'
const browser = detect();
let ip = ''

const _init = async () => {
  try {
    ip = await publicIp.v4()
  } catch (err) {
    console.warn('error retrieving IP')
  }
}
_init()

const storageOnMobile = (type) => {
  return type == "user" || type == "screen" || type == "routes";
}

const _estimate = () => {
  if (navigator.storage && navigator.storage.estimate) {
    return navigator.storage.estimate()
  } else {
    return new Promise(resolve => {
      resolve({ usage: localStorage.length, quota: 1048576 })
    })
  }
}

export const sessionService = {
  set(type, data) {
    if (isDeviceMobile()) {
      if (storageOnMobile(type)) {
        localStorage.setItem(type, JSON.stringify(data));
        return;
      }
    } else {
      if (!data) return localStorage.removeItem(type);
      _estimate().then(function (estimate) {
        let existingDataSize = 0
        const existingData = localStorage.getItem(type);
        if (existingData) {
          existingDataSize = existingData.length * 2
        }

        const newData = JSON.stringify(data)
        const newDataSize = newData.length * 2

        if (estimate.usage + newDataSize - existingDataSize < estimate.quota) {
          try {
            localStorage.setItem(type, newData);
          } catch (err) {
            console.warn('No mora place in local storage')
          }
        } else {
          //exceeding local storage size
          console.log('available storage ', estimate.quota)
          console.log('available usage ', estimate.usage)
          console.log('exceeding size  ', (estimate.usage + newDataSize - existingDataSize))
        }
      });
    }
  },

  destroy(type) {
    localStorage.removeItem(type);
  },
  get(type) {
    const data = localStorage.getItem(type);
    if (!data) return null;
    const res = JSON.parse(data);
    return res;
  },
  isAuth() {
    const data = localStorage.getItem('user');
    if (!data) {
      return false;
    } else {
      const session = JSON.parse(data);
      return session;
    }
  },
  getImg(imgName) {
    const data = localStorage.getItem(imgName);
    if (!data) return null
    const image = JSON.parse(data);
    return image ? image : null;
  },
  getSessionData() {
    const data = localStorage.getItem('sessdata');
    if (!data) return {};
    const sessdata = JSON.parse(data);
    return sessdata || {};
  },
  setSessionData(img, lang, ref) {
    const storageData = localStorage.getItem('sessdata');
    try {
      let data = JSON.parse(storageData);
      if (data) {
        if (lang) {
          data.lang = { ...data.lang, ...lang };
        }
      } else {
        data = {}
        if (lang) {
          data.lang = {
            name: lang.name,
            crc: lang.crc
          };
        }
      }
      if (ref) {
        if (ref.charAt(0) == '?') ref = ref.substr(1)
        const params = ref.split('&')
        const referr = params.find(one => one.split('=')[0] === 'ref')
        data.siteReference = referr.split('=')[1];
      } else {
        delete data.siteReference
      }
      data.ip = ip
      data.browser = browser
      localStorage.setItem('sessdata', JSON.stringify(data));
      return data;
    } catch (err) {
      //Error saving storage data
      console.warn('Not able to save session data in local storage')
    }
  },

  getDefaultLang() {
    const data = localStorage.getItem('lang_default');
    if (!data) return 'en';
    const language = JSON.parse(data);
    return language;
  },
  removeItemsByCrc(images = [], language = {}) {
    const data = localStorage.getItem('sessdata');
    const sessdata = JSON.parse(data);
    try {
      if (images.length > 0) {
        images.forEach(el => {
          localStorage.removeItem(el);
          const index = sessdata.images.findIndex((e) => el === e.name);
          if (index !== -1) {
            sessdata.images.splice(index, 1);
            localStorage.setItem('sessdata', JSON.stringify(sessdata));
          }
        });
      }
      if (language.lang) {
        localStorage.removeItem(`lang_${language.lang}`);
        delete sessdata.lang;
        localStorage.setItem('sessdata', JSON.stringify(sessdata));
      }
    } catch (err) {
      //Error saving storage data
      console.warn('Not able to save session data in local storage')
    }
  }
};
