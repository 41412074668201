import React, { useState } from 'react';
import moment from 'moment';
import Input from '../input/input';
import Button from '../button/button';
import SelectInput from './components/selectInput';
import Checkbox from '../checkbox/checkbox';
import { useTranslation } from 'react-i18next';
import DatePickerComp from '../../_components/datePicker/datePickerComp';
import styles from './dynamicFilters.module.scss';

const DynamicFilters = ({ onSubmit, onClose, form, selectedFilters }) => {
  const [t] = useTranslation();
  const [fields, setFields] = useState(selectedFilters || {});

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    value.trim()
    setFields(fields => ({ ...fields, [name]: (type === 'number') ? +value : value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const filters = []
    form.fields.forEach(({ type, dbname, filter }) => {
      let value = fields[dbname]
      if (Array.isArray(value) && value.length === 0 || !value) return
      if (filter){
        value = { [filter]: value }
      } else {
        if (type === 'select' || type == 'multiselect') {
          value = { $in: value }
        }
        if (type === 'text') {
          value = { '$regex': value, '$options': 'i' }
        }
      }
      filters.push({ [dbname] : value })
    })

    onSubmit(filters, fields)
  };

  const clearFilters = () => {
    setFields({})
    onSubmit([], {})
  }

  const onChangeDate = (date, name) => {
    setFields(prevState => {
      return {
        ...prevState,
        [name]: moment(date).valueOf()
      };
    });
  };

  const selectResponse = (value, obj, dbName, field) => {
    setFields(fields => ({ ...fields, [dbName]: value }));
  };

  const onCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFields(fields => ({ ...fields, [name]: checked }));
  };
  return (
    <form className={styles.dynamicFilters}>
      <div className={styles.filterFields}>
        {form.fields.map((field, i) => {
          if (field.filter === false) return null
          if (field.type === 'text' || field.type === 'number' || field.type === 'textarea') {
            return <div key={i} className={styles.textFields}>
              <Input type={field.type} name={field.dbname} onChange={handleInputChange} value={fields[field.dbname]} placeholder={t(field.label)} label={t(field.columnLabel || field.label)} customClass="inlineSmall"/>
            </div>;
          }
          if (field.type === 'checkbox') {
            return <div key={i} className={styles.checkbox}>
              <Checkbox checkboxClass="checkboxLoginClass"
                name={field.dbname}
                label={t(field.columnLabel || field.label)}
                isSelected={fields[field.dbname]}
                onChange={onCheckboxChange}
              />
            </div>;
          }
          if (field.type === 'select' || field.type == 'multiselect') {
            return <div key={i} className={styles.select}>
              <SelectInput
                type={field.dataType}
                dataName={field.dataName}
                defaultValue={field.defaultValue}
                dbName={field.dbname}
                selectLabel={field.selectLabel}
                selectField={field.selectField}
                label={t(field.columnLabel || field.label)}
                onSelectChange={selectResponse}
                selected={fields[field.dbname] ? fields[field.dbname] : null}
                fields={fields}
                field={field}
                multiselect={true}
                additionalClass='filterSelect'/>
            </div>;
          }
          if (field.type === 'date' || field.type === 'dateBirth') {
            return <div key={i} className={styles.dataPickerAdminFilter}>
              <DatePickerComp onChange={(date) => onChangeDate(date, field.dbname)}
                              name={field.dbname} key={field.dbname} label={t(field.columnLabel || field.label)} />
            </div>
          }
        })}
      </div>
      <div className={styles.btnWrapper}>
        <Button btnClass='btnFormRed' label={t('d57298318')} onClick={clearFilters} />
        <Button btnClass='btnFormGreen' label={t('d27628599')} onClick={handleSubmit} />
        <Button btnClass='btnFormPurple' label={t('d465751263')} onClick={onClose} />
      </div>
    </form>
  );
};
export default DynamicFilters;
