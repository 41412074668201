import i18next from 'i18next';
import { langConstants } from '../constants';
import langService from '../../_services/language.service';
import { sessionService } from '../../_sessionService/storage';
import { history } from '../../_utils';

const run = (mode, data = {}, query) => {
  const request = () => { return { type: langConstants.LANG_REQUEST }; };
  const success = (name, lang, crc) => { return { type: langConstants.LANG_SUCCESS, name, lang, crc }; };
  const failure = (error) => { return { type: langConstants.LANG_FAILURE, error }; };
  const getStorage = (name, lang, crc) => { return { type: langConstants.LANG_STORAGE, name, lang, crc }; };
  return dispatch => {
    dispatch(request());
    const fromStorage = sessionService.get(`lang_${data.lang}`);
    if (mode === 'get') {
      if (fromStorage) {
        dispatch(getStorage(fromStorage.name, fromStorage.lang, fromStorage.crc));
        i18next.addResources(data.lang, 'translation', fromStorage.lang);
      } else {
        langService.run(mode, data, query)
          .then(
            res => {
              i18next.addResources(res.lang, 'translation', res.data);
              dispatch(success(res.lang, res.data, res.crc));
              sessionService.set('lang_default', data.lang);
              sessionService.set(`lang_${data.lang}`, { name: data.lang, crc: res.crc, lang: res.data });
              sessionService.setSessionData(sessionService.getSessionData().images, { name: res.lang, crc: res.crc },sessionService.getSessionData().siteReference);
            },
            error => {
              dispatch(failure(error));
            }
          );
      }

    }
  };
};

export const languageActions = {
  run
};
