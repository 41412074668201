/**
 * Created by laslo on 1/14/22.
 */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { mainActions } from '../../../_store/actions/main.actions';
import Input from '../../input/input';
import Selector from '../../selector/selector';
import Button from '../../button/button';
import { isObjectEmpty, getMapingValue, parseDynamicFieldFilters } from '../../../_utils';
// import { objToArray, arrayToObj, isObjectEmpty, getMapingValue, checkConditions, pullFieldsValue } from '../../_utils';
import i18n from '../../../i18n';
import styles from './keyValue.module.scss';

const KeySelect = ({ field, fields = {}, onChange, error }) => {
  const [t] = useTranslation();
  const [keyValueArray, setKeyValueArray] = useState([{key: '1', value: ''}])
  const [valueData, setValueData] = useState({})

  useEffect(() => {
    let value = fields[field.dbname] || {}
    setValueData(value)
    const result = []
    Object.keys(value).forEach(key => {
      result.push({key, value: value[key]})
    })
    if (result.length === 0) result.push({key: '1', value: ''})
    setKeyValueArray(result);
  }, [fields[field.dbname]]);

  const handleMultiInputChange = (e, index) => {
    const { name, value } = e.target;
    const changingValue = keyValueArray[index]
    changingValue[name] = value
    setKeyValueArray([...keyValueArray])
    // valueData[changingValue.key] = changingValue.value
    // setValueData(valueData)
    // if (onChange && changingValue.key !== undefined && changingValue.value !== undefined) onChange(field.dbname, valueData)
  };

  const onFocusOut = (index) => {
    console.log('index', index)
    const changingValue = keyValueArray[index]
    console.log('changingValue', changingValue)
    valueData[changingValue.key] = changingValue.value
    setValueData(valueData)
    if (onChange && changingValue.key && changingValue.value) onChange(field.dbname, valueData)
  };

  const handleRemoveClick = (e, index) => {
    e.preventDefault();
    const deletedValue = keyValueArray[index]
    keyValueArray.splice(index, 1)
    delete valueData[deletedValue.key]
    setKeyValueArray([...keyValueArray])
    setValueData(valueData)
    if (onChange) onChange(field.dbname, valueData)
  };

  const handleAddClick = (e) => {
    e.preventDefault()
    keyValueArray.push({ key: '', value: ''})
    setKeyValueArray([...keyValueArray])
  };


  return (
    <div key={field.dbname + '-keyValue' } className={styles.keyValueWrapper}>
      <label>{t(field.label)}</label>
      <small>{t(field.description)}</small>
      {keyValueArray.length > 0 && <div className={styles.keyValueStyle} >
        {keyValueArray.map((element, i) => {
          return (
            <React.Fragment key={i}>
              <div className={styles.keyValueInput}>
                <Input type="text" name="key" placeholder={t(field.label1)} value={t(element.key)} disabled={field.label1Disabled} onFocusOut={() => onFocusOut(i)} onChange={e => handleMultiInputChange(e, i)} label={t(field.label1)} />
                <Input type="text" name="value" placeholder={t(field.label2)} value={element.value} onFocusOut={() => onFocusOut(i)} onChange={e => handleMultiInputChange(e, i)} label={t(field.label2)} />
                {keyValueArray.length !== 1 &&
                <Button type="button" btnClass='btnSecondary' label={<i className="fa fa-minus" aria-hidden="true"></i>} onClick={(e) => handleRemoveClick(e, i)} />
                }
              </div>
              {
                keyValueArray.length - 1 === i &&
                <div className={styles.keyValueAdd}>
                  <Button type="button" btnClass='btnPrimary' label={<i className="fa fa-plus" aria-hidden="true"></i>} onClick={(e) => handleAddClick(e)} />
                </div>
              }

              {error && (!element.value && (element.value !== 0)) && field.required && <div className="input-err-msg">{t('d621915485')} {t(field.label1)}</div>}
              {error && (!element.key && (element.key !== 0)) && field.required && <div className="input-err-msg">{t('d621915485')} {t(field.label2)}</div>}
            </React.Fragment>
          )
        })}
      </div>}
    </div>)
};

export default KeySelect;