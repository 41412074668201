import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment'
import styles from './eventDetails.module.scss';
import EventDetailsHeader from './eventDetailsHeader';
import accounting from 'accounting-js';

const EventDetails = ({ result, onClick, isAdmin }) => {
  const [t] = useTranslation();
  const store = useSelector(state => state);

  const [data, setData] = useState({})

  useEffect(() => {
    if (result && result.id) {
      const { contest = {} } = result
      const { skins = {} } = contest
      const temp = {}

      if (!isAdmin) temp[t('d344071578')] = store.auth.user.username
      if (!isAdmin) temp[t('d67771712')] = result.place
      if (!isAdmin) temp[t('d87474551')] = contest.prizeType === 'Skins' ? skins[result.reward] ? skins[result.reward].name: '-'  : contest.prizeType === 'Credit' ? accounting.formatNumber(result.won ? result.won : 0) + ' Credits' : accounting.formatMoney(result.won ? result.won : 0)
      if (!isAdmin) temp[t('d71123719')] = result.points ? result.points : 0
      if (!isAdmin) temp[t('d6902618')] = result.firstPlacePoints && result.points ? result.firstPlacePoints - result.points : 0
      temp[t('d49977315')] = moment(contest.from).format('MM/DD hh:mm A T') //'03/14 15:00 PM EST',
      temp[t('d72212518')] = contest.prizeType === 'Skins' ? 'Skins' : contest.prizeType === 'Credit' ? accounting.formatNumber(contest.prize ? contest.prize : 0) + ' Credits': accounting.formatMoney(contest.prize ? contest.prize : 0)
      temp[t('d71138438')] = contest.entered
      temp[t('d11012376')] = 'COMPLETED'
      temp[t('d71708612')] = result.positionsPaid

      setData(temp)
    }
  }, [result])
  return (
    <div>
      {/* <EventDetailsHeader headerStyle='headerStyle' onClick={onClick} /> */}
      <div className={styles.details}>
        <span className={styles.eventDetailsTitle}>{t('d91974521')}</span>
        <div style={{background:"#080C11"}}>
        {Object.entries(data).map(([key, value]) => {
          return (
            <div className={styles.text} key={key}>
              <h5>{key}</h5>
              <h4>{value}</h4>
            </div>
          );
        })}
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
