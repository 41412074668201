import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Input from '../../../_components/input/input';
import Button from '../../../_components/button/button';
import { useTranslation } from 'react-i18next';
import { userActions } from '../../../_store/actions/user.actions';
import { modalActions } from '../../../_store/actions/modal.actions';
import PageHeading from '../../../_components/pageHeading/pageHeading';

const ModalChangePassword = ({ token, email }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState(false);
  const [user, setUser] = useState({
    token: token,
    email: email,
    password: '',
  });

  const handleChange = e => {
    setError(false);
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value.trim();
    const name = target.name;
    setUser(user => ({ ...user, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setError(false);
      dispatch(userActions.forgotPassword(user));
    } else {
      setError(true);
    }
  };

  const validateForm = () => {
    const isValid = user.password;
    if (!isValid) {
      setError(true);
      return false;
    } else {
      setError(false);
      setIsValid(true);
      return true;
    }
  };

  return (
    <form className="auth-form" onSubmit={handleSubmit}>
      <h1 className="auth-header">{t('d60155617')}</h1>
      <div className="form-body">
        <Input name="password" type="password" value={user.password} onChange={handleChange} placeholder={t('d91901397')} />
        {error && !user.password && <div className="input-err-msg">{t('d61055606')}</div>}
      </div>
      <Button
        label={t('d58938009')}
        btnClass={isValid && !error ? 'btnPrimary' : 'btnSecondary'}
        fullWidth={true}
        type="submit"
      />
    </form>);
};

const ChangePassword = (props) => {
  const { match: { params: { email, token } } } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    openModal();
  }, []);
  const openModal = () => {
    dispatch(modalActions.openModal(<ModalChangePassword token={token} email={email} />, 'Change password modal'), 'md');
  };
  return <div className="container-wrapper">
    <PageHeading heading={t('d60155617')} subheading={t('d60155617')} />
  </div>;
};

export default ChangePassword;
