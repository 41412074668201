
import httpService from './http.service';

class UserService {
  async login(user) {
    return httpService.apiRequest('post', '/api/profile', { mode: 'login', data: user });
  }

  async logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('sessdata');
  }

  async register(user) {
    return httpService.apiRequest('post', '/api/profile', { mode: 'register', data: user });
  }

  async sendReferral(email) {
    return httpService.apiRequest('post', '/api/profile', { mode: 'send-invitation', data: email });
  }
  async validateData(data){
    return httpService.apiRequest('post', '/api/profile', { mode: 'check', data});
  }

  async refreshUser(id) {
    return httpService.apiRequest('post', '/api/profile', { mode: 'get', data: { id } });
  }

  async resetLink(data) {
    return httpService.apiRequest('post', '/api/profile', { mode: 'forgot-password', data: data });
  }

  async forgotPassword(data) {
    return httpService.apiRequest('post', '/api/profile', { mode: 'change-forgot-password', data: data });
  }

  async changePassword(data) {
    return httpService.apiRequest('post', '/api/profile', { mode: 'change-password', data: data });
  }

  async contactUs(data) {
    return httpService.apiRequest('post', '/api/profile', { mode: 'contact-form', data: data });
  }

  async sendEmailTemplate(data) {
    return httpService.apiRequest('post', '/api/profile', { mode: 'emailTemplate', data: data });
  }

}

export default new UserService();
