import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import Input from '../../input/input';
import Button from '../../button/button';
import SelectInput from '../components/selectInput';
import Checkbox from '../../checkbox/checkbox';
import { setReplacements, getListValue } from '../../../_utils';
import { useTranslation } from 'react-i18next';
import { mainActions } from '../../../_store/actions/main.actions';
import DatePickerComp from '../../../_components/datePicker/datePickerComp';
import styles from './CSVExport.module.scss';
import { ExportToCsv } from 'export-to-csv';

const CSVExport = ({ form, onClose, history = false }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [fields, setFields] = useState({});
  const [data, setData] = useState([]);
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);

  const getHeaders = () => {
    return form.fields.map(field => t(field.columnLabel || field.label))
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    value.trim()
    setFields(fields => ({ ...fields, [name]: (type === 'number') ? +value : value }));
    setReady(false)
  };
  const handleSubmit = async (e) => {
    e.preventDefault()
    const { collection, type } = form;
    ;
    const filters = []
    form.fields.forEach(({ type, dbname, filter }) => {
      let value = fields[dbname]
      if (Array.isArray(value) && value.length === 0 || !value) return
      if (filter){
        value = { [filter]: value }
      } else {
        if (type === 'select' || type == 'multiselect') {
          value = { $in: value }
        }
      }
      filters.push({ [dbname] : value })
    })

    let filterString
    if (!history && form.filter) {
      filterString = setReplacements(form.filter)
    } else if (history && form.history) {
      filterString = setReplacements(form.history)
    }

    if (filterString) filters.push(JSON.parse(filterString))
    setLoading(true)
    const result = await dispatch(mainActions.run(collection, type, 'all', { filter: filters }, null, true));

    const csvData = [getHeaders()]
    result.forEach(row =>
        csvData.push(form.fields.map(field => getListValue(field, row, t)))
    )
    setLoading(false)
    setReady(true)
    setData(csvData)
  };

  useEffect(() => {
    if (ready) {
      const options = {
        fieldSeparator: ',',
        quoteStrings: '',
        decimalSeparator: '.',
        showLabels: false,
        showTitle: false,
        title: t(form.name),
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        headers: getHeaders(),
        filename: getName(),
      };
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(data);
      onClose();
    }
  }, [data])

  const clearFilters = () => {
    setFields({})
    setReady(false)
  }

  const onChangeDate = (date, name) => {
    setFields(prevState => {
      return {
        ...prevState,
        [name]: moment(date).valueOf()
      };
    });
    setReady(false)
  };

  const selectResponse = (value, obj, dbName, field) => {
    setFields(fields => ({ ...fields, [dbName]: value }));
    setReady(false)
  };

  const onCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFields(fields => ({ ...fields, [name]: checked }));
    setReady(false)
  };

  const getName = () => {
    return t(form.name)+moment().format('MM-DD-YYYY')
  }

  return (
      <form className={styles.dynamicFilters}>
        <div className={styles.filterFields}>
          {form.fields.map((field, i) => {
            if (field.filter === false) return null
            if (field.type === 'text' || field.type === 'number' || field.type === 'textarea') {
              return <div key={i} className={styles.textFields}>
                <Input type={field.type} name={field.dbname} onChange={handleInputChange} value={fields[field.dbname]} placeholder={t(field.label)} label={t(field.columnLabel || field.label)} customClass="inlineSmall"/>
              </div>;
            }
            if (field.type === 'checkbox') {
              return <div key={i} className={styles.checkbox}>
                <Checkbox checkboxClass="checkboxLoginClass"
                          name={field.dbname}
                          label={t(field.columnLabel || field.label)}
                          isSelected={fields[field.dbname]}
                          onChange={onCheckboxChange}
                />
              </div>;
            }
            if (field.type === 'select' || field.type == 'multiselect') {
              return <div key={i} className={styles.select}>
                <SelectInput
                    type={field.dataType}
                    dataName={field.dataName}
                    defaultValue={field.defaultValue}
                    dbName={field.dbname}
                    selectLabel={field.selectLabel}
                    selectField={field.selectField}
                    label={t(field.columnLabel || field.label)}
                    onSelectChange={selectResponse}
                    selected={fields[field.dbname] ? fields[field.dbname] : null}
                    fields={fields}
                    field={field}
                    multiselect={true}
                    additionalClass='filterSelect'/>
              </div>;
            }
            if (field.type === 'date' || field.type === 'dateBirth') {
              return <div key={i} className={styles.dataPickerAdminFilter}>
                <DatePickerComp onChange={(date) => onChangeDate(date, field.dbname)}
                                name={field.dbname} key={field.dbname} label={t(field.columnLabel || field.label)} />
              </div>
            }
          })}
        </div>
        <div className={styles.btnWrapper}>
          <Button btnClass='btnFormRed' label={t('d57298318')} onClick={clearFilters} />
          <Button  loading={loading} btnClass='btnFormGreen' label={t('d0733608')} onClick={handleSubmit} />
          <Button btnClass='btnFormPurple' label={t('d465751263')} onClick={onClose} />
        </div>
      </form>
  );
}

export default CSVExport;