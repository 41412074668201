import httpService from './http.service';

class PaymentService {
  async run(mode, data = {}, query) {
    const url = query ? `/api/payment?${query}` : '/api/payment';
    return httpService.apiRequest('post', url, { mode, data });
  }
}

export default new PaymentService();
