import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import {useStripe} from '@stripe/react-stripe-js';
import { paymentActions } from '../../_store/actions/payment.actions';
import styles from './style.module.scss';
import CardCheckoutForm from './stripe/CardCheckoutForm'
import Input from "../input/input";
import { PayPalButton } from "react-paypal-button-v2";
import { paypalId } from '../../environment'

import Loading from '../../_components/Loading';

import { useTranslation } from 'react-i18next';

const DepositModal = ({ user, amount, show, onClose }) => {
  const [t] = useTranslation();
  const [modalIsOpen] = useState(show);
  const [depositCode, setDepositCode] = useState()
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();

  const onCardPayment = async (payment_info) => {
    if (payment_info) {
      setLoading(true)
      const result = await dispatch(paymentActions.run('stripe-charge', {
        source: payment_info.id,
        amount,
        userId: user.id,
        email: user.email,
        depositCode
      }));
      if (result) {
        await stripe.confirmCardPayment(result.client_secret)
      }
      onClose(true)
      setLoading(false)

    }


  }

  const onPayPalPayment = async (details, data) => {
    data.amount = Number(amount)
    data.userId = user.id
    data.depositCode = depositCode
    await dispatch(paymentActions.run('paypal-transaction', data));
    setLoading(false)
    onClose(true)
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      contentLabel="Custom modal"
      className={`${styles.customModal} ${styles.md} ${styles.modalYesNo}`}
      overlayClassName={styles.customOverlay}
      ariaHideApp={false}
    >
      { loading && <Loading /> }
      <div className={ loading ? 'invisible' : styles.modalBody}>
        <span className={styles.close} onClick={onClose}><i className="fa fa-times" aria-hidden="true"></i></span>
        <PayPalButton
          options={{
            clientId: paypalId,
            disableFunding: "card"
          }}
          createOrder={(data, actions) => {
            setLoading(true)
            return actions.order.create({
              purchase_units: [{
                amount: {
                  currency_code: "USD",
                  value: amount
                }
              }],
            });
          }}
          amount={amount}
          onSuccess={onPayPalPayment}
          onError={() => setLoading(false)}
          onCancel={() => setLoading(false)}
        />
        {/*<CardCheckoutForm onPayment={onCardPayment} />*/}
        <label>Enter Deposit Code</label>
        <Input
          type="text"
          name="depositCode"
          onChange={e => setDepositCode(e.target.value.trim())}
          value={depositCode}
          placeholder={'Enter Depoist Code'}
          />
      </div>
    </Modal>
  );
};

DepositModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

export default DepositModal;
