import React, { useState, useEffect } from 'react';
import Button from '../../../_components/button/button';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import styles from './events.module.scss';
import { isObjectEmpty } from '../../../_utils';
import { useDispatch /*, useSelector */ } from 'react-redux';
import { mainActions } from '../../../_store/actions/main.actions';
import ModalYesNo from '../../../_components/modal/modalYesNo';
import { getAllPlayers, getAllPlayersActions } from './util';

const EventsPublish = ({ data, onSubmit, toggle, action, onCorrection }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [events, setEvents] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [players, setPlayers] = useState([]);
  const [playerActions, setPlayerActions] = useState({});
  const [clonedPlayerActions, setClonedPlayerActions] = useState({});
  const [match, setMatch] = useState([]);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [editVisibility, setEditVisibility] = useState(-1);
  const [editData, setEditData] = useState({});
  useEffect(() => {
    if (data) {
      handlePublishData(data);
      setMatch(data);
    }
  }, [onSubmit, data]);

  const handlePublishData = (match) => {
    setEvents(match.events);
    const players = getAllPlayers(match);
    const playerActions = getAllPlayersActions(match, players);
    const deepCopy = _.cloneDeep(playerActions);
    setPlayers(players)
    setPlayerActions(playerActions);
    setClonedPlayerActions(deepCopy)
    const actions = Object.keys(match.game.rules).map(key => {
      return key;
    });
    setHeaders(actions);
  };

  const openPublishModal = async (e) => {
    e.preventDefault();
    setShowModalDelete(true);
    setModalData(data);
  };

  const publishData = async (answer, { id, game }) => {
    if (answer === t('d13586174')) {
      dispatch(mainActions.run('events', 'game', 'publish', { id, game: game.name ? game.name : game }, t('d41166397')));
      toggle()
      setShowModalDelete(false);
    } else {
      setShowModalDelete(false);
    }
  };
  const editFinalData = async (index) => {
    setEditVisibility(index);
  };
  const close = async () => {
    setEditVisibility(-1);
    setClonedPlayerActions(_.cloneDeep(playerActions));
    setEditData({});
  };
  const updateFinalData = async (player) => {
    if (!player) return setEditVisibility(-1);
    setUpdating(true)
    try {
      const response = await dispatch(mainActions.run('events', 'game', 'correction', {
        playerId: player.id,
        game: data.game.name,
        matchId: match.id,
        actions: {...editData}
      }, t('d41166397')));
      if (onCorrection) onCorrection(match.id)
      if (response) setEditVisibility(-1);
    } catch (err){
      // console.log(err)
    } finally {
      setUpdating(false)
    }
  };
  const handleEditInput = (e, action, playerId) => {
    const { value } = e.target;
    setEditData(prev => ({
      ...prev, [action]: +value
    }));
    setClonedPlayerActions(prev => {
      prev[playerId][action] = +value;
      return prev;
    });

    setHeaders([...headers]);
  }
  return (
    <div>
      {showModalDelete && <ModalYesNo show={showModalDelete} handleYesNoAnswer={publishData} modalData={modalData} question='d8033115' />}
      <div className={styles.publishWrapper}>
        {events && events.length > 0 && <div className={styles.tbList}>
          <div className={styles.info}>
            <h4>{match.name} {action === "publish" && <Button btnClass='btnFormPurple' label={t('d42245345')} onClick={(e) => { openPublishModal(e); }} />}</h4>
            <h5><span>{t('d58873906')}</span>: {match.game.name}</h5>
          </div>

          <div className={styles.match}>
            <h5><div className={styles.teams}>
              {match.teams.map((team, i) => (<span key={i}>
                {team}<small>VS</small>
              </span>))}
            </div></h5>

            <ul className={styles.head}>
              <li><span> {t('d2582874')}</span></li>
              {headers &&
                <> {headers.map((action, i) => (
                  <li key={i}>
                    <span>{action}</span>
                  </li>
                ))}
                </>}
            </ul>
            <ul className={styles.body}>
              {players.map((player, i) => (
                <li key={i}>
                  <span>{player.nick}</span>
                  {headers.map((key, i) => (
                    <div className={styles.data} key={i}>
                      {editVisibility === player.id ? <input type="text" value={clonedPlayerActions[player.id][key] || 0} name={`${key}-${player.id}`} id={`${key}-${player.id}`} onChange={(e) => { handleEditInput(e, key, player.id); }} /> :
                        <span key={i}>{playerActions[player.id] ? playerActions[player.id][key] || 0 : 0} </span>}
                    </div>
                  ))}
                  {editVisibility === player.id ? <> <Button btnClass='btnFormGreen' label={t('d58938009')} loading={updating} onClick={() => { updateFinalData(player); }} />
                    <Button btnClass='btnFormPurple' label={t('d465751263')} loading={updating} onClick={() => { close(); }} /> </> :
                    <><Button btnClass='btnFormGreen' label={t('d323691769')} onClick={() => { editFinalData(player.id); }} />
                    </>}
                </li>
              ))}
            </ul>
          </div>
        </div>}
      </div>
    </div >
  );
};

export default EventsPublish;
