import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './carouselItem.module.scss';
import moment from 'moment';
import withImages from '../../_components/core/withImages';
import { sessionService } from '../../_sessionService/storage'
import avatarDefault from '../../_assets/images/team.png';

const CarouselItem = ({ item, acquireImages, images }) => {
  const [t] = useTranslation();

  const team1Name = item.teams[0];
  const team2Name = item.teams[1];
  const team1LogoName = (item.game + '_' + team1Name + '_logo').replace(/\s+/g, '_');
  const team2LogoName = (item.game + '_' + team2Name + '_logo').replace(/\s+/g, '_');

  const [team1Logo, setTeam1Logo] = useState(sessionService.getImg(team1LogoName))
  const [team2Logo, setTeam2Logo] = useState(sessionService.getImg(team2LogoName))

  useEffect(() => {
    acquireImages([
      { name: team1LogoName, width: 100, height: 100 },
      { name: team2LogoName, width: 100, height: 100 }
    ]);
  }, []);

  useEffect(() => {
    if (images[team1LogoName]) setTeam1Logo(images[team1LogoName]);
    if (images[team2LogoName]) setTeam2Logo(images[team2LogoName]);
  }, [images[team1LogoName], images[team2LogoName]]);

  return (
    <div className={styles.carouselItem}>
      <div className={styles.itemBody}>
        <div className={styles.itemHead}>
          <div className={styles.itemImg}>
            <img src={team1Logo ? team1Logo : avatarDefault} alt={t(team1Name)} />
          </div>
          <span>{t(team1Name)}</span>
        </div>
        <div>
          <h4>{t('VS')}</h4>
        </div>
        <div className={styles.itemHead}>
          <div className={styles.itemImg}>
            <img src={team2Logo ? team2Logo : avatarDefault} alt={t(team2Name)} />
          </div>
          <span>{t(team2Name)}</span>
        </div>
      </div>
    </div>
  );
};

export default withImages(CarouselItem);
