import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '../auth.scss';
import Input from '../../../_components/input/input';
import Button from '../../../_components/button/button';
import { modalActions } from '../../../_store/actions/modal.actions';
import { userActions } from '../../../_store/actions/user.actions';

const ResetPassword = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const handleChange = e => {
    e.preventDefault();
    setEmail(e.target.value);
  };
  const handleSubmit = e => {
    e.preventDefault();
    dispatch(userActions.resetLink({ email }));
  };

  const closeModal = async () => {
    dispatch(modalActions.closeModal());
  };

  return (
    <form className="auth-form" onSubmit={handleSubmit}>
      <h4>{t('d83084957')}</h4>
      <div className="form-body" >
        <Input name="email" value={email} onChange={handleChange} placeholder={t('d44003993')} />
      </div>
      <Button
        label={t('d83811669')}
        btnClass={email ? 'btnPrimary' : 'btnSecondary'}
        fullWidth={true}
        type="submit"
      />
      <div className="auth-footer">
        <span className='clickable-label' onClick={closeModal}>
          {t('d11281092')}
        </span>
      </div>
    </form>
  );
};

export default ResetPassword;
