import moment from "moment";

export const objToArray = (obj) => {
  if (!obj) return [];
  return Object.keys(obj).map((key) => ({ key, value: obj[key] }));
};

export const arrayToObj = (arr) => {
  if (!arr) return {};
  const obj = {};
  arr.forEach(({ key, value }) => {
    if (key && value) {
      obj[key] = value;
    }
  });
  return obj;
};

export const sortDescendingBy = (key) => {
  return (a, b) => b[key] - a[key];
};

export const sortAscendingBy = (key) => {
  return (a, b) => b[key] - a[key];
};

export const isObjectEmpty = (obj) => {
  if (!obj) return true;
  return Object.keys(obj).length === 0;
};

export const isDeviceMobile = () => {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true;
  } else {
    return false;
  }
};

export const parseDynamicFieldFilters = (field) => {
  let filters = [];

  try {
    if (field.filters) {
      let stingFilters = JSON.stringify(field.filters);
      stingFilters = stingFilters.replace(/"NOW"/g, new Date().getTime());
      stingFilters = stingFilters.replace(/gt/g, "$gt");
      stingFilters = stingFilters.replace(/lt/g, "$lt");
      stingFilters = stingFilters.replace(/#ne#/g, "$ne");
      filters = JSON.parse(stingFilters);
    }
  } catch (err) {}
  return filters;
};

const _getObjPathValue = (path, obj) => {
  let value;
  let data = obj;
  if (path) {
    const depth = path.split(".");
    depth.forEach((subPath) => {
      if (!data) return null;
      if (subPath.indexOf("|") > -1) {
        const mapping = subPath.split("|");
        let merged;
        mapping.forEach((map) =>
          merged ? (merged += "_" + data[map]) : (merged = data[map])
        );
        data = merged;
      } else {
        data = data[subPath];
      }
    });
  }
  if (data !== undefined) value = data;
  return value;
};

export const getMapingValue = (obj = {}, map = {}) => {
  let projection;
  if (typeof map === "object") {
    const { path = "", property = "" } = map;
    projection = path ? path + "." : "";
    projection += property;
  } else {
    projection = map;
  }
  if (!projection) return obj;
  else {
    return _getObjPathValue(projection, obj);
  }
};

const _checkCondition = (value1, operation, value2) => {
  // if (value1 === undefined || value2 === undefined) return false
  if (operation === "exist"){
    return !!value1
  }
  if (operation === "=") {
    return value1 === value2;
  }
  if (operation === "<>") {
    return value1 !== value2;
  }
  if (operation === ">") {
    return value1 > value2;
  }
  if (operation === "<") {
    return value1 < value2;
  }
};

export const checkConditions = (value1, operation, value2) => {
  if (!operation) return false;
  let result = false;
  if (Array.isArray(value2)) {
    let idx = 0;
    const len = value2.length;
    while (!result && idx < len) {
      result = _checkCondition(value1, operation, value2[idx]);
      if (result) break;
      idx++;
    }
  } else {
    result = _checkCondition(value1, operation, value2);
  }
  return result;
};

export const pullFieldsValue = (obj = {}, pullMap = {}, pullNull = false) => {
  const result = {};
  Object.keys(pullMap).forEach((key) => {
    const value = _getObjPathValue(pullMap[key], obj);
    if (pullNull || (value !== undefined && value !== null))
      result[key] = value;
  });
  return result;
};

export const setReplacements = (agg) => {
  if (!agg) return agg;
  let pipeline = agg.replace("TIME_NOW", new Date().getTime());
  return pipeline;
};

const getFieldValue = (type, value, t) => {
  if (value === null || value === undefined) return "N/A";
  let result = value;
  switch (type) {
    case "date":
      result = moment(value).format("MM/DD hh:mmA");
      break;
    case "checkbox":
      result = value ? "d13586174" : "d13276142";
      break;
  }
  if (type === "date") {
    return result;
  } else if (type === "link") {
    return result;
  }
  return t("" + result);
};

export const getListValue = (field, el, t) => {
  const { type, map, dbname } = field;
  let value;
  if (map) {
    const { path, property } = map;
    let data = el;
    if (path) {
      const depth = path.split(".");
      depth.forEach((subPath) => (data = data ? data[subPath] : {}));
    }
    if (data) value = data[property];
  } else {
    value = el[dbname];
  }
  return getFieldValue(type, value, t);
};

export const displayDuration = (duration) => {
  if (!duration || !duration.days || typeof duration.days !== "function")
    return "";
  const days = duration.days();
  if (days > 0) {
    return `${days} ${days === 1 ? "day" : "days"}`;
  }
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  if(seconds < 0) return 'Live'
  return `${hours > 9 ? hours : `0${hours}`}:${
    minutes > 9 ? minutes : `0${minutes}`
  }:${seconds > 9 ? seconds : `0${seconds}`}`;
};
