import moment from 'moment';
import * as XLSX from 'xlsx'
import { isObjectEmpty } from './utils';

const NOT_IMPORTABLE_TYPES = {
  'keyValue': true,
  'keySelect': true,
  'file': true
}

const VALUE_FORMATS = {
  'text': 's',
  'textarea': 's',
  'number': 'n',
  'checkbox': 'b',
  'date': 'd',
  'dateBirth': 'd',
  'select': 's',
  'multiselect': 's'
}

const FORMAT_COMMENT = {
  'text': 'Text',
  'textarea': 'Text',
  'number': 'Number',
  'checkbox': 'Boolean 0/1 or Yes/No',
  'date': 'Date',
  'dateBirth': 'Date',
  'select': 'Text',
  'multiselect': 'Text'
}

export const getFormTemplate = (form, t) => {
  if (form && form.fields && !isObjectEmpty(form.fields)) {
    const fieldJSON = [[], []]
    form.fields.forEach(({ type, columnLabel, label }) => {
      if (!NOT_IMPORTABLE_TYPES[type] && VALUE_FORMATS[type]) {
        fieldJSON[0].push({t: 's', v: t(columnLabel || label), c: [{t: FORMAT_COMMENT[type]}]})
        fieldJSON[1].push({t: VALUE_FORMATS[type]})
      }
    });
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.aoa_to_sheet(fieldJSON);
    Object.keys(ws).forEach(key => ws[key].c ? ws[key].c.hidden = true : null)
    console.log(ws)
    XLSX.utils.book_append_sheet(wb, ws, 'data')

    XLSX.writeFile(wb, `${t(form.name)}_template.xlsx`);
  }
};

const _format = (data, type, dateFormat) => {
  let result
  if (type === 'number') {
    result = Number(data)
    if (!result && result !== 0) {
      result = null
    }
  } else if (type === 'checkbox') {
    result = data == 1 || (''+data.toUpperCase()) === 'YES'
  } else if (type === 'date' || type === 'dateBirth') {
    result = moment(data, dateFormat)
    if (result) result = result.valueOf()
  } else {
    result = ''+data
  }
  return result
}

export const parseFormFromXLSXFile = (file, form, t,  dateFormat = 'MM/DD/YYYY') => {
  var wb = XLSX.read(file,{ type: 'binary', cellDates: true,});
  const ws = wb.Sheets['data'];
  const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});
  const headers = dataParse[0]
  form.fields.forEach(({ dbname, type, columnLabel, label }) => {
    const headerString =  t(columnLabel || label)
    const idx = headers.indexOf(headerString)
    if (idx > -1) headers[idx] = { type, dbname }
  });
  const result = []
  const length = dataParse.length
  for (let i = 1; i<length; i++) {
    const data = {}
    const row = dataParse[i]
    headers.forEach((fieldData, idx) => {
      if (fieldData.dbname) {
        const rowData = row[idx]
        if (rowData) {
          data[fieldData.dbname] = _format(rowData, fieldData.type, dateFormat)
        }
      }
    })
    result.push(data)
  }
  return result
}