import httpService from './http.service';

class ImageService {
  async getImage(width, height, imgName) {
    return httpService.apiRequest('post', `/api/file?name=${imgName}&height=${height}&width=${width}`, {});
  }

  async upload(data) {
    return httpService.apiRequest('post', '/api/file/upload', data);
  }

  async deleteImage(title, dataId){
    return httpService.apiRequest('post', `/api/file/delete?title=${title}&dataId=${dataId}`, {});
  }
}

export default new ImageService();
