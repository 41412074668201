/**
 * Created by laslo on 9/18/20.
 */
export const getAllPlayers = (data) => {
  const teamLength = data.teams.length;
  const teams = [];
  for (let i = 0; i < teamLength; i++) {
    teams.push(...data['playersTeam' + (i + 1)]);
  }
  return teams;
};
export const getAllPlayersActions = ({ events = [] }, players) => {
  const playerActionMap = {}
  events.forEach(({ actions = {}}) => {
    actions.forEach(action => {
      const key = Object.keys(action)[0]
      const playerId = action[key]
      let playerData = playerActionMap[playerId]
      if (!playerData){
        playerData = {}
        playerActionMap[playerId] = playerData
      }
      playerData[key] = (playerData[key] || 0) + 1
    })
  })
  players.forEach(player => playerActionMap[player.id] ? playerActionMap[player.id].player = player: playerActionMap[player.id] = { player })
  return playerActionMap;
};