export const paymentConstants = {
  TOKEN_REQUEST: 'TOKEN_REQUEST',
  TOKEN_FAILURE: 'TOKEN_FAILURE',
  TOKEN_SUCCESS: 'TOKEN_SUCCESS',

  DEPOSIT_REQUEST: 'DEPOSIT_REQUEST',
  DEPOSIT_FAILURE: 'DEPOSIT_FAILURE',
  DEPOSIT_SUCCESS: 'DEPOSIT_SUCCESS',

  WITHDRAW_REQUEST: 'WITHDRAW_REQUEST',
  WITHDRAW_FAILURE: 'WITHDRAW_FAILURE',
  WITHDRAW_SUCCESS: 'WITHDRAW_SUCCESS',

  CONVERT_REQUEST: 'CONVERT_REQUEST',
  CONVERT_FAILURE: 'CONVERT_FAILURE',
  CONVERT_SUCCESS: 'CONVERT_SUCCESS',

  TRANSACTION_REQUEST: 'TRANSACTION_REQUEST',
  TRANSACTION_FAILURE: 'TRANSACTION_FAILURE',
  TRANSACTION_SUCCESS: 'TRANSACTION_SUCCESS'
};
