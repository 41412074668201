
import httpService from './http.service';

class DocumentsService {
  async uploadDocument(data = {}) {
    return httpService.apiRequest('post', '/api/file/upload-document', data);
  }

  async getDocForUser(userId) {
    return httpService.apiRequest('post', `/api/file/documents?userId=${userId}`, {});
  }

  async downloadocument(type,subtype, userId) {
    return httpService.apiRequest('post', `/api/file/download-document?type=${type}&subtype=${subtype}&userId=${userId}`, {}, {'Content-Type': 'application/json'
    }, 'arraybuffer');
  }

  async deleteDocForUser(documentId, userId) {
    return httpService.apiRequest('post', `/api/file/document/delete?userId=${userId}&documentId=${documentId}`, {});
  }
}

export default new DocumentsService();
