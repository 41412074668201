import React, { useState, useEffect } from 'react';
import styles from './faq.module.scss';
import { useTranslation } from 'react-i18next';
import PageHeading from '../../../_components/pageHeading/pageHeading';
import { useSelector, useDispatch } from 'react-redux';
import { mainActions } from '../../../_store/actions/main.actions';
import arrowDown from '../../../_assets/images/icons/arrowDown.svg';

const Faq = () => {
    const [t] = useTranslation();
    const [basicsState, setBasicsState] = useState(false);
    const handleBasic = () => {
        setBasicsState(!basicsState);
    }
    const [managementState, setManagementState] = useState(false);
    const handleManagement = () => {
        setManagementState(!managementState);
    }
    const [aboutState, setAboutState] = useState(false);
    const handleAbout = () => {
        setAboutState(!aboutState);
    }
    const [contactState, setContactState] = useState(false);
    const handleContact = () => {
        setContactState(!contactState);
    }
    const [displayedBasic, setDisplayedBasic] = useState('');
    const [displayedManagement, setDisplayedManagement] = useState('');
    const [displayedAbout, setDisplayedAbout] = useState('');

    const dispatch = useDispatch();
    const store = useSelector(state => state);

    const getGamesSelector = () => {
        dispatch(mainActions.run('data', 'game', 'all'));
    };

    const [games, setGames] = useState([]);
    useEffect(() => {
        getGamesSelector();
    }, [])

    useEffect(() => {
        if (store.main.data && store.main.data.game && store.main.data.game.list) {
            setGames(store.main.data.game.list)
        }
    }, [store.main.data && store.main.data.game && store.main.data.game.list])

    const renderScoringRules = (rules = []) => {
        return (
            <React.Fragment>
                {Object.keys(rules).map((key, i) => {
                    return (
                        <React.Fragment key={key + '-' + i}>
                            <div className={styles.gameRule} key={key}>
                                <p className={styles.ruleKey}>{t(key)}:</p>
                                <p className={styles.ruleValue}> {t(rules[key])} {t('d9202545')}</p>
                            </div>
                        </React.Fragment>
                    )
                })
                }
            </React.Fragment>
        )
    };
    const basicTxt = [
        {
            id: 1,
            header: t('d4595534'),
            text: <p> EliteDuels.gg is the world's first skill-based single player fantasy esports platform. </p>
        },
        {
            id: 2,
            header: t('d47087067'),
            text: <p>{t('d47532831')} <br /> {t('d47917445')} <br /> {t('d48373625')} <br /> {t('d48757429')}</p>
        },
        {
            id: 3,
            header: t('d49344408'),
            text: <p>
                {t('d49736845')}:
                {games.map(item => {
                    return (
                        <span className={styles.games} key={item.id}> {item.name} </span>
                    )
                })}
            </p>
        },
        {
            id: 4,
            header: 'Who can play on EliteDuels?',
            text: <p> 
                {t('d51116402')} <br /> <br /> 
                {/* {t('d5142403')} <br /> <br /> 
                {t('d51948537')} <br /> <br /> 
                {t('d53827437')} <br /> <br />  */}
                {/* {t('d5411400')} <br /> <br /> 
                {t('d54587773')} <br /> <br />  */}
                {/* {t('d10281799')} <br /> <br />  */}
                The following jurisdictions are allowed to play daily fantasy and single player fantasy on EliteDuels (as of January 2023): <br /> <br />
                Armenia, Belarus, Bosnia & Herzegovina, Bulgaria, Croatia, Cyprus, Czech Republic, Estonia, Georgia, Germany, Gibraltar, Ireland, Italy, 
                Latvia, Liechtenstein, Lithuania, Poland, Portugal, Romania, Serbia, Spain, Switzerland, Turkey, Alberta, Quebec, British Columbia, Kahnawake, 
                Manitoba, Nova Scotia, Saskatchewan, Alaska, Arkansas, California, Colorado, Washington DC, Florida, Georgia, Illinois, Kansas, Kentucky, 
                Massachusetts 21+, Michigan, Minnesota, New Mexico, North Carolina, North Dakota, Oklahoma, Rhode Island, South Carolina, South Dakota, 
                Texas, Utah, Vermont, Virginia, Wisconsin, Wyoming, Argentina, Bolivia, Brazil, Costa Rica, El Salvador, Guyana, Honduras, MExico, Nicaragua, 
                Panama, Paraguay, Peru, Suriname, Venezuela <br /> <br />
                {t('d55374256')}  It is the users responsibility to ensure they are playing in a legal jurisdiction. <br /> <br /> 
                {/* {t('d55863458')} */}
                Users who are not from one of the above areas can maintain their accounts and play with free contests.  Winnings can be withdrawn from free contests only. <br /> <br />
                If you country or state isn’t listed above, and you feel it is a mistake, please email <a href="mailto:support@eliteduels.com" target="_blank" rel="noopener noreferrer">support@eliteduels.com</a> or join our discord channel to speak with a customer service representative.  
            </p>
        }
    ]
    const managementTxt = [
        {
            id: 1,
            header: t('d74373544'),
            text: <p>EliteDuels accepts PayPal at this time.</p>
        },
        {
            id: 2,
            header: t('d69933643'),
            text: <p>1. If you are located in the United States or Canada, you can request a personal check in the mail  <br /> <br /> 2. PayPal We will approve ID verification request only when we receive the following documents <br /> <br /> 1. Driver’s License, Passport, or any other government Issued ID (two of the following) OR <br /> <br /> 2. Utility Bill, Bank Statement, or others which display your current address</p>
        },
        {
            id: 3,
            header: t('d8153634'),
            text: <p>{t('d81904492')}</p>
        },
        {
            id: 4,
            header: t('d79895980'),
            text: <p>{t('d81111406')}</p>
        },
        {
            id: 5,
            header: t('d78558265'),
            text: <p>{t('d79165391')}</p>
        },
        {
            id: 6,
            header: t('d77536021'),
            text: <p>{t('d77846827')}</p>
        },
    ];
    const aboutTxt = [
        {
            id: 6,
            header: t('d80027640'),
            text: <p> If a match is cancelled, players will be removed from player picks.  If you have a 2-man player pick, and 1 player is cancelled or removed, then the entry fee will be returned, and the player pick cancelled.  If you have 3 or 4 players selected, then the payout will be adjusted.  For example, 1 player is cancelled from your 3 players picked, the payout will be adjusted to 2 player picks.</p>
        },
        {
            id: 8,
            header: t('d82833672'),
            text: <p>{t('d83171606')}</p>
        },
        {
            id: 9,
            header: t('d83458238'),
            text: <p>If a player selected doesn’t play, they will be considered “DNP” and removed from the player pick.</p>
        },
    ];
    const contactTxt = {
        id: 1,
        text: <p><a href="https://t.co/3WhtvPxHrs" target="_blank" rel="noopener noreferrer">Join our discord server for immediate support! </a> </p>
    };
    return (
        <div className="faq-wrapper">
            <PageHeading heading={t('d29324663')} subheading={t('d29083902')} />
            <div className={styles.wrapper}>
                <div className={styles.basics}>
                    <div className={styles.title} onClick={handleBasic} >
                        <img src={arrowDown} alt="arrow_down"></img>{t('d40277987')}
                    </div>
                    <div className={basicsState ? styles.text : styles.hiddenTitle}>
                        {basicTxt.map(item => (
                            <div className={styles.singleTxt} key={item.id}>
                                <h5 onClick={() => displayedBasic === item.id ?
                                    setDisplayedBasic('') : setDisplayedBasic(item.id)}> {item.header} </h5>
                                <div className={displayedBasic === item.id ? styles.display : styles.hidden}> {item.text} </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.basics}>
                    <div className={styles.title} onClick={handleManagement}>
                        <img src={arrowDown} alt="arrow_down"></img>{t('d40514799')}
                    </div>
                    <div className={managementState ? styles.text : styles.hiddenTitle}>
                        {managementTxt.map(item => (
                            <div className={styles.singleTxt} key={item.id}>
                                <h5 onClick={() => displayedManagement === item.id ?
                                    setDisplayedManagement('') : setDisplayedManagement(item.id)}> {item.header} </h5>
                                <div className={displayedManagement === item.id ? styles.display : styles.hidden}> {item.text} </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.basics}>
                    <div className={styles.title} onClick={handleAbout}>
                        <img src={arrowDown} alt="arrow_down"></img>{t('d40748801')}
                    </div>
                    <div className={aboutState ? styles.text : styles.hiddenTitle}>
                        {aboutTxt.map(item => (
                            <div className={styles.singleTxt} key={item.id}>
                                <h5 onClick={() => displayedAbout === item.id ?
                                    setDisplayedAbout('') : setDisplayedAbout(item.id)}> {item.header} </h5>
                                <div className={displayedAbout === item.id ? styles.display : styles.hidden}> {item.text} </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.basics}>
                    <div className={styles.title} onClick={handleContact}>
                        <img src={arrowDown} alt="arrow_down"></img>{t('d40913853')}
                    </div>
                    <div>
                        <div className={styles.singleTxt}>
                            <div className={contactState ? styles.display : styles.hidden}>
                                {contactTxt.text}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faq;