import React, { useCallback, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import verifyIdentityImg from "../../../_assets/images/verify_identity.svg";
import Checkbox from "../../../_components/checkbox/checkbox";

import styles from "./Step4.module.scss";

const isValid = (type) => {
  return (
    type === "image/heic" ||
    type === "image/png" ||
    type === "image/jpg" ||
    type === "image/jpeg"
  );
};

const Step4 = ({ user, handleChange, handleUploadFile, file, error }) => {
  const [isValidExtension, setIsValidExtension] = useState(true);
  const [fileName, setFileName] = useState("");

  const [t] = useTranslation();

  const identification = useMemo(
    () => [
      { value: "d61504108", label: t("d61504108"), type: "d759510" },
      { value: "d63654046", label: t("d63654046"), type: "d759510" },
      { value: "d20106971", label: t("d20106971"), type: "d759510" },
    ],
    [t]
  );

  const onDrop = useCallback((acceptedFiles) => {
    const [file] = acceptedFiles;
    if (!file) return;
    if (!isValid(file?.type)) {
      setIsValidExtension(false);
      return;
    } else {
      const data = new FormData();
      setFileName(file?.name || '');
      data.append("file", file);
      data.append("type", "d759510");
      data.append("subtype", "d61504108");
      handleUploadFile(data);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className={styles.step4Wrapper}>
      <div className={styles.inputFieldsWrapper}>
        <h5>Verify Your Identity</h5>
        <p className={styles.description}>
          Please upload an image of the front of your Drivers License below.
          Images are deleted from our system after review.
        </p>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p className={styles.dragAndDropPlace}>Drop the file here ...</p>
          ) : (
            <p className={styles.dragAndDropPlace}>
              Drag or Click to Browse Image
            </p>
          )}
        </div>
        <div>
          <p>{fileName}</p>
        </div>
        <div className="input-err-msg">
          Accepted file formats: png, jpeg, heic, pdfs
        </div>
        {error && !user?.skipUploadFile && !file && (
          <div className="input-err-msg">
            Please upload a document or skip this step
          </div>
        )}
        {!isValidExtension ? (
          <div className="input-err-msg">Not valid file format</div>
        ) : null}
        <div className="register-checkbox-wrapper">
          <div className="register-checkbox">
            <Checkbox
              checkboxClass="checkboxLoginClass"
              label="I will do it later"
              name="skipUploadFile"
              isSelected={user.skipUploadFile}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className={styles.imgWrapper}>
        <h5>{t("d61504108")}</h5>
        <img src={verifyIdentityImg} />
      </div>
    </div>
  );
};

Step4.propTypes = {
  user: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleUploadFile: PropTypes.func.isRequired,
};

export default Step4;
