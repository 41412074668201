import React from 'react';
import PropTypes from 'prop-types';
import styles from './checkbox.module.scss';

const Checkbox = ({ name, value, isSelected, onChange, label, checkboxClass, desc }) => {
  return (
    <>
      {desc && <small className={styles.desc}>{desc}</small>}
      <label className={styles[checkboxClass]}>
        <input name={name} value={value} type="checkbox" checked={isSelected} onChange={onChange} />
        <span className={styles.checkmark} />
        <span className={styles.spanLabel}> {label} </span>
      </label>
    </>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string,
  isSelected: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
  checkboxClass: PropTypes.string
};

export default Checkbox;
