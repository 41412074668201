import React from "react";

import styles from "./playerPropsHowToPlay.module.scss";

const PlayerPropsHowToPlay = () => {
  return (
    <div className={styles.globalWrapper}>
      <h4>How it Works - Basic</h4>
      <div className={styles.wrapper}>
        <div className={styles.paragraphDiv}>
          <p>Select between 3 and 5 available players</p>
          <p>Choose your entry fee between $.50 and $20</p>
          <p>Example, (3/3, 4/4, 5/5)</p>
          <p>You lose if any of these picks incorrect. Example (0/3, 0/4, 2/5 etc.)</p>
        </div>
      </div>
    </div>
  );
};

export default PlayerPropsHowToPlay;
