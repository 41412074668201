import { userConstants } from '../constants';

const user = JSON.parse(localStorage.getItem('user'));
const initialUser = user ? user : null;

const initialState = {
  user: initialUser
};

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user,
      };
    case userConstants.LOGIN_FAILURE:
      return { ...state };
    case userConstants.REGISTER_REQUEST:
      return {
        ...state
      };
    case userConstants.REGISTER_SUCCESS:
      return {
        ...state
      };
    case userConstants.REGISTER_FAILURE:
      return {
        ...state
      };
    case userConstants.SET_ROUTE:
      return {
        ...state,
        routeRedirect: action.redirect
      };
    case userConstants.REFRESH_REQUEST:
      return {
        ...state
      };
    case userConstants.REFRESH_SUCCESS:
      return {
        ...state,
        user: action.user,
      };
    case userConstants.REFRESH_FAILURE:
      return {
        ...state
      };
    case userConstants.REFERRAL_REQUEST:
      return {
        ...state
      };
    case userConstants.REFERRAL_SUCCESS:
      return {
        ...state
      };
    case userConstants.REFERRAL_FAILURE:
      return {
        ...state
      };
    case userConstants.LOGOUT:
      return {
        ...state,
        user: null
      };
    case userConstants.FORGOT_REQUEST:
      return {
        ...state,
      };
    case userConstants.FORGOT_SUCCESS:
      return {
        ...state
      };
    case userConstants.FORGOT_FAILURE:
      return {
        ...state
      };
    case userConstants.RESET_LINK_REQUEST:
      return {
        ...state,
      };
    case userConstants.RESET_LINK_SUCCESS:
      return {
        ...state
      };
    case userConstants.RESET_LINK_FAILURE:
      return {
        ...state
      };
    case userConstants.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
      };
    case userConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state
      };
    case userConstants.CHANGE_PASSWORD_FAILURE:
      return {
        ...state
      };
    case userConstants.UPDATE_USER:
      return {
        ...state,
        user: action.user
      };
    case userConstants.EMAIL_TEMPLATE_REQUEST:
      return {
        ...state,
      };
    case userConstants.EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
      };
    case userConstants.EMAIL_TEMPLATE_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
