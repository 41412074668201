import React from 'react';
import { useTranslation } from 'react-i18next';
import './pagination.scss';
import 'rc-pagination/assets/index.css';
import Selector from '../selector/selector';
import Pagination from 'rc-pagination';
import PropTypes from 'prop-types';

const PAGE_OPTIONS = ['2', '4', '6', '8', '10', '20'].map(value=> ({ value: Number(value), label: value}))

const CustomPagination = ({ el_GR, data = [], currentPage, currentPageSize, defaultPageSize = 10, onChange, total }) => {
  const [t] = useTranslation();

  const NextIcon = () => {
    return (
      <span className="navigation-span">{t('d84431338')}</span>
    );
  };

  const PreviousIcon = () => {
    return (
      <span className="navigation-span">{t('d84983469')}</span>
    );
  };

  const onPageSizeChange = (option) => {
    const pageSize = option.value
    onChange(currentPage, pageSize)
  };

  const onPaginationChange = (page) => {
    onChange(page, currentPageSize)
  }

  return !data || total === 0  || ( total < currentPageSize && defaultPageSize === currentPageSize )? null : (
    <div className="pagination-wrapper">
      <Pagination
        className="pagination-component"
        total={total}
        locale={{ el_GR }}
        defaultPageSize={defaultPageSize}
        pageSize={currentPageSize}
        nextIcon={NextIcon}
        prevIcon={PreviousIcon}
        defaultCurrent={1}
        current={currentPage}
        onChange={onPaginationChange}
        showLessItems
      />
      <Selector selectClassname="paginator-pagesize-selector selectForm"
        selectedOption={PAGE_OPTIONS.find(one => one.value === currentPageSize)}
        options={PAGE_OPTIONS}
        handleChangeSelect={(e) => onPageSizeChange(e)} />
    </div>
  );
};

CustomPagination.prototypes = {
  total: PropTypes.number,
  defaultPageSize: PropTypes.number,
  onChange: PropTypes.func
};

export default CustomPagination;
