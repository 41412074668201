import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './input.module.scss';
import eyeIcon from '../../_assets/images/icons/eyeIcon.svg';
import copy from "copy-to-clipboard";

const Input = ({ type, name, value, checked, placeholder, onChange, label, autofocus = false, desc, customClass, disabled = false, onCopy, onFocusOut }) => {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const copyToClipboard = (text) => {
    copy(text)
    if (onCopy) onCopy()
  }

  return (
    <div className={`${styles.formGroup} ${styles[customClass]}`} >
      {label && <label htmlFor={name}>{label}</label>}
      {desc && <small className={styles.desc}>{desc}</small>}
      <div className={styles.passwordInput}>
          <input
            id={name}
            className={styles.formInput}
            type={type !== 'password' ? type : showPassword ? ('text') : ('password')}
            name={name}
            value={value}
            checked={checked}
            placeholder={placeholder}
            onBlur={onFocusOut}
            onChange={onChange}
            autoFocus={autofocus}
            disabled={disabled}
          />
          {type === 'password' && value && value.length ? (
            <img src={eyeIcon} alt="eye" onClick={toggleShowPassword} />
          ) : null}
          {type === 'link' ? (
              <i className="fa fa-copy" aria-hidden="true" onClick={() => { copyToClipboard(value) }}></i>
          ) : null}

      </div>
    </div >
  );
};

Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
};

export default Input;
