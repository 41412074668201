import React from 'react';
import styles from './tabs.module.scss';
import angleRight from '../../_assets/images/icons/angleRight.svg';
import { useTranslation } from 'react-i18next';

const Tabs = ({ tabs, changeTab, activeTab }) => {
  const [t] = useTranslation();

  return (
    <div className={styles.tabsWrapper}>
      <ul>
        {Object.keys(tabs).map((key, i) => (
          <li key={i} className={`${activeTab === tabs[key] ? `${styles.active}` : ''}`} onClick={() => { changeTab(tabs[key]) }}>
            <h5> {t(tabs[key])}</h5>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Tabs;
