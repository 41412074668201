import React from "react";
import PropTypes from "prop-types";
import checked from ".././../_assets/images/icons/checked.svg";
import redCros from ".././../_assets/images/icons/cros.svg";
import playerImg from "./../../_assets/images/player.png";
import { isFinite } from "lodash";

import styles from "./livePlayerCard.module.scss";

const LivePlayerCard = ({
  nick,
  image,
  value,
  bet,
  correct,
  action,
  boosted,
  canceled,
  dnp,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.info}>
        {!dnp && canceled ? (
          <div className={styles.canceled}>
            <span>canceled</span>
          </div>
        ) : null}
        {dnp ? (
          <div className={styles.canceled}>
            <span>Did not play</span>
          </div>
        ) : null}
        <div className={styles.imgDiv}>
          <img
            src={image || playerImg}
            className={boosted ? styles.imgBoosted : styles.imgNormal}
          />
        </div>
        <div className={styles.borderDiv}>
          <p className={styles.nick}>{nick}</p>
          {action && <p className={styles.action}>{action}</p>}
          {isFinite(value) && <p className={styles.value}>{value}</p>}
          <span className={bet === "over" ? styles.over : styles.under}>
            {bet}
          </span>
        </div>
        <div>
          {correct !== undefined && (
            <img
              className={styles.checkImage}
              src={correct ? checked : redCros}
            />
          )}
        </div>
      </div>
    </div>
  );
};

PropTypes.LivePlayerCard = {
  nick: PropTypes.string.isRequired,
  image: PropTypes.string,
  value: PropTypes.number.isRequired,
  action: PropTypes.string.isRequired,
  bet: PropTypes.string.isRequired,
  correct: PropTypes.bool,
  canceled: PropTypes.bool,
  dnp: PropTypes.bool,
};

export default LivePlayerCard;
