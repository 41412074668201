import React, { useEffect, useState } from 'react';
import PageHeading from '../../_components/pageHeading/pageHeading';
import Button from '../../_components/button/button';
import './admin.scss';
// import SmallTitle from '../../_components/smallTitle/smallTitle';
import List from './list/list';
import EventsManagement from './eventsManagement/eventsManagement';
import Transaction from './transactions/transactions';
import Revenue from './revenue/revenue';
import UserPlayerPicks from './userPlayerPicks/userPlayerPicks';
import ConnectedTransactions from './connectedTransactions/connectedTransactions';
import Activity from './activity/activity';
import Config from './config/config';
import { useDispatch, useSelector } from 'react-redux';
import { formsActions } from '../../_store/actions/foms.actions';
import { useTranslation } from 'react-i18next';
import angleRight from '../../_assets/images/icons/angleRight.svg';
import { PrivateRoute } from '../../_routes/privateRoute';
import { NavLink } from 'react-router-dom';

const AdminLanding = (props) => {
  const [t] = useTranslation();
  const store = useSelector(state => state);
  const dispatch = useDispatch();
  const [forms, setForms] = useState([]);
  const [selected, setSelected] = useState({});
  useEffect(() => {
    if (store.auth.user.role === 'user') {
      props.history.push('/lobby');
    }
  }, [props.history]);

  useEffect(() => {
    dispatch(formsActions.run('all'));
  }, []);

  useEffect(() => {
    if (store.forms && store.forms.data) {
      setForms(store.forms.data);
      const found = store.forms.data.find((el) => {
        return el.roles.indexOf(store.auth.user.role) > -1;
      });
      if (found) {
        setSelected(found);
        props.history.push(`/admin/${found.route}`);
      } else {
        props.history.push('/lobby');
      }

    }
  }, [store.forms && store.forms.data]);

  const changeTabs = (selected) => {
    setSelected(selected);
  };

  const getCustomForms = (selected, form) => {
    if (selected.name === 'd01008401' ){
      return (<PrivateRoute path={`/admin/${form.route}`} component={EventsManagement} form={selected} name='subroute' />)
    } else if (selected.name === 'd81503932' ){
      return (<PrivateRoute path={`/admin/${form.route}`} component={Transaction} form={selected} name='subroute' />)
    } else if (selected.name === 'Activities' ){
      return (<PrivateRoute path={`/admin/${form.route}`} component={Activity} form={selected} name='subroute' />)
    } else if (selected.name === 'Config' ){
      return (<PrivateRoute path={`/admin/${form.route}`} component={Config} form={selected} name='subroute' />)
    } else if (selected.name === 'Revenue' ){
      return (<PrivateRoute path={`/admin/${form.route}`} component={Revenue} form={selected} name='subroute' />)
    } else if (selected.name === 'User Player Picks' ){
      return (<PrivateRoute path={`/admin/${form.route}`} component={UserPlayerPicks} form={selected} name='subroute' />)
    } else if (selected.name === 'Connected Transactions' ){
      return (<PrivateRoute path={`/admin/${form.route}`} component={ConnectedTransactions} form={selected} name='subroute' />)
    }

    if (!form.roles || form.roles.length === 0 || form.roles.indexOf(store.auth.user.role) > -1){
      return (<PrivateRoute path={`/admin/${form.route}`} component={List} name='subroute' form={selected} />)
    }
  }

  return (
    <div className="container-fluid-wrapper">
      <PageHeading heading={t("d68422714")} subheading={t("d94387808")} />
      <div className="adminWrapper">
        <div className="generateBtns">
          {forms && forms.map(form =>
            !form.roles || form.roles.length === 0 || form.roles.indexOf(store.auth.user.role) > -1 ?
              <NavLink to={`/admin/${form.route}`} key={`option-button-${form.id}`} className="inactive" onClick={() => { changeTabs(form); }}> {t(form.name)} {selected.id === form.id && <img src={angleRight} alt="angle_right" />}</NavLink> : null
          )}
        </div>
        <div className="content">
          {forms && forms.map(form =>
            <React.Fragment key={form.name}>
              {getCustomForms(selected, form)}
            </React.Fragment>
          )}

        </div>
      </div>
    </div >
  );
};

export default AdminLanding;
