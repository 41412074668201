
import httpService from './http.service';

class CountriesService {
  async run(mode, data = {}, query) {
    const url = query ? `/api/countries?${query}` : '/api/countries';
    return httpService.apiRequest('post', url, { mode, data });
  }
}

export default new CountriesService();
