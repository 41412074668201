import React, { useEffect, useState } from 'react';
import styles from './playerDisplay.module.scss';
import { useTranslation } from 'react-i18next';
import accounting from 'accounting-js';
import { imageActions } from '../../_store/actions/image.actions';
import { useSelector, useDispatch } from 'react-redux';
import LogoSmall from '../../_assets/images/logo/newLogo-sm.svg';
import avatarDefault from '../../_assets/images/player.png';
import { sessionService } from '../../_sessionService/storage';

const PlayerDisplay = ({ data }) => {
    const [t] = useTranslation();
    const store = useSelector(state => state);
    const dispatch = useDispatch();

    const [img, setImage] = useState(sessionService.getImg(`${data.id}_profileAvatar`));

    const getImg = async (name) => {
        await dispatch(imageActions.getImage(name, 1000, 1000))
    }

    useEffect(() => {
        if (store.image && store.image[`${data.id}_profileAvatar`]) {
            setImage(store.image[`${data.id}_profileAvatar`]);
        }
    }, [store.image && store.image[`${data.id}_profileAvatar`]]);

    useEffect(() => {
        getImg(`${data.id}_profileAvatar`)
    }, [data])

    let formatedDate = new Date(data.birthDate).toLocaleDateString("en-US")
    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <img className={styles.avatar} src={img ? img : avatarDefault} alt="altImg" />
                <div className={styles.info}>
                    <h4 className={styles.nick}>{t('d82635619')}: {data.nick} </h4>
                    {data.teamName && <h4 className={styles.nick}> {t('d21583920')}: {data.teamName}</h4>}
                </div>

            </div>
            <div className={styles.content}>
                <div className={styles.info}>
                    <h5> {t('d63257556')}: </h5>
                    <span>{formatedDate ? formatedDate : ''}</span>
                </div>
                <div className={styles.info}>
                    <h5> {t('d1125659')}: </h5>
                    <span>{data && data.fppm ? data.fppm : ''}</span>
                </div>

                <div className={styles.info}>
                    <h5> {t('d520584')}: </h5>
                    <span>{data && data.country ? data.country : ''}</span>
                </div>

                <div className={styles.info}>
                    <h5> {t('d68274296')}: </h5>
                    <span>{data && data.salary ? accounting.formatMoney(data.salary) : ''}</span>
                </div>
                <img src={LogoSmall} alt="logo_sm" />
            </div>

        </div>
    )
}

export default PlayerDisplay;
