import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { modalActions } from "../../_store/actions/modal.actions";
import EventDetails from "../eventDetails/eventDetails";
import styles from "./gameInfo.module.scss";

function GameInfo({ result }) {

  const [t] = useTranslation();
  const dispatch = useDispatch()

  const showStatistic = () => {
    dispatch(
      modalActions.openModal(
        <EventDetails result={result} />,"Teams Preview","md"
      )
    )
  }
  return (
    <div className={styles.wrapper}>
      {result?.contest?.name && <p className={styles.heading}>{result.contest.name.length < 30 ? result.contest.name : result.contest.name.substr(0,30) + '...' }</p> }
      <div className={styles.infoWrapper}>
        <div className={styles.teams}>
          <p>
            {result?.contest?.matchesData[0]?.teams && result.contest.matchesData[0].teams[0] || ''}/
            {result?.contest?.matchesData[0]?.teams && result.contest.matchesData[0].teams[1] || ''}
          </p>
        </div>
        <div className={styles.info}>
          <div className={styles.paragraphDiv} >
            <p className={styles.greenText}>{t("d71842661")}: </p>
            <p>{result && result?.contest?.prize } {result && result?.contest?.prizeType } {t("d58871498")}</p>
          </div>
          <div className={styles.paragraphDiv} >
            <p className={styles.greenText}>{t("d42026764")} {t('d234103673')}: </p>
            <p> {t(result && result?.contest?.contestType) }</p>
          </div>
          <div className={styles.paragraphDiv} >
            <p className={styles.greenText}>{t("d97917174")} {t('d6777170')}: </p>
            <p> {result && result?.players.length}</p>
          </div>
          <div className={styles.paragraphDiv} >
            <p className={styles.greenText}>{t("d41831975")}: </p>
            {result?.to && <p> {moment(result && result?.to).format('MM/DD hh:mmA')}</p>}
          </div>
        </div>
        <p className={styles.fullDetails} onClick={showStatistic} >See full Details</p>
      </div>
    </div>
  );
}

export default GameInfo;
