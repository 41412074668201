import React, { useState, useEffect, useMemo } from "react";
import withImages from "../core/withImages";
import mainService from "../../_services/main.service";
import styles from "./slides.module.scss";

const Slides = ({ acquireImages, images, position, ids, show }) => {
  const [slideImages, setSlideImages] = useState([]);
  const [activeImage, setActiveImage] = useState(null);

  useEffect(() => {
    getSlides();
  }, [ids?.length, show, position]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      nextSlide(slideImages, activeImage);
    }, 6000);
    return () => clearTimeout(timeout);
  }, [slideImages, activeImage]);

  const getSlides = async () => {
    const filter = [];
    if (!show) {
      setSlideImages([]);
      setActiveImage(null);
      return;
    }
    if (ids && Array.isArray(ids) && ids.length) {
      filter.push({ id: { $in: ids } });
    }
    if ((!ids || !ids.length) && position) {
      filter.push({ position });
    }
    let slidesArray = await mainService.run("data", "carosel", "all", {
      filter,
    });

    setSlideImages(slidesArray);
    if (slidesArray.length > 0) {
      if (acquireImages)
        acquireImages(
          slidesArray.map((one) => ({
            name: `${one.id}_image`,
            width: position === "Top" ? 734 : 300,
            height: position === "Top" ? 262 : 1200,
          }))
        );
      setActiveImage(slidesArray[0]);
    }
  };

  const nextSlide = (slides, active) => {
    if (slides.length === 0) return;
    let idx = active ? slides.findIndex((one) => one.id === active.id) : 0;
    idx++;
    if (idx >= slides.length) idx = 0;
    setActiveImage(slides[idx]);
  };

  const setSlide = (n) => {
    setActiveImage(slideImages[n]);
  };

  return (
    <div className={styles.wrapper}>
      {activeImage && (
        <>
          {/* <div
            className={styles.images}
            style={{
              background: `url(${images[activeImage.id + "_image"]})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
              cursor: "pointer",
            }}
            onClick={() => activeImage.link && window.open(activeImage.link)}
          ></div> */}
          <div className={styles.images}>
            {images[activeImage.id + "_image"] ? (
              <img
                src={images[activeImage.id + "_image"]}
                style={{
                  objectFit: position === "Top" ? "fill" : "cover",
                  height: "100%",
                  width: "100%",
                  cursor: "pointer",
                }}
                onClick={() =>
                  activeImage.link && window.open(activeImage.link)
                }
              ></img>
            ) : null}
          </div>
          <div className={styles.slider}>
            {slideImages.map((n, i) => (
              <div
                key={"slide-image-" + i}
                className={activeImage.id === n.id ? "active" : "inactive"}
                onClick={() => setSlide(i)}
              ></div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default withImages(Slides);
