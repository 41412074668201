import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ContestCard from "../card/contestCard";
import Loading from "../Loading";
import withImages from "../../_components/core/withImages";

import styles from "./liveContests.module.scss";
import { useTranslation } from "react-i18next";

const LiveContests = React.forwardRef(
  ({ contests = [], acquireImages, images = {}, loading }, ref) => {
    const [t] = useTranslation();

    useEffect(() => {
      fetchImages();
    }, [contests]);

    const fetchImages = () => {
      if (acquireImages)
        acquireImages(
          contests.map((contest) => ({
            name: `${contest?.contest?.id || contest.id}_contestBackground`,
            width: 150,
            height: 100,
          }))
        );
    };

    return (
      <section className={styles.wrapper}>
        <div className={styles.contests}>
          {contests?.map((contest, i) => {
            if (contests.length - 1 === i) {
              return (
                <ContestCard
                  ref={ref}
                  key={contest?.contest?.id || contest.id}
                  contest={contest.contest || contest}
                  userEventId={contest.id}
                  image={
                    images[
                      `${contest?.contest?.id || contest.id}_contestBackground`
                    ]
                  }
                />
              );
            }
            return (
              <ContestCard
                key={contest?.contest?.id || contest.id}
                contest={contest.contest || contest}
                userEventId={contest.id}
                image={
                  images[
                    `${contest?.contest?.id || contest.id}_contestBackground`
                  ]
                }
              />
            );
          })}
        </div>
      </section>
    );
  }
);

PropTypes.LiveContests = {
  contests: PropTypes.array,
  acquireImages: PropTypes.func,
  images: PropTypes.object,
  loading: PropTypes.bool,
};

export default withImages(LiveContests);
