import React from "react";
import styles from "./topPerformers.module.scss";

function TopPerformers({performers}) {

  const bodyPeformersDiv = performers.map((el, index) => {
    return (
      <div key={index} className={styles.mvps}>
          <p>{el.action}</p>
          <p className={styles.value} >{el.value}</p>
          <p className={styles.nick} >{el.player.nick}</p>
        </div>
    )
  })

  return (
    <div className={styles.wrapper}>
      <h1>Top Performers</h1>
      <div className={styles.body}>
        {bodyPeformersDiv}
      </div>
    </div>
  );
}

export default TopPerformers;