import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Input from '../../_components/input/input';
import Button from '../../_components/button/button';
import { useTranslation } from 'react-i18next';
import { userActions } from '../../_store/actions/user.actions';

const PasswordModal = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState(false);
  const [user, setUser] = useState({
    oldPassword: '',
    password: '',
    confirmPassword: '',
  });
  const handleChange = e => {
    setError(false);
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value.trim();
    const name = target.name;
    setUser(user => ({ ...user, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setError(false);
      dispatch(userActions.changePassword(user));
    } else {
      setError(true);
    }
  };

  const validateForm = () => {
    const isValid = user.oldPassword &&
      user.password &&
      user.confirmPassword &&
      (user.password === user.confirmPassword);
    if (!isValid) {
      setError(true);
      return false;
    } else {
      setError(false);
      setIsValid(true);
      return true;
    }
  };
  return (
    <div>
      <form className="auth-form" onSubmit={handleSubmit}>
        <h4 className="auth-header">{t('d60155617')}</h4>
        <div className="form-body">
          <Input name="oldPassword" type="password" value={user.oldPassword} onChange={handleChange} placeholder={t('d91638408')} />
          {error && !user.oldPassword && <div className="input-err-msg">{t('d60624389')}</div>}

          <Input name="password" type="password" value={user.password} onChange={handleChange} placeholder={t('d91901397')} />
          {error && !user.password && <div className="input-err-msg">{t('d61055606')}</div>}

          <Input name="confirmPassword" type="password" value={user.confirmPassword} onChange={handleChange} placeholder={t('d92125757')} />
          {error && !user.confirmPassword && <div className="input-err-msg">{t('d38218003')}</div>}
          {error && (user.confirmPassword !== user.password) && <div className="input-err-msg">{t('d38453969')}</div>}

        </div>
        <Button
          label={t('d58938009')}
          btnClass={isValid && !error ? 'btnPrimary' : 'btnSecondary'}
          fullWidth={true}
          type="submit"
        />
      </form>
    </div>
  );
};

export default PasswordModal;
