import React, { useState, useEffect } from 'react';
import styles from './walletOverview.module.scss';
import BalanceChart from '../balanceChart/balanceChart';
import { useTranslation } from 'react-i18next';
import accounting from 'accounting-js';
import balance  from '../../_assets/images/icons/balance.svg'

const WalletOverviewLeft = ({ wallet = {} }) => {
  const [t] = useTranslation();
  const [chartValue, setChartValue] = useState(0);
  const getChartValue = ({ totalWin = 0, amount = 0 }) => {
    const percentage = amount ? Math.round(100 * totalWin / amount) : totalWin ? 100 : 0
    setChartValue(percentage)
  }

  useEffect(() => {
    getChartValue(wallet);
  }, [wallet])

  return (
    <div>
    <div className={styles.container}>
      <div className={styles.balanceWrapper}>
      <h4> {t('d33137637')} </h4>
      <div className={styles.balance}>
        <img src={balance} alt="Balance" />
      <h4 className={styles.balanceValue}>
        {accounting.formatMoney(wallet.amount || 0)}
      </h4>
      </div>
    </div>
      {/* <BalanceChart value={chartValue} color="#8f9bb3" trailColor="#090d28" /> */}
      <div className={styles.text}>
        <span className={styles.flex}>
          <h5>{t('d33738730')}:</h5> <h5 className={styles.balance}>{accounting.formatMoney(wallet.totalWin || 0)}</h5>
        </span>
        <span>
          <h5>{t('d34033605')}:</h5><h5 className={styles.balance}>{accounting.formatMoney(wallet.amount || 0)}</h5>
        </span>
        <span>
          <h5>{t('d3542824')}: </h5><h5 className={styles.balance}>{accounting.formatMoney(wallet.lastWin || 0)}</h5>
        </span>
      </div>
    </div>
    <div className={styles.spacer}></div>
    </div>
  );
};

export default WalletOverviewLeft;
