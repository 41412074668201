/**
 * Created by laslo on 8/20/20.
 */

export const hook = {
  'influencer' : (name, value, data) => {
    if (value) {
      switch (name) {
        case 'name' :
          data.link = window.location.origin.toString() + '/#/lobby?ref=' + encodeURIComponent(btoa(value))
      }
    }
  },
  'player' : (name, value, data, selectData) => {
    console.log('data', data)
    console.log('selectData', selectData)
    if (value) {
      switch (name) {
        case 'fppm' :
          if (data.game) {
            const game = (selectData['data-game'] || []).find(one => one.name === data.game)
            if (game) {
              const multiplier = game.multiplier ? Number(game.multiplier) : 210 ;
              const minSalary = game.minSalary ? Number(game.minSalary) : 5000;
              const maxSalary = game.maxSalary ? Number(game.maxSalary) : 18000;
              const rounding = 100;
              if (!value) value = 0
              value = Number(value)
              data.salary = value * multiplier
              if (data.salary < minSalary) data.salary = minSalary
              else if (data.salary > maxSalary) data.salary = maxSalary
              data.salary =  rounding * Math.round(data.salary / rounding)
            }
          }
      }
    }
  }

}