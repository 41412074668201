import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { eventConstants } from '../_store/constants';
import { wsUrl } from '../environment';

const Socket = () => {
  const store = useSelector((state) => state);
  let socket;
  let live = true
  let reconnectTime = 500;

  const dispatch = useDispatch();

  const disconnect = () => {
    live = false
    if (socket) {
      socket.close()
    }
  }

  const connect = (username) => {
    live = true
    socket = new WebSocket(`${wsUrl}/${username}`);

    socket.onopen = () => {
      reconnectTime = 1000;
    };

    socket.onclose = () => {
      if (!live) {
        return
      }
      setTimeout(() => {
        if (live) {
          connect(username);
        }
      }, reconnectTime);
    };

    socket.onmessage = (message) => {
      try {
        // const event = typeof(message.data) === 'object' ? JSON.parse(message.data) : message.data;
        const event = JSON.parse(message.data);
        if (event) {
          dispatch({ type: eventConstants.RECEIVED_EVENT, event });
        }
      } catch (err) {
        console.log('socket error parsing websocket data');
      }
    };

    socket.onerror = () => {
      reconnectTime *= 2;
      if (reconnectTime > 30000) reconnectTime = 30000;
    };
  };

  useEffect(() => {
    if (store.auth.user && store.auth.user.username) {
      connect(store.auth.user.username);
    } else {
      disconnect()
    }
  }, [store.auth.user]);

  useEffect(() => {
    return () => disconnect()
  }, []);

  return (<div />);
};

export default Socket;
