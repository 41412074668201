import React from 'react';
import PageHeading from '../../_components/pageHeading/pageHeading';
import { useTranslation } from 'react-i18next';

const UnderConstruction = () => {
  const [t] = useTranslation();
  return (
    <div className="container-wrapper">
      <PageHeading heading={t('d93198106')} subheading={t("d93464055")} />
    </div>
  );
};

export default UnderConstruction;
