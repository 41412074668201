import React, { useState, useEffect, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Login from "../auth/loginComponent/login";
import Loading from "../../_components/Loading";
import Iframe from "../../_components/iframe/iframe";
import LiveContests from "../../_components/liveContests/liveContests";
import Slides from "../../_components/slides/slides";
import PlayerProps from "../../_components/playerProps/playerProps";
import storageService from "../../_services/storage.service";
import { mainActions } from "../../_store/actions/main.actions";
import { modalActions } from "../../_store/actions/modal.actions";
import { userActions } from "../../_store/actions/user.actions";
import { preparePlayerProps, sortPlayerProps, filterPlayerPropsByGame } from "../../_utils/contest";

import PlayerPropsDetails from "../../_components/playerPropsDetails/playerPropsDetails";
import PlayerPropsHowToPlay from "../../_components/playerPropsDetails/playerPropsHowToPlay";
import { ReactComponent as CloseIcon } from "../../_assets/images/icons/closeIcon.svg";
import { ReactComponent as ArrowDown } from "../../_assets/images/icons/arrowDownWhite.svg";

import  BagOfMoney  from '../../_assets/images/landing/2639867_bag_money_icon.png'
import  SupportIcon  from '../../_assets/images/landing/9054987_bx_support_icon.png'
import  WithdrawalIcon  from '../../_assets/images/landing/9054911_bx_money_withdraw_icon.png'

import  TwitterIcon  from '../../_assets/images/landing/104461_twitter_icon.png'
import  InstagramIcon  from '../../_assets/images/landing/1161954_instagram_icon.png'
import  FacebookIcon  from '../../_assets/images/landing/104458_facebook_social media_fb_social_icon.png'
import  DiscordIcon  from '../../_assets/images/landing/3069758_circle_discord_gaming_messenger_round icon_icon.png'
import  YoutubeIcon  from '../../_assets/images/landing/5279120_play_video_youtube_youtuble logo_icon.png'

import styles from "./landing.module.scss";
import "./landing.module.scss";

const mapGames = (games = [], t) => {
  return games.map((game) => {
    return {
      type: "game",
      value: t(game.name) || game.name,
      label: t(game.name) || game.name,
      id: game.id,
    };
  });
};

const Landing = (props) => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [contests, setContests] = useState(
    storageService.getData("data", "contest", "all")
  );
  const [loading, setLoading] = useState(true);
  const [stream, setStream] = useState({});
  const [selectedPlayerProps, setSelectedPlayerProps] = useState([]);
  const [playerPicksData, setPlayerPicksData] = useState(
    storageService.getData("data", "playerPicks", "all") || []
  );
  const [playerProps, setPlayerProps] = useState(
    storageService.getData("data", "playerPicks", "all") || []
  );
  const [games, setGames] = useState(
    storageService.getData("data", "lobby_games", "all")
  );

  const [gameTypesFilter, setFilterGameTypes] = useState({});
  const [showMoreGames, setShowMoreGames] = useState(false);
  const [limitConfig, setLimitConfig] = useState(storageService.getData("config", "limit", "data") || {});
  const [minPlayers, setMinPlayers] = useState(3)

  const gamesData = useMemo(() => {
    return mapGames(games, t);
  }, [games]);

  const iframeDivRef = useRef(null);

  const history = useHistory();

  const getConfig = async () => {
    let config = await dispatch(mainActions.run("config", "limit", "get"));
    config = config || {}
    setLimitConfig(config);
    if (config.playerPropsMultiplier) {
      setMinPlayers(Math.min(Object.keys(config.playerPropsMultiplier)) || 3)
    }
  };

  const getData = async () => {
    setLoading(true);
    const now = new Date().getTime();
    const filter = [{ from: { $gt: now }, publish: true }];

    const result = await dispatch(
      mainActions.run(
        "data",
        "contest",
        "all",
        { filter, sort: { from: 1 } },
        null,
        true
      )
    );
    delete filter[0].publish;
    filter[0] = {
      ...filter[0],
      canceled: { $ne: true },
      finished: { $exists: false },
    };
    const playerPicksResult = await dispatch(
      mainActions.run(
        "data",
        "playerPicks",
        "all",
        { filter, sort: { from: 1 } },
        null,
        true
      )
    );
    const sortedPlayerPicks = playerPicksResult && Array.isArray(playerPicksResult) ? sortPlayerProps(playerPicksResult) : []
    storageService.setData("data", "playerPicks", "all", sortedPlayerPicks);
    setContests(result);
    setPlayerPicksData(sortedPlayerPicks);
    const gamesData = await dispatch(mainActions.run("data", "game", "all"));
    setGames(gamesData)
    setLoading(false);
  };

  useEffect(() => {
    const getStream = async () => {
      const streamData = await dispatch(
        mainActions.run("data", "stream", "get")
      );
      setStream(streamData);
    };
    getStream();
  }, []);

  useEffect(() => {
    if (props.location && props.location.state) {
      // If user user isn't autentificated open Login page and don't rerender this component
      if (!store.auth.user) {
        // setRerender(false);
        dispatch(userActions.saveRoute(props.location.state.redirectTo));
        dispatch(modalActions.openModal(<Login />, "Login modal", "md"));
      }
    }
  }, [props.location && props.location.state]);

  useEffect(() => {
    getData();
    getConfig()
  }, []);

  useEffect(() => {
    if (playerPicksData && gameTypesFilter) {
      setPlayerProps(filterPlayerPropsByGame(playerPicksData, gameTypesFilter));
    }
  }, [playerPicksData, gameTypesFilter]);

  const showHowToPlay = () => {
    dispatch(
      modalActions.openModal(<PlayerPropsHowToPlay />, t("d38515927"), "lg")
    );
  };

  const showMoreDetails = () => {
    dispatch(
      modalActions.openModal(
        <PlayerPropsDetails playerPicksData={playerPicksData} />,
        "More details",
        "lg"
      )
    );
  };

  const toggleMoreGames = () => {
    setShowMoreGames((prev) => !prev);
  };

  const removeFromFilter = (item) => {
    if (gameTypesFilter[item]) {
      const updatedFilter = { ...gameTypesFilter };
      delete updatedFilter[item];
      setFilterGameTypes(updatedFilter);
    }
  };

  const addToGameFilter = (item) => {
    const updatedFilter = { ...gameTypesFilter, [item.label]: item.label };

    setFilterGameTypes(updatedFilter);
  };

  const handlePlayerCardClick = (playerPick, bet) => {
    const index = selectedPlayerProps.findIndex(
      (item) => item.id === playerPick.id
    );

    if (index === -1) {
      setSelectedPlayerProps((prev) => [...prev, { ...playerPick, bet }]);
      return;
    }
    if (selectedPlayerProps[index].bet === bet) {
      removeSelectedPlayer(index);
      return;
    }

    const updatedPlayers = selectedPlayerProps.map((pl, idx) => {
      if (idx === index)
        return {
          ...pl,
          bet,
        };
      return pl;
    });

    setSelectedPlayerProps(updatedPlayers);
  };

  const removeSelectedPlayer = (index) => {
    const newArray = [...selectedPlayerProps];
    newArray.splice(index, 1);
    setSelectedPlayerProps(newArray);
  };

  const handleProceed = (alwaysGo) => {
    storageService.setData(
      "data",
      "selectedPlayerPicks",
      "all",
      selectedPlayerProps
    );
    if ( alwaysGo || (
      selectedPlayerProps &&
      Array.isArray(selectedPlayerProps) &&
      selectedPlayerProps.length)
    )
      history.push(`/lobby/picks`);
  };

  const selectedPlayersMap = {};
  selectedPlayerProps.forEach(
    (item) => (selectedPlayersMap[item.id] = item.bet)
  );

  const style = iframeDivRef.current?.offsetHeight
    ? { height: iframeDivRef.current.offsetHeight }
    : {};

  return (
    <div className="global-layout-content">
      <section className="layout-content-left-banner">
        <Slides position="Side" show={true} />
      </section>
      <main className="layout-content landing-page">
        <section className="layout-content-center">
          <div className="upper-wrapper">
            <div className="left">
              <div className="iframeWrapper">
                <Iframe streamUrl={stream?.link} ref={iframeDivRef} />
              </div>
            </div>
            <div className="right" style={style}>
              <Slides position="Top" show={true} />
            </div>
          </div>
          <div className="lower-wrapper">
            <div className={styles.info}>
              <p className={styles.smallText}>Number 1 Daily Fantasy Esports Platform</p>
              <p className={styles.bigText}>100% Match on first Deposits up to $100</p>
              <p className={styles.greenText}>{"Choose over/under on " + (minPlayers || 3) + " or more players"}</p>
              <p className={styles.greenText}>Get them all right and win!</p>
              <button className={styles.playNow} onClick={() => handleProceed(true)}>
                <span>Play Now</span>
              </button>
            </div>
            <div className="lower-right">
              <div className={styles.filteredTypesWrapper}>
                {Object.keys(gameTypesFilter).map((gameType) => (
                  <div
                    className={styles.filteredType}
                    key={gameType + "filter"}
                  >
                    <span>{gameTypesFilter[gameType]}</span>{" "}
                    <span
                      className={styles.closeIconWrapper}
                      onClick={() => removeFromFilter(gameType)}
                    >
                      <CloseIcon />
                    </span>
                  </div>
                ))}
              </div>
              <header className={styles.header}>
                <div className="type">Player picks</div>
                <div>
                  <span className={styles.howToPlay} onClick={showHowToPlay}>
                    How to play
                  </span>
                  <span className={styles.details} onClick={showMoreDetails}>
                    More details
                  </span>
                </div>
                <div className={styles.dropdown} onClick={toggleMoreGames}>
                  <span className={styles.dropdownMoreGames}>
                    More games
                    <ArrowDown />
                  </span>
                  <ul
                    className={
                      showMoreGames
                        ? styles.dropdownContentOpen
                        : styles.dropdownContent
                    }
                  >
                    {gamesData.map((item) => (
                      <li
                        key={item.id}
                        className={
                          gameTypesFilter[item.value] ? styles.selected : ""
                        }
                        onClick={() => addToGameFilter(item)}
                      >
                        {item.label}
                      </li>
                    ))}
                  </ul>
                </div>
                {Array.isArray(selectedPlayerProps) &&
                selectedPlayerProps.length >= minPlayers ? (
                  <button className={styles.proceedBtn} onClick={handleProceed}>
                    <span>Lock in player props</span>
                  </button>
                ) : null}
              </header>
              <div className="player-props-wrapper">
                {playerProps &&
                Array.isArray(playerProps) &&
                playerProps.length ? (
                  <PlayerProps
                    playerProps={playerProps}
                    onClick={handlePlayerCardClick}
                    selectedPlayersMap={selectedPlayersMap}
                  />
                ) : !loading ? (
                  <div className="message-info">
                    There are no players available now.
                  </div>
                ) : (
                  <Loading />
                )}
              </div>
            </div>
          </div>
          <div className="landing-footer">
            <div className="landing-footer-advertisement-wraper">
              <div className="landing-footer-advertisement">
                <img src={BagOfMoney} alt="bag of money"/>
                <p>BIG PAYOUTS</p>
              </div>
              <div className="landing-footer-advertisement">
                <img src={SupportIcon} alt="support"/>
                <p>24/7 Discord Support</p>
              </div>
              <div className="landing-footer-advertisement">
                <img src={WithdrawalIcon} alt="withdrawal"/>
                <p>Fast Withdrawals</p>
              </div>
            </div>
            <div className="landing-footer-social">
              <a href="https://www.facebook.com/eliteduels/" target="blank">
                <img src={FacebookIcon} alt="facebook"/>
              </a>
              <a href="https://twitter.com/eliteduels" target="blank">
                <img src={TwitterIcon} alt="twitter"/>
              </a>
              <a href="https://www.instagram.com/eliteduels/" target="blank">
                <img src={InstagramIcon} alt="instagram"/>
              </a>
              <a href="https://t.co/3WhtvPxHrs" target="blank">
                <img src={DiscordIcon} alt="discord"/>
              </a>
              <a href="https://www.youtube.com/channel/UCyzi4bxkqDzaseeByxt9dxg" target="blank">
                <img src={YoutubeIcon} alt="youtube"/>
              </a>
            </div>
            <div className="landing-footer-links-wraper">
              <div className="landing-footer-links">
                <p className="landing-footer-link-header">Company</p>
                <a className="landing-footer-link" href="#/terms_of_service">Terms Of Use</a>
                <a className="landing-footer-link" href="#/privacy_policy">Privacy Policy</a>
                <a className="landing-footer-link" href="https://discord.gg/WWWDZXTNy3" target="blank">Support</a>
              </div>
              <div className="landing-footer-links">
                <p className="landing-footer-link-header">About</p>
                <a className="landing-footer-link" href="#/about">About EliteDuels</a>
                <a className="landing-footer-link" href="#/withdraw_policy">Withdraw</a>
              </div>
              <div className="landing-footer-links">
                <p className="landing-footer-link-header">Games</p>
                <a className="landing-footer-link" href="#/how_to_play">How To Play</a>
                <a className="landing-footer-link" href="#/responsible_gaming">Responsible Gaming</a>
                <a className="landing-footer-link" href="#/faq">FAQ</a>
              </div>
            </div>
          </div>
        </section>

      </main>
      <section className="layout-content-right-banner">
        <Slides position="Side" show={true} />
      </section>
    </div>
  );
};

export default Landing;
