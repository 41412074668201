import { imageConstants } from '../constants';

const initialState = {
  loading: false
};
export const image = (state = initialState, action) => {
  switch (action.type) {
    case imageConstants.IMG_REQUEST:
      return { ...state, loading: true };
    case imageConstants.IMG_SUCCESS:
      return {
        ...state,
        [action.name]: action.data.data,
        loading: false,
      };
    case imageConstants.IMG_STORAGE:
      return {
        ...state,
        [action.name]: action.data,

      };
    case imageConstants.IMG_FAILURE:
      return { ...state, loading: false };
    case imageConstants.IMG_DELETE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case imageConstants.IMG_DELETE_FAILURE:
      return {
        ...state,
        loading: false
      }
    case imageConstants.IMG_DELETE_SUCCESS:
      delete state[action.name]
      return {
        ...state,
        loading: false
      }
    default:
      return state;
  }
};
