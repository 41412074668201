import React, { useState, useEffect } from "react";
import * as _ from "lodash";
import accounting from "accounting-js";
import EventHeader from "../../_components/eventHeader/eventHeader";
import CarouselItem from "../../_components/carouselItem/carouselItem";
import Carousel from "react-grid-carousel";
import EventLineup from "../../_components/eventLineup/eventLineup";
import LineupTabs from "../../_components/lineupTabs/lineupTabs";
import LineupTable from "../../_components/lineupTable/lineupTable";
import { useSelector, useDispatch } from "react-redux";
import { mainActions } from "../../_store/actions/main.actions";
import withImages from "../../_components/core/withImages";
import storageService from "../../_services/storage.service";
import { getPipeline } from "../../_utils";
import { useTranslation } from "react-i18next";
import SubLinks from "../../_components/subRoutes/subRoutesTitle";
import "./event.scss";
import { useHistory, NavLink } from "react-router-dom";
import SmallTitle from "../../_components/smallTitle/smallTitle";
import Loading from "../../_components/Loading";
import Login from "../auth/loginComponent/login";
import useWindowDimensions from "../../_utils/screenSize";
import PerfectScrollbar from "react-perfect-scrollbar";
import { modalActions } from "../../_store/actions/modal.actions";
import PlayerDisplay from "../../_components/playerDisplay/playerDisplay";
import Slides from "../../_components/slides/slides";

const Event = (props) => {
  const { width } = useWindowDimensions();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const {
    match: { path },
  } = props;
  const {
    match: {
      params: { id, userContestId },
    },
  } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [selectedData, setSelectedData] = useState({});
  const [tiers, setTiers] = useState([]);
  const history = useHistory();
  const [contest, setContest] = useState(
    storageService.getData("data", "userContestData", "get", id)
  );
  const [matches, setMatches] = useState(
    storageService.getData("data", "matches", "all")
  );
  const [players, setPlayers] = useState(
    storageService.getData("data", "profile", "all")
  );
  const [allSelectedPlayers, setAllSelectedPlayers] = useState([]);
  const [playersForCompare, setPlayersForCompare] = useState([]);
  const [processing, setProcessing] = useState(true);
  const [team, setTeam] = useState();
  const [tierPlayers, setTierPlayers] = useState({});
  const [salaryCapPlayers, setSalaryCapPlayers] = useState([]);
  const [captainPlayers, setCaptainPlayers] = useState({});
  const [influencerTeam, setInfluencerTeam] = useState([]);
  const openContestInfo = (data) => {
    dispatch(
      modalActions.openModal(
        <PlayerDisplay data={data} />,
        "Player modal",
        "md"
      )
    );
  };
  const tbHeading = [t("d6777170"),  t("d21583920"), t("d68274296"), t("d1125659") ];
  let timeout;

  useEffect(() => {
    const contestEntry = sessionStorage.getItem('contest_entry')
    if (contestEntry) {
      sessionStorage.removeItem('contest_entry')
      sessionStorage.removeItem('playerPool_Entry')

      setTeam(contestEntry.team)
    }
  }, [])

  useEffect(() => {
    setProcessing(true);
    fetchData();
  }, [userContestId]);

  const fetchData = async () => {
    const result = await dispatch(
      mainActions.run(
        "data",
        "contest",
        "event",
        { id, userId: store.auth.user ? store.auth.user.id : '-1', userContestId },
        null
      )
    );

    setContest(result);

    if (result.subtype && result.subtype === "beatThePro") {
      setInfluencerTeam(result.influencerTeam || []);
    }
  };

  useEffect(() => {
    if (contest) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (contest.matches) {
          let matchPlayers = [];
          setMatches(contest.matches);
          contest.matches.forEach((match) => {
            if (match.teams) {
              match.teams.forEach((team, idx) => {
                const temp = match["playersTeam" + (idx + 1)];
                if (temp) {
                  temp.forEach((el) => {
                    el.teamName = team;
                  });
                  if (temp && temp.length > 0) {
                    matchPlayers = matchPlayers.concat(temp);
                  }
                }
              });
            }
          });
          matchPlayers = _.uniqBy(matchPlayers, (one) => one.id);
          setPlayers(
            matchPlayers.sort(
              (a, b) => Number(b.fppm || 0) - Number(a.fppm || 0)
            )
          );
          if (matchPlayers.length === 0) {
            setProcessing(false);
          }
        }
      }, 500);
    }
  }, [contest]);

  useEffect(() => {
    if (players.length > 0) {
      const team = getTeam(contest);
      setTeam(team);
    }
  }, [players]);

  const getTeam = () => {
    const team = [];
    let player;
    players.map((p) => {
      player = playerInList(p);
      player && team.push(p);
    });
    return team;
  };

  const playerInList = (player) => {
    let found;
    if (contest && contest.team) {
      found = contest.team.find((id) => id === player.id);
    }
    return found;
  };

  useEffect(() => {
    if (
      store.main.data &&
      store.main.data.contest &&
      store.main.data.contest.data &&
      store.main.data.contest.data[id]
    ) {
      setContest(store.main.data.contest.data[id]);
    }
  }, [
    store.main.data && store.main.data.contest && store.main.data.contest.data,
  ]);

  useEffect(() => {
    if (contest && contest.contestType === "d09094712") {
      // Contest type Tiers
      tiersLogic();
    } else if (contest && contest.contestType === "d12792751") {
      // Contest type Captain

      captainLogic();
    } else if (contest && contest.contestType === "d85876432") {
      // Contest type Salary cap
      salaryCapLogic();
    }
  }, [players]);

  useEffect(() => {
    if (contest && contest.contestType === "d09094712") {
      // Contest type Tiers edit
      tiersCaptainLogicEdit(contest.contestType);
    }
  }, [tierPlayers, contest]);

  useEffect(() => {
    if (contest && contest.contestType === "d85876432") {
      // Contest type Salary cap edit
      salaryCapLogicEdit();
    }
  }, [salaryCapPlayers, contest]);

  useEffect(() => {
    if (contest && contest.contestType === "d12792751") {
      // Contest type Captain edit
      tiersCaptainLogicEdit(contest.contestType);
    }
  }, [captainPlayers, contest]);

  const tiersLogic = () => {
    if (players && players.length > 0 && contest.game.tiers) {
      const tierLimits = [];
      const tierKeys = Object.keys(contest.game.tiers).sort(
        (a, b) => Number(a) - Number(b)
      );
      tierKeys.forEach((key) => tierLimits.push(contest.game.tiers[key]));
      setTiers(tierLimits);
      const count = players.length;
      let addeddCount = 0;
      const tierResult = {};
      tierLimits.forEach((tier, i) => {
        let tierPlayersCount;
        if (i === tierLimits - 1) {
          tierPlayersCount = players.count - addeddCount;
        } else {
          tierPlayersCount = Math.round((tier / 100) * count);
        }
        tierResult[i] = players.slice(
          addeddCount,
          addeddCount + tierPlayersCount
        );
        addeddCount += tierPlayersCount;
      });
      setTierPlayers(tierResult);
    }
  };
  const captainLogic = () => {
    if (players && players.length > 0) {
      const list = {
        0: [],
        1: [],
      };
      players.forEach((player, i) => {
        if (player.salary > contest.captainCap) {
          list[0].push(player);
        } else {
          list[1].push(player);
        }
      });
      Object.keys(list).forEach((key) => {
        list[key].sort((a, b) => b.salary - a.salary);
      });
      setCaptainPlayers(list);
      setTiers(["d64667885", "d6777170"]);
    }
  };
  const salaryCapLogic = () => {
    if (players && players.length > 0) {
      players.sort((a, b) => b.salary - a.salary);

      setSalaryCapPlayers(players);
    }
  };

  const tiersCaptainLogicEdit = (contestType) => {
    let listPlayers = {};
    if (contestType === "d09094712") {
      listPlayers = { ...tierPlayers };
    } else if (contestType === "d12792751") {
      listPlayers = { ...captainPlayers };
    }
    if (listPlayers && Object.keys(listPlayers).length > 0) {
      if (contest && contest.userContest && userContestId) {
        const listSelectedPlayers = contest.userContest.team;
        const playersByTiers = [];
        listSelectedPlayers.forEach((el) => {
          _.find(listPlayers, (list, i) => {
            _.find(list, (found) => {
              if (found.id === el) {
                playersByTiers.push({ tier: i, player: found });
              }
            });
          });
        });
        setAllSelectedPlayers(playersByTiers);
        setPlayersForCompare(playersByTiers);
      }
      setProcessing(false);
    }
  };
  const salaryCapLogicEdit = () => {
    if (salaryCapPlayers && salaryCapPlayers.length > 1) {
      if (contest && contest.userContest && userContestId) {
        const listSelectedPlayers = contest.userContest.team;
        const playersByTiers = [];
        listSelectedPlayers.forEach((el) => {
          _.find(salaryCapPlayers, (list) => {
            if (list.id === el) {
              playersByTiers.push({ player: list });
            }
          });
        });
        setAllSelectedPlayers(playersByTiers);
        setPlayersForCompare(playersByTiers);
      }
      setProcessing(false);
    }
  };

  const changeTab = (idx) => {
    setActiveTab(idx);
  };

  const selectPlayer = (player) => {
    if (
      (contest && contest.contestType === "d09094712") ||
      (contest && contest.contestType === "d12792751")
    ) {
      if (activeTab < tiers.length - 1) {
        changeTab(+activeTab + 1);
      }
      setSelectedData({ tier: activeTab, player: player });
    } else if (contest && contest.contestType === "d85876432") {
      setSelectedData({ player: player });
    }
  };

  const onEnter = async (data, paymentMetod) => {
    const paymentAmount =
      paymentMetod === "cash"
        ? { entryFee: data.entryFee ? data.entryFee : contest.entryFee }
        : {
            entryCreditFee: data.entryCreditFee
              ? data.entryCreditFee
              : contest.entryCreditFee,
          };
    if (contest.userContest && contest.userContest.id && userContestId) {
      await dispatch(
        mainActions.run(
          "data",
          "userContest",
          "update",
          {
            id: contest.userContest.id,
            contestId: contest.id,
            team: data.map((el) => el.player.id),
          },
          t("d79057940")
        )
      );
      history.push({ pathname: "/contests", hash: "#upcoming" });
    } else {
      if (!store.auth.user) {
        sessionStorage.setItem(`contest_entry`, { contestId: contest.id, team: data.map((el) => el.player.id)});
        dispatch(modalActions.openModal(<Login />, "Login modal", "md"));
        return
      }
      const result = await dispatch(
        mainActions.run(
          "data",
          "userContest",
          "new",
          {
            userId: store.auth.user.id,
            contestId: contest.id,
            name: contest.name,
            team: data.map((el) => el.player.id),
            ...paymentAmount,
          },
          t("d38764184")
        )
      );
      if (result) {
        dispatch(
          mainActions.agg(
            "wallet",
            "transactions",
            "user",
            "get",
            getPipeline("wallet", store.auth.user.id),
            store.auth.user.id
          )
        );
        history.push({ pathname: "/contests", hash: "#upcoming" });
      }
    }
  };

  const compareSelectedPlayers = (data) => {
    setPlayersForCompare(data);
  };
  
  const caroselIds = contest?.carosel || []
  
  return (
    <div className="global-layout-content">
    <div className="layout-content-left-banner">
      <Slides position="Side" ids={caroselIds} show={!!caroselIds.length} />
    </div>
    <div className="layout-content">
      {contest && matches && (
        <>
          <div className="layout-content-center">
            <div className="container">
              <div className="wrapper">
                <div className="title">
                  <div className="text">
                    <div className="gameNameDiv">
                      <h5>{contest && contest.name}</h5><p>{!contest.entryFee && '[FREE ENTRY]'  }</p>
                    </div>
                    <div className="gameInfoDiv">
                      <div className="gameInfoDivUp">
                        <p>{t("d58873906").toLocaleUpperCase()}:</p>
                        <p className="rightParagraph">{contest && contest.gameName.toLocaleUpperCase()}</p>
                      </div>
                      <div className="gameInfoDivDown">
                      <p >
                        {t("d42026764").toLocaleUpperCase()} {t("d58873906").toLocaleUpperCase()}:
                      </p>
                      <p className="rightParagraph">{contest && t(contest.contestType).toLocaleUpperCase()}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="boxBasicInfo">
                  <div className="top">
                    {contest && matches && (
                      <EventHeader contest={contest} matches={matches} />
                    )}
                  </div>
                  {contest && contest.subtype === "beatThePro" && (
                    <div className="middle">
                      {t("d27625835")}
                      <div className="tableCustom">
                        <div className="tableHead">
                          <span>#</span>
                          <span className="colWithImg">{t("d6777170")}</span>
                          <span className="width40">{t("d68274296")}</span>
                          <span className="width10">{t("d1125659")}</span>
                        </div>

                        <PerfectScrollbar className="purpleScroll">
                          <div className="tableBody">
                            {influencerTeam &&
                              influencerTeam.length > 0 &&
                              influencerTeam.map((data, i) => (
                                <div key={data.id}>
                                  <span>{i + 1}</span>
                                  <span
                                    className="colWithImg"
                                    onClick={() => openContestInfo(data)}
                                  >
                                    <h5>{data.nick}</h5>
                                  </span>
                                  <span className="width40 colorGreen">
                                    <h5>
                                      {accounting.formatMoney(
                                        data.salary ? data.salary : 0
                                      )}
                                    </h5>
                                  </span>
                                  <h5 className="width10">{data.fppm}</h5>
                                </div>
                              ))}
                          </div>
                        </PerfectScrollbar>
                      </div>
                    </div>
                  )}
                  <div className="bottom">
                    {matches?.length > 0 && (
                        <div className="carousel">
                          <div className='selectPlayers' style={{ display: "flex" }}>
                            <SmallTitle title={t("matches")+ ":"}></SmallTitle>
                            <div className={"eventTitle"}>
                              [{matches.length}]
                            </div>
                          </div>
                          <div className="matchesWrapper">
                            {matches.map(match => match && match.id && <div key={match.id} className="matchItem"><CarouselItem item={match} key={match.id} /></div>)}
                          </div>
                        </div>
                    )}
                  </div>
                </div>
              </div>
              <div style={{ width: "4%" }}></div>
              <div className="wrapper-bottom">
                <div className="top">
                  {contest && contest.maxEntries && (
                    <>
                      {!processing &&
                      ((+contest.entered < +contest.totalEntries &&
                        contest.userMaxEntries < contest.maxEntries) ||
                        allSelectedPlayers.length > 0) ? (
                        <EventLineup
                          isUpdate={
                            contest &&
                            contest.userContest &&
                            contest.userContest.id &&
                            userContestId
                          }
                          selectedData={selectedData}
                          contest={contest}
                          tiers={tiers}
                          onEnter={onEnter}
                          selectedEditData={allSelectedPlayers}
                          contestType={contest.contestType}
                          compare={compareSelectedPlayers}
                        />
                      ) : !processing ? (
                        <div>
                          {contest.userMaxEntries &&
                            +contest.userMaxEntries == +contest.maxEntries && (
                              <h4>
                                {t("d69342477")}{" "}
                                <NavLink
                                  to={{
                                    pathname: "/contests",
                                    hash: "#upcoming",
                                  }}
                                  className="nav-link"
                                >
                                  {" "}
                                  {t("d10908779")}
                                </NavLink>
                              </h4>
                            )}
                          {contest.entered &&
                            +contest.entered == +contest.totalEntries && (
                              <h4>{t("d39664522")}</h4>
                            )}
                        </div>
                      ) : (
                        <Loading />
                      )}
                    </>
                  )}
                </div>
                <div className="bottom">
                  {contest && contest.from > new Date().getTime() && (
                    <>
                      {((contest && contest.contestType === "d09094712") ||
                        (contest && contest.contestType === "d12792751")) && (
                        <LineupTabs
                          tiers={tiers}
                          activeTab={activeTab}
                          changeTab={changeTab}
                          contest={contest}
                          contestType={contest.contestType}
                        />
                      )}
                      {contest && contest.contestType === "d09094712" && (
                        <LineupTable
                          tbHeading={tbHeading}
                          selectedPlayers={playersForCompare}
                          tbData={tierPlayers[activeTab]}
                          onClick={selectPlayer}
                          contest={contest}
                        />
                      )}
                      {contest && contest.contestType === "d85876432" && (
                        <>
                        <div className="selectPlayersDiv">
                          <hr /><SmallTitle title={t("d2582874") + " " + t("d465729339") }></SmallTitle><hr />
                        </div>
                          <LineupTable
                            tbHeading={tbHeading}
                            selectedPlayers={playersForCompare}
                            tbData={salaryCapPlayers}
                            onClick={selectPlayer}
                            contest={contest}
                          />
                        </>
                      )}
                      {contest && contest.contestType === "d12792751" && (
                        <LineupTable
                          tbHeading={tbHeading}
                          selectedPlayers={playersForCompare}
                          tbData={captainPlayers[activeTab]}
                          onClick={selectPlayer}
                          contest={contest}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* { team && <div className="container-wrapper">
	  <div className="team">
	    <SmallTitle title={t('d18067502')}></SmallTitle>
	    <div className="player">
	      {team.map((t) => (
		<div key={t.id}> 
		  {t.nick}
		  <span> {t.team} </span>
		  <span> {t.fppm} </span>
		</div>
	      ))}
	    </div>
	  </div>
	</div> } */}
            {/* <div className="layout-content-reversed">
      {contest && (contest.from > new Date().getTime()) && <div className="layout-content-left">
        {((contest && contest.contestType === 'd09094712') || (contest && contest.contestType === 'd12792751')) &&
          <div className="contest-types">
            <LineupTabs tiers={tiers} activeTab={activeTab} changeTab={changeTab} contestType={contest.contestType} /></div>}
        {contest && contest.contestType === 'd09094712' &&
          <LineupTable tbHeading={tbHeading} selectedPlayers={playersForCompare} tbData={tierPlayers[activeTab]} onClick={selectPlayer} contest={contest} />}
        {contest && contest.contestType === 'd85876432' &&
          <><div className="contest-types">
            <SmallTitle title={t('d4036504')}></SmallTitle>
          </div>
            <LineupTable tbHeading={tbHeading} selectedPlayers={playersForCompare} tbData={salaryCapPlayers} onClick={selectPlayer} contest={contest} />
          </>}
        {contest && contest.contestType === 'd12792751' &&
          <LineupTable tbHeading={tbHeading} selectedPlayers={playersForCompare} tbData={captainPlayers[activeTab]} onClick={selectPlayer} contest={contest} />}
      </div>}
      {contest && contest.maxEntries &&
        <div className="layout-content-right">
          {!processing && ((contest.userTotalEntries && +contest.userTotalEntries.length < +contest.totalEntries && contest.userMaxEntries.length < contest.maxEntries) || allSelectedPlayers.length > 0) ?
            <EventLineup
              isUpdate={contest && contest.userContest && contest.userContest.id}
              selectedData={selectedData}
              contest={contest}
              tiers={tiers}
              onEnter={onEnter}
              selectedEditData={allSelectedPlayers}
              contestType={contest.contestType}
              compare={compareSelectedPlayers} /> :
            !processing ? <div>
              {(contest.userMaxEntries && contest.userMaxEntries.length == contest.maxEntries) && <h4>{t('d69342477')} <NavLink to={{ pathname: '/contests', hash: '#upcoming' }} className="nav-link"> {t('d10908779')}</NavLink></h4>}
              {(contest.userTotalEntries && contest.userTotalEntries.length == contest.totalEntries) && <h4>{t('d39664522')}</h4>}
            </div> : <Loading />}
        </div>}
    </div> */}
          </div>
        </>
      )}
    </div>
    <section className="layout-content-right-banner">
        <Slides position="Side" ids={caroselIds} show={!!caroselIds.length} />
      </section>
    </div>
  );
};

export default withImages(Event);
