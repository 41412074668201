import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LinksNav from './linksNav';
import AuthNav from './authNav';
import { NavLink } from 'react-router-dom';
import logo from '../../_assets/images/logo/logo.svg';
import ToggleMobNav from './toggleMobNav';
import UserMenu from './userMenu';
import useWindowDimensions from '../../_utils/screenSize';
import { modalActions } from '../../_store/actions/modal.actions';
import Login from '../../_pages/auth/loginComponent/login';
import Register from '../../_pages/auth/registerComponent/register';
import Button from '../button/button';
import { useTranslation } from 'react-i18next';

import './navbar.scss';

const Navbar = ({ ready }) => {
  const [t] = useTranslation();
  const node = useRef();
  const { width } = useWindowDimensions();
  const store = useSelector(state => state);
  const dispatch = useDispatch();
  const [user, setUser] = useState();
  const [isMobile, setIsMobile] = useState((width >= 880) ? false : true);
  const [showNavLinks, setShowNavLinks] = useState((width >= 880) ? true : false);
  const [showUserMenu, setShowUserMenu] = useState(false);

  const openLoginModal = () => {
    dispatch(modalActions.openModal(<Login />, 'Login modal', 'md'));
  };
  const openRegisterModal = () => {
    dispatch(modalActions.openModal(<Register />, 'Register modal', 'xlg'));
  };

  useEffect(() => {
    setUser(store.auth.user);
  }, [store.auth && store.auth.user]);

  useEffect(() => {
    if (width >= 880) {
      setIsMobile(false);
      setShowNavLinks(true);
      setShowUserMenu(false);
    } else {
      setIsMobile(true);
      setShowNavLinks(false);
      setShowUserMenu(false);
    }
  }, [width]);

  useEffect(() => {
    if (!isMobile) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, []);

  const toggleLinkNav = () => {
    if (isMobile) {
      setShowNavLinks(!showNavLinks);
      setShowUserMenu(false);
    }
  };

  const toggleAuthNav = () => {
    setShowUserMenu(!showUserMenu);
    if (isMobile) {
      setShowNavLinks(false);
    }
  };

  const handleClickOutside = (e) => {
    if (!node.current.contains(e.target)) {
      setShowUserMenu(false);
    }
  };
  return (
    <div className="main-navbar">
      {isMobile && <ToggleMobNav toggleLinkNav={toggleLinkNav} />}
      <div ref={node}>
        <div className="auth-nav">
          <div className="container-wrapper">
            <div className="brand-logo">
              <NavLink to="/" ><img src={logo} alt="EliteDuels" /></NavLink>
            </div>
            <div className="links-nav-container">
              <LinksNav ready={ready} show={showNavLinks} toggleLinkNav={toggleLinkNav} />
            </div>
            <div className="nav-right">
              {user ? <AuthNav user={user} ready={ready} toggleAuthNav={toggleAuthNav} showUserMenu={showUserMenu} isMobile={isMobile} /> : (ready ? <ul>
                <li onClick={openLoginModal}>{t('d343815399')}</li>
                <li onClick={openRegisterModal} ><Button label={t('d67564498')} btnClass="btnPrimary" /></li>
              </ul> : null)}
            </div>
          </div>
        </div>
        {isMobile && <div className="container-fluid-wrapper mob-visible">
          <UserMenu toggleAuthNav={toggleAuthNav} show={showUserMenu} />
        </div>}
      </div>
      <div>
      </div>
    </div>
  );
};

export default Navbar;
