import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import Button from '../../_components/button/button';
import { imageActions } from '../../_store/actions/image.actions';
import { base64toBlob } from '../../_utils/images';
import { modalActions } from '../../_store/actions/modal.actions';
import useWindowDimensions from '../../_utils/screenSize';
import styles from './profile.module.scss';

const AvatarModal = ({ id, avatarSrc }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const [avatar, setAvatar] = useState(avatarSrc);
  const [scale, setScale] = useState(1);
  let editorRef;

  const setEditorRef = (editor) => editorRef = editor;

  const onClickSave = (e) => {
    e.preventDefault();
    if (editorRef) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      const canvas = editorRef.getImage();
      // If you want the image resized to the canvas size (also a HTMLCanvasElement)

      const formData = new FormData();
      formData.append('title', 'avatar');
      formData.append('file', base64toBlob(canvas.toDataURL()));
      formData.append('dataId', id);

      dispatch(imageActions.uploadImage(`${id}_avatar`, 200, 200, formData));
      dispatch(modalActions.closeModal());
    }
  };

  const handleDrop = dropped => {
    setAvatar(dropped[0]);
  };

  const onWheel = (e) => {
    setScale(scale + (e.deltaY < 1 ? -0.1 : 0.1))
  };

  return (
    <div className={styles.avatarUpload}>
      <Dropzone
        onDrop={handleDrop}
        noClick
        noKeyboard
        style={{ width: width < 500 ? '250px' : '400px', height: width < 500 ? '250px' : '400px' }}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} onWheel={onWheel}>
            <AvatarEditor
              ref={setEditorRef}
              width={width < 500 ? 250 : 400}
              height={width < 500 ? 250 : 400}
              image={avatar}
              scale={scale}
            />
            <input {...getInputProps()} />
          </div>
        )}
      </Dropzone>
      <h6 style={{ padding: '10px 0', textAlign: 'center' }}>{t('d49875215')}</h6>
      <Button
        label={t('d58938009')}
        btnClass="btnPrimary"
        fullWidth={true}
        type="submit"
        onClick={onClickSave}
      />
    </div>
  );
};

export default AvatarModal;
