import React from 'react';
import styles from './searchBar.module.scss';
import Input from '../input/input';
import Button from '../button/button';
import searchSvg from '../../_assets/images/icons/search.svg';
import PropTypes from 'prop-types';

const SearchBar = ({ onClick, onChange, value, placeholder }) => {
  const searchImage = <img src={searchSvg} alt="search" />;
  return (
    <form className={styles.searchWrapper}>
      <Input type="text" name="searchTerm" onChange={onChange} value={value} placeholder={placeholder} />
      <Button
        label={searchImage}
        btnClass="btnPrimary"
        type="submit"
        onClick={onClick}
      />
    </form>
  );
};

SearchBar.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default SearchBar;
