import React, { useState } from 'react';
import accounting from 'accounting-js';
import mainService from '../../../_services/main.service';
import toastService from '../../../_services/toastService';
import styles from './connectedTransactions.module.scss';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DatePickerComp from '../../../_components/datePicker/datePickerComp';
import Input from '../../../_components/input/input';
import Button from '../../../_components/button/button';
import moment from 'moment';

import arrowDown from '../../../_assets/images/icons/navbar/arrowDown_icon.png';
import arrowUp from '../../../_assets/images/icons/navbar/arrowUp_icon.png';

const _connectTransactions = (from, to, transactions = []) => {
  const transactionMap = {}
  const result = []

  transactions.filter(one => one.createdAt >= from && one.createdAt <= to ).forEach(one => {
    if (one.connectedTransactionId){
      if (!transactionMap[one.connectedTransactionId]) {
        one.connected = []
        transactionMap[one.connectedTransactionId] = one
        result.push(one)
      } else {
        transactionMap[one.connectedTransactionId].connected.push(one)
      }
    } else {
      if (transactionMap[one.id]) {
        transactionMap[one.id].connected.push(one)
      } else {
        result.push(one)
      }
    }
  })

  return result
}

const ConnectedTransactions = () => {
  const [result, setResult] = useState([]);
  const [from, setFrom] = useState(moment().startOf('month').toDate());
  const [to, setTo] = useState(moment().endOf('month').toDate());
  const [text, setText] = useState('');
  const [t] = useTranslation();

  const setDate = (type, date) => {
    if (type === 'from'){
      setFrom(moment(date).startOf('day').toDate())
    } else if (type === 'to'){
      setTo(moment(date).endOf('day').toDate())
    }
  }

  const handleTextChange = (e) => {
    e.preventDefault()
    const { value = '' } = e.target;
    setText(value.trim())
  }

  const getData = async (id) => {
    const filter = {}
    if (text && text.trim()) {
      filter['$or'] = [{ username: {'$regex' : `^${text.trim()}`, '$options' : 'i'} }, { email: {'$regex' : `^${text.trim()}`, '$options' : 'i'} }]
    } else {
      toastService.show('error', 'Please enter username, email or some search text');
      return
    }
    const data = await mainService.run('transactions', 'user', 'connected', { filter } );
    const connected = _connectTransactions(from.getTime(), to.getTime(), data && data.rows)
    console.log(connected)
    setResult(connected)
  }

  const elementOpen = (el, open) => {
    el.open = open
    setResult([...result])
  }

  const renderConnectedTransactions = (one, index) => {
    return one.connected && one.connected.map((el, i) => (
      <div key={el.id} className={styles.connectedBody}>
        <div className={styles.items}>
          <small>{index + '_' + (i + 1)}</small>
          <span className={styles.date}>{moment(el.createdAt).format('MM-DD-YYYY hh:mm:ss A')}</span>
          <span className={styles.name}>{el.username}</span>
          <span className={styles.email}>{el.email}</span>
          <span className={styles.type}>{el.transactionType}</span>
          <span className={styles.number}>{accounting.formatMoney(el.amount || 0)}</span>
          <span className={styles.number}>{accounting.formatNumber(el.credit || 0)}</span>
          <span className={styles.action}></span>
        </div>
      </div>
    ))
  }

  const renderTransactions = () => {
    return (
      <div>{result && result.length > 0 ? <div className={styles.tb}>
        <PerfectScrollbar className="purpleScroll">
          <div className={styles.head}>
            <div className={styles.items}>
              <small>#</small>
              <span className={styles.date}>{t('Date')}</span>
              <span className={styles.name}>{t('Username')}</span>
              <span className={styles.email}>{t('Email')}</span>
              <span className={styles.type}>{t('Type')}</span>
              <span className={styles.number}>{t('Amount')}</span>
              <span className={styles.number}>{t('Credit')}</span>
              <span className={styles.action}>{t('Connected')}</span>
            </div>
          </div>
          {result.map((el, i) => (
            <div>
              <div key={el.id} className={styles.body}>
                <div className={styles.items}>
                  <small>{i + 1}</small>
                  <span className={styles.date}>{moment(el.createdAt).format('MM-DD-YYYY hh:mm:ss A')}</span>
                  <span className={styles.name}>{el.username}</span>
                  <span className={styles.email}>{el.email}</span>
                  <span className={styles.type}>{el.transactionType}</span>
                  <span className={styles.number}>{accounting.formatMoney(el.amount || 0)}</span>
                  <span className={styles.number}>{accounting.formatNumber(el.credit || 0)}</span>
                  <span className={styles.action}>{
                    el.connected && el.connected.length ?
                      el.open ? <img src={arrowUp} alt="arrow_up" onClick={() => elementOpen(el, false)}></img> :
                        <img src={arrowDown} alt="arrow_down" onClick={() => elementOpen(el, true)}></img>
                      :null
                  }</span>
                </div>
              </div>
              {el.open && renderConnectedTransactions(el, i)}
            </div>
          ))}
        </PerfectScrollbar>
      </div> : <div className={styles.msg}> <div className="message-info">
        {result ? t('No Activities for entered filters') : ''}
      </div> </div>}
      </div>
    )
  }

  return (
    <div className={styles.wrapperManagement}>
      <DatePickerComp startDate={from} onChange={(date) => setDate('from', date)} label='From Date' noMinDate={true} hideTime={true}/>
      <DatePickerComp startDate={to} onChange={(date) => setDate('to', date)} label='To Date' noMinDate={true} hideTime={true}/>
      <Input type='text' onChange={handleTextChange} value={text} placeholder={t('Enter username or email')} label={t('Search text')}/>
      <Button btnClass='btnFormGreen' label={'Load'} onClick={() => { getData() }} />
      <div>
        {renderTransactions()}
      </div>
    </div>);
};

export default ConnectedTransactions;