import { imageConstants } from '../constants';
import { sessionService } from '../../_sessionService/storage';
import imageService from '../../_services/image.service';
import { history } from '../../_utils';

const IN_PROGRESS = {}

const pushImage = (name, data) => { return { type: imageConstants.IMG_STORAGE, name, data } };

const getImage = (imgName, width, height) => {
  const storage = (name, data) => { return { type: imageConstants.IMG_STORAGE, name, data } };
  const request = () => { return { type: imageConstants.IMG_REQUEST } }
  const success = (name, data) => { return { type: imageConstants.IMG_SUCCESS, name, data } };
  const failure = (error) => { return { type: imageConstants.IMG_FAILURE, error } }
  return dispatch => {
    dispatch(request())
    const fromStorage = sessionService.getImg(imgName);
    if (fromStorage) {
      dispatch(storage(imgName, fromStorage))
    } else {
      if (!IN_PROGRESS[`${imgName}_${height}_${width}`])
      IN_PROGRESS[`${imgName}_${height}_${width}`] = true
      return imageService.getImage(width, height, imgName)
        .then(
          res => {
            if (res) {
              delete IN_PROGRESS[`${imgName}_${height}_${width}`]
              dispatch(success(res.name, res));
              sessionService.set(res.name, res.data);
              sessionService.setSessionData({ crc: res.crc, dataId: res.dataId, name: res.name }, sessionService.getSessionData().lang, sessionService.getSessionData().siteReference);
            }
            return res;
          },
          error => {
            delete IN_PROGRESS[`${imgName}_${height}_${width}`]
            dispatch(failure(error));
          }
        )
    }
  };
};

const uploadImage = (name, width, height, data) => {
  const request = () => { return { type: imageConstants.IMG_UPLOAD_REQUEST }; };
  const success = () => { return { type: imageConstants.IMG_UPLOAD_SUCCESS }; };
  const failure = (error) => { return { type: imageConstants.IMG_UPLOAD_FAILURE, error }; };
  return dispatch => {
    dispatch(request());
    imageService.upload(data)
      .then(
        res => {
          dispatch(success(res.name, res));
          sessionService.destroy(name);
          dispatch(getImage(name, width, height));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };
};

const deleteImage = (title, dataId) => {
  const request = () => { return { type: imageConstants.IMG_DELETE_REQUEST }; };
  const success = (name) => { return { type: imageConstants.IMG_DELETE_SUCCESS, name }; };
  const failure = (error) => { return { type: imageConstants.IMG_DELETE_FAILURE, error }; };
  return dispatch => {
    dispatch(request());
    imageService.deleteImage(title, dataId)
      .then(
        res => {
          dispatch(success(`${dataId}_${title}`, res));
          sessionService.destroy(`${dataId}_${title}`);
        },
        error => {
          dispatch(failure(error));
        }
      );
  };
};

export const imageActions = {
  getImage,
  uploadImage,
  pushImage,
  deleteImage
};
