import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { isFunction } from "lodash";

import { displayDuration } from "../../_utils";
import { CONTEST_PLAYER_PROPS } from "../../_utils/constants";

import styles from "./playerPropCard.module.scss";

const PlayerPropCard = ({
  playerPick,
  player,
  action,
  value,
  image,
  isDefaultImage,
  logo,
  boosted,
  from,
  selected,
  playerPropsId,
  match,
  game,
  name,
  canceled,
  onClick,
}) => {
  const [t] = useTranslation();
  const { nick, team } = player;
  const [duration, setDuration] = useState(
    moment.duration(from - new Date().getTime())
  );
  useEffect(() => {
    const timer = setInterval(() => {
      const interval = moment.duration(from - new Date().getTime());
      setDuration(interval);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const handleOnClick = (bet) => {
    if ((!onClick && !isFunction(onClick)) || canceled) return;
    onClick(playerPick, bet);
  };

  return (
    <article
      style={{ borderColor: boosted ? "#ff9900" : "white" }}
      className={
        boosted
          ? `${styles.cardWrapper} ${styles.boostedCard}`
          : styles.cardWrapper
      }
    >
      {canceled ? (
        <div className={styles.canceledOverlay}>
          <span>Canceled</span>
        </div>
      ) : null}
      <div
        className={styles.playerImage}
        // style={{
        //   backgroundImage: `linear-gradient(to top, rgba(51, 51, 51, 0.8) 0%, rgba(51, 51, 51, 0.5) 10%, rgba(51, 51, 51, 0) 32%),
        //   url(${image})`,
        // }}
      >
        <img
          src={image}
          alt=""
          className={isDefaultImage ? styles.noImage : styles.image}
        />
        {logo && <img className={styles.teamLogo} src={logo} alt="" />}
        {/* <span
          style={{ backgroundImage: `url(${logo})` }}
          className={styles.teamLogo}
        ></span> */}
        <span className={selected ? styles.selected : ""}></span>
        {boosted ? (
          <span className={styles.boosted}>
            <span>Boosted</span>
          </span>
        ) : null}
      </div>
      <div className={styles.cardBody}>
        <div className={styles.playerName}>
          <span className={styles.nameLabel}>{t("d2582874")}:</span>
          <div className={styles.name}>
            <span>{nick}</span>
          </div>
          <div className={styles.teamDiv}>
            <span className={styles.team}>{team}</span>
          </div>
          <span className={styles.playerPropName}>
            {name && name.length && name.length > 40
              ? name.substring(0, 40) + "..."
              : name
              ? name
              : ""}
          </span>
        </div>
        <div className={styles.killsBet}>
          <div className={styles.actionWrapper}>
            <div className={styles.action}>
              <span className={styles.actionValue}>{value}</span>
              <span className={styles.actionLabel}>{action}</span>
            </div>
            <div className={styles.arrowWrapper}>
              <div
                className={
                  selected === CONTEST_PLAYER_PROPS.BET_TYPES.OVER
                    ? styles.overSelected
                    : styles.over
                }
                onClick={() =>
                  handleOnClick(CONTEST_PLAYER_PROPS.BET_TYPES.OVER)
                }
              >
                <span className={styles.arrowUp}></span>
                <span className={styles.overLabel}>Over</span>
              </div>
              <div
                className={
                  selected === CONTEST_PLAYER_PROPS.BET_TYPES.UNDER
                    ? styles.underSelected
                    : styles.under
                }
                onClick={() =>
                  handleOnClick(CONTEST_PLAYER_PROPS.BET_TYPES.UNDER)
                }
              >
                <span>Under</span>
                <span className={styles.arrowDown}></span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.nextEvent}>
          <p className={styles.nextEventLabel}>Next event:</p>
          <p>{from ? moment(from).format("MM/DD/YYYY") : ""}</p>
        </div>
        <div className={styles.time}>
          <span className={styles.duration}>{displayDuration(duration)}</span>
        </div>
      </div>
    </article>
  );
};

PlayerPropCard.propTypes = {
  player: PropTypes.object.isRequired,
  action: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  image: PropTypes.string,
  logo: PropTypes.string,
  boosted: PropTypes.bool,
  selcted: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

export default PlayerPropCard;
