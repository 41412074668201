import React, { useState, useEffect } from 'react';
import Button from '../../../_components/button/button';
import mainService from '../../../_services/main.service';
import toastService from '../../../_services/toastService';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import styles from './EventResult.module.scss';

const _getType = (type) => {
  return type
}

const EventResult = ({ fields = {}, selectData = {}, error, onChange }) => {
  const [t] = useTranslation();
  const [playerActions, setPlayerActions] = useState({});
  const [clonedPlayerActions, setClonedPlayerActions] = useState({});
  const [editVisibility, setEditVisibility] = useState(-1);
  const [players, setPlayers] = useState([])
  const [actions, setActions] = useState([]);
  const [editData, setEditData] = useState({});
  const [updating, setUpdating] = useState(false);

  useEffect(()=> {
    if (fields.id) {
      getData()
    }
  }, [fields.id])

  useEffect(() => {
    if (selectData['data-game'] && fields.game) {
      const game = selectData['data-game'].find(one => one.name == fields.game)
      if (game) {
        setActions(Object.keys(game.rules))
      }
    }
  }, [selectData['data-game'], fields.game])

  const editFinalData = async (playerId) => {
    setEditVisibility(playerId);
    setEditData(playerActions[playerId]);
  };

  const getData = async () => {
    if (fields.id) {
      const data = await mainService.run('data', 'match', 'events', { id: fields.id });
      const { players = [], events = [] } = data
      setPlayers(players)
      const playerActions = {}
      events.forEach(one => {
        let playerData = playerActions[one.userId]
        if (!playerData) {
          playerData = {}
          playerActions[one.userId] = playerData
        }
        if (!playerData[one.action]) playerData[one.action] = 1
        else playerData[one.action]++
      })

      players.forEach(one => {
        if (!playerActions[one.id]) playerActions[one.id] = {}
      })

      setPlayerActions(playerActions)
      setClonedPlayerActions(_.cloneDeep(playerActions))
    }
  }

  const updateFinalData = async (player) => {
    if (!player) return setEditVisibility(-1);
    setUpdating(true)
    try {
      const response = mainService.run('events', 'game', 'correction', {
        playerId: player.id,
        game: fields.game,
        matchId: fields.id,
        actions: {...editData}
      });
      const playerData = playerActions[player.id]
      Object.keys(editData).forEach(key => playerData[key] = editData[key])
      setPlayerActions({...playerActions})
      toastService.show('success', t('d41166397'));
      if (response) setEditVisibility(-1);
    } catch (err){
      // console.log(err)
    } finally {
      setUpdating(false)
    }
  };

  const handleEditInput = (e, action, playerId) => {
    const { value } = e.target;
    setEditData(prev => ({
      ...prev, [action]: +value
    }));
    setClonedPlayerActions(prev => {
      prev[playerId][action] = +value;
      return prev;
    });

    setActions([...actions]);
  }

  const close = async () => {
    setEditVisibility(-1);
    setClonedPlayerActions(_.cloneDeep(playerActions));
    setEditData({});
  };

  return (
    <div key={'eventResult' } className={styles.publishWrapper}>
    { fields.id ?
        <div className={styles.tbList}>
          <div className={styles.match}>
            <ul className={styles.head}>
              <li><span> {t('d2582874')}</span></li>
              {actions &&
                <> {actions.map((action, i) => (
                  <li key={i}>
                    <span>{action}</span>
                  </li>
                ))}
                </>}
            </ul>
            <ul className={styles.body}>
              {players.map((player, i) => (
                <li key={i}>
                  <span>{player.nick}</span>
                  {actions.map((key, i) => (
                    <div className={styles.data} key={i}>
                      {editVisibility === player.id ? <input type="text" value={clonedPlayerActions[player.id][key] || 0} name={`${key}-${player.id}`} id={`${key}-${player.id}`} onChange={(e) => { handleEditInput(e, key, player.id); }} /> :
                        <span key={i}>{playerActions[player.id] ? playerActions[player.id][key] || 0 : 0} </span>}
                    </div>
                  ))}
                  {editVisibility === player.id ? <> <Button btnClass='btnFormGreen' label={t('d58938009')} loading={updating} onClick={() => { updateFinalData(player); }} />
                    <Button btnClass='btnFormPurple' label={t('d465751263')} loading={updating} onClick={() => { close(); }} /> </> :
                    <><Button btnClass='btnFormGreen' label={t('d323691769')} onClick={() => { editFinalData(player.id); }} />
                    </>}
                </li>
              ))}
            </ul>
          </div>
        </div>
       : null }
    </div>
  )
};

export default EventResult;