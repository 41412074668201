import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { imageActions } from '../../../_store/actions/image.actions';
import gamePlaceholder from '../../../_assets/images/card_placeholder.jpg';

const ImagePreview = ({ name, id }) => {
  const store = useSelector(state => state);
  const dispatch = useDispatch();
  const [image, setImage] = useState('');
  useEffect(() => {
    fetchData();
  }, [id]);
  useEffect(() => {
    if (store.image && store.image[id + '_' + name]) {
      setImage(store.image[id + '_' + name]);
    } else {
      setImage('')
    }
  }, [store.image && store.image[id + '_' + name]]);
  const fetchData = async () => {
    await dispatch(imageActions.getImage(id + '_' + name, 400, 200));
  };
  return <div><img src={image ? image : gamePlaceholder} alt="img" /></div>;
};

export default ImagePreview;