import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Input from '../input/input';
import Button from '../button/button';
import SelectInput from './components/selectInput';
import ImagePreview from './components/imagePreview';
import KeySelect from './components/keySelect';
import KeyValue from './components/keyValue';
import PlayerPropSelect from './components/PlayerPropSelect';
import CheckList from './components/CheckList';
import Transactions from './components/Transactions';
import EventResult from './components/EventResult';
import Checkbox from '../checkbox/checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import toastService from '../../_services/toastService';
import DatePickerComp from '../../_components/datePicker/datePickerComp';
import DatePickerBirth from '../../_components/datePicker/datePickerBirth';
import styles from './dynamicForm.module.scss';
import { imageActions } from '../../_store/actions/image.actions';
import { mainActions } from '../../_store/actions/main.actions';
import mainService from '../../_services/main.service';
import { objToArray, arrayToObj, isObjectEmpty, getMapingValue, checkConditions, pullFieldsValue } from '../../_utils';
import Download from '../download/download';
import { hook } from '../../processing'
import ModalYesNo from '../modal/modalYesNo';
// import i18n from '../../i18n';
// import { forms } from '../../_store/reducers/forms.reducer';

const DynamicForm = ({ history, onSubmit, form, formMode, editData, isImport }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [fields, setFields] = useState({});
  const [selectData, setSelectData] = useState({});
  const [actionInProgress, setActionsInProgress] = useState({});
  const [customAction, setCustomAction] = useState();
  const [file, setFile] = useState({});
  const [calledPrePull, setCalledPrePull] = useState(false);
  const [prePullData, setPrePullData] = useState();
  const [error, setError] = useState(false);
  const [errorDate, setErrorDate] = useState(false);
  const [imageBeforeUpload, setImageBeforeUpload] = useState({});
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [modalData, setModalData] = useState([]);

  const store = useSelector(state => state);

  const prePullCall = async () => {
    if (form.prePull && !calledPrePull) {
      setCalledPrePull(true)
      const result = await mainService.run(form.prePull.collection, form.prePull.type, form.prePull.mode)
      setPrePullData(result)
    }
  }

  useEffect(() => {
    prePullCall()
  }, [form])

  useEffect(() => {
    if (customAction) {
      handleCustomButton(customAction)
    }
  }, [customAction])

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    value.trim()
    if (hook[form.type]) hook[form.type](name, value, fields, selectData)
    setFields(fields => ({ ...fields, [name]: (type === 'number') ? +value : value }));
  };
  const handleSubmit = async (e) => {

    e.preventDefault();
    if (validateForm(fields, form)) {
      let response;
      const data = { ...fields };
      Object.keys(data).map(k => { if (typeof data[k] === 'string') { data[k] = data[k].trim() } });
      if (form && form.type && form.type === 'contest') {
        if (!data.publish) {
          toastService.show('warning', t('d40661135'));
        }
      }
      if (!isImport) {
        if (!fields.id) {
          response = await dispatch(mainActions.run(form.collection, form.type, 'new', data, t('d40747639')));
        } else {
          response = await dispatch(mainActions.run(form.collection, form.type, 'update', data, t('d41166397')));
        }
        let dataId = getMapingValue(response && response.id ? response : editData, form.mapField || 'id');
        if (dataId && !isObjectEmpty(file)) {
          dataId = dataId.replace(/\s+/g, '_')
          Object.keys(file).forEach(key => {
            file[key].append('dataId', dataId);
            dispatch(imageActions.uploadImage(`${dataId}_${key}`, 200, 200, file[key]));
          });
          setFile({});
        }
        if (response) {
          if (onSubmit) onSubmit(!fields.id ? 'add' : 'edit');
          setFields({});
          setImageBeforeUpload({});
          setFile({});
        }
      } else {
        if (onSubmit) onSubmit(fields);
      }
    } else {
      setError(true);
    }
  };
  useEffect(() => {
    if (form && form.fields && isObjectEmpty(fields)) {
      const checkedFields = { ...(editData || {}) }
      form.fields.forEach(({ type, dbname, initialValue }) => {
        if (type === 'date' || type === 'dateBirth') {
          if (!checkedFields[dbname]) checkedFields[dbname] = Date.now();
        }
        if (!checkedFields[dbname] && initialValue) checkedFields[dbname] = initialValue
      });
      if (isImport && editData && !isObjectEmpty(editData)){
        validateForm(checkedFields, form)
      }
      setFields(checkedFields);
    }
  }, [form, onSubmit]);

  const onChangeDate = (date, name) => {
    setErrorDate(false);
    setFields(prevState => {
      return {
        ...prevState,
        [name]: moment(date).valueOf()
      };
    });
  };

  const onFileChange = (e, name) => {
    const file = e.target.files[0];
    const data = new FormData();
    data.append('file', file);
    data.append('title', name);

    setFile(prevFile => ({ ...prevFile, [name]: data }));
    if (file) {
      setImageBeforeUpload((prevState) => ({ ...prevState, [name]: URL.createObjectURL(file) }));
    }
  };

  const saveKeySelect = (dbname, value) => {
    setFields(fields => ({ ...fields, [dbname]: value }));
  }

  const pullFields = (obj, field) => {
    if (obj && field.pullMap) {
      return pullFieldsValue(obj, field.pullMap)
    }
    return {}
  }

  const selectResponse = (value, obj, dbName, field) => {
    const pulledFields = pullFields(obj, field)
    const depedentValues = {}
    const depedentFields = form.fields.filter(one => one.selectFilter === field.dbname)
    if (value !== fields[dbName]){
      depedentFields.forEach(one => depedentValues[one.dbname] = (one.type === 'multiselect' ? [] : null))
    }

    setFields(fields => ({ ...fields, ...pulledFields, ...depedentValues, [dbName]: value }));
  };

  const validateForm = (fields, form) => {
    const requiredMap = {};
    let isValid = true;
    form.fields.forEach(el => {
      if (el.required) requiredMap[el.dbname] = el.required;
      if (el.type === 'date' && form.type === 'contest') {
        if ((fields.from && fields.to) && fields.to < fields.from) {
          isValid = false;
        } else {
          isValid = true;
        }
      } else if (el.required && (el.type === 'keyValue' || el.type === 'keySelect')) {
        const obj = fields[el.dbname]
        if (!obj) isValid = false
        else {
          Object.keys(obj).forEach(key => isValid = key && isValid && obj[key])
        }
        if (!isValid) {
          setError(false)
          return false
        }
      }
    });
    if (isValid) {
      const arrValid = Object.entries(requiredMap).map(([key]) => {
        return checkField(fields, key);
      });
      isValid = arrValid.every(v => v === true);
      if (isValid) { setError(false); } else { setError(true); }
      return isValid;
    } else {
      setErrorDate(true);
      return isValid;
    }
  };

  const checkField = (fields, fieldKey) => {
    let isValid;
    const validArr = Object.keys(fields).some(key => {
      if (fieldKey === key) {
        if (Array.isArray(fields[key])) {
          fields[key].forEach(obj => {
            const valid = !Object.keys(obj).some(x => (obj[x] === null || obj[x] === '' || (Array.isArray(obj) && obj.length === 0)));
            isValid = valid;
            return isValid;
          });
        } else {
          const valid = !(fields[key] === null || fields[key] === '');
          isValid = valid;
          return isValid;
        }
      }
      return isValid;
    });
    return validArr;
  };

  const handleCustomButton = async (buttonData) => {
    if (buttonData) {
      try {
        await mainService.run(buttonData.collection, buttonData.type, buttonData.mode, fields)
        if (buttonData.message) toastService.show('success', t(buttonData.message))
        if (buttonData.close) onSubmit()
      } catch (err) {

      } finally {
        setActionsInProgress(prev => {
          prev[buttonData.label] = false
          return prev
        })
        setCustomAction(null)
      }
    }
  }

  const _renderCustomButtons = () => {
    return (form.buttons || []).map(one => {
      if (_isVisibleCondition(one, fields)) {
        return (<Button btnClass={one.btnClass || 'btnPrimary'} loading={actionInProgress[one.label]} label={t(one.label)} onClick={e => {
          setActionsInProgress(prev => {
            prev[one.label] = true
            return prev
          })
          setCustomAction(one)

        }}/>)
      }
    })
  }

  const onCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFields(fields => ({ ...fields, [name]: checked }));
  };

  const _getImageId = (obj, map) => {
    let imageId = getMapingValue(fields, form.mapField || 'id')
    if (!imageId) return
    return imageId.replace(/\s+/g, '_')
  }

  const selectDataReceived = (dataName, type, response) => {
    selectData[dataName+'-'+type] = response
    setSelectData(selectData)
  }
  const copyToClipboard = (fieldValue) => {
    toastService.show('success', t('d62575158'))
  }

  const deleteFile = async (title) => {
    dispatch(imageActions.deleteImage(title, editData.id))
    toastService.show('success', t('d65237007'))
  }

  const openDeleteModal = async (e, title) => {
    e.preventDefault();
    setShowModalDelete(true);
    setModalData({ title });
  };

  const handleDelete = async (answer, data) => {
    if (answer === t('d13586174')) {
      deleteFile(data.title)
      setShowModalDelete(false);
    } else {
      setShowModalDelete(false);
    }
  };

  const proccessShowIf = () => {

  }

  const _isVisibleCondition = (field, fields) => {
    if (!field.showIf) return true
    let show = true
    let showIfs = []
    if (!Array.isArray(field.showIf)) showIfs.push(field.showIf)
    else showIfs = field.showIf
    showIfs.forEach(showIf => {
      show = show && checkConditions(getMapingValue(fields, showIf.field), showIf.operation, showIf.value)
    })
    return show
  }

  return (
    <form className={styles.dynamicForm}>
      {showModalDelete && <ModalYesNo show={showModalDelete} handleYesNoAnswer={handleDelete} modalData={modalData} question='d54856307' />}
      {form.fields.map((field, i) => {
        if (field.hidden || !_isVisibleCondition(field, fields)){
          return null
        } else if (field.type === 'labelSeparator') {
          return <div key={i} style={{ display: 'block' }}><p className={styles.title}>{t(field.label)}</p></div>;
        } else if (field.type === 'text' || field.type === 'number' || field.type === 'link') {
          return <div key={i} style={{ display: field.editHidden && !isObjectEmpty(editData) ? 'none' : 'block' }} >
            <Input data-tip='Testing' type={field.type} name={field.dbname} desc={t(field.description)} onChange={handleInputChange} value={fields[field.dbname]} placeholder={t(field.label)} label={t(field.label)} disabled={field.disabled} onCopy={copyToClipboard}/>
            {error && (!fields[field.dbname] && (fields[field.dbname] !== 0)) && field.required && <div className="input-err-msg">{t('d621915485')} {t(field.label)}</div>}
          </div>;
        } else if (field.type === 'textarea') {
          return <div key={i} style={{ display: field.editHidden && !isObjectEmpty(editData) ? 'none' : 'block' }}>
            <label className={styles.textAreaLabel}> {t(field.label)} </label>
            <textarea className={styles.textarea} type={field.type} name={field.dbname} onChange={handleInputChange} value={fields[field.dbname]} placeholder={t(field.label)} />
            {error && (!fields[field.dbname] && (fields[field.dbname] !== 0)) && field.required && <div className="input-err-msg">{t('d621915485')} {t(field.label)}</div>}
          </div>;
        } else if (field.type === 'checkbox') {
          return <div key={i} className={styles.checkbox} style={{ display: field.editHidden && !isObjectEmpty(editData) ? 'none' : 'block' }}>
            <Checkbox checkboxClass="checkboxLoginClass"
              name={field.dbname}
              label={t(field.label)}
              isSelected={fields[field.dbname] != undefined ? fields[field.dbname] : field.defaultValue}
              onChange={onCheckboxChange}
              desc={t(field.description)}
            />
          </div>;
        } else if (field.type === 'keySelect') {
          return <KeySelect key={"keySelect-"+i+"-"+field.dbname} field={field} fields={fields} onChange={saveKeySelect} error={error}/>
        } else if (field.type === 'keyValue') {
          return <KeyValue key={"keyValue-"+i+"-"+field.dbname} field={field} fields={fields} onChange={saveKeySelect} error={error}/>
        } else if (field.type === 'playerPropSelect') {
          return <PlayerPropSelect key={"PlayerPropSelect-"+i+"-"+field.dbname}  selectData={selectData} prePullData={prePullData} field={field} fields={fields} onChange={saveKeySelect} error={error}/>
        } else if (field.type === 'eventResult') {
          return <EventResult key={"EventResult-"+i+"-"+field.dbname}  selectData={selectData} prePullData={prePullData} field={field} fields={fields} onChange={saveKeySelect} error={error}/>
        } else if (field.type === 'checkList') {
          return <CheckList key={"CheckList-"+i+"-"+field.dbname} field={field} fields={fields} onChange={saveKeySelect} error={error}/>
        } else if (field.type === 'label') {
          return <div key={i} style={{ display: field.editHidden && !isObjectEmpty(editData) ? 'none' : 'block' }}><p className={styles.title}>{fields[field.dbname]}</p></div>;
        } else if (field.type === 'select' || field.type === 'multiselect') {
          return <div key={i} style={{ display: field.editHidden && !isObjectEmpty(editData) ? 'none' : 'block' }}><SelectInput
            history={history}
            type={field.dataType}
            dataName={field.dataName}
            dbName={field.dbname}
            defaultValue={field.defaultValue}
            selectLabel={field.selectLabel}
            selectField={field.selectField}
            selectDataReceived={selectDataReceived}
            label={t(field.label)}
            onSelectChange={selectResponse}
            selected={fields[field.dbname] ? fields[field.dbname] : null}
            fields={fields}
            field={field}
            multiselect={field.type === 'multiselect'} />
            {error && (!fields[field.dbname] || (Array.isArray(fields[field.dbname]) && fields[field.dbname].length ===0)) && field.required && <div className="input-err-msg">{t('d621915485')} {t(field.label)}</div>}
          </div>;
        } else if (field.type === 'date') {
          return <div key={i} style={{ display: field.editHidden && !isObjectEmpty(editData) ? 'none' : 'block' }}><DatePickerComp startDate={(fields[field.dbname]) ? new Date(fields[field.dbname]) : Date.now()}
            onChange={(date) => onChangeDate(date, field.dbname)} name={field.dbname} key={field.dbname} label={t(field.label)} />
            {error && (!fields[field.dbname] && (fields[field.dbname] !== 0)) && field.required && <div className="input-err-msg">{t('d621915485')} {t(field.label)}</div>}
            {errorDate && field.dbname === 'to' && <div className="input-err-msg">{t('d74551381')}</div>}
          </div>
        } else if (field.type === 'dateBirth') {
          return <div key={i} style={{ display: field.editHidden && !isObjectEmpty(editData) ? 'none' : 'block' }}><DatePickerBirth manualMinDate={false} startDate={(fields[field.dbname]) ? new Date(fields[field.dbname]) : Date.now()}
            onChange={(date) => onChangeDate(date, field.dbname)} name={field.dbname} key={field.dbname} label={t(field.label)} />
            {error && (!fields[field.dbname] && (fields[field.dbname] !== 0)) && field.required && <div className="input-err-msg">{t('d621915485')} {t(field.label)}</div>}
          </div>
        } else if (field.type === 'file') {
          return <div key={i} className={styles.fileUpload} style={{ display: field.editHidden && !isObjectEmpty(editData) ? 'none' : 'block' }}>
            <label>{t(field.label)}</label>
            <div className={styles.imageDiv}>
              <input type="file" onChange={(e) => { onFileChange(e, field.dbname) }} />
              {imageBeforeUpload[field.dbname] && <img src={imageBeforeUpload[field.dbname]} alt="Upload box" />}
              {fields['id'] && <ImagePreview name={field.dbname} id={_getImageId(fields, form.mapField || 'id')} /> }
              {store.image && store.image[fields?.id + '_' + field.dbname] && <Button btnClass='btnSecondary' label={<i className="fa fa-trash-o" aria-hidden="true"></i>} onClick={(e) => { openDeleteModal(e,field.dbname) }}/> }

            </div>
          </div>;
        } else if (field.type === 'transactions') {
          return <Transactions fields={fields}/>
        } else if (field.type === 'download' && fields.id) {
          const emailTemplate = form.fields.find(obj => {
            return obj.type === 'emailTemplate';
          });
          return <div key={i}>
            {emailTemplate ? <Download userId={fields.id} emailTemplate={emailTemplate} /> : <Download userId={fields.id} />}
          </div>;
        }
      })}

      { formMode !== 'view' ? <div className={styles.buttons}>
        <Button btnClass='btnPrimary' label={t('d33545245')} loading={actionInProgress['main']} onClick={handleSubmit} />
        {_renderCustomButtons()}
      </div> : null }
    </form>
  );
};
export default DynamicForm;